import React, {useContext, useEffect, useRef, useState} from "react";
import styles from "./SelectView.module.css";
import {Button, Divider, Grid, IconButton, InputBase, MenuItem, Typography} from "@material-ui/core";
import {
    DeviceEsaData,
    Discipline,
    PowerStatus,
    Target as TargetType,
    WebSocketMessage,
} from "../../api/generated/esa/models";
import {Aim} from "../icons/generated/military/outlined";
import {translation, translationOrName, translationString, translationStringOrName} from "../i18n";
import Menu from "@material-ui/core/Menu";
import {Close, Search} from "@material-ui/icons";
import {useIntl} from "react-intl";
import {TrainingSessionControllerApi} from "../../api/generated/esa";
import {SelectContext} from "./SelectContextProvider";
import {Skeleton} from "@material-ui/lab";
import {TargetView} from "../target/TargetView";
import {AppContext} from "../AppContextProvider";
import {defaultDevice} from "../header/ConnectionStatusEsa";

const trainingSessionApi = new TrainingSessionControllerApi();

function TargetListItem(props: {target: TargetType}) {
    const {target} = props;
    const imageSize = 48;

    return (
        <Grid container>
            <Grid
                justify="center"
                alignItems="center"
                container
                style={{position: "relative", width: imageSize, height: imageSize, marginRight: 8}}>
                {target && <TargetView caliberMm={target.defaultCaliberMm} {...{target}} disableNumbers />}
            </Grid>

            <Grid>
                <Typography>{target.name && translationOrName("target", target.name)}</Typography>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{visibility: target.hitZones ? "hidden" : "visible"}}>
                    {translation("label.target.details_radius", {radius: target.targetRadius})}
                </Typography>
            </Grid>
        </Grid>
    );
}

export function SelectTarget() {
    const {discipline, selectedTargetObject, selectedWeapon, trafficPattern, saveToLocalStorage} = useContext(
        SelectContext,
    );

    const [device, setDevice] = useState<DeviceEsaData>(defaultDevice);
    const {
        userDevice: {deviceId},
        webSocket,
    } = useContext(AppContext);

    useEffect(() => {
        function messageListener(messageEvent: MessageEvent) {
            const json: WebSocketMessage = JSON.parse(messageEvent.data);
            const deviceUpd = json.deviceEsaData;

            if (deviceUpd && deviceUpd.id === deviceId) {
                setDevice(deviceUpd);
            }
        }

        webSocket.addEventListener("message", messageListener);
        return function cleanup() {
            webSocket.removeEventListener("message", messageListener);
        };
    }, [deviceId, webSocket, setDevice]);

    function onClick() {
        if (discipline && selectedTargetObject) {
            const params = {
                name: discipline,
                targetName: selectedTargetObject.name,
                weaponId: selectedWeapon?.id,
                phase: 0,
                trafficPattern: trafficPattern ?? undefined,
            };
            trainingSessionApi.select(params).then(saveToLocalStorage);
        }
    }

    return (
        <>
            <Button
                disabled={device.powerStatus === (PowerStatus.OFF || PowerStatus.UNKNOWN)}
                id="start_action"
                size="large"
                {...{onClick}}
                className={styles.startButton}
                startIcon={<Aim />}
                variant="contained"
                color="secondary">
                <b>{translation("button.select")}</b>
            </Button>
            <SelectTargetContent />
        </>
    );
}

export function SelectTargetContent() {
    const {
        disciplineTargets,
        discipline,
        selectedTarget,
        setSelectedTarget,
        selectedTargetObject,
        targets,
    } = useContext(SelectContext);
    const targetContainer = useRef<HTMLDivElement | null>(null);
    const target = selectedTargetObject;
    const [showTargetList, setShowTargetList] = useState(false);
    const targetListAnchor = useRef(null);
    const [filter, setFilter] = useState("");
    const intl = useIntl();
    const selectedDiscipline: Discipline = disciplineTargets.filter(it => it.name === discipline)[0];
    const changeable = selectedDiscipline?.targets.length <= 1;
    const skeletonSize = targetContainer.current
        ? Math.min(targetContainer.current.clientHeight, targetContainer.current.clientWidth)
        : 0;

    return (
        <Grid container style={{height: "100%", padding: 8}}>
            <Grid id="discipline_target_change" className={styles.targetOptionContainer} item>
                <Typography variant="h6" color={changeable ? "textSecondary" : "primary"}>
                    {translation("title.target.selected_target")}
                </Typography>
                {selectedTarget && (
                    <>
                        <Typography color="textSecondary">{translationOrName("target", selectedTarget)}</Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{visibility: target?.hitZones ? "hidden" : "visible"}}>
                            {translation("label.target.details_radius", {radius: selectedTargetObject?.targetRadius})}
                        </Typography>
                    </>
                )}
                <Button
                    id="select_target_action"
                    ref={targetListAnchor}
                    onClick={() => setShowTargetList(true)}
                    color="primary"
                    variant="outlined"
                    style={{marginTop: "var(--devSpacing2)"}}
                    disabled={changeable}>
                    {translation("button.change")}
                </Button>

                <Menu
                    id="target_list"
                    onClose={() => {
                        setShowTargetList(false);
                    }}
                    anchorEl={targetListAnchor.current}
                    keepMounted
                    open={showTargetList}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: `0 16px`,
                        }}>
                        <InputBase
                            id="target_search_input"
                            onChange={e => setFilter(e.target.value)}
                            autoFocus
                            placeholder={translationString(intl, "label.input.target.search_targets")}
                        />
                        <Search />
                        <IconButton
                            onClick={() => {
                                setShowTargetList(false);
                            }}>
                            <Close />
                        </IconButton>
                        <Divider orientation="vertical" />
                    </div>
                    <Divider />
                    {targets
                        .filter(it => {
                            return translationStringOrName(intl, "target", it.name)
                                .toLowerCase()
                                .includes(filter.toLowerCase());
                        })
                        .map(it => (
                            <MenuItem
                                key={it.name}
                                id={`target_${it.name}`}
                                onClick={() => {
                                    setSelectedTarget(it.name);
                                    setShowTargetList(false);
                                }}>
                                <TargetListItem target={it} />
                            </MenuItem>
                        ))}
                </Menu>
            </Grid>

            {target ? (
                <TargetView caliberMm={target.defaultCaliberMm} {...{target}} />
            ) : (
                <Skeleton
                    variant="circle"
                    style={{margin: "auto"}}
                    width={skeletonSize - 8}
                    height={skeletonSize - 8}
                />
            )}
        </Grid>
    );
}
