import {Button} from "@material-ui/core";
import React, {ChangeEvent, useContext} from "react";
import {SessionComment} from "../../../../api/generated/esa/models";
import {TrainingSessionControllerApi} from "../../../../api/generated/esa/apis";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "./Comment.module.css";
import {localizedTitle, translatedString, translation} from "../../../i18n";
import {useIntl} from "react-intl";
import {NotificationContext} from "../../../NotificationContextProvider";

const sessionApi = new TrainingSessionControllerApi();

export function create(sessionComment: SessionComment) {
    return sessionApi.createSessionComment({id: sessionComment.id, body: sessionComment.value});
}

export function SessionCommentDialog(props: {
    comment: SessionComment;
    setComment: Setter<SessionComment>;
    sessionId: string;
    open: boolean;
    onClose: () => void;
}) {
    const {comment, setComment, sessionId, onClose, open} = props;
    const {notify} = useContext(NotificationContext);
    const intl = useIntl();

    function remove() {
        if (!comment) return;
        sessionApi.removeSessionComment({id: sessionId}).then(() => {
            setComment({value: "", id: ""});
            onClose();
        });
    }

    function add() {
        if (!comment) return;
        create(comment).then(() => {
            notify("info", "message.comment_added");
            onClose();
        });
    }

    function onChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setComment({value: e.target.value, id: sessionId});
    }

    const exists = comment.value.length > 0;

    return (
        <>
            <Dialog {...{open, onClose}} maxWidth="lg">
                <DialogTitle className={styles.commentHead}>{localizedTitle("add_comment")}</DialogTitle>
                <DialogContent className={styles.commentContent}>
                    <TextField
                        id="outlined-multiline-static"
                        label={translatedString(intl, "label", "comment")}
                        {...{onChange}}
                        multiline
                        value={comment?.value}
                        rows={4}
                        variant="outlined"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={remove}
                        variant="outlined"
                        style={{
                            marginRight: "auto",
                            color: "var(--devPaletteErrorLight)",
                            borderColor: "var(--devPaletteErrorLight)",
                        }}>
                        {translation("button.remove")}
                    </Button>
                    <Button onClick={() => onClose()} color="primary">
                        {translation("button.cancel")}
                    </Button>
                    <Button onClick={add} disabled={!exists} variant="contained" color="primary">
                        {translation("button.update")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
