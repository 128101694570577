import {createMuiTheme} from "@material-ui/core/styles";
import {EnhancedTheme} from "./Theme";
import lightLogin from "../../resources/images/INTARSO-software-main-screen-light.svg";
import darkLogin from "../../resources/images/INTARSO-software-main-screen-dark.svg";
import {ReactComponent as LogoLight} from "../../resources/icons/packs/custom/filled/INTARSO-Logo-Light.svg";
import {ReactComponent as LogoDark} from "../../resources/icons/packs/custom/filled/INTARSO-Logo-Dark.svg";
import {NightsStay, WbSunny} from "@material-ui/icons";

//Predefined colour palette
const white = "#ffffff";

//Blues
// const USAFA_Light = "#b3cbdb";
const USAFA_Main = "#015287";
// const USAFA_Dark = "#01386a";

// const Prussian_Light = "#b7c0c5";
const Prussian_Main = "#102c3d";
const Prussian_Dark = "#091b27";

// const Beau_Light = "#ecf0f2";
// const Beau_Main = "#C1CCD5";
// const Beau_Dark = "#abb9c4";

// Oranges
// const Maximum_Light = "#fdeac4";
const Maximum_Main = "#f8b839";
// const Maximum_Dark = "#f5a024";

// const Marigold_Light = "#f9e3b6";
// const Marigold_Main = "#eba00a";
// const Marigold_Dark = "#e28405";

// const Banana_Light = "#fef5e2";
// const Banana_Main = "#fadf9e";
// const Banana_Dark = "#f8d282";

export const preLightTheme = createMuiTheme({
    palette: {
        type: "light",
        primary: {
            main: USAFA_Main,
            contrastText: white,
        },
        secondary: {
            main: Maximum_Main,
        },
        background: {
            paper: "#EDF4FA",
            default: "#C2CDD6",
        },
    },
});

export const LightTheme: EnhancedTheme = {
    ...preLightTheme,
    images: {
        applicationHeaderLogo: LogoDark,
        loginScreenSidebar: lightLogin,
        themeRepresentationIcon: WbSunny,
    },
    extendedPalette: {
        loginScreenSidebarBackground: "#EDF4FA",
    },
};

export const preDarkTheme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: Maximum_Main,
        },
        secondary: {
            main: USAFA_Main,
            contrastText: white,
        },
        background: {
            default: Prussian_Dark,
            paper: Prussian_Main,
        },
    },
});

export const DarkTheme: EnhancedTheme = {
    ...preDarkTheme,
    images: {
        applicationHeaderLogo: LogoLight,
        loginScreenSidebar: darkLogin,
        themeRepresentationIcon: NightsStay,
    },
    extendedPalette: {
        loginScreenSidebarBackground: Prussian_Dark,
    },
};
