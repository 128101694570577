import React from "react";
import {Grid, ListItemText} from "@material-ui/core";
import {translationOrName} from "../../i18n";
import {TrainingSession} from "../../../api/generated/esa";
import SessionDate from "./SessionDate";
import {Body1} from "../../common/TypographyVariants";
import {ReviewScore} from "./ReviewSessions";

export default function ReviewSessionsText(props: {children?: React.ReactNode; session: TrainingSession}) {
    const {children, session} = props;

    return (
        <ListItemText
            disableTypography
            key={session.id}
            primary={<Body1>{translationOrName("discipline", session.disciplineName)}</Body1>}
            secondary={
                <Grid container justify="flex-start">
                    <SessionDate startTime={session.startTime} />

                    <ReviewScore decimalScore={session.decimalScore} fullRingScore={session.fullRingScore} />
                    <span style={{marginLeft: "auto"}} />
                    {children}
                </Grid>
            }
        />
    );
}
