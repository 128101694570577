import React from "react";
import {Grid} from "@material-ui/core";
import {translation} from "../i18n";
import {Body1, H2, H5} from "../common/TypographyVariants";

export function SuperAdminPanel() {
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={{height: "100%"}}>
            <H2 color="primary">
                <b>{translation("title.sorry_admin")}</b>
            </H2>
            <H5>{translation("label.subtitle.sorry_admin")}</H5>
            <Body1 color="textSecondary"> {translation("message.sorry_admin")}</Body1>
        </Grid>
    );
}
