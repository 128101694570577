/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CompetitionClass
 */
export interface CompetitionClass {
    /**
     *
     * @type {Array<string>}
     * @memberof CompetitionClass
     */
    classes: Array<string>;
}

export function CompetitionClassFromJSON(json: any): CompetitionClass {
    return CompetitionClassFromJSONTyped(json, false);
}

export function CompetitionClassFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompetitionClass {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        classes: json["classes"],
    };
}

export function CompetitionClassToJSON(value?: CompetitionClass | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        classes: value.classes,
    };
}
