/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DeviceType {
    LightStandard = "LightStandard",
    LightInfrared = "LightInfrared",
    LightRGB = "LightRGB",
    LightRGBMuzzleFlash = "LightRGBMuzzleFlash",
    LightPolice = "LightPolice",
    MuzzleFlash = "MuzzleFlash",
    Audio = "Audio",
    PresentationSit = "PresentationSit",
    Mit = "Mit",
    SatLight = "SatLight",
    SatHeavy = "SatHeavy",
    Mat = "Mat",
    SitaPopup = "SitaPopup",
    SitaReverse = "SitaReverse",
    SitaBldc = "SitaBldc",
    SitaSwivel = "SitaSwivel",
    SitaSlider = "SitaSlider",
    SitaSlasherLeft = "SitaSlasherLeft",
    SitaSlasherRight = "SitaSlasherRight",
    SitSlasher = "SitSlasher",
    SitSlasherReverse = "SitSlasherReverse",
    SitaPopupRotate = "SitaPopupRotate",
    SitPopupRotate = "SitPopupRotate",
    SitaScissor = "SitaScissor",
    SitScissor = "SitScissor",
    Esa = "Esa",
    Sq17 = "Sq17",
    Sq55 = "Sq55",
    Sq80 = "Sq80",
    Sq120 = "Sq120",
    Sqxx3Sensor = "Sqxx3Sensor",
    Sqxx4Sensor = "Sqxx4Sensor",
    LomahSmall = "LomahSmall",
    LomahBig = "LomahBig",
    LomahChamber = "LomahChamber",
}

export function DeviceTypeFromJSON(json: any): DeviceType {
    return DeviceTypeFromJSONTyped(json, false);
}

export function DeviceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceType {
    return json as DeviceType;
}

export function DeviceTypeToJSON(value?: DeviceType | null): any {
    return value as any;
}
