import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    Competition,
    CompetitionControllerApi,
    CompetitionTitle,
    ConstantsNumberEsa,
    ConstantsStringEsa,
    EsaControllerApi,
    HelpControllerApi,
    Hit,
    HitControllerApi,
    Phase,
    TrainingSessionControllerApi,
    User,
    UserDevice,
    UsersControllerApi,
    WebSocketMessage,
    WebSocketMessageActionActionEnum,
} from "../../api/generated/esa";
import {AppContext, phasePlaceholder} from "../AppContextProvider";
import {translationString, translationStringOrName} from "../i18n";
import {getPhaseStatus, Status} from "../timer/Timer";
import {calculateAverageScore} from "../Score";
import {useIntl} from "react-intl";
import {isAdmin} from "../common/utils";

export type Lane = {
    id: number;
    phase: Phase;
    laneName: string;
    userName?: string;
    hits: Hit[];
    fp: string;
    totalScore?: number;
    averageScore?: string;
    competition?: string;
    countryCode?: string;
    shots?: string;
    status?: string;
    disciplineName?: string;
};

export interface SpectatorContextI {
    range: Lane[];
    setNewCompetition: Setter<Competition>;
    allDevices: UserDevice[];
    setFinals: Setter<boolean>;
    finals: boolean;
    spectatorControls: boolean;
    setSpectatorControls: Setter<boolean>;
    competitionIds: CompetitionTitle[];
    setCompetitionIds: Setter<CompetitionTitle[]>;
    setSelectedCompetitionId: Setter<CompetitionTitle>;
    competition: Competition;
    newCompetition: Competition;
    totalHits: number;
    lastHitDevice?: number;
    helpRequests: string[];
    setHelpRequests: Setter<string[]>;
}

export const ALPHABET = "abcdefghijklmnopqrstuvwxyz";
export const NEW_COMPETITION_ID = "new";
export const NEW_COMPETITION_TITLE: CompetitionTitle = {
    id: NEW_COMPETITION_ID,
    disciplineName: ConstantsStringEsa.NO_TRAINING_NAME,
    userCount: 0,
};

export const NEW_COMPETITION_EMPTY = {
    id: NEW_COMPETITION_ID,
    userIds: [],
    deviceIds: [],
    phases: [],
    deviceToPhase: {},
    phaseName: ConstantsStringEsa.NO_TRAINING_PHASE_NAME,
};

export const SpectatorContext = React.createContext<SpectatorContextI>({
    range: [],
    allDevices: [],
    setNewCompetition: () => {},
    setFinals: () => {},
    finals: false,
    setSpectatorControls: () => {},
    spectatorControls: true,
    competitionIds: [],
    setCompetitionIds: () => {},
    competition: NEW_COMPETITION_EMPTY,
    newCompetition: NEW_COMPETITION_EMPTY,
    setSelectedCompetitionId: () => {},
    totalHits: 0,
    lastHitDevice: undefined,
    helpRequests: [],
    setHelpRequests: () => {},
});

const esaSessionApi = new EsaControllerApi();
const trainingSessionControllerApi = new TrainingSessionControllerApi();
const competitionApi = new CompetitionControllerApi();
const hitControllerApi = new HitControllerApi();
const userControllerApi = new UsersControllerApi();
const helpControllerApi = new HelpControllerApi();

export default function SpectatorContextProvider(props: React.PropsWithChildren<any>) {
    const {children} = props;
    const {connected, webSocket, getServerTime, user} = useContext(AppContext);
    const [allDevices, setAllDevices] = useState<UserDevice[]>([]);
    const [finals, setFinals] = useState(true);
    const [spectatorControls, setSpectatorControls] = useState(true);
    const [allHits, setHits] = useState<Hit[]>([]);
    const [lastHitDevice, setLastHitDevice] = useState<number | undefined>();
    const [selectedCompetition, setSelectedCompetitionId] = useState<CompetitionTitle>(NEW_COMPETITION_TITLE);
    const [competitionIds, setCompetitionIds] = useState<CompetitionTitle[]>([]);
    const [currentCompetition, setCurrentCompetition] = useState<Competition>(NEW_COMPETITION_EMPTY);
    const [newCompetition, setNewCompetition] = useState<Competition>(NEW_COMPETITION_EMPTY);
    const [users, setUsers] = useState<User[]>([]);
    const [helpRequests, setHelpRequests] = useState<string[]>([]);
    const intl = useIntl();
    const competition = selectedCompetition.id === NEW_COMPETITION_ID ? newCompetition : currentCompetition;
    const setCompetition = selectedCompetition.id === NEW_COMPETITION_ID ? setNewCompetition : setCurrentCompetition;

    const range: Lane[] = useMemo(
        () =>
            Object.values(competition.deviceToPhase)
                .map((phase, n) => {
                    const disciplineSelected = phase.disciplineName !== ConstantsStringEsa.NO_TRAINING_NAME;
                    const phaseStatus = disciplineSelected ? getPhaseStatus(getServerTime(), phase) : undefined;

                    const device = allDevices.find(d => d.deviceId === phase.deviceId);
                    const userName = getUserName(users, phase, phaseStatus, device);

                    const common = {
                        id: n + 1,
                        laneName: device?.deviceAlias ?? translationString(intl, "label.disconnected"),
                        userName,
                        competition: phase.competitionId,
                        countryCode: device?.countryCode,
                        hits: allHits.filter(hits => hits.phaseId === phase.id),
                        phase,
                        fp: ALPHABET[n] ?? "",
                    };

                    if (disciplineSelected) {
                        const disciplineName = translationStringOrName(intl, "discipline", phase.disciplineName);
                        const shots = `${phase.currentShots ?? "0"}${
                            phase.maxShots < ConstantsNumberEsa.MAX_SHOTS_PER_PHASE ? "/" + phase.maxShots : ""
                        }`;
                        const status = translationStringOrName(
                            intl,
                            "component.timer.status",
                            phaseStatus ?? "unlimited",
                        );
                        const totalScore = phase.fullRingScore ? phase.fullRingScore : phase.decimalScore;
                        const averageScore = calculateAverageScore(totalScore, phase.currentShots);

                        return {
                            ...common,
                            totalScore,
                            averageScore,
                            shots,
                            status,
                            disciplineName,
                        };
                    } else {
                        return common;
                    }
                })
                .sort((p1, p2) => (p1.id > p2.id ? 0 : 1)),
        [competition.deviceToPhase, getServerTime, allDevices, users, intl, allHits],
    );

    const totalHits = allHits.length;

    useEffect(() => {
        if (finals) {
            if (spectatorControls) {
                window.location.href = "#spectate-controls";
            } else {
                window.location.href = "#spectate";
            }
        } else {
            if (spectatorControls) {
                window.location.href = "#qualification-controls";
            } else {
                window.location.href = "#qualification";
            }
        }
    }, [finals, spectatorControls]);

    useEffect(() => {
        if (connected) {
            competitionApi.activeCompetitions().then(setCompetitionIds);
            userControllerApi.getAllUsers({}).then(setUsers);
            esaSessionApi.getUserDevices().then(setAllDevices);
            helpControllerApi.getHelpMessages().then(setHelpRequests);
        }
    }, [connected, webSocket]);

    useEffect(() => {
        setLastHitDevice(undefined);
        if (selectedCompetition.id !== NEW_COMPETITION_ID) {
            competitionApi
                .getCompetition({id: selectedCompetition.id, phaseName: selectedCompetition.phaseName})
                .then(c => {
                    setCompetition(c);
                    setCompetitionIds(old => {
                        const exists =
                            old.findIndex(
                                it =>
                                    it.id === selectedCompetition.id &&
                                    (it.phaseName === undefined || it.phaseName === selectedCompetition.phaseName),
                            ) !== -1;

                        const updated = old.map(it =>
                            it.id === selectedCompetition.id ? {...it, userCount: c.userIds.length} : it,
                        );

                        return exists ? updated : [...updated, selectedCompetition];
                    });

                    const phase = Object.values(c.deviceToPhase).map(it => it.id);
                    return hitControllerApi.getHits({phase});
                })
                .then(setHits);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompetition.id, selectedCompetition.phaseName, setCompetitionIds]);

    useEffect(
        () => {
            const deviceIds = newCompetition.deviceIds;
            const userIds = allDevices
                .filter(it => deviceIds.includes(it.deviceId) && it.userId)
                .map(it => it.userId ?? "none");
            const existingDevices = Object.keys(newCompetition.deviceToPhase);
            const newDeviceIds = newCompetition.deviceIds.filter(it => !existingDevices.includes(it.toString()));
            const newDevices = allDevices.filter(it => newDeviceIds.includes(it.deviceId));
            const getPhasesForNewDevices = newDevices.map((it, i) => {
                if (it?.userId) {
                    return trainingSessionControllerApi.getDiscipline({userId: it.userId});
                } else {
                    return Promise.resolve({...phasePlaceholder, id: `none${i}`, deviceId: it.deviceId});
                }
            });

            Promise.all(getPhasesForNewDevices).then(newPhases =>
                setNewCompetition(old => {
                    const deviceToPhase: {[key: string]: Phase} = Object.fromEntries(
                        Object.entries(old.deviceToPhase).filter(([, phase]) => deviceIds.includes(phase.deviceId)),
                    );

                    newPhases.forEach(p => {
                        deviceToPhase[p.deviceId] = p;
                    });

                    return {
                        id: old.id,
                        phaseName: newPhases[0]?.name ?? ConstantsStringEsa.NO_TRAINING_PHASE_NAME,
                        deviceIds,
                        userIds,
                        deviceToPhase,
                    };
                }),
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [newCompetition.deviceIds],
    );

    //todo check retrigger
    useEffect(() => {
        function updateListener(event: MessageEvent) {
            const update: WebSocketMessage = JSON.parse(event.data);

            if (update.deviceEsaData) {
                esaSessionApi.getUserDevices().then(setAllDevices);
            }

            const device = update.userDevice;
            if (device) {
                setAllDevices(old => [device, ...old.filter(it => it.deviceId !== device.deviceId)]);
            }

            const phase = update.phase;
            if (phase && phase.competitionId && competitionIds.findIndex(it => it.id === phase.competitionId) === -1) {
                const competitionTitle: CompetitionTitle = {
                    id: phase.competitionId,
                    disciplineName: phase.disciplineName,
                    startTime: phase.start,
                    userCount: -1,
                };
                setCompetitionIds(old => [...old, competitionTitle]);
            }

            if (phase && phase.competitionId === competition.id) {
                setCompetition(old => {
                    const deviceToPhase = old.deviceToPhase;
                    deviceToPhase[phase.deviceId] = phase;

                    return {
                        ...old,
                        deviceToPhase,
                    };
                });
            }

            if (
                update.action &&
                update.action.action === WebSocketMessageActionActionEnum.REQUESTASSISTANCE &&
                update.action.user &&
                isAdmin(user)
            ) {
                setHelpRequests(old => [...old, update.action?.user ?? "none"]);
            }

            const hit = update.hit;
            if (hit && competition.deviceIds.includes(hit.deviceId)) {
                setLastHitDevice(hit.deviceId);
                setHits(oldHits => {
                    return [...oldHits, hit];
                });
            }
        }

        webSocket.addEventListener("message", updateListener);
        return function cleanup() {
            webSocket.removeEventListener("message", updateListener);
        };
    }, [
        webSocket,
        setHits,
        competition.id,
        competition.deviceIds,
        setCompetition,
        competitionIds,
        setCompetitionIds,
        setAllDevices,
        user,
    ]);

    const context = useMemo<SpectatorContextI>(() => {
        return {
            ...{
                range,
                setNewCompetition,
                allDevices,
                setFinals,
                finals,
                spectatorControls,
                setSpectatorControls,
                competitionIds,
                setCompetitionIds,
                competition,
                newCompetition,
                setSelectedCompetitionId,
                totalHits,
                lastHitDevice,
                helpRequests,
                setHelpRequests,
            },
        };
    }, [
        range,
        setNewCompetition,
        allDevices,
        setFinals,
        finals,
        spectatorControls,
        setSpectatorControls,
        competitionIds,
        competition,
        newCompetition,
        setSelectedCompetitionId,
        totalHits,
        lastHitDevice,
        helpRequests,
        setHelpRequests,
    ]);

    return <SpectatorContext.Provider value={context}>{children}</SpectatorContext.Provider>;
}

function getUserName(users: User[], phase: Phase, status?: Status, device?: UserDevice) {
    if (status === "Ended") {
        return users.find(u => u.id === phase.userId)?.name;
    } else {
        return device?.userName;
    }
}
