import React from "react";
import {DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import styles from "./CustomDialog.module.css";
import {TransitionProps} from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {children?: React.ReactElement},
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @deprecated use Popup
 */
export default function CustomDialog(props: {
    children: JSX.Element;
    dialogTitle: JSX.Element;
    dialogActions?: JSX.Element;
    open: boolean;
    setOpen: Setter<boolean>;
}) {
    return (
        <Dialog
            id="custom_dialog"
            disableEnforceFocus
            PaperProps={{className: styles.dialog}}
            open={props.open}
            onClose={() => props.setOpen(false)}
            TransitionComponent={Transition}>
            <DialogTitle className={styles.header}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item xs style={{marginLeft: 8}}>
                        {props.dialogTitle}
                    </Grid>
                    <Grid container onClick={() => props.setOpen(false)} item xs justify="flex-end">
                        <IconButton id="close_action_header_dialog">
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{padding: 0, overflow: "hidden"}}>
                <Grid container className={styles.container}>
                    {props.children}
                </Grid>
                <div style={{height: 50}} />
            </DialogContent>
            {props.dialogActions && (
                <DialogActions className={styles.border}>
                    <Grid container direction="row" justify="flex-end">
                        {props.dialogActions}
                    </Grid>
                </DialogActions>
            )}
        </Dialog>
    );
}
