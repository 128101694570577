import React, {ReactNode, useContext} from "react";
import {phasePlaceholder} from "../AppContextProvider";
import {AppSettings} from "../AppSettingsProvider";
import Timer, {PHASE_NOTIFICATION} from "../timer/Timer";
import {CompetitionControllerApi} from "../../api/generated/esa";
import {NEW_COMPETITION_EMPTY, NEW_COMPETITION_TITLE, SpectatorContext} from "./SpectatorContextProvider";
import {saveTrainingSessionToLocalStorage} from "../select-view/SelectContextProvider";
import {NotificationContext} from "../NotificationContextProvider";

const api = new CompetitionControllerApi();

export default function SpectatorTimer(props: {
    id: string;
    setHeader: (id: string, header: ReactNode | String) => void;
    allowControls: boolean;
}) {
    const {notify, unNotify} = useContext(NotificationContext);

    const {
        competition: {id: competitionId, userIds, deviceToPhase, deviceIds},
        setCompetitionIds,
        setSelectedCompetitionId,
        setNewCompetition,
        allDevices,
    } = useContext(SpectatorContext);

    const phase = Object.values(deviceToPhase)[0] ?? phasePlaceholder;

    const {
        Components: {
            Timer: {warmUpSec},
        },
    } = useContext(AppSettings);

    const millisecondsInSecond = 1000;
    const warmup = phase.started || phase.unlimited ? 0 : warmUpSec * millisecondsInSecond;

    function clearCompetition() {
        setSelectedCompetitionId(NEW_COMPETITION_TITLE);
        setCompetitionIds(old => old.filter(it => it.id !== competitionId));
    }

    function remove() {
        api.removeCompetition({competitionId}).then(clearCompetition);
    }

    function pause() {
        api.pauseCompetition({competitionId}).then();
    }

    function start() {
        api.startCompetition({competitionId, warmup}).then();
    }

    function reset() {
        saveTrainingSessionToLocalStorage({
            discipline: phase.disciplineName,
            weapon: phase.weaponId,
            target: phase.targetName,
        });

        const connectedDeviceIds = allDevices.map(it => it.deviceId).filter(it => deviceIds.includes(it));
        if (connectedDeviceIds.length !== deviceIds.length) {
            notify("warning", "message.not_all_devices_online", {
                translationValues: {diff: deviceIds.length - connectedDeviceIds.length},
            });
        }

        setNewCompetition({...NEW_COMPETITION_EMPTY, deviceIds: connectedDeviceIds});
        setSelectedCompetitionId(NEW_COMPETITION_TITLE);
    }

    function next() {
        phase.nextPhase &&
            api
                .selectCompetition({
                    disciplines: phase.disciplineName,
                    targetName: phase.targetName,
                    phaseN: phase.nextPhase,
                    weaponId: phase.weaponId,
                    userIds,
                    competitionId,
                })
                .then(() => unNotify(PHASE_NOTIFICATION));
    }

    function complete() {
        api.completeCompetition({competitionId}).then(clearCompetition);
    }

    return <Timer {...{...props, phase, remove, pause, start, reset, next, complete}} />;
}
