/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";
import {Phase, PhaseFromJSON, PhaseToJSON} from "./";

/**
 *
 * @export
 * @interface TrainingSession
 */
export interface TrainingSession {
    /**
     *
     * @type {string}
     * @memberof TrainingSession
     */
    id: string;
    /**
     *
     * @type {number}
     * @memberof TrainingSession
     */
    deviceId: number;
    /**
     *
     * @type {string}
     * @memberof TrainingSession
     */
    deviceAlias: string;
    /**
     *
     * @type {string}
     * @memberof TrainingSession
     */
    disciplineName: string;
    /**
     *
     * @type {number}
     * @memberof TrainingSession
     */
    startTime: number;
    /**
     *
     * @type {number}
     * @memberof TrainingSession
     */
    decimalScore: number;
    /**
     *
     * @type {number}
     * @memberof TrainingSession
     */
    fullRingScore?: number;
    /**
     *
     * @type {Array<Phase>}
     * @memberof TrainingSession
     */
    phases: Array<Phase>;
}

export function TrainingSessionFromJSON(json: any): TrainingSession {
    return TrainingSessionFromJSONTyped(json, false);
}

export function TrainingSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingSession {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json["id"],
        deviceId: json["deviceId"],
        deviceAlias: json["deviceAlias"],
        disciplineName: json["disciplineName"],
        startTime: json["startTime"],
        decimalScore: json["decimalScore"],
        fullRingScore: !exists(json, "fullRingScore") ? undefined : json["fullRingScore"],
        phases: (json["phases"] as Array<any>).map(PhaseFromJSON),
    };
}

export function TrainingSessionToJSON(value?: TrainingSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        deviceId: value.deviceId,
        deviceAlias: value.deviceAlias,
        disciplineName: value.disciplineName,
        startTime: value.startTime,
        decimalScore: value.decimalScore,
        fullRingScore: value.fullRingScore,
        phases: (value.phases as Array<any>).map(PhaseToJSON),
    };
}
