/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from "../runtime";
import {Phase, PhaseFromJSON, PhaseToJSON} from "./";

/**
 *
 * @export
 * @interface Competition
 */
export interface Competition {
    /**
     *
     * @type {string}
     * @memberof Competition
     */
    id: string;
    /**
     *
     * @type {{ [key: string]: Phase; }}
     * @memberof Competition
     */
    deviceToPhase: {[key: string]: Phase};
    /**
     *
     * @type {Array<string>}
     * @memberof Competition
     */
    userIds: Array<string>;
    /**
     *
     * @type {Array<number>}
     * @memberof Competition
     */
    deviceIds: Array<number>;
    /**
     *
     * @type {string}
     * @memberof Competition
     */
    phaseName: string;
}

export function CompetitionFromJSON(json: any): Competition {
    return CompetitionFromJSONTyped(json, false);
}

export function CompetitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Competition {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json["id"],
        deviceToPhase: mapValues(json["deviceToPhase"], PhaseFromJSON),
        userIds: json["userIds"],
        deviceIds: json["deviceIds"],
        phaseName: json["phaseName"],
    };
}

export function CompetitionToJSON(value?: Competition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        deviceToPhase: mapValues(value.deviceToPhase, PhaseToJSON),
        userIds: value.userIds,
        deviceIds: value.deviceIds,
        phaseName: value.phaseName,
    };
}
