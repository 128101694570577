import {useEffect, useState} from "react";
import {ConstantsStringCommon, Target, TargetControllerApi, Weapon, WeaponsControllerApi} from "./api/generated/esa";
import {defaultTarget, defaultWeapon} from "./defaultValues";

const targetControllerApi = new TargetControllerApi();
const weaponsControllerApi = new WeaponsControllerApi();

export function useTarget(targetName?: string) {
    const [target, setTarget] = useState<Target>(defaultTarget);

    useEffect(() => {
        if (targetName === target.name) {
            // do nothing
        } else if (targetName === "empty") {
            setTarget(defaultTarget);
        } else if (targetName) {
            targetControllerApi.get({targetName: targetName}).then(setTarget);
        }
    }, [targetName, target.name, setTarget]);

    return target;
}

export function useWeapon(weaponId?: string) {
    const [weapon, setWeapon] = useState<Weapon>(defaultWeapon);

    useEffect(() => {
        if (weaponId === weapon?.id) {
            // do nothing
        } else if (weaponId === ConstantsStringCommon.NO_WEAPON) {
            setWeapon(defaultWeapon);
        } else if (weaponId) {
            weaponsControllerApi.getWeapon({weaponId: weaponId}).then(setWeapon);
        }
    }, [weaponId, weapon.id, setWeapon]);

    return weapon;
}
