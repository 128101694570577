/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface WeaponCategory
 */
export interface WeaponCategory {
    /**
     *
     * @type {Array<string>}
     * @memberof WeaponCategory
     */
    categories: Array<string>;
}

export function WeaponCategoryFromJSON(json: any): WeaponCategory {
    return WeaponCategoryFromJSONTyped(json, false);
}

export function WeaponCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeaponCategory {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        categories: json["categories"],
    };
}

export function WeaponCategoryToJSON(value?: WeaponCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        categories: value.categories,
    };
}
