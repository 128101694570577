/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";
import {
    PhaseType,
    PhaseTypeFromJSON,
    PhaseTypeToJSON,
    ScoringType,
    ScoringTypeFromJSON,
    ScoringTypeToJSON,
    TrafficPattern,
    TrafficPatternFromJSON,
    TrafficPatternToJSON,
} from "./";

/**
 *
 * @export
 * @interface PhaseDescription
 */
export interface PhaseDescription {
    /**
     *
     * @type {string}
     * @memberof PhaseDescription
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof PhaseDescription
     */
    duration: number;
    /**
     *
     * @type {number}
     * @memberof PhaseDescription
     */
    series: number;
    /**
     *
     * @type {number}
     * @memberof PhaseDescription
     */
    shots?: number;
    /**
     *
     * @type {number}
     * @memberof PhaseDescription
     */
    firstWarningAt?: number;
    /**
     *
     * @type {number}
     * @memberof PhaseDescription
     */
    secondWarningAt?: number;
    /**
     *
     * @type {ScoringType}
     * @memberof PhaseDescription
     */
    scoring: ScoringType;
    /**
     *
     * @type {TrafficPattern}
     * @memberof PhaseDescription
     */
    trafficPattern?: TrafficPattern;
    /**
     *
     * @type {PhaseType}
     * @memberof PhaseDescription
     */
    type: PhaseType;
    /**
     *
     * @type {string}
     * @memberof PhaseDescription
     */
    targetName?: string;
}

export function PhaseDescriptionFromJSON(json: any): PhaseDescription {
    return PhaseDescriptionFromJSONTyped(json, false);
}

export function PhaseDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhaseDescription {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: json["name"],
        duration: json["duration"],
        series: json["series"],
        shots: !exists(json, "shots") ? undefined : json["shots"],
        firstWarningAt: !exists(json, "firstWarningAt") ? undefined : json["firstWarningAt"],
        secondWarningAt: !exists(json, "secondWarningAt") ? undefined : json["secondWarningAt"],
        scoring: ScoringTypeFromJSON(json["scoring"]),
        trafficPattern: !exists(json, "trafficPattern") ? undefined : TrafficPatternFromJSON(json["trafficPattern"]),
        type: PhaseTypeFromJSON(json["type"]),
        targetName: !exists(json, "targetName") ? undefined : json["targetName"],
    };
}

export function PhaseDescriptionToJSON(value?: PhaseDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        duration: value.duration,
        series: value.series,
        shots: value.shots,
        firstWarningAt: value.firstWarningAt,
        secondWarningAt: value.secondWarningAt,
        scoring: ScoringTypeToJSON(value.scoring),
        trafficPattern: TrafficPatternToJSON(value.trafficPattern),
        type: PhaseTypeToJSON(value.type),
        targetName: value.targetName,
    };
}
