/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {TypeInfo, TypeInfoFromJSON, TypeInfoToJSON} from "./";

/**
 *
 * @export
 * @interface WeaponType
 */
export interface WeaponType {
    /**
     *
     * @type {Array<TypeInfo>}
     * @memberof WeaponType
     */
    types: Array<TypeInfo>;
}

export function WeaponTypeFromJSON(json: any): WeaponType {
    return WeaponTypeFromJSONTyped(json, false);
}

export function WeaponTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeaponType {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        types: (json["types"] as Array<any>).map(TypeInfoFromJSON),
    };
}

export function WeaponTypeToJSON(value?: WeaponType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        types: (value.types as Array<any>).map(TypeInfoToJSON),
    };
}
