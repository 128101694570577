export const ARHits = [
    {x: -6, y: -2, score: 8.5, timeStamp: 1617121061504},
    {x: 5, y: 2, score: 8.8, timeStamp: 1617121046380},
    {x: -6, y: 8, score: 7, timeStamp: 1617121031375},
    {x: 3, y: -2, score: 9.6, timeStamp: 1617121016120},
    {x: 0, y: 10, score: 7, timeStamp: 1617121001422},
    {x: -2, y: 11, score: 6.5, timeStamp: 1617120985881},
    {x: -10, y: 5, score: 6.5, timeStamp: 1617120970623},
    {x: 7, y: -1, score: 8.2, timeStamp: 1617120955302},
    {x: -1, y: 8, score: 7.8, timeStamp: 1617120940783},
    {x: 10, y: 0, score: 7, timeStamp: 1617120925262},
    {x: -7, y: -2, score: 8.1, timeStamp: 1617120910255},
    {x: 3, y: 6, score: 8.3, timeStamp: 1617120894657},
    {x: 2, y: 0, score: 10.2, timeStamp: 1617120880492},
    {x: -1, y: 2, score: 10.1, timeStamp: 1617120865288},
    {x: 2, y: -6, score: 8.5, timeStamp: 1617120850220},
    {x: 10, y: -3, score: 6.8, timeStamp: 1617120834951},
    {x: 7, y: -6, score: 7.3, timeStamp: 1617120820032},
    {x: -10, y: -4, score: 6.7, timeStamp: 1617120804571},
    {x: -3, y: -1, score: 9.7, timeStamp: 1617120789849},
    {x: 6, y: 10, score: 6.3, timeStamp: 1617120774561},
    {x: -2, y: -5, score: 8.8, timeStamp: 1617120758381},
    {x: 4, y: 0, score: 9.4, timeStamp: 1617120744535},
    {x: 5, y: 4, score: 8.4, timeStamp: 1617120729284},
    {x: 9, y: -3, score: 7.2, timeStamp: 1617120713618},
    {x: 10, y: 7, score: 6.1, timeStamp: 1617120699503},
    {x: 5, y: -8, score: 7.2, timeStamp: 1617120684417},
    {x: 5, y: 7, score: 7.6, timeStamp: 1617120667445},
    {x: -11, y: -2, score: 6.5, timeStamp: 1617120653566},
    {x: -1, y: -6, score: 8.6, timeStamp: 1617120637867},
    {x: 11, y: 6, score: 6, timeStamp: 1617120622451},
    {x: 11, y: 6, score: 6, timeStamp: 1617120607312},
    {x: 4, y: -2, score: 9.2, timeStamp: 1617120594109},
    {x: -3, y: -4, score: 9, timeStamp: 1617120579351},
    {x: -6, y: 10, score: 6.3, timeStamp: 1617120563747},
    {x: 12, y: 2, score: 6.1, timeStamp: 1617120549031},
    {x: -7, y: 8, score: 6.7, timeStamp: 1617120534314},
    {x: 5, y: 1, score: 9, timeStamp: 1617120517402},
    {x: 3, y: -1, score: 9.7, timeStamp: 1617120504338},
    {x: 2, y: -2, score: 9.9, timeStamp: 1617120486131},
    {x: -5, y: 2, score: 8.8, timeStamp: 1617120470603},
    {x: 8, y: 5, score: 7.2, timeStamp: 1617120458184},
    {x: 6, y: -8, score: 7, timeStamp: 1617120443098},
    {x: 11, y: 6, score: 6, timeStamp: 1617120425877},
    {x: -9, y: 3, score: 7.2, timeStamp: 1617120409878},
    {x: 9, y: 6, score: 6.7, timeStamp: 1617120395135},
    {x: 2, y: 2, score: 9.9, timeStamp: 1617120383205},
    {x: -7, y: 10, score: 6.1, timeStamp: 1617120367189},
    {x: -5, y: 3, score: 8.7, timeStamp: 1617120351633},
    {x: -9, y: 1, score: 7.4, timeStamp: 1617120334100},
    {x: 12, y: -2, score: 6.1, timeStamp: 1617120321819},
    {x: 0, y: -3, score: 9.8, timeStamp: 1617120303798},
    {x: 7, y: 2, score: 8.1, timeStamp: 1617120289132},
    {x: 6, y: -2, score: 8.5, timeStamp: 1617120273499},
    {x: 5, y: -3, score: 8.7, timeStamp: 1617120262051},
    {x: 1, y: 8, score: 7.8, timeStamp: 1617120243142},
    {x: 9, y: -1, score: 7.4, timeStamp: 1617120231383},
    {x: 0, y: 2, score: 10.2, timeStamp: 1617120214601},
    {x: 10, y: 1, score: 7, timeStamp: 1617120198359},
    {x: 1, y: -8, score: 7.8, timeStamp: 1617120183136},
    {x: -1, y: 10, score: 7, timeStamp: 1617120169822},
    {x: 1, y: 8, score: 7.8, timeStamp: 1617120153536},
    {x: 1, y: 3, score: 9.7, timeStamp: 1617120139036},
    {x: 3, y: -2, score: 9.6, timeStamp: 1617120125599},
    {x: 9, y: 3, score: 7.2, timeStamp: 1617120111816},
    {x: -1, y: -3, score: 9.7, timeStamp: 1617120092874},
    {x: -8, y: -6, score: 7, timeStamp: 1617120076444},
    {x: -8, y: -1, score: 7.8, timeStamp: 1617120062669},
    {x: 8, y: 2, score: 7.7, timeStamp: 1617120052320},
    {x: 1, y: 11, score: 6.6, timeStamp: 1617120031096},
    {x: -5, y: -4, score: 8.4, timeStamp: 1617120020888},
    {x: -10, y: -4, score: 6.7, timeStamp: 1617120005765},
    {x: -6, y: 10, score: 6.3, timeStamp: 1617119992167},
    {x: 2, y: -1, score: 10.1, timeStamp: 1617119975671},
    {x: 2, y: -4, score: 9.2, timeStamp: 1617119956941},
    {x: -2, y: 4, score: 9.2, timeStamp: 1617119946336},
    {x: -9, y: 0, score: 7.4, timeStamp: 1617119929721},
    {x: -7, y: 6, score: 7.3, timeStamp: 1617119916216},
    {x: 10, y: -6, score: 6.3, timeStamp: 1617119898059},
    {x: -6, y: -10, score: 6.3, timeStamp: 1617119880721},
    {x: 7, y: -10, score: 6.1, timeStamp: 1617119868784},
    {x: -1, y: -4, score: 9.4, timeStamp: 1617119854352},
    {x: -1, y: 9, score: 7.4, timeStamp: 1617119838583},
    {x: 11, y: -1, score: 6.6, timeStamp: 1617119821803},
    {x: 1, y: -4, score: 9.4, timeStamp: 1617119810942},
    {x: 4, y: -4, score: 8.7, timeStamp: 1617119792529},
    {x: 0, y: -6, score: 8.6, timeStamp: 1617119774736},
    {x: 2, y: -6, score: 8.5, timeStamp: 1617119758776},
    {x: -10, y: -2, score: 6.9, timeStamp: 1617119748107},
    {x: 2, y: 11, score: 6.5, timeStamp: 1617119732799},
    {x: -9, y: 5, score: 6.9, timeStamp: 1617119720454},
    {x: -4, y: 1, score: 9.4, timeStamp: 1617119700001},
    {x: -3, y: -8, score: 7.6, timeStamp: 1617119691320},
    {x: -5, y: 6, score: 7.9, timeStamp: 1617119671630},
    {x: -7, y: 0, score: 8.2, timeStamp: 1617119660290},
    {x: -4, y: -7, score: 7.8, timeStamp: 1617119643288},
    {x: -1, y: -5, score: 9, timeStamp: 1617119623395},
];
