import React, {useContext, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    Grid,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from "@material-ui/core";
import styles from "./Burger.module.css";
import {About} from "../about-dialog/About";
import {Support} from "../support-dialog/Support";
import {trackClick} from "../AnalyticsClickTracker";
import {AppContext} from "../AppContextProvider";
import {translation, translationOrName} from "../i18n";
import {NavigationLocation} from "./NavigationLocation";
import {uniqueId} from "lodash";
import {H5} from "../common/TypographyVariants";
import {AppLayout} from "../LayoutManager";
import {navigate} from "./Navigation";
import CloseIcon from "@material-ui/icons/Close";
import "./Burger.override.css";

export function Burger(props: {
    profile: React.ReactNode;
    locations: NavigationLocation[];
    controls: React.ReactNode[];
    tools: React.ReactNode[];
    help: React.ReactNode[];
}) {
    const {profile, locations, controls, tools, help} = props;
    const {burgerOpen, setBurgerOpen} = useContext(AppLayout);
    const [sessionInProgressDialog, setSessionInProgressDialog] = useState(false);
    const {analytics} = useContext(AppContext);

    return (
        <>
            <IconButton id="burger_menu" component="span" style={{margin: "0 8px"}} onClick={() => setBurgerOpen(true)}>
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="right"
                id="burger_menu_drawer_container"
                open={burgerOpen}
                onClose={() => setBurgerOpen(false)}>
                <div id="burger_menu_drawer" className={styles.navigationContainer}>
                    <IconButton
                        className={styles.closeButton}
                        id="close_burger_menu"
                        onClick={() => setBurgerOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                    <Divider />

                    <Hidden mdUp>
                        <Grid container>
                            <div className={styles.userContainer}>{profile}</div>
                        </Grid>
                    </Hidden>

                    <Hidden smUp>
                        <Divider />
                        <List
                            subheader={
                                <ListSubheader className={styles.listSubheader} component="div">
                                    {translation("title.subheader.navigation")}
                                </ListSubheader>
                            }>
                            {locations.map(it => {
                                return (
                                    <ListItem
                                        button
                                        id={"navigation_location_mobile_" + it.location}
                                        key={uniqueId("menu_")}
                                        onClick={() => {
                                            trackClick(analytics, it.location);
                                            navigate(it);
                                        }}>
                                        <ListItemIcon>{it.icon}</ListItemIcon>
                                        <ListItemText primary={translationOrName("button", it.location)} />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Divider />
                    </Hidden>

                    <List
                        id="burger_controls"
                        subheader={
                            <ListSubheader className={styles.listSubheader} component="div">
                                {translation("title.subheader.controls")}
                            </ListSubheader>
                        }>
                        {controls}
                    </List>

                    <Divider />

                    {tools.filter(_ => _).length > 0 && (
                        <>
                            <List
                                id="tools_list"
                                subheader={
                                    <ListSubheader className={styles.listSubheader} component="div">
                                        {translation("title.subheader.tools")}
                                    </ListSubheader>
                                }>
                                {tools}
                            </List>
                            <Divider />
                        </>
                    )}

                    <List
                        id="help_list"
                        subheader={
                            <ListSubheader className={styles.listSubheader} component="div">
                                {translation("title.subheader.help")}
                            </ListSubheader>
                        }>
                        {help}

                        <Dialog open={sessionInProgressDialog} onClose={() => setSessionInProgressDialog(false)}>
                            <DialogTitle>
                                <H5 color="error">Training session in progress</H5>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    If you wish to take a look at application features you must finish or discard your
                                    current training session.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setSessionInProgressDialog(false)}>Close</Button>
                            </DialogActions>
                        </Dialog>
                        <Support />
                        <About />
                    </List>
                </div>
            </Drawer>
        </>
    );
}
