import {ConstantsStringCommon, MeasurementUnit, Target, Weapon} from "./api/generated/esa";

export const defaultTarget: Target = {
    name: "target",
    defaultCaliber: "",
    disableInnerTen: false,
    dpmX: 1,
    dpmY: 1,
    scoreOnFirstRing: 0,
    weaponType: "",
    targetImage: "",
    targetRadius: 1,
    defaultCaliberMm: 0,
    ringStep: 0,
    innerTenRadius: 0,
    targetCardWidth: 1,
    targetCardHeight: 1,
    bullsEye: 0,
    hitZones: false,
    includeNinthRingNumbering: false,
    whiteCenter: true,
};

export const defaultWeapon: Weapon = {
    id: ConstantsStringCommon.NO_WEAPON,
    name: "Name",
    type: "None",
    category: "None",
    displayCaliber: undefined,
    caliberMm: 0,
    caliberInch: 2,
    measurementUnit: MeasurementUnit.Metric,
    chsGain: 7,
    nchsGain: 7,
    nchsTimeout: 70,
    nchsThreshold: 30,
};
