import React, {useContext} from "react";
import {IconButton} from "@material-ui/core";
import ReviewSessionsText from "../../components/review-view/components/ReviewSessionsText";
import AddCommentIcon from "@material-ui/icons/AddComment";
import {AppContext} from "../../components/AppContextProvider";
import {AppContextTypeMock} from "./PortalMock";
import {ReactComponent as MenuIcon} from "@fortawesome/fontawesome-free/svgs/solid/caret-square-down.svg";
import styles from "../../components/review-view/components/Review.module.css";
import {ReviewSessionsContent} from "../../components/review-view/components/ReviewSessions";

export function ReviewSessionsMock() {
    const {setBookDemoTranslationKey} = useContext(AppContext) as AppContextTypeMock;

    return (
        <ReviewSessionsContent
            actions={s => (
                <ReviewSessionsText session={s}>
                    <IconButton
                        id="add_comment_button"
                        edge="end"
                        size="small"
                        onClick={() => setBookDemoTranslationKey(["label.comment", "demo.review_comment"])}
                        style={{margin: "0 4px"}}>
                        <AddCommentIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => setBookDemoTranslationKey(["demo.review_menu.title", "demo.review_menu"])}
                        className={styles.sessionMenuButton}>
                        <MenuIcon className="svgIcon" />
                    </IconButton>
                </ReviewSessionsText>
            )}
        />
    );
}
