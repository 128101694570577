import {Hit} from "../../../../api/generated/esa/models";

export function getMPI(hits: Hit[], axis: "x" | "y", cardSize: number) {
    if (!cardSize) {
        return 0;
    }
    const hitsInTarget = hits.filter(it => {
        return Math.max(it.x, it.y) < cardSize / 2;
    });

    if (hitsInTarget.length > 0) {
        return (
            hitsInTarget.map((hit: Hit, index, arr) => hit[axis]).reduce((it: number, acc: number) => it + acc) /
            hitsInTarget.length
        );
    } else return 0;
}
