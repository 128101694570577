/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ConstantsStringCommon {
    ADMIN_USER_ID = "admin",
    EMPTY_USER_ID = "empty",
    GENERATE_TARGET_RENDER = "generated",
    GUEST_USER_ID = "guest",
    NO_WEAPON = "none",
    ROLE_ADMIN = "ADMIN",
    ROLE_SPECTATOR = "SPECTATOR",
    ROLE_USER = "USER",
}

export function ConstantsStringCommonFromJSON(json: any): ConstantsStringCommon {
    return ConstantsStringCommonFromJSONTyped(json, false);
}

export function ConstantsStringCommonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConstantsStringCommon {
    return json as ConstantsStringCommon;
}

export function ConstantsStringCommonToJSON(value?: ConstantsStringCommon | null): any {
    return value as any;
}
