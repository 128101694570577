/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {PowerStatus, PowerStatusFromJSON, PowerStatusToJSON} from "./";

/**
 *
 * @export
 * @interface EsaData
 */
export interface EsaData {
    /**
     *
     * @type {boolean}
     * @memberof EsaData
     */
    onOff: boolean;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    gain: number;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    timeout: number;
    /**
     *
     * @type {string}
     * @memberof EsaData
     */
    threshold: string;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    burstSeparation: number;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    temperature: number;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    paperFeed: number;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    sensor: number;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    voltage: number;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    lightPercentage: number;
    /**
     *
     * @type {PowerStatus}
     * @memberof EsaData
     */
    lightStatus: PowerStatus;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    fwMajorVersion: number;
    /**
     *
     * @type {number}
     * @memberof EsaData
     */
    fwMinorVersion: number;
}

export function EsaDataFromJSON(json: any): EsaData {
    return EsaDataFromJSONTyped(json, false);
}

export function EsaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EsaData {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        onOff: json["onOff"],
        gain: json["gain"],
        timeout: json["timeout"],
        threshold: json["threshold"],
        burstSeparation: json["burstSeparation"],
        temperature: json["temperature"],
        paperFeed: json["paperFeed"],
        sensor: json["sensor"],
        voltage: json["voltage"],
        lightPercentage: json["lightPercentage"],
        lightStatus: PowerStatusFromJSON(json["lightStatus"]),
        fwMajorVersion: json["fwMajorVersion"],
        fwMinorVersion: json["fwMinorVersion"],
    };
}

export function EsaDataToJSON(value?: EsaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        onOff: value.onOff,
        gain: value.gain,
        timeout: value.timeout,
        threshold: value.threshold,
        burstSeparation: value.burstSeparation,
        temperature: value.temperature,
        paperFeed: value.paperFeed,
        sensor: value.sensor,
        voltage: value.voltage,
        lightPercentage: value.lightPercentage,
        lightStatus: PowerStatusToJSON(value.lightStatus),
        fwMajorVersion: value.fwMajorVersion,
        fwMinorVersion: value.fwMinorVersion,
    };
}
