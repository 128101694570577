/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
    User,
    UserCounts,
    UserCountsFromJSON,
    UserCreationRequest,
    UserCreationRequestToJSON,
    UserCsv,
    UserCsvFromJSON,
    UserFromJSON,
    UserToJSON,
} from "../models";

export interface CreateUserRequest {
    userCreationRequest: UserCreationRequest;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface GetAllUsersRequest {
    includeAdmin?: boolean;
    includeGuest?: boolean;
}

export interface ImportUsersRequest {
    body: string;
}

export interface LogoutRequest {
    userId: string;
}

export interface UpdateUserRequest {
    user: User;
    secret?: string;
}

/**
 *
 */
export class UsersControllerApi extends runtime.BaseAPI {
    /**
     */
    async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userCreationRequest === null || requestParameters.userCreationRequest === undefined) {
            throw new runtime.RequiredError(
                "userCreationRequest",
                "Required parameter requestParameters.userCreationRequest was null or undefined when calling createUser.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/users/`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: UserCreationRequestToJSON(requestParameters.userCreationRequest),
        });

        return new runtime.JSONApiResponse(response, jsonValue => UserFromJSON(jsonValue));
    }

    /**
     */
    async createUser(requestParameters: CreateUserRequest): Promise<User> {
        const response = await this.createUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                "userId",
                "Required parameter requestParameters.userId was null or undefined when calling deleteUser.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }

    /**
     */
    async getAllUsersRaw(requestParameters: GetAllUsersRequest): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeAdmin !== undefined) {
            queryParameters["includeAdmin"] = requestParameters.includeAdmin;
        }

        if (requestParameters.includeGuest !== undefined) {
            queryParameters["includeGuest"] = requestParameters.includeGuest;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async getAllUsers(requestParameters: GetAllUsersRequest): Promise<Array<User>> {
        const response = await this.getAllUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUsersCsvRaw(): Promise<runtime.ApiResponse<Array<UserCsv>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/export`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(UserCsvFromJSON));
    }

    /**
     */
    async getUsersCsv(): Promise<Array<UserCsv>> {
        const response = await this.getUsersCsvRaw();
        return await response.value();
    }

    /**
     */
    async importUsersRaw(requestParameters: ImportUsersRequest): Promise<runtime.ApiResponse<UserCounts>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError(
                "body",
                "Required parameter requestParameters.body was null or undefined when calling importUsers.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/users/import`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, jsonValue => UserCountsFromJSON(jsonValue));
    }

    /**
     */
    async importUsers(requestParameters: ImportUsersRequest): Promise<UserCounts> {
        const response = await this.importUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async loggedInUsersRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/active`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async loggedInUsers(): Promise<Array<string>> {
        const response = await this.loggedInUsersRaw();
        return await response.value();
    }

    /**
     */
    async logoutRaw(requestParameters: LogoutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                "userId",
                "Required parameter requestParameters.userId was null or undefined when calling logout.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/active/{userId}`.replace(
                `{${"userId"}}`,
                encodeURIComponent(String(requestParameters.userId)),
            ),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logout(requestParameters: LogoutRequest): Promise<void> {
        await this.logoutRaw(requestParameters);
    }

    /**
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError(
                "user",
                "Required parameter requestParameters.user was null or undefined when calling updateUser.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.secret !== undefined) {
            queryParameters["secret"] = requestParameters.secret;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/users/`,
            method: "PATCH",
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateUser(requestParameters: UpdateUserRequest): Promise<void> {
        await this.updateUserRaw(requestParameters);
    }

    /**
     */
    async whoAmIRaw(): Promise<runtime.ApiResponse<User>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/current`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => UserFromJSON(jsonValue));
    }

    /**
     */
    async whoAmI(): Promise<User> {
        const response = await this.whoAmIRaw();
        return await response.value();
    }
}
