import React, {useContext} from "react";
import style from "./SpectatorTarget.module.css";
import {Button, Grid, Paper} from "@material-ui/core";
import {translation, translationString} from "../i18n";
import {GridCellParams, GridColDef} from "@material-ui/data-grid";
import {CustomToolbar} from "../common/CustomToolbar";
import {useIntl} from "react-intl";
import {CustomNoRowsOverlay} from "../common/CustomNoRowsOverlay";
import {NEW_COMPETITION_ID, SpectatorContext} from "./SpectatorContextProvider";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import UserAssignment from "./components/UserAssignment";
import {usePrevious} from "../common/ReactUtils";
import {ConstantsStringEsa, HelpControllerApi} from "../../api/generated/esa";
import {CustomLoadingOverlay} from "../common/CustomLoadingOverlay";
import {CommonDataGrid} from "../common/CommonDataGrid";

function countryCell(params: GridCellParams) {
    return <>{params.value && getUnicodeFlagIcon(params.value)}</>;
}
const helpControllerApi = new HelpControllerApi();

export default function SpectatorTable() {
    const [userMenuEl, setUserMenuEl] = React.useState<null | HTMLElement>(null);
    const {
        competition: {id: competitionId},
        totalHits,
        range,
        lastHitDevice,
        setSelectedCompetitionId,
        helpRequests,
        setHelpRequests,
    } = useContext(SpectatorContext);
    const intl = useIntl();
    const prevTotalHits = usePrevious(totalHits);

    function competitionCell(params: GridCellParams) {
        if (params.value && params.value !== competitionId) {
            return (
                <Button
                    variant="outlined"
                    onClick={() =>
                        setSelectedCompetitionId({
                            id: params.value as string,
                            disciplineName: ConstantsStringEsa.NO_TRAINING_NAME,
                            userCount: 0,
                        })
                    }
                    size="small">
                    {translation("button.view")}
                </Button>
            );
        } else {
            return <></>;
        }
    }

    function userCell(params: GridCellParams) {
        const deviceId = params.row.phase.deviceId;
        if (competitionId === NEW_COMPETITION_ID && !params.value) {
            return (
                <Button
                    data-device-id={deviceId}
                    variant="outlined"
                    onClick={e => setUserMenuEl(e.currentTarget)}
                    size="small">
                    {translation("button.assign")}
                </Button>
            );
        } else {
            return <>{params.value}</>;
        }
    }

    const columns: GridColDef[] = [
        {field: "id", headerName: "#", width: 30},
        {
            field: "userName",
            headerName: translationString(intl, "title.spectator.user"),
            width: 140,
            renderCell: userCell,
            align: "center",
        },
        {
            field: "laneName",
            headerName: translationString(intl, "title.spectator.lane"),
            width: 160,
        },
        {
            field: "fp",
            headerName: translationString(intl, "title.spectator.firing_point"),
            width: 150,
            hide: true,
        },
        {
            field: "countryCode",
            headerName: translationString(intl, "title.spectator.country"),
            width: 30,
            hide: true,
            renderCell: countryCell,
        },
        {
            field: "totalScore",
            headerName: translationString(intl, "title.spectator.score"),
            width: 130,
        },
        {
            field: "averageScore",
            headerName: translationString(intl, "title.spectator.average"),
            width: 130,
        },
        {
            field: "competition",
            headerName: translationString(intl, "title.spectator.competition"),
            width: 160,
            renderCell: competitionCell,
            align: "center",
        },
        {
            field: "status",
            headerName: translationString(intl, "title.spectator.status"),
            width: 130,
        },
        {
            field: "disciplineName",
            headerName: translationString(intl, "label.input.discipline"),
            width: 130,
        },
        {
            field: "shots",
            headerName: translationString(intl, "title.spectator.shots"),
            width: 130,
        },
    ];

    return (
        <>
            <Grid container className={style.spectatorContainer}>
                <Grid
                    id="spectator_table"
                    item
                    xs={12}
                    component={Paper}
                    container
                    className={style.fullUserTableContainer}>
                    <CommonDataGrid
                        components={{
                            Toolbar: CustomToolbar,
                            NoRowsOverlay: CustomNoRowsOverlay,
                            LoadingOverlay: CustomLoadingOverlay,
                        }}
                        rows={range}
                        getRowClassName={r => {
                            return (
                                helpRequested(r.row.userName, helpRequests) ??
                                highlightLastHit(totalHits, prevTotalHits, r.row.phase.deviceId, lastHitDevice)
                            );
                        }}
                        onRowClick={r => {
                            clearHelp(r.row.userName, helpRequests, setHelpRequests);
                        }}
                        columns={columns}
                        autoHeight
                        hideFooter={true}
                        disableColumnMenu
                    />
                </Grid>
            </Grid>
            <UserAssignment {...{userMenuEl, setUserMenuEl}} />
        </>
    );
}

/**
  2 classes to enable highlight when hitting the same target
 */
export function highlightLastHit(
    totalHits: number,
    prevTotalHits: number | undefined,
    deviceId: number,
    lastHitDevice?: number,
) {
    if (prevTotalHits && totalHits !== prevTotalHits && deviceId === lastHitDevice) {
        return totalHits % 2 === 0 ? style.lastHit1 : style.lastHit2;
    } else {
        return "";
    }
}

export function helpRequested(userName: string | undefined, helpRequesters: string[]) {
    return userName && helpRequesters.includes(userName) ? style.help : undefined;
}

export function clearHelp(userName: string | undefined, helpRequesters: string[], setHelpRequests: Setter<string[]>) {
    if (userName && helpRequesters.includes(userName)) {
        setHelpRequests(helpRequests => helpRequests.filter(userReq => userReq !== userName));
        void helpControllerApi.clear({userName});
    }
}
