import {Value} from "../types/settingsTypes";
import React from "react";
import {translatedText} from "../../../i18n";
import {TextField} from "@material-ui/core";

export function FormatterInput<T extends Value>(props: {value: T; setValue: (value: T) => void; name: string}) {
    return (
        <TextField
            required
            id="standard-required"
            label={translatedText("settings", props.name)}
            defaultValue={props.value}
            onChange={event => props.setValue(event.target.value as T)}
        />
    );
}
