/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {BlockDevice, BlockDeviceFromJSON, SystemInfo, SystemInfoFromJSON} from "../models";

export interface BackupRequest {
    name: string;
}

export interface ShowKeyboardRequest {
    locale: string;
    theme: string;
}

/**
 *
 */
export class SystemControllerApi extends runtime.BaseAPI {
    /**
     */
    async backupRaw(requestParameters: BackupRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError(
                "name",
                "Required parameter requestParameters.name was null or undefined when calling backup.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/usb/{name}/backup`.replace(
                `{${"name"}}`,
                encodeURIComponent(String(requestParameters.name)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async backup(requestParameters: BackupRequest): Promise<string> {
        const response = await this.backupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUsbRaw(): Promise<runtime.ApiResponse<Array<BlockDevice>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/usb`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(BlockDeviceFromJSON));
    }

    /**
     */
    async getUsb(): Promise<Array<BlockDevice>> {
        const response = await this.getUsbRaw();
        return await response.value();
    }

    /**
     */
    async getWifiRaw(): Promise<runtime.ApiResponse<number>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/wifi`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getWifi(): Promise<number> {
        const response = await this.getWifiRaw();
        return await response.value();
    }

    /**
     */
    async hideKeyboardRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/keyboard`,
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async hideKeyboard(): Promise<void> {
        await this.hideKeyboardRaw();
    }

    /**
     */
    async infoRaw(): Promise<runtime.ApiResponse<SystemInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/info`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => SystemInfoFromJSON(jsonValue));
    }

    /**
     */
    async info(): Promise<SystemInfo> {
        const response = await this.infoRaw();
        return await response.value();
    }

    /**
     */
    async leaveRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/leave`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async leave(): Promise<void> {
        await this.leaveRaw();
    }

    /**
     */
    async nowRaw(): Promise<runtime.ApiResponse<number>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/now`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async now(): Promise<number> {
        const response = await this.nowRaw();
        return await response.value();
    }

    /**
     */
    async powerOffRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/powerOff`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async powerOff(): Promise<void> {
        await this.powerOffRaw();
    }

    /**
     */
    async profilesRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/profiles`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async profiles(): Promise<Array<string>> {
        const response = await this.profilesRaw();
        return await response.value();
    }

    /**
     */
    async restartRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/restart`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async restart(): Promise<void> {
        await this.restartRaw();
    }

    /**
     */
    async showKeyboardRaw(requestParameters: ShowKeyboardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.locale === null || requestParameters.locale === undefined) {
            throw new runtime.RequiredError(
                "locale",
                "Required parameter requestParameters.locale was null or undefined when calling showKeyboard.",
            );
        }

        if (requestParameters.theme === null || requestParameters.theme === undefined) {
            throw new runtime.RequiredError(
                "theme",
                "Required parameter requestParameters.theme was null or undefined when calling showKeyboard.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.locale !== undefined) {
            queryParameters["locale"] = requestParameters.locale;
        }

        if (requestParameters.theme !== undefined) {
            queryParameters["theme"] = requestParameters.theme;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/keyboard`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async showKeyboard(requestParameters: ShowKeyboardRequest): Promise<void> {
        await this.showKeyboardRaw(requestParameters);
    }
}
