import ReconnectingWebSocket from "reconnecting-websocket";
import {DEFAULT_DEVICE_KEY, TABLET_NAME} from "./select-view/SelectLane";

const heartbeatInterval = 1500;
const maxRetriesBeforePageReload = 10;

export function placeholderHeartbeatSocket() {
    return new ReconnectingWebSocket("", [], {startClosed: true});
}

export function createHeartbeatSocket(setConnected: Setter<boolean>) {
    const tabletName = localStorage.getItem(TABLET_NAME) ?? "";
    const defaultDeviceKey = localStorage.getItem(DEFAULT_DEVICE_KEY) ?? "";

    const webSocket = new ReconnectingWebSocket(
        `ws://${window.location.host}/ws/sync?tabletName=${tabletName}&defaultDeviceKey=${defaultDeviceKey}`,
        [],
        {
            startClosed: true,
        },
    );
    let reconnect: ReturnType<typeof setTimeout>;

    function heartbeat() {
        if (webSocket?.readyState === 1) {
            webSocket.send("♥");
            reconnect = setTimeout(() => webSocket.reconnect(), heartbeatInterval);
        }
    }

    webSocket.addEventListener("message", () => clearTimeout(reconnect));
    webSocket.addEventListener("open", () => setConnected(true));
    webSocket.addEventListener("close", () => setConnected(false));
    webSocket.addEventListener("error", () => {
        if (webSocket.retryCount === maxRetriesBeforePageReload) {
            window.location.reload();
        }
    });
    document.addEventListener("visibilitychange", () => {
        if (document.visibilityState !== "hidden") {
            setConnected(false);
            webSocket.reconnect();
            setConnected(true);
        }
    });

    webSocket.reconnect();

    setInterval(heartbeat, heartbeatInterval);

    return webSocket;
}
