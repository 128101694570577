/*
##########################################################################
# This file is generated automatically 
using: src/components/icons/functions/generateImports.js
## Generator makes .tsx files that include import/export of custom 
icons that are located in : src/resources/icons/packs
## Pack's folder must contain 'pack name' directory => directories
of icon 'types'
##If introduced icon file names with different patterns => Must edit
getImportTitle() function in order to equalize import titles.
##########################################################################
#
# To update this and all other icon import/export files run: npm run icons
#
##########################################################################
*/

import React from "react";
import {SvgIcon, SvgIconProps} from "@material-ui/core";

import {ReactComponent as CrownIcon} from "../../../../resources/icons/packs/warfare/outlined/001-crown.svg";
import {ReactComponent as BunkerIcon} from "../../../../resources/icons/packs/warfare/outlined/002-bunker.svg";
import {ReactComponent as EnlistIcon} from "../../../../resources/icons/packs/warfare/outlined/003-enlist.svg";
import {ReactComponent as Canon2Icon} from "../../../../resources/icons/packs/warfare/outlined/004-canon-2.svg";
import {ReactComponent as Revolver1Icon} from "../../../../resources/icons/packs/warfare/outlined/005-revolver-1.svg";
import {ReactComponent as ShootingTarget1Icon} from "../../../../resources/icons/packs/warfare/outlined/006-shooting-target-1.svg";
import {ReactComponent as CatapultIcon} from "../../../../resources/icons/packs/warfare/outlined/007-catapult.svg";
import {ReactComponent as PistolIcon} from "../../../../resources/icons/packs/warfare/outlined/008-pistol.svg";
import {ReactComponent as AimIcon} from "../../../../resources/icons/packs/warfare/outlined/009-aim.svg";
import {ReactComponent as DroneIcon} from "../../../../resources/icons/packs/warfare/outlined/010-drone.svg";
import {ReactComponent as ShootingTargetIcon} from "../../../../resources/icons/packs/warfare/outlined/011-shooting-target.svg";
import {ReactComponent as SmokeGrenadeIcon} from "../../../../resources/icons/packs/warfare/outlined/012-smoke-grenade.svg";
import {ReactComponent as Helmet2Icon} from "../../../../resources/icons/packs/warfare/outlined/013-helmet-2.svg";
import {ReactComponent as MachineGun3Icon} from "../../../../resources/icons/packs/warfare/outlined/014-machine-gun-3.svg";
import {ReactComponent as Explosion1Icon} from "../../../../resources/icons/packs/warfare/outlined/015-explosion-1.svg";
import {ReactComponent as ChemicalWeaponIcon} from "../../../../resources/icons/packs/warfare/outlined/016-chemical-weapon.svg";
import {ReactComponent as Bomb9Icon} from "../../../../resources/icons/packs/warfare/outlined/017-bomb-9.svg";
import {ReactComponent as TentIcon} from "../../../../resources/icons/packs/warfare/outlined/018-tent.svg";
import {ReactComponent as RemoteControlIcon} from "../../../../resources/icons/packs/warfare/outlined/019-remote-control.svg";
import {ReactComponent as Supplies1Icon} from "../../../../resources/icons/packs/warfare/outlined/020-supplies-1.svg";
import {ReactComponent as Axe1Icon} from "../../../../resources/icons/packs/warfare/outlined/021-axe-1.svg";
import {ReactComponent as VikingHelmetIcon} from "../../../../resources/icons/packs/warfare/outlined/022-viking-helmet.svg";
import {ReactComponent as ArmourIcon} from "../../../../resources/icons/packs/warfare/outlined/023-armour.svg";
import {ReactComponent as Shield1Icon} from "../../../../resources/icons/packs/warfare/outlined/024-shield-1.svg";
import {ReactComponent as ShieldIcon} from "../../../../resources/icons/packs/warfare/outlined/025-shield.svg";
import {ReactComponent as CrossbowIcon} from "../../../../resources/icons/packs/warfare/outlined/026-crossbow.svg";
import {ReactComponent as ArcheryIcon} from "../../../../resources/icons/packs/warfare/outlined/027-archery.svg";
import {ReactComponent as AxeIcon} from "../../../../resources/icons/packs/warfare/outlined/028-axe.svg";
import {ReactComponent as Sword1Icon} from "../../../../resources/icons/packs/warfare/outlined/029-sword-1.svg";
import {ReactComponent as SwordIcon} from "../../../../resources/icons/packs/warfare/outlined/030-sword.svg";
import {ReactComponent as WalkieTalkieIcon} from "../../../../resources/icons/packs/warfare/outlined/031-walkie-talkie.svg";
import {ReactComponent as MapIcon} from "../../../../resources/icons/packs/warfare/outlined/032-map.svg";
import {ReactComponent as HazmatIcon} from "../../../../resources/icons/packs/warfare/outlined/033-hazmat.svg";
import {ReactComponent as Bomb8Icon} from "../../../../resources/icons/packs/warfare/outlined/034-bomb-8.svg";
import {ReactComponent as CheckPointIcon} from "../../../../resources/icons/packs/warfare/outlined/035-check-point.svg";
import {ReactComponent as RevolverIcon} from "../../../../resources/icons/packs/warfare/outlined/036-revolver.svg";
import {ReactComponent as MachineGun2Icon} from "../../../../resources/icons/packs/warfare/outlined/037-machine-gun-2.svg";
import {ReactComponent as FlameThrowerIcon} from "../../../../resources/icons/packs/warfare/outlined/038-flame-thrower.svg";
import {ReactComponent as Canon1Icon} from "../../../../resources/icons/packs/warfare/outlined/039-canon-1.svg";
import {ReactComponent as Ship1Icon} from "../../../../resources/icons/packs/warfare/outlined/040-ship-1.svg";
import {ReactComponent as BarbedWireIcon} from "../../../../resources/icons/packs/warfare/outlined/041-barbed-wire.svg";
import {ReactComponent as MachineGun1Icon} from "../../../../resources/icons/packs/warfare/outlined/042-machine-gun-1.svg";
import {ReactComponent as BulletsIcon} from "../../../../resources/icons/packs/warfare/outlined/043-bullets.svg";
import {ReactComponent as UziIcon} from "../../../../resources/icons/packs/warfare/outlined/044-uzi.svg";
import {ReactComponent as Jeep1Icon} from "../../../../resources/icons/packs/warfare/outlined/045-jeep-1.svg";
import {ReactComponent as MachineGunIcon} from "../../../../resources/icons/packs/warfare/outlined/046-machine-gun.svg";
import {ReactComponent as Helmet1Icon} from "../../../../resources/icons/packs/warfare/outlined/047-helmet-1.svg";
import {ReactComponent as LauncherIcon} from "../../../../resources/icons/packs/warfare/outlined/048-launcher.svg";
import {ReactComponent as BazookaIcon} from "../../../../resources/icons/packs/warfare/outlined/049-bazooka.svg";
import {ReactComponent as ArmyHelicopterIcon} from "../../../../resources/icons/packs/warfare/outlined/050-army-helicopter.svg";
import {ReactComponent as Truck1Icon} from "../../../../resources/icons/packs/warfare/outlined/051-truck-1.svg";
import {ReactComponent as JeepIcon} from "../../../../resources/icons/packs/warfare/outlined/052-jeep.svg";
import {ReactComponent as TruckIcon} from "../../../../resources/icons/packs/warfare/outlined/053-truck.svg";
import {ReactComponent as HammerIcon} from "../../../../resources/icons/packs/warfare/outlined/054-hammer.svg";
import {ReactComponent as Bomb7Icon} from "../../../../resources/icons/packs/warfare/outlined/055-bomb-7.svg";
import {ReactComponent as ShipIcon} from "../../../../resources/icons/packs/warfare/outlined/056-ship.svg";
import {ReactComponent as Bomb6Icon} from "../../../../resources/icons/packs/warfare/outlined/057-bomb-6.svg";
import {ReactComponent as Mine1Icon} from "../../../../resources/icons/packs/warfare/outlined/058-mine-1.svg";
import {ReactComponent as MineIcon} from "../../../../resources/icons/packs/warfare/outlined/059-mine.svg";
import {ReactComponent as Bomb5Icon} from "../../../../resources/icons/packs/warfare/outlined/060-bomb-5.svg";
import {ReactComponent as Plane1Icon} from "../../../../resources/icons/packs/warfare/outlined/061-plane-1.svg";
import {ReactComponent as ExplosionIcon} from "../../../../resources/icons/packs/warfare/outlined/062-explosion.svg";
import {ReactComponent as Bullet1Icon} from "../../../../resources/icons/packs/warfare/outlined/063-bullet-1.svg";
import {ReactComponent as MilitaryHelicopterIcon} from "../../../../resources/icons/packs/warfare/outlined/064-military-helicopter.svg";
import {ReactComponent as CanonIcon} from "../../../../resources/icons/packs/warfare/outlined/065-canon.svg";
import {ReactComponent as GunIcon} from "../../../../resources/icons/packs/warfare/outlined/066-gun.svg";
import {ReactComponent as PlaneIcon} from "../../../../resources/icons/packs/warfare/outlined/067-plane.svg";
import {ReactComponent as MolotovCocktailIcon} from "../../../../resources/icons/packs/warfare/outlined/068-molotov-cocktail.svg";
import {ReactComponent as KnifeIcon} from "../../../../resources/icons/packs/warfare/outlined/069-knife.svg";
import {ReactComponent as BeretIcon} from "../../../../resources/icons/packs/warfare/outlined/070-beret.svg";
import {ReactComponent as Bomb4Icon} from "../../../../resources/icons/packs/warfare/outlined/071-bomb-4.svg";
import {ReactComponent as CompassIcon} from "../../../../resources/icons/packs/warfare/outlined/072-compass.svg";
import {ReactComponent as Grenade1Icon} from "../../../../resources/icons/packs/warfare/outlined/073-grenade-1.svg";
import {ReactComponent as GrenadeIcon} from "../../../../resources/icons/packs/warfare/outlined/074-grenade.svg";
import {ReactComponent as TargetIcon} from "../../../../resources/icons/packs/warfare/outlined/075-target.svg";
import {ReactComponent as SubmarineIcon} from "../../../../resources/icons/packs/warfare/outlined/076-submarine.svg";
import {ReactComponent as HelmetIcon} from "../../../../resources/icons/packs/warfare/outlined/077-helmet.svg";
import {ReactComponent as BulletIcon} from "../../../../resources/icons/packs/warfare/outlined/078-bullet.svg";
import {ReactComponent as Bomb3Icon} from "../../../../resources/icons/packs/warfare/outlined/079-bomb-3.svg";
import {ReactComponent as Rank3Icon} from "../../../../resources/icons/packs/warfare/outlined/080-rank-3.svg";
import {ReactComponent as Jet2Icon} from "../../../../resources/icons/packs/warfare/outlined/081-jet-2.svg";
import {ReactComponent as Rank2Icon} from "../../../../resources/icons/packs/warfare/outlined/082-rank-2.svg";
import {ReactComponent as BinocularsIcon} from "../../../../resources/icons/packs/warfare/outlined/083-binoculars.svg";
import {ReactComponent as Tank1Icon} from "../../../../resources/icons/packs/warfare/outlined/084-tank-1.svg";
import {ReactComponent as TankIcon} from "../../../../resources/icons/packs/warfare/outlined/085-tank.svg";
import {ReactComponent as Bomb2Icon} from "../../../../resources/icons/packs/warfare/outlined/086-bomb-2.svg";
import {ReactComponent as RadioIcon} from "../../../../resources/icons/packs/warfare/outlined/087-radio.svg";
import {ReactComponent as SuppliesIcon} from "../../../../resources/icons/packs/warfare/outlined/088-supplies.svg";
import {ReactComponent as Bomb1Icon} from "../../../../resources/icons/packs/warfare/outlined/089-bomb-1.svg";
import {ReactComponent as MedalIcon} from "../../../../resources/icons/packs/warfare/outlined/090-medal.svg";
import {ReactComponent as BulletProofVestIcon} from "../../../../resources/icons/packs/warfare/outlined/091-bullet-proof-vest.svg";
import {ReactComponent as Jet1Icon} from "../../../../resources/icons/packs/warfare/outlined/092-jet-1.svg";
import {ReactComponent as JetIcon} from "../../../../resources/icons/packs/warfare/outlined/093-jet.svg";
import {ReactComponent as DogTagIcon} from "../../../../resources/icons/packs/warfare/outlined/094-dog-tag.svg";
import {ReactComponent as Rank1Icon} from "../../../../resources/icons/packs/warfare/outlined/095-rank-1.svg";
import {ReactComponent as RankIcon} from "../../../../resources/icons/packs/warfare/outlined/096-rank.svg";
import {ReactComponent as GasMaskIcon} from "../../../../resources/icons/packs/warfare/outlined/097-gas-mask.svg";
import {ReactComponent as RadarIcon} from "../../../../resources/icons/packs/warfare/outlined/098-radar.svg";
import {ReactComponent as BombIcon} from "../../../../resources/icons/packs/warfare/outlined/099-bomb.svg";
import {ReactComponent as FirstAidKitIcon} from "../../../../resources/icons/packs/warfare/outlined/100-first-aid-kit.svg";

export const Crown = (props: SvgIconProps) => <SvgIcon {...props} component={CrownIcon} viewBox="0 0 512 512" />;
export const Bunker = (props: SvgIconProps) => <SvgIcon {...props} component={BunkerIcon} viewBox="0 0 512 512" />;
export const Enlist = (props: SvgIconProps) => <SvgIcon {...props} component={EnlistIcon} viewBox="0 0 512 512" />;
export const Canon2 = (props: SvgIconProps) => <SvgIcon {...props} component={Canon2Icon} viewBox="0 0 512 512" />;
export const Revolver1 = (props: SvgIconProps) => (
    <SvgIcon {...props} component={Revolver1Icon} viewBox="0 0 512 512" />
);
export const ShootingTarget1 = (props: SvgIconProps) => (
    <SvgIcon {...props} component={ShootingTarget1Icon} viewBox="0 0 512 512" />
);
export const Catapult = (props: SvgIconProps) => <SvgIcon {...props} component={CatapultIcon} viewBox="0 0 512 512" />;
export const Pistol = (props: SvgIconProps) => <SvgIcon {...props} component={PistolIcon} viewBox="0 0 512 512" />;
export const Aim = (props: SvgIconProps) => <SvgIcon {...props} component={AimIcon} viewBox="0 0 512 512" />;
export const Drone = (props: SvgIconProps) => <SvgIcon {...props} component={DroneIcon} viewBox="0 0 512 512" />;
export const ShootingTarget = (props: SvgIconProps) => (
    <SvgIcon {...props} component={ShootingTargetIcon} viewBox="0 0 512 512" />
);
export const SmokeGrenade = (props: SvgIconProps) => (
    <SvgIcon {...props} component={SmokeGrenadeIcon} viewBox="0 0 512 512" />
);
export const Helmet2 = (props: SvgIconProps) => <SvgIcon {...props} component={Helmet2Icon} viewBox="0 0 512 512" />;
export const MachineGun3 = (props: SvgIconProps) => (
    <SvgIcon {...props} component={MachineGun3Icon} viewBox="0 0 512 512" />
);
export const Explosion1 = (props: SvgIconProps) => (
    <SvgIcon {...props} component={Explosion1Icon} viewBox="0 0 512 512" />
);
export const ChemicalWeapon = (props: SvgIconProps) => (
    <SvgIcon {...props} component={ChemicalWeaponIcon} viewBox="0 0 512 512" />
);
export const Bomb9 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb9Icon} viewBox="0 0 512 512" />;
export const Tent = (props: SvgIconProps) => <SvgIcon {...props} component={TentIcon} viewBox="0 0 512 512" />;
export const RemoteControl = (props: SvgIconProps) => (
    <SvgIcon {...props} component={RemoteControlIcon} viewBox="0 0 512 512" />
);
export const Supplies1 = (props: SvgIconProps) => (
    <SvgIcon {...props} component={Supplies1Icon} viewBox="0 0 512 512" />
);
export const Axe1 = (props: SvgIconProps) => <SvgIcon {...props} component={Axe1Icon} viewBox="0 0 512 512" />;
export const VikingHelmet = (props: SvgIconProps) => (
    <SvgIcon {...props} component={VikingHelmetIcon} viewBox="0 0 512 512" />
);
export const Armour = (props: SvgIconProps) => <SvgIcon {...props} component={ArmourIcon} viewBox="0 0 512 512" />;
export const Shield1 = (props: SvgIconProps) => <SvgIcon {...props} component={Shield1Icon} viewBox="0 0 512 512" />;
export const Shield = (props: SvgIconProps) => <SvgIcon {...props} component={ShieldIcon} viewBox="0 0 512 512" />;
export const Crossbow = (props: SvgIconProps) => <SvgIcon {...props} component={CrossbowIcon} viewBox="0 0 512 512" />;
export const Archery = (props: SvgIconProps) => <SvgIcon {...props} component={ArcheryIcon} viewBox="0 0 512 512" />;
export const Axe = (props: SvgIconProps) => <SvgIcon {...props} component={AxeIcon} viewBox="0 0 512 512" />;
export const Sword1 = (props: SvgIconProps) => <SvgIcon {...props} component={Sword1Icon} viewBox="0 0 512 512" />;
export const Sword = (props: SvgIconProps) => <SvgIcon {...props} component={SwordIcon} viewBox="0 0 512 512" />;
export const WalkieTalkie = (props: SvgIconProps) => (
    <SvgIcon {...props} component={WalkieTalkieIcon} viewBox="0 0 512 512" />
);
export const Map = (props: SvgIconProps) => <SvgIcon {...props} component={MapIcon} viewBox="0 0 512 512" />;
export const Hazmat = (props: SvgIconProps) => <SvgIcon {...props} component={HazmatIcon} viewBox="0 0 512 512" />;
export const Bomb8 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb8Icon} viewBox="0 0 512 512" />;
export const CheckPoint = (props: SvgIconProps) => (
    <SvgIcon {...props} component={CheckPointIcon} viewBox="0 0 512 512" />
);
export const Revolver = (props: SvgIconProps) => <SvgIcon {...props} component={RevolverIcon} viewBox="0 0 512 512" />;
export const MachineGun2 = (props: SvgIconProps) => (
    <SvgIcon {...props} component={MachineGun2Icon} viewBox="0 0 512 512" />
);
export const FlameThrower = (props: SvgIconProps) => (
    <SvgIcon {...props} component={FlameThrowerIcon} viewBox="0 0 512 512" />
);
export const Canon1 = (props: SvgIconProps) => <SvgIcon {...props} component={Canon1Icon} viewBox="0 0 512 512" />;
export const Ship1 = (props: SvgIconProps) => <SvgIcon {...props} component={Ship1Icon} viewBox="0 0 512 512" />;
export const BarbedWire = (props: SvgIconProps) => (
    <SvgIcon {...props} component={BarbedWireIcon} viewBox="0 0 512 512" />
);
export const MachineGun1 = (props: SvgIconProps) => (
    <SvgIcon {...props} component={MachineGun1Icon} viewBox="0 0 512 512" />
);
export const Bullets = (props: SvgIconProps) => <SvgIcon {...props} component={BulletsIcon} viewBox="0 0 512 512" />;
export const Uzi = (props: SvgIconProps) => <SvgIcon {...props} component={UziIcon} viewBox="0 0 512 512" />;
export const Jeep1 = (props: SvgIconProps) => <SvgIcon {...props} component={Jeep1Icon} viewBox="0 0 512 512" />;
export const MachineGun = (props: SvgIconProps) => (
    <SvgIcon {...props} component={MachineGunIcon} viewBox="0 0 512 512" />
);
export const Helmet1 = (props: SvgIconProps) => <SvgIcon {...props} component={Helmet1Icon} viewBox="0 0 512 512" />;
export const Launcher = (props: SvgIconProps) => <SvgIcon {...props} component={LauncherIcon} viewBox="0 0 512 512" />;
export const Bazooka = (props: SvgIconProps) => <SvgIcon {...props} component={BazookaIcon} viewBox="0 0 512 512" />;
export const ArmyHelicopter = (props: SvgIconProps) => (
    <SvgIcon {...props} component={ArmyHelicopterIcon} viewBox="0 0 512 512" />
);
export const Truck1 = (props: SvgIconProps) => <SvgIcon {...props} component={Truck1Icon} viewBox="0 0 512 512" />;
export const Jeep = (props: SvgIconProps) => <SvgIcon {...props} component={JeepIcon} viewBox="0 0 512 512" />;
export const Truck = (props: SvgIconProps) => <SvgIcon {...props} component={TruckIcon} viewBox="0 0 512 512" />;
export const Hammer = (props: SvgIconProps) => <SvgIcon {...props} component={HammerIcon} viewBox="0 0 512 512" />;
export const Bomb7 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb7Icon} viewBox="0 0 512 512" />;
export const Ship = (props: SvgIconProps) => <SvgIcon {...props} component={ShipIcon} viewBox="0 0 512 512" />;
export const Bomb6 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb6Icon} viewBox="0 0 512 512" />;
export const Mine1 = (props: SvgIconProps) => <SvgIcon {...props} component={Mine1Icon} viewBox="0 0 512 512" />;
export const Mine = (props: SvgIconProps) => <SvgIcon {...props} component={MineIcon} viewBox="0 0 512 512" />;
export const Bomb5 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb5Icon} viewBox="0 0 512 512" />;
export const Plane1 = (props: SvgIconProps) => <SvgIcon {...props} component={Plane1Icon} viewBox="0 0 512 512" />;
export const Explosion = (props: SvgIconProps) => (
    <SvgIcon {...props} component={ExplosionIcon} viewBox="0 0 512 512" />
);
export const Bullet1 = (props: SvgIconProps) => <SvgIcon {...props} component={Bullet1Icon} viewBox="0 0 512 512" />;
export const MilitaryHelicopter = (props: SvgIconProps) => (
    <SvgIcon {...props} component={MilitaryHelicopterIcon} viewBox="0 0 512 512" />
);
export const Canon = (props: SvgIconProps) => <SvgIcon {...props} component={CanonIcon} viewBox="0 0 512 512" />;
export const Gun = (props: SvgIconProps) => <SvgIcon {...props} component={GunIcon} viewBox="0 0 512 512" />;
export const Plane = (props: SvgIconProps) => <SvgIcon {...props} component={PlaneIcon} viewBox="0 0 512 512" />;
export const MolotovCocktail = (props: SvgIconProps) => (
    <SvgIcon {...props} component={MolotovCocktailIcon} viewBox="0 0 512 512" />
);
export const Knife = (props: SvgIconProps) => <SvgIcon {...props} component={KnifeIcon} viewBox="0 0 512 512" />;
export const Beret = (props: SvgIconProps) => <SvgIcon {...props} component={BeretIcon} viewBox="0 0 512 512" />;
export const Bomb4 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb4Icon} viewBox="0 0 512 512" />;
export const Compass = (props: SvgIconProps) => <SvgIcon {...props} component={CompassIcon} viewBox="0 0 512 512" />;
export const Grenade1 = (props: SvgIconProps) => <SvgIcon {...props} component={Grenade1Icon} viewBox="0 0 512 512" />;
export const Grenade = (props: SvgIconProps) => <SvgIcon {...props} component={GrenadeIcon} viewBox="0 0 512 512" />;
export const Target = (props: SvgIconProps) => <SvgIcon {...props} component={TargetIcon} viewBox="0 0 512 512" />;
export const Submarine = (props: SvgIconProps) => (
    <SvgIcon {...props} component={SubmarineIcon} viewBox="0 0 512 512" />
);
export const Helmet = (props: SvgIconProps) => <SvgIcon {...props} component={HelmetIcon} viewBox="0 0 512 512" />;
export const Bullet = (props: SvgIconProps) => <SvgIcon {...props} component={BulletIcon} viewBox="0 0 512 512" />;
export const Bomb3 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb3Icon} viewBox="0 0 512 512" />;
export const Rank3 = (props: SvgIconProps) => <SvgIcon {...props} component={Rank3Icon} viewBox="0 0 512 512" />;
export const Jet2 = (props: SvgIconProps) => <SvgIcon {...props} component={Jet2Icon} viewBox="0 0 512 512" />;
export const Rank2 = (props: SvgIconProps) => <SvgIcon {...props} component={Rank2Icon} viewBox="0 0 512 512" />;
export const Binoculars = (props: SvgIconProps) => (
    <SvgIcon {...props} component={BinocularsIcon} viewBox="0 0 512 512" />
);
export const Tank1 = (props: SvgIconProps) => <SvgIcon {...props} component={Tank1Icon} viewBox="0 0 512 512" />;
export const Tank = (props: SvgIconProps) => <SvgIcon {...props} component={TankIcon} viewBox="0 0 512 512" />;
export const Bomb2 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb2Icon} viewBox="0 0 512 512" />;
export const Radio = (props: SvgIconProps) => <SvgIcon {...props} component={RadioIcon} viewBox="0 0 512 512" />;
export const Supplies = (props: SvgIconProps) => <SvgIcon {...props} component={SuppliesIcon} viewBox="0 0 512 512" />;
export const Bomb1 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb1Icon} viewBox="0 0 512 512" />;
export const Medal = (props: SvgIconProps) => <SvgIcon {...props} component={MedalIcon} viewBox="0 0 512 512" />;
export const BulletProofVest = (props: SvgIconProps) => (
    <SvgIcon {...props} component={BulletProofVestIcon} viewBox="0 0 512 512" />
);
export const Jet1 = (props: SvgIconProps) => <SvgIcon {...props} component={Jet1Icon} viewBox="0 0 512 512" />;
export const Jet = (props: SvgIconProps) => <SvgIcon {...props} component={JetIcon} viewBox="0 0 512 512" />;
export const DogTag = (props: SvgIconProps) => <SvgIcon {...props} component={DogTagIcon} viewBox="0 0 512 512" />;
export const Rank1 = (props: SvgIconProps) => <SvgIcon {...props} component={Rank1Icon} viewBox="0 0 512 512" />;
export const Rank = (props: SvgIconProps) => <SvgIcon {...props} component={RankIcon} viewBox="0 0 512 512" />;
export const GasMask = (props: SvgIconProps) => <SvgIcon {...props} component={GasMaskIcon} viewBox="0 0 512 512" />;
export const Radar = (props: SvgIconProps) => <SvgIcon {...props} component={RadarIcon} viewBox="0 0 512 512" />;
export const Bomb = (props: SvgIconProps) => <SvgIcon {...props} component={BombIcon} viewBox="0 0 512 512" />;
export const FirstAidKit = (props: SvgIconProps) => (
    <SvgIcon {...props} component={FirstAidKitIcon} viewBox="0 0 512 512" />
);
