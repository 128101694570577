import React, {ReactNode} from "react";
import {CircularProgress, Typography} from "@material-ui/core";
import styles from "./LoadingScreen.module.css";

export default function LoadingScreen(props: {message?: string | ReactNode}) {
    const {message} = props;

    return (
        <div className={styles.loading}>
            <div>
                <CircularProgress size="8em" thickness={2} />
                {message && <Typography variant="h6">{props.message}</Typography>}
            </div>
        </div>
    );
}
