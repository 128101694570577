export const DJV2CHits = [
    {x: 69, y: 92, score: 8, timeStamp: 1632818969543},
    {x: 38, y: 70, score: 9, timeStamp: 1632818969041},
    {
        x: 76,
        y: 52,
        score: 8,
        timeStamp: 1632818969018,
    },
    {x: 67, y: 13, score: 9, timeStamp: 1632818968515},
    {x: 28, y: 98, score: 8, timeStamp: 1632818968472},
    {
        x: 97,
        y: 95,
        score: 5,
        timeStamp: 1632818968454,
    },
    {x: 76, y: 68, score: 8, timeStamp: 1632818967835},
    {x: 62, y: 35, score: 9, timeStamp: 1632818967802},
    {
        x: 51,
        y: 69,
        score: 8,
        timeStamp: 1632818967300,
    },
    {x: 44, y: 63, score: 9, timeStamp: 1632818967258},
    {x: 14, y: 3, score: 10, timeStamp: 1632818966774},
    {
        x: 84,
        y: 37,
        score: 8,
        timeStamp: 1632818966742,
    },
    {x: 75, y: 26, score: 9, timeStamp: 1632818966255},
    {x: 13, y: 22, score: 10, timeStamp: 1632818966204},
    {
        x: 65,
        y: 62,
        score: 8,
        timeStamp: 1632818965729,
    },
    {x: 19, y: 12, score: 10, timeStamp: 1632818965700},
    {x: 40, y: 12, score: 10, timeStamp: 1632818965203},
    {
        x: 29,
        y: 38,
        score: 10,
        timeStamp: 1632818964043,
    },
    {x: 11, y: 26, score: 10, timeStamp: 1632818963504},
    {x: 7, y: 61, score: 9, timeStamp: 1632818963264},
    {
        x: 49,
        y: 18,
        score: 9,
        timeStamp: 1632818963246,
    },
    {x: 85, y: 21, score: 8, timeStamp: 1632818963232},
    {x: 90, y: 76, score: 8, timeStamp: 1632818962410},
    {
        x: 32,
        y: 69,
        score: 9,
        timeStamp: 1632818962389,
    },
    {x: 44, y: 66, score: 9, timeStamp: 1632818961937},
    {x: 72, y: 95, score: 8, timeStamp: 1632818961916},
    {
        x: 54,
        y: 86,
        score: 8,
        timeStamp: 1632818961894,
    },
    {x: 8, y: 10, score: 10, timeStamp: 1632818961430},
    {x: 27, y: 5, score: 10, timeStamp: 1632818961410},
    {
        x: 74,
        y: 60,
        score: 8,
        timeStamp: 1632818960987,
    },
    {x: 64, y: 27, score: 9, timeStamp: 1632818960948},
    {x: 14, y: 84, score: 8, timeStamp: 1632818960538},
    {
        x: 6,
        y: 88,
        score: 8,
        timeStamp: 1632818960520,
    },
    {x: 45, y: 63, score: 9, timeStamp: 1632818960104},
    {x: 11, y: 16, score: 10, timeStamp: 1632818959695},
    {
        x: 24,
        y: 9,
        score: 10,
        timeStamp: 1632818959333,
    },
    {x: 93, y: 55, score: 8, timeStamp: 1632818959323},
    {x: 18, y: 52, score: 9, timeStamp: 1632818955112},
    {
        x: 83,
        y: 58,
        score: 8,
        timeStamp: 1632818955091,
    },
    {x: 93, y: 48, score: 8, timeStamp: 1632818954647},
    {x: 8, y: 30, score: 10, timeStamp: 1632818954617},
    {
        x: 76,
        y: 65,
        score: 8,
        timeStamp: 1632818954595,
    },
    {x: 82, y: 77, score: 8, timeStamp: 1632818954074},
    {x: 67, y: 47, score: 9, timeStamp: 1632818953905},
    {
        x: 34,
        y: 2,
        score: 10,
        timeStamp: 1632818953799,
    },
    {x: 81, y: 52, score: 8, timeStamp: 1632818953750},
    {x: 96, y: 45, score: 8, timeStamp: 1632818953731},
    {
        x: 12,
        y: 46,
        score: 10,
        timeStamp: 1632818953200,
    },
    {x: 16, y: 12, score: 10, timeStamp: 1632818953175},
    {x: 36, y: 37, score: 9, timeStamp: 1632818952756},
    {
        x: -58,
        y: 47,
        score: 9,
        timeStamp: 1632818929540,
    },
    {x: -97, y: 82, score: 5, timeStamp: 1632818928931},
    {
        x: -38,
        y: 22,
        score: 10,
        timeStamp: 1632818928558,
    },
    {x: -13, y: 49, score: 9, timeStamp: 1632818925306},
    {
        x: -36,
        y: 95,
        score: 8,
        timeStamp: 1632818924958,
    },
    {x: -48, y: 72, score: 8, timeStamp: 1632818924619},
    {
        x: -70,
        y: 14,
        score: 9,
        timeStamp: 1632818915246,
    },
    {x: -80, y: 79, score: 8, timeStamp: 1632818915228},
    {
        x: -48,
        y: 50,
        score: 9,
        timeStamp: 1632818914881,
    },
    {x: -83, y: 26, score: 8, timeStamp: 1632818914860},
    {
        x: -41,
        y: 69,
        score: 9,
        timeStamp: 1632818914503,
    },
    {x: -93, y: 45, score: 8, timeStamp: 1632818914204},
    {
        x: -42,
        y: 38,
        score: 9,
        timeStamp: 1632818914175,
    },
    {x: -37, y: 76, score: 8, timeStamp: 1632818913874},
    {
        x: -55,
        y: 74,
        score: 8,
        timeStamp: 1632818913551,
    },
    {x: -79, y: 86, score: 8, timeStamp: 1632818913521},
    {
        x: -8,
        y: 100,
        score: 8,
        timeStamp: 1632818913203,
    },
    {x: -39, y: 68, score: 9, timeStamp: 1632818913181},
    {
        x: -38,
        y: 12,
        score: 10,
        timeStamp: 1632818912853,
    },
    {x: -21, y: 69, score: 9, timeStamp: 1632818912834},
    {
        x: -99,
        y: 53,
        score: 8,
        timeStamp: 1632818912464,
    },
    {x: -67, y: 36, score: 9, timeStamp: 1632818912432},
    {x: 0, y: 83, score: 9, timeStamp: 1632818912130},
    {
        x: -93,
        y: 67,
        score: 8,
        timeStamp: 1632818911782,
    },
    {x: -6, y: 81, score: 9, timeStamp: 1632818911624},
    {x: -76, y: 51, score: 8, timeStamp: 1632818911600},
    {
        x: -57,
        y: 69,
        score: 8,
        timeStamp: 1632818910947,
    },
    {x: -73, y: 42, score: 9, timeStamp: 1632818910665},
    {
        x: -29,
        y: 95,
        score: 8,
        timeStamp: 1632818910330,
    },
    {x: -13, y: -24, score: 10, timeStamp: 1632818863965},
    {
        x: -6,
        y: -72,
        score: 9,
        timeStamp: 1632818863941,
    },
    {x: -46, y: -9, score: 10, timeStamp: 1632818863767},
    {
        x: -60,
        y: -31,
        score: 9,
        timeStamp: 1632818863592,
    },
    {x: -6, y: -76, score: 9, timeStamp: 1632818863425},
    {
        x: -91,
        y: -96,
        score: 5,
        timeStamp: 1632818863242,
    },
    {x: -7, y: -78, score: 9, timeStamp: 1632818863073},
    {
        x: -64,
        y: -30,
        score: 9,
        timeStamp: 1632818862817,
    },
    {x: -29, y: -26, score: 10, timeStamp: 1632818862751},
    {
        x: -46,
        y: -95,
        score: 8,
        timeStamp: 1632818862574,
    },
    {x: -13, y: -3, score: 10, timeStamp: 1632818862401},
    {
        x: -82,
        y: -69,
        score: 8,
        timeStamp: 1632818862294,
    },
    {x: -23, y: -16, score: 10, timeStamp: 1632818862104},
    {
        x: -30,
        y: -56,
        score: 9,
        timeStamp: 1632818862071,
    },
    {x: -20, y: -47, score: 9, timeStamp: 1632818861823},
    {
        x: -69,
        y: -66,
        score: 8,
        timeStamp: 1632818861659,
    },
    {x: -19, y: -92, score: 8, timeStamp: 1632818861457},
    {
        x: -71,
        y: -12,
        score: 9,
        timeStamp: 1632818861252,
    },
    {x: -32, y: -38, score: 9, timeStamp: 1632818861231},
    {
        x: -98,
        y: -31,
        score: 8,
        timeStamp: 1632818860889,
    },
    {x: -85, y: -48, score: 8, timeStamp: 1632818860725},
    {
        x: -96,
        y: -20,
        score: 8,
        timeStamp: 1632818860528,
    },
    {x: -82, y: -83, score: 8, timeStamp: 1632818860501},
    {
        x: -49,
        y: -25,
        score: 9,
        timeStamp: 1632818860275,
    },
    {x: -4, y: -5, score: 10, timeStamp: 1632818860009},
    {x: 22, y: -29, score: 10, timeStamp: 1632819253530},
    {x: 20, y: -12, score: 10, timeStamp: 1632819253509},
    {
        x: 89,
        y: -53,
        score: 8,
        timeStamp: 1632819253287,
    },
    {x: 37, y: -58, score: 9, timeStamp: 1632819253087},
    {x: 40, y: -77, score: 8, timeStamp: 1632819252907},
    {
        x: 20,
        y: -21,
        score: 10,
        timeStamp: 1632819252665,
    },
    {x: 81, y: -11, score: 9, timeStamp: 1632819252623},
    {x: 56, y: -31, score: 9, timeStamp: 1632819252356},
    {
        x: 22,
        y: -89,
        score: 8,
        timeStamp: 1632819252330,
    },
    {x: 35, y: -77, score: 9, timeStamp: 1632819252090},
    {x: 81, y: -59, score: 8, timeStamp: 1632819251920},
    {
        x: 7,
        y: -73,
        score: 9,
        timeStamp: 1632819251716,
    },
    {x: 10, y: 0, score: 10, timeStamp: 1632819251682},
    {x: 54, y: -57, score: 9, timeStamp: 1632819251486},
    {
        x: 6,
        y: -59,
        score: 9,
        timeStamp: 1632819251298,
    },
    {x: 35, y: -98, score: 8, timeStamp: 1632819251136},
    {x: 95, y: -68, score: 8, timeStamp: 1632819250937},
    {
        x: 61,
        y: -46,
        score: 9,
        timeStamp: 1632819250779,
    },
    {x: 8, y: -57, score: 9, timeStamp: 1632819250572},
    {x: 63, y: -93, score: 8, timeStamp: 1632819250386},
    {
        x: 90,
        y: -49,
        score: 8,
        timeStamp: 1632819250187,
    },
    {x: 42, y: -50, score: 9, timeStamp: 1632819248629},
];
