import React, {useContext, useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {Button, Chip, Grid} from "@material-ui/core";
import {User, UsersControllerApi} from "../../api/generated/esa";
import {Delete, Edit, Lock} from "@material-ui/icons";
import {translation, TranslationKey, translationOrName, translationString} from "../i18n";
import {useIntl} from "react-intl";
import {isSuperAdmin} from "../common/utils";
import {
    GridCellParams,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from "@material-ui/data-grid";
import Confirmation from "../common/Confirmation";
import {NotificationContext} from "../NotificationContextProvider";
import {AppContext} from "../AppContextProvider";
import GetAppIcon from "@material-ui/icons/GetApp";
import {ImportButtonUser} from "./components/ImportButtonUser";
import {CustomNoRowsOverlay} from "../common/CustomNoRowsOverlay";
import {CustomLoadingOverlay} from "../common/CustomLoadingOverlay";
import {CommonDataGrid} from "../common/CommonDataGrid";

const usersControllerApi = new UsersControllerApi();

export default function UserTable(props: {
    users: User[];
    setEditableUser: Setter<User | undefined>;
    loadUsers: () => void;
}) {
    const {users, setEditableUser, loadUsers} = props;
    const [loggedId, setLoggedIn] = useState<string[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [filteredSorted, setFilteredSorted] = useState<User[]>([]);
    const {user: currentUser} = useContext(AppContext);
    const {notify} = useContext(NotificationContext);
    const intl = useIntl();

    useEffect(() => {
        usersControllerApi.loggedInUsers().then(setLoggedIn);
    }, []);

    function deleteUsers() {
        Promise.all(
            selected.map(userId =>
                usersControllerApi.deleteUser({
                    ...{
                        userId,
                    },
                }),
            ),
        ).then(() => {
            setSelected([]);
            loadUsers();
            const translationValues = {users: selected.length === 1 ? "" : "s"};
            notify("success", "label.users_deleted", {...{translationValues}});
        });
    }

    function getHeaderName(key: TranslationKey) {
        return translationString(intl, key);
    }

    const commonDef = {
        filterable: true,
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: getHeaderName("label.users.name"),
            width: 150,
            ...commonDef,
        },
        {
            field: "roles",
            headerName: getHeaderName("label.users.roles"),
            width: 365,
            renderCell: (params: GridCellParams) => {
                return (
                    <>
                        {params.row.roles.map((it: string) => (
                            <Chip
                                label={translationOrName("roles", it)}
                                variant={it.toLowerCase() === "admin" ? "default" : "outlined"}
                            />
                        ))}
                    </>
                );
            },
        },
        {
            field: "secretType",
            headerName: getHeaderName("label.users.protected"),
            width: 100,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridCellParams) => {
                return <>{params.row.secretType === "password" && <Lock color="disabled" />}</>;
            },
        },
        {
            field: "firstName",
            headerName: getHeaderName("label.logout"),
            width: 140,
            renderCell: (params: GridCellParams) => {
                return (
                    <>
                        {loggedId.includes(params.row.id) && (
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    usersControllerApi
                                        .logout({userId: params.row.id})
                                        .then(() => void usersControllerApi.loggedInUsers().then(setLoggedIn));
                                }}>
                                {translation("label.logout")}
                            </Button>
                        )}{" "}
                    </>
                );
            },
        },
        {
            field: "id",
            headerName: getHeaderName("settings.edit"),
            width: 130,
            renderCell: (params: GridCellParams) => {
                return (
                    <>
                        <IconButton
                            id={`user_edit_${params.row.name}`}
                            style={{margin: "0 16px"}}
                            onClick={() => {
                                setEditableUser(params.row as User);
                            }}
                            component="span">
                            <Edit color="action" />
                        </IconButton>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        setFilteredSorted(
            users.filter(it => {
                return !(isSuperAdmin(it) || it.id === currentUser.id);
            }),
        );
    }, [users, currentUser.id]);

    function SelectedUsersHandler() {
        return (
            <Grid container alignItems="center" style={{marginLeft: 8}}>
                {`${selected.length} `}
                {translation("label.users_selected", {users: selected.length})}
                <Confirmation
                    title="title.prompt.delete"
                    text={
                        <>
                            <b> {selected.length} </b>{" "}
                            {translation("label.users_from_database", {users: selected.length})}
                        </>
                    }
                    onClick={deleteUsers}
                    okButton="button.delete">
                    <Button
                        id="delete_selected_users"
                        color="inherit"
                        style={{
                            marginLeft: "auto",
                        }}
                        startIcon={<Delete />}>
                        {translation("button.delete")}
                    </Button>
                </Confirmation>
            </Grid>
        );
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer
                style={
                    selected.length
                        ? {backgroundColor: "var(--devPaletteErrorDark)", color: "var(--devPaletteErrorContrastText)"}
                        : {}
                }>
                {selected.length ? (
                    <SelectedUsersHandler />
                ) : (
                    <>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <Button
                            disabled={users.length <= 1}
                            id="user_management_export_action_select"
                            style={{marginLeft: "auto"}}
                            endIcon={<GetAppIcon />}
                            color="primary"
                            href="api/users/export">
                            {translation("button.user_management.export")}
                        </Button>
                        <ImportButtonUser name="user_management_import_action" loadUsers={loadUsers} />
                    </>
                )}
            </GridToolbarContainer>
        );
    }

    return (
        <Grid id="#user_management_table" style={{height: "100%", width: "100%"}}>
            <CommonDataGrid
                components={{
                    Toolbar: CustomToolbar,
                    LoadingOverlay: CustomLoadingOverlay,
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                selectionModel={selected}
                onSelectionModelChange={newSelectionModel => {
                    setSelected(newSelectionModel.selectionModel as string[]);
                }}
                rows={filteredSorted}
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                disableColumnMenu
            />
        </Grid>
    );
}
