import React, {ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {hashFromUrl} from "./common/utils";
import {AppLayout, AppLayoutType, placeholderScreen, Screen} from "./LayoutManager";
import LoadingScreen from "./LoadingScreen";
import {translation} from "./i18n";
import {AppContext} from "./AppContextProvider";
import defaultScreen from "../config/screens/defaultScreen.json";
import reviewScreen from "../config/screens/reviewScreen.json";
import selectScreen from "../config/screens/selectScreen.json";
import laneScreen from "../config/screens/laneScreen.json";
import spectateFinals from "../config/screens/spectate/finals/spectateFinals.json";

import spectateFinalsControls from "../config/screens/spectate/finals/spectateFinalsControls.json";
import qualification from "../config/screens/spectate/qualification/spectateQualification.json";
import qualificationControls from "../config/screens/spectate/qualification/spectateQualificationControls.json";
import {ConstantsNumberCommon, ConstantsStringEsa} from "../api/generated/esa";

export default function AppLayoutProviderEsa(props: {children?: ReactNode}) {
    const [screen, setScreen] = useState<Screen>(placeholderScreen);
    const [screenEditable, setScreenEditable] = useState(false);
    const [addComponentShow, setAddComponentShow] = useState(false);
    const [location, setLocation] = useState(hashFromUrl());
    const [burgerOpen, setBurgerOpen] = useState(false);

    const {
        userDevice: {deviceId},
        phase: {disciplineName},
        analytics,
    } = useContext(AppContext);

    const layout = useMemo<AppLayoutType>(
        () => ({
            ...{
                screen,
                setScreen,
                screenEditable,
                setScreenEditable,
                addComponentShow,
                setAddComponentShow,
                location,
                burgerOpen,
                setBurgerOpen,
            },
        }),
        [
            screen,
            setScreen,
            screenEditable,
            setScreenEditable,
            addComponentShow,
            setAddComponentShow,
            location,
            burgerOpen,
            setBurgerOpen,
        ],
    );

    useEffect(() => {
        function hashChangeListener(event: HashChangeEvent) {
            setLocation(hashFromUrl(event.newURL));
            analytics.page();
        }
        window.addEventListener("hashchange", hashChangeListener);
        return function cleanup() {
            window.removeEventListener("hashchange", hashChangeListener);
        };
    }, [setLocation, analytics]);

    useEffect(() => {
        const getConfig = location.split("/")[0];
        const configName = ["", "start"].includes(getConfig) ? "default" : location;
        const deviceUndefined = deviceId === ConstantsNumberCommon.DEVICE_UNDEFINED;
        const trainingUndefined = disciplineName === ConstantsStringEsa.NO_TRAINING_NAME;

        if (configName === "default" && deviceUndefined) {
            setScreen(laneScreen as Screen);
        } else if (configName === "default" && trainingUndefined) {
            setScreen(selectScreen as Screen);
        } else if (configName === "default") {
            setScreen(defaultScreen as Screen);
        } else if (configName === "review") {
            setScreen(reviewScreen as Screen);
        } else if (configName === "spectate") {
            setScreen(spectateFinals as Screen);
        } else if (configName === "spectate-controls") {
            setScreen(spectateFinalsControls as Screen);
        } else if (configName === "qualification") {
            setScreen(qualification as Screen);
        } else if (configName === "qualification-controls") {
            setScreen(qualificationControls as Screen);
        }
    }, [location, setScreen, disciplineName, deviceId]);

    return screen === placeholderScreen ? (
        <LoadingScreen message={translation("message.loading_layout")} />
    ) : (
        <AppLayout.Provider value={layout}>{props.children}</AppLayout.Provider>
    );
}
