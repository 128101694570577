/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface WebSocketMessagePhasePeriod
 */
export interface WebSocketMessagePhasePeriod {
    /**
     *
     * @type {string}
     * @memberof WebSocketMessagePhasePeriod
     */
    type: WebSocketMessagePhasePeriodTypeEnum;
    /**
     *
     * @type {string}
     * @memberof WebSocketMessagePhasePeriod
     */
    className?: WebSocketMessagePhasePeriodClassNameEnum;
    /**
     *
     * @type {number}
     * @memberof WebSocketMessagePhasePeriod
     */
    time: number;
}

export function WebSocketMessagePhasePeriodFromJSON(json: any): WebSocketMessagePhasePeriod {
    return WebSocketMessagePhasePeriodFromJSONTyped(json, false);
}

export function WebSocketMessagePhasePeriodFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): WebSocketMessagePhasePeriod {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: json["type"],
        className: !exists(json, "className") ? undefined : json["className"],
        time: json["time"],
    };
}

export function WebSocketMessagePhasePeriodToJSON(value?: WebSocketMessagePhasePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        type: value.type,
        className: value.className,
        time: value.time,
    };
}

/**
 * @export
 * @enum {string}
 */
export enum WebSocketMessagePhasePeriodTypeEnum {
    Shoot = "Shoot",
    Stop = "Stop",
    FirstWarning = "FirstWarning",
    SecondWarning = "SecondWarning",
}
/**
 * @export
 * @enum {string}
 */
export enum WebSocketMessagePhasePeriodClassNameEnum {
    Error = "Error",
    Success = "Success",
    Warning = "Warning",
    Info = "Info",
    Primary = "Primary",
    Secondary = "Secondary",
}
