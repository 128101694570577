import {Button} from "@material-ui/core";
import React, {useContext, useEffect, useRef, useState} from "react";
import {v4 as uuid} from "uuid";
import {translation} from "../../i18n";
import {AppContext} from "../../AppContextProvider";
import {CloudControllerApi} from "../../../api/generated/esa";
import {NotificationContext} from "../../NotificationContextProvider";
import styles from "./Cloud.module.css";
import Popup from "../../common/Popup";
import {setAvoidMultipleOpenTabs} from "./LoginContent";

const cloudControllerApi = new CloudControllerApi();
const cloudName = process.env.REACT_APP_CLOUD_URL?.replace(/^(http|https?):\/\//, "");

export function Cloud(props: {style: {}}) {
    const {style} = props;
    const [token, setToken] = useState<string | undefined>();
    const {refresh} = useContext(AppContext);
    const {notify} = useContext(NotificationContext);
    const popup = useRef<Window | null>();

    function onClick() {
        setToken(uuid());
    }

    useEffect(() => {
        if (token) {
            let retries = 30;
            const interval = setInterval(() => {
                cloudControllerApi
                    .externalLogin({...{token}})
                    .then(user => {
                        if (user.id !== "guest") {
                            popup.current?.close();
                            clearInterval(interval);
                            setAvoidMultipleOpenTabs();
                            refresh();
                        }
                    })
                    .finally(() => {
                        if (retries-- < 0) {
                            popup.current?.close();
                            clearInterval(interval);
                            notify("error", "cloud.login_failed");
                            setToken(undefined);
                        }
                    });
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [token, refresh, notify, setToken]);

    return (
        <>
            <Popup
                onClose={() => setToken(undefined)}
                show={token !== undefined}
                title={translation("cloud.login", {...{cloudName}})}
                okButton={<></>}
                okButtonElement={<></>}
                closeButton="button.cancel">
                <iframe
                    title="login"
                    className={styles.loginIframe}
                    src={`${process.env.REACT_APP_CLOUD_URL}/login?device_token=${token}&embed=true`}
                />
            </Popup>

            <Button id="cloud_login" {...{style, onClick}} size="large" variant="outlined" color="primary">
                {translation("cloud.login", {...{cloudName}})}
            </Button>
        </>
    );
}
