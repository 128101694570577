import React, {useContext} from "react";
import {MenuItem, Typography} from "@material-ui/core";
import DropdownButton from "./DropdownButton";
import {ReactComponent as Minus} from "@fortawesome/fontawesome-free/svgs/solid/minus.svg";
import {ReactComponent as Plus} from "@fortawesome/fontawesome-free/svgs/solid/plus.svg";
import {translation} from "../i18n";
import {AppContext} from "../AppContextProvider";
import {trackClick} from "../AnalyticsClickTracker";
import styles from "./ZoomControl.module.css";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {CenteringOptions} from "../lomah/target/components/Center";

export default function ZoomControl(props: {
    zoom: number;
    setZoom: (zoom: number) => void;
    zoomLevels?: number[];
    autoZoom?: boolean;
    setAutoZoom?: (auto: boolean) => void;
    autoCenter?: CenteringOptions;
    style?: React.CSSProperties;
    numberingOffset?: number;
}) {
    const zoomInStep = 0.25;
    const defaultLevels = [...Array(4)].map((i, index) => (index + 1) / 2);
    const {zoom, setZoom, zoomLevels, style, setAutoZoom, autoZoom, numberingOffset} = props;
    const {analytics} = useContext(AppContext);

    function changeZoom(level: number) {
        setZoom(Math.max(level, 1));
        setAutoZoom && setAutoZoom(false);
    }

    const maxZoom = zoomLevels && zoom >= zoomLevels[zoomLevels.length - 1];
    const activeButtonPlus = autoZoom || maxZoom;
    const activeButtonMinus = autoZoom || zoom <= 1;
    const disableAuto: CenteringOptions[] = ["MPI", "Hit", "Off"];
    const startAt = numberingOffset ?? 1;

    return (
        <ToggleButtonGroup orientation="vertical" id="target_zoom_control" className={styles.zoomControl} {...{style}}>
            <ToggleButton
                className={styles.controlButton}
                value="out"
                onClick={() => {
                    trackClick(analytics, "Target.ZoomOut");
                    setZoom(Math.max(zoom - zoomInStep, 1));
                }}
                disabled={activeButtonMinus}>
                <Minus className="svgIcon" />
            </ToggleButton>
            <ToggleButton value="set" component="div" className={styles.selectButton}>
                <DropdownButton
                    buttonClass={styles.controlButton}
                    source={<ZoomLabel {...{autoZoom, zoom, zoomLevels, startAt}} />}>
                    {setAutoZoom && (
                        <MenuItem
                            disabled={props.autoCenter && disableAuto.includes(props.autoCenter)}
                            onClick={() => {
                                trackClick(analytics, "Target.Zoom.Auto");
                                setAutoZoom(true);
                            }}>
                            {translation("component.target.zoom.level.auto")}
                        </MenuItem>
                    )}
                    {zoomLevels
                        ? zoomLevels.map((level, index) => (
                              <MenuItem
                                  key={index + "_zoom_index"}
                                  onClick={() => {
                                      trackClick(analytics, "Target.Zoom.Ring." + (index + 1));
                                      changeZoom(level);
                                  }}>
                                  {translation("component.target.zoom.level.ring", {ringIndex: index + startAt})}
                              </MenuItem>
                          ))
                        : defaultLevels.map(level => (
                              <MenuItem
                                  key={level}
                                  onClick={() => {
                                      trackClick(analytics, "Target.Zoom." + level);
                                      changeZoom(level);
                                  }}>
                                  {level * 100}%
                              </MenuItem>
                          ))}
                </DropdownButton>
            </ToggleButton>
            <ToggleButton
                value="in"
                className={styles.controlButton}
                disabled={activeButtonPlus}
                onClick={() => {
                    trackClick(analytics, "Target.ZoomIn");
                    setZoom(zoom + zoomInStep);
                }}>
                <Plus className="svgIcon" />
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

function ZoomLabel(props: {autoZoom?: boolean; zoom: number; zoomLevels?: number[]; startAt: number}) {
    const {autoZoom, zoom, zoomLevels, startAt} = props;

    if (autoZoom) {
        return (
            <Typography variant="body2" color="primary" gutterBottom>
                {translation("component.target.zoom.level.auto")}
            </Typography>
        );
    } else if (zoomLevels?.includes(zoom)) {
        return (
            <span>
                {translation("component.target.zoom.level.ring", {ringIndex: zoomLevels.indexOf(zoom) + startAt})}
            </span>
        );
    } else {
        return <span>{(zoom * 100).toFixed(0) + "%"}</span>;
    }
}
