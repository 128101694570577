import React from "react";
import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton} from "@material-ui/data-grid";

export function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}
