import React, {ReactNode, useContext, useState} from "react";

import Timer, {PHASE_NOTIFICATION} from "../../components/timer/Timer";
import {AppContext, phasePlaceholder} from "../../components/AppContextProvider";
import {useSnackbar} from "notistack";
import {AppSettings} from "../../components/AppSettingsProvider";
import {AppContextTypeMock, startedPhase} from "./PortalMock";
import {isTimeEnded} from "../../components/common/utils";

export default function TimerMock(props: {id: string; setHeader: (id: string, header: ReactNode | String) => void}) {
    const {phase, setPhase, select, trainingSessions, setTrainingSessions, setPhases} = useContext(
        AppContext,
    ) as AppContextTypeMock;
    const {closeSnackbar} = useSnackbar();
    const {
        Components: {
            Timer: {warmUpSec},
        },
    } = useContext(AppSettings);

    const millisecondsInSecond = 1000;
    const warmUp = phase.started || phase.unlimited ? 0 : warmUpSec * millisecondsInSecond;
    const [pausedDuration, setPausedDuration] = useState(0);
    const [pausedTime, setPausedTime] = useState(0);

    function saveToLocalPhaseRepository(incomingPhase: Phase) {
        const time = Date.now();
        const start = incomingPhase.start ?? time;
        const currentPause = phase.paused ? time - pausedTime : 0;
        const duration = time - start - pausedDuration - currentPause;
        const localPhase = {
            ...incomingPhase,
            start: start,
            started: incomingPhase.started,
            paused: undefined,
            nextPhase: undefined,
            duration: duration,
            notPaused: true,
            end: time,
        };

        setPhases(phases => {
            const updated = {...phases};
            const phasesMap = {...(updated[localPhase.sessionId] ?? {})};
            phasesMap[localPhase.id] = localPhase;
            updated[localPhase.sessionId] = phasesMap;
            return updated;
        });
        setPausedDuration(0);
    }

    function start() {
        setPhase(it => {
            const shouldStart = Date.now();
            const startAt = it.start ? it.start : shouldStart + warmUp;
            const endAt = shouldStart + warmUp + (it.paused ?? 0);
            const pauseTime = shouldStart - pausedTime;
            it.started && setPausedDuration(old => old + pauseTime);
            return startedPhase(it, startAt, endAt);
        });
    }

    function pause() {
        setPhase(it => {
            if (it.end && !isTimeEnded(it.end)) {
                const now = Date.now();
                const pausedAt = it.end - now;
                setPausedTime(now);
                return {...it, paused: pausedAt, end: undefined, notPaused: false};
            } else {
                return it;
            }
        });
    }

    function remove() {
        setPhases(old => {
            delete old[`${phase.sessionId}`];
            return {...old};
        });
        setPhase(phasePlaceholder);
    }

    function next() {
        saveToLocalPhaseRepository(phase);
        setPhase(phase => {
            return {...phase, end: Date.now()};
        });
        phase.nextPhase && select(phase.disciplineName, phase.nextPhase, phase.targetName, phase.weaponId);
    }

    function reset() {
        setTrainingSessions(trainingSessions.slice(0, trainingSessions.length - 1));
        select(phase.disciplineName, 0, phase.targetName, phase.weaponId);
    }

    function complete() {
        closeSnackbar(PHASE_NOTIFICATION);
        if (!phase.started) {
            remove();
            return;
        }
        saveToLocalPhaseRepository(phase);
        window.location.href = "#review";
        setPhase(phasePlaceholder);
    }

    return <Timer {...props} {...{phase, pause, start, next, complete, reset, remove}} allowControls={true} />;
}
