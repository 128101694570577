/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {Hit, HitFromJSON, HitToJSON} from "../models";

export interface EditHitRequest {
    id: string;
    hit: Hit;
}

export interface GetHitsRequest {
    phase: Array<string>;
}

export interface RemoveHitRequest {
    id: string;
    hit: Hit;
}

/**
 *
 */
export class HitControllerApi extends runtime.BaseAPI {
    /**
     */
    async editHitRaw(requestParameters: EditHitRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling editHit.",
            );
        }

        if (requestParameters.hit === null || requestParameters.hit === undefined) {
            throw new runtime.RequiredError(
                "hit",
                "Required parameter requestParameters.hit was null or undefined when calling editHit.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/hits/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: HitToJSON(requestParameters.hit),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async editHit(requestParameters: EditHitRequest): Promise<void> {
        await this.editHitRaw(requestParameters);
    }

    /**
     */
    async getHitsRaw(requestParameters: GetHitsRequest): Promise<runtime.ApiResponse<Array<Hit>>> {
        if (requestParameters.phase === null || requestParameters.phase === undefined) {
            throw new runtime.RequiredError(
                "phase",
                "Required parameter requestParameters.phase was null or undefined when calling getHits.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.phase) {
            queryParameters["phase"] = requestParameters.phase;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/hits`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(HitFromJSON));
    }

    /**
     */
    async getHits(requestParameters: GetHitsRequest): Promise<Array<Hit>> {
        const response = await this.getHitsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async removeHitRaw(requestParameters: RemoveHitRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling removeHit.",
            );
        }

        if (requestParameters.hit === null || requestParameters.hit === undefined) {
            throw new runtime.RequiredError(
                "hit",
                "Required parameter requestParameters.hit was null or undefined when calling removeHit.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/hits/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
            body: HitToJSON(requestParameters.hit),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeHit(requestParameters: RemoveHitRequest): Promise<void> {
        await this.removeHitRaw(requestParameters);
    }
}
