import {IconButton} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {SessionComment} from "../../../../api/generated/esa/models";
import CommentIcon from "@material-ui/icons/Comment";
import AddCommentIcon from "@material-ui/icons/AddComment";
import {TrainingSessionControllerApi} from "../../../../api/generated/esa/apis";
import {SessionCommentDialog} from "./Comment";
import styles from "../../components/Review.module.css";

const sessionApi = new TrainingSessionControllerApi();

export function SessionCommentButton(props: {sessionId: string}) {
    const {sessionId} = props;
    const [comment, setComment] = useState<SessionComment>({
        id: "",
        value: "",
    });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        sessionApi.sessionComment({id: sessionId}).then(setComment);
    }, [sessionId, open, setComment]);

    function handleClickOpen() {
        setOpen(true);
    }

    function onClose() {
        setOpen(false);
    }

    return (
        <>
            {comment && comment.value.length > 0 ? (
                <IconButton
                    id="comment_button"
                    className={styles.sessionCommentButton}
                    edge="end"
                    onClick={handleClickOpen}>
                    <CommentIcon color="primary" />
                </IconButton>
            ) : (
                <IconButton
                    id="add_comment_button"
                    className={styles.sessionCommentButton}
                    edge="end"
                    size="small"
                    onClick={handleClickOpen}>
                    <AddCommentIcon />
                </IconButton>
            )}

            <SessionCommentDialog {...{onClose, sessionId, open, comment, setComment}} />
        </>
    );
}
