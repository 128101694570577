import React, {useContext, useState} from "react";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS} from "react-joyride";
import {Tooltip} from "./components/Tooltip";
import {JoyrideContext} from "./JoyrideContext";
import {Collapse} from "@material-ui/core";
import {Hide} from "./Joyride.utils";

export function JoyrideProvider() {
    const [run, setRun] = useState(true);
    const {steps, setJoyride, index, setIndex, animatedHide} = useContext(JoyrideContext);

    function end() {
        setRun(false);
        setJoyride(false);
        setIndex(0);
    }

    const handleJoyrideCallback = (data: CallBackProps) => {
        const {action, index, type, status} = data;
        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
            end();
        } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
            const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            setIndex(stepIndex);
        }
    };

    return (
        <>
            <Joyride
                tooltipComponent={props => (
                    <Collapse in={!animatedHide}>
                        <Tooltip {...props} {...{end}} />
                    </Collapse>
                )}
                callback={handleJoyrideCallback}
                stepIndex={index}
                continuous
                disableOverlayClose
                run={run}
                spotlightPadding={0}
                scrollToFirstStep
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
            />

            <Hide id="step_index_provider">{index}</Hide>
        </>
    );
}
