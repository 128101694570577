import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {TargetInnerSVG} from "../lomah/target/TargetInnerSVG";
import {ShotColors, ShotDistinction} from "../settings-dialog/adapter/types/targetTypes";
import {Keys} from "../settings-dialog/adapter/components/CustomKeyFormatter";
import {AppSettings} from "../AppSettingsProvider";
import styles from "./TargetView.module.css";
import {debounce, floor} from "lodash";
import {ConstantsStringCommon, Hit, Target} from "../../api/generated/esa";
import HitArrow from "./HitArrow";
import {rectIntersects} from "../common/utils";
import HitCircle from "./HitCircle";
import {getMPI} from "../lomah/target/functions/getMPI";
import MPIIcon from "../../resources/icons/packs/custom/filled/MPI.svg";
import classes from "../lomah/target/Target.module.css";
import {getShotDistinction} from "../lomah/target/functions/getColor";

export const MAX_PCNT = 0.9;
const minAccessibilityRatio = 80;

export type HitVisibility = "left" | "right" | "top" | "bottom" | "visible";

export type TargetViewProps = {
    target: Target;
    caliberMm: number;
    hits?: Hit[];
    disableNumbers?: boolean;
    zoom?: number;
    setFocusedShotRef?: (instance: SVGCircleElement | null) => void;
    setCenterRef?: (instance: SVGCircleElement | null) => void;
    setMpiRef?: (instance: SVGCircleElement | null) => void;
    onSelect?: (n: string) => void;
    selected?: string;
    highlightHitId?: string;
    shotBlink?: boolean;
};

export type HitVisibilityProps = {id: string; visibility: HitVisibility; pcnt: number};

export function TargetView(props: TargetViewProps) {
    const {
        Components: {
            Lomah: {
                mpi,
                Target: {shotColor, shotDistinction},
            },
        },
    } = useContext(AppSettings);

    const {
        target,
        caliberMm,
        target: {targetCardWidth, targetCardHeight, dpmX, dpmY, ringStep, targetImage},
        hits,
        disableNumbers,
        zoom,
        selected,
        onSelect,
        highlightHitId,
        shotBlink,
        setCenterRef,
        setMpiRef,
        setFocusedShotRef,
    } = props;

    const containerRef = useRef<HTMLDivElement | null>(null);
    const hitsRefs = useRef<Array<SVGCircleElement | null>>([]);

    const [hitVisibility, setHitVisibility] = useState<Array<HitVisibilityProps>>([]);
    const [hitPos, setHitPos] = useState<Array<{hit: Hit; cx: number; cy: number; fill: string}>>([]);
    const [mpiPos, setMpiPos] = useState<{x: number; y: number}>({x: 0, y: 0});

    const shotSize = caliberMm / 2;
    const defaultFill = ShotColors.filter((color: Keys) => color.value === shotColor)[0];
    const scale = zoom ?? defaultZoom(target);
    const halfWidth = targetCardWidth / 2;
    const halfHeight = targetCardHeight / 2;

    useEffect(() => {
        if (hits) {
            hitsRefs.current = hitsRefs.current.slice(0, hits.length);
            const positions = hits.map((hit, i) => {
                const cx = halfWidth + hit.x / dpmX;
                const cy = halfHeight - hit.y / dpmY;
                const fill = getShotDistinction(shotDistinction as ShotDistinction, i, defaultFill, hits.length);
                return {hit, cx, cy, fill};
            });
            setHitPos(positions);
        }
    }, [hits, halfWidth, halfHeight, dpmX, dpmY, defaultFill, shotDistinction]);

    useEffect(() => {
        if (hits) {
            const mpiX = halfWidth + getMPI(hits, "x", targetCardWidth);
            const mpiY = halfHeight - getMPI(hits, "y", targetCardHeight);
            setMpiPos({x: mpiX, y: mpiY});
        }
    }, [hits, targetCardWidth, targetCardHeight, halfWidth, halfHeight, shotSize]);

    const debouncedCalculateHitVisibility = useMemo(
        () => debounce(() => calculateHitVisibility(containerRef, hits, zoom, setHitVisibility, hitsRefs), 300),
        [containerRef, hits, zoom, setHitVisibility, hitsRefs],
    );

    const [auraRadius, textStrokeWidth] = useMemo(() => {
        const largestDimension = Math.max(targetCardHeight, targetCardWidth);
        const accessibilityRatio = largestDimension / shotSize;
        const auraRadius = largestDimension / minAccessibilityRatio;
        const auraEnabled = minAccessibilityRatio < accessibilityRatio;
        const textStrokeWidth = floor(shotSize * 0.05, 2);

        return [auraEnabled ? auraRadius : null, textStrokeWidth];
    }, [targetCardHeight, targetCardWidth, shotSize]);

    return (
        <div className={styles.targetAndArrowsContainer}>
            <div
                ref={containerRef}
                onScroll={debouncedCalculateHitVisibility}
                className={styles.targetContainer}
                style={{
                    overflow: zoom === 1 || zoom === undefined ? "hidden" : "scroll",
                }}>
                <svg
                    viewBox={`0 0 ${targetCardWidth} ${targetCardHeight}`}
                    className={styles.target}
                    onClick={onSelect ? () => onSelect("") : undefined}
                    style={{
                        transform: `scale( ${scale} )`,
                        transformOrigin: zoom ? "0 0" : undefined,
                        fontSize: ringStep / 2,
                    }}>
                    {targetImage && targetImage !== ConstantsStringCommon.GENERATE_TARGET_RENDER ? (
                        <image
                            width={targetCardWidth}
                            height={targetCardHeight}
                            href={`${window.location.origin}/silhouettes/${targetImage}`}
                        />
                    ) : (
                        <TargetInnerSVG {...{target, disableNumbers}} />
                    )}

                    {hitPos.map((pos, i) => {
                        const {hit, cx, cy, fill} = pos;
                        const isLatest = hit.id === highlightHitId;
                        const isSelected = hit.id === selected;

                        return (
                            <HitCircle
                                {...{
                                    key: `hit_${hit.id}`,
                                    hit,
                                    cx,
                                    cy,
                                    hitsRefs,
                                    r: shotSize,
                                    fill,
                                    containerRef,
                                    updateRef: zoom
                                        ? r => {
                                              hitsRefs.current[i] = r;

                                              if (setFocusedShotRef && r && isSelected) {
                                                  setFocusedShotRef(r);
                                              } else if (setFocusedShotRef && !selected && r && isLatest) {
                                                  setFocusedShotRef(r);
                                              }
                                          }
                                        : undefined,
                                    onClick: onSelect
                                        ? e => {
                                              e.stopPropagation();
                                              onSelect(hit.id);
                                          }
                                        : undefined,
                                    auraRadius,
                                    textStrokeWidth,
                                    isLatest,
                                    isSelected,
                                    shotBlink,
                                }}
                            />
                        );
                    })}

                    <circle fillOpacity="0" ref={setCenterRef} cx={halfWidth} cy={halfHeight} r={1} />
                    <circle fillOpacity="0" ref={setMpiRef} cx={mpiPos.x} cy={mpiPos.y} r={1} />

                    {mpi && hits && hits.length > 1 && (
                        <HitMpi cx={mpiPos.x} cy={mpiPos.y} {...{shotSize, auraRadius, caliberMm}} />
                    )}
                </svg>
            </div>
            {hitVisibility
                .filter(it => it.visibility !== "visible")
                .map(it => (
                    <HitArrow
                        key={`${it.id}_arrow`}
                        hitVisibility={it.visibility}
                        pcnt={it.pcnt}
                        fill={defaultFill.value}
                    />
                ))}
        </div>
    );
}

function HitMpi(props: {cx: number; cy: number; shotSize: number; caliberMm: number; auraRadius: number | null}) {
    const {cx, cy, shotSize, auraRadius, caliberMm} = props;

    return (
        <>
            {auraRadius && <circle {...{cx, cy}} r={auraRadius} fill="#febf32" opacity={0.3} />}
            <image
                x={cx - shotSize}
                y={cy - shotSize}
                width={caliberMm}
                height={caliberMm}
                href={MPIIcon}
                className={classes.mpi}
            />
        </>
    );
}

function calculateHitVisibility(
    containerRef: React.MutableRefObject<HTMLDivElement | null>,
    hits: Hit[] | undefined,
    zoom: number | undefined,
    setHitVisibility: Setter<Array<HitVisibilityProps>>,
    hitsRefs: React.MutableRefObject<Array<SVGCircleElement | null>>,
) {
    const box = containerRef.current?.getBoundingClientRect();
    if (box && hits && zoom) {
        setHitVisibility(
            hitsRefs.current.map(hit => {
                const visibility = hit ? rectIntersects(hit.getBoundingClientRect(), box) : "visible";
                if (hit && (visibility === "left" || visibility === "right")) {
                    const pcnt = Math.max(
                        Math.min((hit.getBoundingClientRect().top - box.top) / box.height, MAX_PCNT),
                        0,
                    );
                    return {id: hit!.id, visibility, pcnt};
                } else if (hit && (visibility === "top" || visibility === "bottom")) {
                    const pcnt = Math.max(
                        Math.min((hit.getBoundingClientRect().left - box.left) / box.width, MAX_PCNT),
                        0,
                    );
                    return {id: hit.id, visibility, pcnt};
                } else {
                    return {id: hit?.id ?? "none", visibility, pcnt: 50};
                }
            }),
        );
    }
}

function defaultZoom(target: Target) {
    if (target.targetImage === ConstantsStringCommon.GENERATE_TARGET_RENDER) {
        const cardSize = Math.max(target.targetCardWidth, target.targetCardHeight);
        return floor(cardSize / (target.targetRadius * 2), 1);
    } else {
        return 1;
    }
}
