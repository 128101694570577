/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PowerStatus {
    UNKNOWN = "UNKNOWN",
    OFF = "OFF",
    ON = "ON",
}

export function PowerStatusFromJSON(json: any): PowerStatus {
    return PowerStatusFromJSONTyped(json, false);
}

export function PowerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerStatus {
    return json as PowerStatus;
}

export function PowerStatusToJSON(value?: PowerStatus | null): any {
    return value as any;
}
