import React from "react";
import {translation, translationOrName} from "../../i18n";
import {EnhancedStep, nextAfterClick} from "../Joyride.utils";
import StepContainer from "../components/StepContainer";
import TranslatedMarkdown from "../../common/TranslatedMarkdown";

function t(key: string) {
    return translationOrName("joyride.serverless", key);
}

export const ServerlessRide: EnhancedStep[] = [
    {
        content: (
            <StepContainer>
                <TranslatedMarkdown translationKey="joyride.serverless.welcome.content" />
            </StepContainer>
        ),
        placement: "center",
        target: "body",
    },
    {
        content: (
            <StepContainer>
                <h2>{t("select_discipline.title")}</h2>
                <p> {t("select_discipline.content")}</p>
                <p> {t("select_discipline.content2")}</p>
                <b>{t("select_discipline.content3")}</b>
            </StepContainer>
        ),
        placement: "right-start",
        ...nextAfterClick("discipline_1_10_AR40"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("start.title")}</h2>
                <p> {t("start.content")}</p>
            </StepContainer>
        ),
        placement: "right-start",
        ...nextAfterClick("start_action"),
    },
    {
        content: (
            <StepContainer>
                <TranslatedMarkdown translationKey="joyride.serverless.live.content" />
            </StepContainer>
        ),
        placement: "center",
        target: "body",
    },
    {
        content: (
            <StepContainer>
                <h2>{t("play.title")}</h2>
                <p> {t("play.content")}</p>
                <p> {t("play.content2")}</p>
            </StepContainer>
        ),
        placement: "top",
        ...nextAfterClick("start"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("shot.title")}</h2>
                <p> {t("shot_sighting.content")}</p>
            </StepContainer>
        ),
        placement: "right-start",
        ...nextAfterClick("demo_button"),
    },

    {
        content: (
            <StepContainer>
                <h2>{t("next.title")}</h2>
                <p> {t("next.content")}</p>
            </StepContainer>
        ),
        placement: "left-end",
        ...nextAfterClick("next"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("shot.title")}</h2>
                <p> {t("shot_qualification.content")}</p>
                <p> {t("shot_qualification.content2")}</p>
            </StepContainer>
        ),
        placement: "right-start",
        ...nextAfterClick("demo_button"),
    },
    {
        content: (
            <>
                <TranslatedMarkdown translationKey="joyride.serverless.pause" />
            </>
        ),
        placement: "left-end",
        ...nextAfterClick("pause"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("menu.title")}</h2>
                <p>{t("menu.content")}</p>
            </StepContainer>
        ),
        placement: "left-start",
        ...nextAfterClick(
            "burger_menu",
            "#burger_menu_drawer_container > div.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16",
        ),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("theme.title")}</h2>
                <p>{t("theme.content")}</p>
            </StepContainer>
        ),
        placement: "left-start",
        ...nextAfterClick("theme_control_button"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("theme.title2")}</h2>
                <p>{t("theme.content2")}</p>
            </StepContainer>
        ),
        placement: "left-start",
        ...nextAfterClick("theme_control_button"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("close_menu.title")}</h2>
                <p>{t("close_menu.content")}</p>
            </StepContainer>
        ),
        placement: "left-start",
        ...nextAfterClick("close_burger_menu"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("quick_settings.title")}</h2>
                <p>{t("quick_settings.content")}</p>
                <p>{t("quick_settings.content2")}</p>
            </StepContainer>
        ),
        placement: "left-start",
        ...nextAfterClick(
            "quick_settings_livetarget",
            "#custom_dialog > div.MuiDialog-container.MuiDialog-scrollPaper",
        ),
    },

    {
        content: (
            <StepContainer>
                <h2>{t("settings_controls.title")}</h2>
                <p>{t("settings_controls.content")}</p>
            </StepContainer>
        ),
        placement: "left-end",
        ...nextAfterClick("settings_action_ok"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("navigate_review.title")}</h2>
                <p>{t("navigate_review.content")}</p>
            </StepContainer>
        ),
        placement: "top",
        ...nextAfterClick("complete"),
    },
    {
        content: (
            <>
                <h2>{translation("button.confirm")}</h2>
                <p>{t("confirm.content")}</p>
            </>
        ),
        placement: "right-start",
        ...nextAfterClick("popup_ok_action"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("review_screen.title")}</h2>
                <p>{t("review_screen.content")}</p>
                <p>{t("review_screen.content2")}</p>
            </StepContainer>
        ),
        placement: "center",
        target: "body",
    },
    {
        content: (
            <StepContainer>
                <h2>{t("calendar.title")}</h2>
                <p>{t("calendar.content")}</p>
            </StepContainer>
        ),
        placement: "right",
        target: "#ReviewCalendar_component_wrapper",
    },

    {
        content: (
            <StepContainer>
                <h2>{t("navigate_select.title")}</h2>
                <p>{t("navigate_select.content")}</p>
            </StepContainer>
        ),
        placement: "bottom-start",
        ...nextAfterClick("navigation_location_select"),
    },
    {
        content: (
            <StepContainer>
                <h2>{t("last.title")}</h2>
                <p>{t("last.content")}</p>
            </StepContainer>
        ),
        placement: "center",
        target: "body",
    },
];
