import React, {MouseEvent, useContext} from "react";
import {Hit} from "../../api/generated/esa";
import {AppSettings} from "../AppSettingsProvider";
import clsx from "clsx";
import classes from "../lomah/target/Target.module.css";
import animations from "../../styles/Animations.module.css";

export default function HitCircle(props: {
    hit: Hit;
    cx: number;
    cy: number;
    r: number;
    fill: string;
    updateRef?: (instance: SVGCircleElement | null) => void;
    onClick?: (e: MouseEvent) => void;
    isSelected?: boolean;
    isLatest: boolean;
    auraRadius: number | null;
    textStrokeWidth: number;
    shotBlink?: boolean; //todo
}) {
    const {
        Components: {
            Lomah: {
                Target: {shotBlink, shotNumbers},
            },
        },
    } = useContext(AppSettings);

    const {hit, cx, cy, r, fill, updateRef, isSelected, isLatest, onClick, auraRadius, textStrokeWidth} = props;

    return (
        <>
            {auraRadius && (
                <circle
                    {...{cx, cy, onClick, fill}}
                    r={auraRadius}
                    className={clsx(
                        classes.aura,
                        isSelected ? classes.selected : isLatest && classes.latest,
                        shotBlink && animations.hitHeartbeat,
                    )}
                />
            )}
            <circle
                {...{cx, cy, r, onClick, fill}}
                id={`hit_${hit.id}`}
                ref={updateRef}
                className={clsx(
                    classes.hit,
                    isSelected ? classes.selected : isLatest && classes.latest,
                    shotBlink && animations.hitHeartbeat,
                )}
            />
            {shotNumbers && <HitCircleText n={hit.n} fill="white" {...{cx, cy, r, onClick, textStrokeWidth}} />}
        </>
    );
}

export function HitCircleText(props: {
    n: number;
    cx: number;
    cy: number;
    r: number;
    textStrokeWidth: number;
    fill: string;
    onClick?: (e: MouseEvent) => void;
}) {
    const {n, cx, cy, r, textStrokeWidth, onClick, fill} = props;

    return (
        <text
            id={`${n}_hit_number`}
            x={cx}
            y={cy}
            {...{onClick}}
            fontSize={r}
            strokeWidth={textStrokeWidth}
            fill={fill}
            textAnchor="middle"
            alignmentBaseline="middle"
            fontWeight="bold"
            cursor="pointer"
            stroke="black">
            <tspan />

            {n}
        </text>
    );
}
