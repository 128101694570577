import React, {useContext, useEffect, useMemo, useState} from "react";
import {Hit, HitControllerApi, Target, Weapon, WebSocketMessage} from "../../api/generated/esa";
import {AppContext} from "../AppContextProvider";
import {defaultTarget, defaultWeapon} from "../../defaultValues";
import {useTarget, useWeapon} from "../../customHooks";

export interface SingleShooterLiveContextI {
    hits: Hit[];
    selectedHit: string;
    setSelectedHit: Setter<string>;
    openSeries: number;
    setOpenSeries: Setter<number>;
    tableSeries: boolean;
    setTableSeries: Setter<boolean>;
    target: Target;
    weapon: Weapon;
}

export const SingleShooterLiveContext = React.createContext<SingleShooterLiveContextI>({
    hits: [],
    selectedHit: "",
    setSelectedHit: () => {},
    openSeries: 0,
    setOpenSeries: () => {},
    tableSeries: false,
    setTableSeries: () => {},
    target: defaultTarget,
    weapon: defaultWeapon,
});

const lomahsApi = new HitControllerApi();

export default function SingleShooterLiveContextProvider(props: React.PropsWithChildren<any>) {
    const {children} = props;
    const {webSocket, connected, phase} = useContext(AppContext);
    const [selectedHit, setSelectedHit] = useState<string>("");
    const [hits, setHits] = useState<Hit[]>([]);
    const [openSeries, setOpenSeries] = useState<number>(0);
    const [tableSeries, setTableSeries] = useState(true);
    const target = useTarget(phase.targetName);
    const weapon = useWeapon(phase.weaponId);

    useEffect(() => {
        if (connected) {
            lomahsApi.getHits({phase: phase.id}).then(it => setHits(it.sort((a, b) => b.timeStamp - a.timeStamp)));
        }
    }, [phase.id, connected]);

    useEffect(() => {
        function updateListener(event: MessageEvent) {
            const update: WebSocketMessage = JSON.parse(event.data);
            const hit: Hit | undefined = update.hit;

            if (hit && hit?.phaseId === phase.id) {
                setSelectedHit("");
                setHits(hitsUpd => {
                    const hitExist: boolean = hitsUpd.some(it => it.id === hit.id);
                    if (hitExist) {
                        const updatedHists: Hit[] = [...hitsUpd.filter(it => it.id !== hit.id), hit];
                        return updatedHists.sort((a, b) => a.timeStamp - b.timeStamp);
                    } else {
                        return [hit, ...hitsUpd];
                    }
                });
            }
        }

        webSocket.addEventListener("message", updateListener);

        return function cleanup() {
            webSocket.removeEventListener("message", updateListener);
        };
    }, [webSocket, setHits, phase.id]);

    const context = useMemo<SingleShooterLiveContextI>(() => {
        return {
            ...{
                hits,
                selectedHit,
                setSelectedHit,
                openSeries,
                setOpenSeries,
                tableSeries,
                setTableSeries,
                target,
                weapon,
            },
        };
    }, [hits, selectedHit, setSelectedHit, openSeries, setOpenSeries, tableSeries, setTableSeries, target, weapon]);

    return <SingleShooterLiveContext.Provider value={context}>{children}</SingleShooterLiveContext.Provider>;
}
