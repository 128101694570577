import React, {useContext} from "react";
import {SelectTargetContent} from "../../components/select-view/SelectTarget";
import {SelectContext} from "../../components/select-view/SelectContextProvider";
import {Button} from "@material-ui/core";
import styles from "../../components/select-view/SelectView.module.css";

import {translation} from "../../components/i18n";
import {AppContextTypeMock} from "./PortalMock";
import {AppContext} from "../../components/AppContextProvider";
import {Aim} from "../../components/icons/generated/military/outlined";
import {ConstantsStringCommon} from "../../api/generated/esa";

export default function SelectTargetMock() {
    const {select} = useContext(AppContext) as AppContextTypeMock;
    const {discipline, selectedTargetObject, selectedWeapon} = useContext(SelectContext);

    function onClick() {
        if (discipline && selectedTargetObject) {
            select(discipline, 0, selectedTargetObject.name, selectedWeapon?.id ?? ConstantsStringCommon.NO_WEAPON);
        }
    }

    return (
        <>
            <Button
                id="start_action"
                size="large"
                {...{onClick}}
                className={styles.startButton}
                startIcon={<Aim />}
                variant="contained"
                color="secondary">
                <b>{translation("button.select")}</b>
            </Button>
            <SelectTargetContent />
        </>
    );
}
