/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface TrafficPattern
 */
export interface TrafficPattern {
    /**
     *
     * @type {number}
     * @memberof TrafficPattern
     */
    shootingTime: number;
    /**
     *
     * @type {number}
     * @memberof TrafficPattern
     */
    waitingTime: number;
    /**
     *
     * @type {number}
     * @memberof TrafficPattern
     */
    repeat: number;
}

export function TrafficPatternFromJSON(json: any): TrafficPattern {
    return TrafficPatternFromJSONTyped(json, false);
}

export function TrafficPatternFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrafficPattern {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        shootingTime: json["shootingTime"],
        waitingTime: json["waitingTime"],
        repeat: json["repeat"],
    };
}

export function TrafficPatternToJSON(value?: TrafficPattern | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        shootingTime: value.shootingTime,
        waitingTime: value.waitingTime,
        repeat: value.repeat,
    };
}
