import React, {useContext, useEffect, useMemo, useState} from "react";
import {ConstantsStringEsa, EsaDate, Hit, Phase, TrainingSession} from "../../api/generated/esa";
import {AppContext} from "../../components/AppContextProvider";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import {useIntl} from "react-intl";
import {
    hitsHistory1,
    hitsHistory1Qualification,
    hitsHistory2,
    hitsHistory2Qualification,
    hitsHistory3,
    hitsHistory3Qualification,
    trainingSessionsHistory1,
} from "./mockdata/DataMock";
import {AppContextTypeMock} from "./PortalMock";
import {ReviewContext, ReviewContextType} from "../../components/review-view/ReviewContextProvider";
import {subDays} from "date-fns";
import {useTarget, useWeapon} from "../../customHooks";

const today = new Date();
const calendarToday = {
    day: today.getDate(),
    month: today.getMonth(),
    year: today.getFullYear(),
};
const yesterday = subDays(today, 1);
const calendarYesterday = {
    day: yesterday.getDate(),
    month: yesterday.getMonth(),
    year: yesterday.getFullYear(),
};

const dayBeforeYesterday = subDays(today, 2);
const calendarDayBeforeYesterday = {
    day: dayBeforeYesterday.getDate(),
    month: dayBeforeYesterday.getMonth(),
    year: dayBeforeYesterday.getFullYear(),
};

const sessionHistory = [
    {
        hits: [...hitsHistory1, ...hitsHistory1Qualification],
        date: calendarToday,
    },
    {
        hits: [...hitsHistory2, ...hitsHistory2Qualification],
        date: calendarYesterday,
    },
    {
        hits: [...hitsHistory3, ...hitsHistory3Qualification],
        date: calendarDayBeforeYesterday,
    },
];

export default function ReviewContextProviderMock(props: React.PropsWithChildren<any>) {
    const {children} = props;
    const {trainingSessions, user: currentUser, hits: allHits} = useContext(AppContext) as AppContextTypeMock;
    const [hits, setHits] = useState<Hit[]>(hitsHistory1);
    const [selectedHit, setSelectedHit] = useState<string | undefined>();
    const [expanded, setExpanded] = useState(true);
    const [userId, setUserId] = useState<string>(currentUser.id);
    const [session, setSession] = useState<TrainingSession | undefined>();
    const [reviewPhase, setReviewPhase] = useState<Phase | undefined>();

    const [selectedDate, setSelectedDate] = useState<EsaDate>(calendarToday);
    const [hitsInSeries, setHitsInSeries] = useState(0);

    const [language, setLanguage] = useState(enLocale);
    const [sessions, setSessions] = useState<TrainingSession[]>(trainingSessionsHistory1);
    const [sessionDays, setSessionDays] = useState<Set<number>>(new Set([calendarToday.day]));
    const [visibleDate, setVisibleDate] = useState<MaterialUiPickersDate>(new Date());
    const target = useTarget(reviewPhase?.targetName);
    const weapon = useWeapon(reviewPhase?.weaponId);

    const [openSeries, setOpenSeries] = useState<number>(0);
    const [tableSeries, setTableSeries] = useState(true);

    const intl = useIntl();
    const {locale} = intl;

    useEffect(() => {
        locale === "en" && setLanguage(enLocale);
        locale === "de" && setLanguage(deLocale);
    }, [locale]);

    useEffect(() => {
        setSession(undefined);
    }, [selectedDate]);

    function sortByDate(trainingA: TrainingSession, trainingB: TrainingSession) {
        return trainingB.startTime - trainingA.startTime;
    }

    useEffect(() => {
        const sessionsWithScore = trainingSessionsHistory1.map(session => {
            const sessionHits = sessionHistory.find(it => it.date.day === selectedDate.day)?.hits || [];
            const score = sessionHits.length === 0 ? 0 : sessionHits.map(_ => _.score).reduce((it, acc) => it + acc);
            return {...session, decimalScore: score};
        });
        if (
            selectedDate.day === calendarToday.day &&
            selectedDate.month === calendarToday.month &&
            selectedDate.year === calendarToday.year &&
            trainingSessions.length > 0
        ) {
            const sortedList = trainingSessions
                .filter(s => s.disciplineName !== ConstantsStringEsa.NO_TRAINING_NAME && s.startTime && s.decimalScore)
                .sort(sortByDate);
            if (!session && sortedList[0]) {
                setReviewPhase(sortedList[0].phases[0]);
                setSession(sortedList[0]);
            }
            setSessions([...sessionsWithScore, ...sortedList]);
        } else {
            setReviewPhase(trainingSessionsHistory1[0].phases[0]);
            setSessions(sessionsWithScore);
            setSession(trainingSessionsHistory1[0]);
        }
    }, [trainingSessions, selectedDate, session, setReviewPhase, visibleDate]);

    useEffect(() => {
        const visibleYear = sessionHistory.filter(it => it.date.year === visibleDate?.getFullYear());
        const visibleMonth = visibleYear.filter(it => it.date.month === visibleDate?.getMonth());
        const daysInVisibleMonth = visibleMonth.map(it => it.date.day);
        const result = visibleMonth.find(it => it.date.day === selectedDate.day);
        setSessionDays(new Set(daysInVisibleMonth));

        if (
            selectedDate.day === calendarToday.day &&
            selectedDate.month === calendarToday.month &&
            selectedDate.year === calendarToday.year
        ) {
            const mockedHits = result ? result.hits : [];
            setHits([...allHits, ...mockedHits].filter(hit => hit.phaseId === reviewPhase?.id));
        } else {
            result && setHits(result.hits.filter(hit => hit.phaseId === reviewPhase?.id));
        }
    }, [reviewPhase?.id, selectedDate, allHits, visibleDate]);

    const context = useMemo<ReviewContextType>(() => {
        return {
            ...{
                selectedDate,
                setSelectedDate,
                phase: reviewPhase,
                setPhase: setReviewPhase,
                session,
                setSession,
                userId,
                setUserId,
                hitsInSeries,
                setHitsInSeries,
                expanded,
                setExpanded,
                selectedHit,
                setSelectedHit,
                hits,
                setHits,
                sessionDays,
                setSessionDays,
                sessions,
                setSessions,
                language,
                visibleDate,
                setVisibleDate,
                isLoading: false,
                openSeries,
                setOpenSeries,
                tableSeries,
                setTableSeries,
                target,
                weapon,
                rangeName: "INTARSO Range",
            },
        };
    }, [
        selectedDate,
        setSelectedDate,
        reviewPhase,
        setReviewPhase,
        session,
        setSession,
        userId,
        setUserId,
        hitsInSeries,
        setHitsInSeries,
        expanded,
        setExpanded,
        selectedHit,
        setSelectedHit,
        hits,
        setHits,
        sessionDays,
        setSessionDays,
        sessions,
        setSessions,
        language,
        visibleDate,
        setVisibleDate,
        openSeries,
        setOpenSeries,
        tableSeries,
        setTableSeries,
        target,
        weapon,
    ]);

    return <ReviewContext.Provider value={context}>{children}</ReviewContext.Provider>;
}
