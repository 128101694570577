/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface Path
 */
export interface Path {
    /**
     *
     * @type {string}
     * @memberof Path
     */
    d?: string;
}

export function PathFromJSON(json: any): Path {
    return PathFromJSONTyped(json, false);
}

export function PathFromJSONTyped(json: any, ignoreDiscriminator: boolean): Path {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        d: !exists(json, "d") ? undefined : json["d"],
    };
}

export function PathToJSON(value?: Path | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        d: value.d,
    };
}
