/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {Target, TargetFromJSON} from "../models";

export interface GetRequest {
    targetName: string;
}

/**
 *
 */
export class TargetControllerApi extends runtime.BaseAPI {
    /**
     */
    async getRaw(requestParameters: GetRequest): Promise<runtime.ApiResponse<Target>> {
        if (requestParameters.targetName === null || requestParameters.targetName === undefined) {
            throw new runtime.RequiredError(
                "targetName",
                "Required parameter requestParameters.targetName was null or undefined when calling get.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/targets/{targetName}`.replace(
                `{${"targetName"}}`,
                encodeURIComponent(String(requestParameters.targetName)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => TargetFromJSON(jsonValue));
    }

    /**
     */
    async get(requestParameters: GetRequest): Promise<Target> {
        const response = await this.getRaw(requestParameters);
        return await response.value();
    }
}
