import {Keys} from "../components/CustomKeyFormatter";

export const timeFormats: Keys[] = [
    {
        name: "12 h",
        value: "hh:mm aa",
    },
    {
        name: "24 h",
        value: "HH:mm",
    },
];

export const dateFormats: Keys[] = [
    {
        name: "dd/mm/yyyy",
        value: "dd/MM/yyyy",
    },
    {
        name: "mm/dd/yyyy",
        value: "MM/dd/yyyy",
    },
];
