import React, {useContext, useEffect, useState} from "react";
import {Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, Slider, Typography} from "@material-ui/core";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import AddBoxIcon from "@material-ui/icons/AddBox";
import {localizedLabel, translation, translationOrName} from "../../../i18n";
import {AppSettingsControl} from "../../../AppSettingsProvider";
import {AppContext} from "../../../AppContextProvider";
import {DefaultDeviceCalibration, EsaControllerApi} from "../../../../api/generated/esa";

type Controller = {
    min: number;
    max: number;
    name: string;
    value: number;
    type?: string;
};

const api = new EsaControllerApi();

function CalibrationController(props: {
    it: Controller;
    disabled: boolean;
    config: DefaultDeviceCalibration;
    setConfig: Setter<DefaultDeviceCalibration>;
    setUpdated: (updated: boolean) => void;
}) {
    const {value: initialValue, max, min, name, type} = props.it;
    const disabled = !props.disabled;
    const {setConfig, setUpdated} = props;
    const [value, setValue] = useState(initialValue);
    const [addButton, setAddButton] = useState(true);
    const [subtractButton, setSubtractButton] = useState(true);

    function handleChange(event: any, newValue: number | number[]) {
        setUpdated(true);
        setValue(newValue as number);
    }

    function add() {
        setUpdated(true);
        value < max && setValue(value + 1);
    }

    function subtract() {
        setUpdated(true);
        value > min && setValue(value - 1);
    }

    useEffect(() => {
        setAddButton(value < max);
        setSubtractButton(value > min);
        setConfig(prevValue => ({...prevValue, [name]: value}));
    }, [value, max, min, name, setConfig]);

    return (
        <>
            <Typography gutterBottom style={{marginTop: 8}} color={disabled ? "textSecondary" : "textPrimary"}>
                {translationOrName("label.config", name)}:{" "}
                <b>
                    {type === "sec" ? value / 100 : value}
                    {type}
                </b>
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <IconButton disabled={disabled || !subtractButton} component="span" onClick={subtract}>
                        <IndeterminateCheckBoxIcon />
                    </IconButton>
                </Grid>
                <Grid item container style={{width: 250}}>
                    <Slider step={1} {...{value, max, min, disabled}} onChange={handleChange} />
                </Grid>
                <Grid item>
                    <IconButton disabled={disabled || !addButton} component="span" onClick={add}>
                        <AddBoxIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
}

const DEFAULT_CALIBRATION = {
    id: "none",
    gain: "0",
    timeoutCs: 0,
    threshold: "",
    paperMovement: 0,
    sensorSensitivity: "0",
};

export const CALIBRATION_LIMITS = {
    nchsGainMin: 1,
    nchsGainMax: 7,
    nchsTimeoutMin: 1,
    nchsTimeoutMax: 255,
    nchsThresholdMin: 10,
    nchsThresholdMax: 127,
    paperMovementMin: 1,
    paperMovementMax: 255,
    frameSensorSensivityMin: 0,
    frameSensorSensivityMax: 127,
};

export function FormatterDeviceCalibration() {
    const [config, setConfig] = useState<DefaultDeviceCalibration>(DEFAULT_CALIBRATION);
    const [updated, setUpdated] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const {setDeviceCalibration} = useContext(AppSettingsControl);
    const {connected} = useContext(AppContext);

    useEffect(() => {
        disabled && updated && setDeviceCalibration(config);
    }, [config, disabled, updated, setDeviceCalibration]);

    useEffect(() => {
        api.getDefaultDeviceCalibration().then(setConfig);
    }, [connected]);

    if (config !== DEFAULT_CALIBRATION) {
        const {gain, timeoutCs, threshold, paperMovement, sensorSensitivity} = config;
        const fields: Controller[] = [
            {
                value: +gain,
                name: "gain",
                max: CALIBRATION_LIMITS.nchsGainMax,
                min: CALIBRATION_LIMITS.nchsGainMin,
            },
            {
                value: timeoutCs,
                name: "timeoutCs",
                max: CALIBRATION_LIMITS.nchsTimeoutMax,
                min: CALIBRATION_LIMITS.nchsTimeoutMin,
                type: " msec",
            },
            {
                value: +threshold,
                name: "threshold",
                max: CALIBRATION_LIMITS.nchsThresholdMax,
                min: CALIBRATION_LIMITS.nchsThresholdMin,
            },
            {
                value: paperMovement,
                name: "paperMovement",
                max: CALIBRATION_LIMITS.paperMovementMax,
                min: CALIBRATION_LIMITS.paperMovementMin,
                type: " mm",
            },
            {
                value: +sensorSensitivity,
                name: "sensorSensitivity",
                max: CALIBRATION_LIMITS.frameSensorSensivityMax,
                min: CALIBRATION_LIMITS.frameSensorSensivityMin,
            },
        ];

        return (
            <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                alignContent="center"
                style={{margin: 8}}>
                <Typography variant="h2" color="error">
                    {translation("title.warning")}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    {translation("message.config.warning")}
                </Typography>

                <Grid item direction="column" alignItems="flex-start" spacing={2} container xs={12}>
                    <FormControlLabel
                        style={{marginBottom: 16}}
                        control={
                            <Checkbox
                                checked={disabled}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setDisabled(event.target.checked)
                                }
                                color="primary"
                            />
                        }
                        label={localizedLabel("checkbox.config")}
                    />

                    {fields.map(it => {
                        return (
                            <CalibrationController
                                {...{it, disabled, config, setConfig, setUpdated}}
                                key={it.name + "_controller"}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        );
    } else return <CircularProgress />;
}
