import React, {useContext, useEffect} from "react";

import {HitTable} from "../lomah/live-scoring/HitTable";
import {AppContext} from "../AppContextProvider";
import {FormControlLabel, Grid, Switch, Typography} from "@material-ui/core";
import {translatedText, translation} from "../i18n";
import {AppSettings} from "../AppSettingsProvider";
import {convertMM} from "../lomah/live-scoring/functions/convertMM";
import {getMPI as MPI} from "../lomah/target/functions/getMPI";
import {Units} from "../lomah/live-scoring/components/Edit";
import {ContainerProps} from "../lomah/Lomah";
import {SingleShooterLiveContext} from "./SingleShooterLiveContextProvider";
import {NotificationContext} from "../NotificationContextProvider";

export function LiveTable(props: ContainerProps) {
    const {setHeader, id} = props;
    const {notify} = useContext(NotificationContext);
    const {
        hits,
        setSelectedHit,
        selectedHit,
        openSeries,
        setOpenSeries,
        tableSeries,
        setTableSeries,
        target,
    } = useContext(SingleShooterLiveContext);
    const {phase} = useContext(AppContext);
    const {maxShots, series} = phase;
    const {
        Global: {units},
        Components: {
            Lomah: {mpi, precision},
        },
    } = useContext(AppSettings);
    const hitsInSeries = phase.series > 1 ? maxShots / series : 0;
    const seriesAvailable = phase.series > 1;

    useEffect(() => {
        const displayMPIX = convertMM(MPI(hits, "x", target.targetCardWidth), units as Units, precision);
        const displayMPIY = convertMM(MPI(hits, "y", target.targetCardHeight), units as Units, precision);
        function formatTotalScore() {
            if (phase.fullRingScore) {
                return (
                    <Typography component="span" color="primary">
                        <b id="total">{phase.fullRingScore}</b> ({phase.decimalScore.toFixed(1)})
                    </Typography>
                );
            } else {
                return (
                    <Typography component="span" color="primary">
                        <b id="total">{phase.decimalScore.toFixed(1)}</b>
                    </Typography>
                );
            }
        }

        setHeader(
            id,
            <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item xs>
                    {translatedText("component.hits_table_score", "header", {
                        score: formatTotalScore(),
                    })}
                    {mpi &&
                        translatedText("component.hits_table_score", "header_mpi", {
                            mpiX: displayMPIX,
                            mpiY: displayMPIY,
                        })}
                </Grid>
                <Grid item>
                    <Grid container direction="row" justify="flex-end">
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={!seriesAvailable}
                                    checked={tableSeries}
                                    color="primary"
                                    onChange={() => {
                                        setTableSeries(!tableSeries);
                                        notify("info", "label.quick_series_setting");
                                    }}
                                />
                            }
                            label={translation("component.hits_table.series_switch")}
                        />
                    </Grid>
                </Grid>
            </Grid>,
        );
    }, [
        hits,
        mpi,
        phase,
        id,
        setHeader,
        seriesAvailable,
        tableSeries,
        units,
        setTableSeries,
        notify,
        precision,
        target.targetCardHeight,
        target.targetCardWidth,
    ]);
    return (
        <HitTable
            onSelect={setSelectedHit}
            selected={selectedHit}
            {...{phase, hits, selectedHit, tableSeries, hitsInSeries, openSeries, setOpenSeries}}
            startOnFirstShoot={!phase.started}
        />
    );
}
