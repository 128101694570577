import React, {useContext} from "react";
import {ListItemIcon, ListItemText, MenuItem} from "@material-ui/core";
import Confirmation from "../../../common/Confirmation";
import DeleteIcon from "@material-ui/icons/Delete";
import {translation} from "../../../i18n";
import {TrainingSession, TrainingSessionControllerApi} from "../../../../api/generated/esa";
import {ReviewContext} from "../../ReviewContextProvider";

const api = new TrainingSessionControllerApi();

export default function RemoveButton(props: {session: TrainingSession; closeMenu: () => void}) {
    const {closeMenu, session} = props;
    const phase = session.phases[0];
    const {setSession, setPhase, setSessions} = useContext(ReviewContext);
    const trainingId = session.id;

    return (
        <Confirmation
            title="title.delete_training_session"
            text="message.results_will_be_deleted"
            okButton="button.delete"
            onClick={() => {
                closeMenu();
                phase &&
                    api
                        .remove({
                            trainingId,
                            currentPhaseId: phase.id,
                        })
                        .then(() => {
                            setSession(undefined);
                            setPhase(undefined);
                            setSessions(old => old.filter(it => it.id !== trainingId));
                        });
            }}>
            <MenuItem id="delete_training_session_button" disabled={Boolean(phase.competitionId)}>
                <ListItemIcon>
                    <DeleteIcon />
                </ListItemIcon>
                <ListItemText
                    primary={translation("button.delete")}
                    secondary={Boolean(phase.competitionId) && translation("info.session_deletion_disabled")}
                />
            </MenuItem>
        </Confirmation>
    );
}
