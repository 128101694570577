/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface License
 */
export interface License {
    /**
     *
     * @type {number}
     * @memberof License
     */
    licencedDevices: number;
    /**
     *
     * @type {string}
     * @memberof License
     */
    companyName: string;
    /**
     *
     * @type {number}
     * @memberof License
     */
    purchaseDate: number;
}

export function LicenseFromJSON(json: any): License {
    return LicenseFromJSONTyped(json, false);
}

export function LicenseFromJSONTyped(json: any, ignoreDiscriminator: boolean): License {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        licencedDevices: json["licencedDevices"],
        companyName: json["companyName"],
        purchaseDate: json["purchaseDate"],
    };
}

export function LicenseToJSON(value?: License | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        licencedDevices: value.licencedDevices,
        companyName: value.companyName,
        purchaseDate: value.purchaseDate,
    };
}
