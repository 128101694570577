import {ShotDistinction} from "../../../settings-dialog/adapter/types/targetTypes";
import {Keys} from "../../../settings-dialog/adapter/components/CustomKeyFormatter";

export function getShotDistinction(type: ShotDistinction, index: number, color: Keys, length: number) {
    if (type === ShotDistinction.Fade) {
        const a = 1 / (length - index);
        return `rgba(${color.value}, ${a})`;
    } else {
        return `rgb(${color.value}`;
    }
}
