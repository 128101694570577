import React, {ChangeEvent, useContext} from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import {SettingFormatterOrDefinition, SettingsDefinitions, SettingsValues} from "../adapter/types/settingsTypes.js";
import {definition} from "../adapter/types/settingsDefinition";
import classes from "./Tree.module.css";
import {intersects} from "../../common/utils";
import {AppContext} from "../../AppContextProvider";
import {translatedText} from "../../i18n";
import clsx from "clsx";

export function isSettingDefinition([, setting]: SettingFormatterOrDefinition) {
    return typeof setting.render !== "function";
}

export function isSettingFormatter(o: SettingFormatterOrDefinition) {
    return !isSettingDefinition(o);
}

function mapTree(path: string, settings: SettingsDefinitions, values: SettingsValues, roles: [], defaultPath?: string) {
    function hasSettingsDefinitionsForCurrentUser([category]: SettingFormatterOrDefinition) {
        return Object.values(settings[category]).filter(d => !d.roles || intersects(d.roles as [], roles)).length > 0;
    }

    const selectedArr = (defaultPath && defaultPath.split("/")) ?? [];
    const selected = selectedArr[selectedArr.length - 1];

    return Object.entries(settings)
        .filter(isSettingDefinition)
        .filter(hasSettingsDefinitionsForCurrentUser)
        .map(([category, setting]) => (
            <TreeItem
                id={`${category.toLowerCase()}_settings_tree`}
                key={`${path}/${category}`}
                nodeId={`${path}/${category}`}
                label={
                    <div className={clsx(classes.treeItem, category === selected && classes.selected)}>
                        <Typography variant="body2">{translatedText("settings.category", category)}</Typography>
                    </div>
                }>
                {mapTree(
                    `${path}/${category}`,
                    settings[category] as SettingsDefinitions,
                    values[category] as SettingsValues,
                    roles,
                    defaultPath,
                )}
            </TreeItem>
        ));
}

export const SettingsAdapterTree = (props: {
    setSettingsPath: Setter<string>;
    stagedSettings: SettingsValues;
    defaultPath?: string;
}) => {
    const {
        user: {roles},
    } = useContext(AppContext);
    const {setSettingsPath, defaultPath, stagedSettings} = props;
    const modPath = defaultPath
        ? defaultPath
              .slice(1)
              .split("/")
              .map((component, index, array) => `${index > 0 ? "/" + array[index - 1] : ""}/${component}`)
        : [""];
    return (
        <TreeView
            className={classes.treeView}
            selected={modPath[modPath.length - 1]}
            expanded={modPath}
            onNodeSelect={(event: ChangeEvent<{}>, node: string | string[]) => {
                if (typeof node === "string") {
                    setSettingsPath(node);
                }
            }}>
            {mapTree("", definition, stagedSettings, roles, defaultPath)}
        </TreeView>
    );
};
