/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";
import {
    PhasePeriod,
    PhasePeriodFromJSON,
    PhasePeriodToJSON,
    PhaseType,
    PhaseTypeFromJSON,
    PhaseTypeToJSON,
    ScoringType,
    ScoringTypeFromJSON,
    ScoringTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface Phase
 */
export interface Phase {
    /**
     *
     * @type {string}
     * @memberof Phase
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof Phase
     */
    userId: string;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    deviceId: number;
    /**
     *
     * @type {string}
     * @memberof Phase
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof Phase
     */
    sessionId: string;
    /**
     *
     * @type {string}
     * @memberof Phase
     */
    competitionId?: string;
    /**
     *
     * @type {string}
     * @memberof Phase
     */
    weaponId: string;
    /**
     *
     * @type {string}
     * @memberof Phase
     */
    disciplineName: string;
    /**
     *
     * @type {boolean}
     * @memberof Phase
     */
    unlimited: boolean;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    maxShots: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    series: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    nextPhase?: number;
    /**
     *
     * @type {PhaseType}
     * @memberof Phase
     */
    type: PhaseType;
    /**
     *
     * @type {Array<PhasePeriod>}
     * @memberof Phase
     */
    periods: Array<PhasePeriod>;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    duration: number;
    /**
     *
     * @type {ScoringType}
     * @memberof Phase
     */
    scoring: ScoringType;
    /**
     *
     * @type {string}
     * @memberof Phase
     */
    targetName: string;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    day: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    month: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    year: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    created: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    start?: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    end?: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    paused?: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    currentShots: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    decimalScore: number;
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    fullRingScore?: number;
    /**
     *
     * @type {boolean}
     * @memberof Phase
     */
    started: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Phase
     */
    notPaused: boolean;
}

export function PhaseFromJSON(json: any): Phase {
    return PhaseFromJSONTyped(json, false);
}

export function PhaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Phase {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json["id"],
        userId: json["userId"],
        deviceId: json["deviceId"],
        name: !exists(json, "name") ? undefined : json["name"],
        sessionId: json["sessionId"],
        competitionId: !exists(json, "competitionId") ? undefined : json["competitionId"],
        weaponId: json["weaponId"],
        disciplineName: json["disciplineName"],
        unlimited: json["unlimited"],
        maxShots: json["maxShots"],
        series: json["series"],
        nextPhase: !exists(json, "nextPhase") ? undefined : json["nextPhase"],
        type: PhaseTypeFromJSON(json["type"]),
        periods: (json["periods"] as Array<any>).map(PhasePeriodFromJSON),
        duration: json["duration"],
        scoring: ScoringTypeFromJSON(json["scoring"]),
        targetName: json["targetName"],
        day: json["day"],
        month: json["month"],
        year: json["year"],
        created: json["created"],
        start: !exists(json, "start") ? undefined : json["start"],
        end: !exists(json, "end") ? undefined : json["end"],
        paused: !exists(json, "paused") ? undefined : json["paused"],
        currentShots: json["currentShots"],
        decimalScore: json["decimalScore"],
        fullRingScore: !exists(json, "fullRingScore") ? undefined : json["fullRingScore"],
        started: json["started"],
        notPaused: json["notPaused"],
    };
}

export function PhaseToJSON(value?: Phase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        userId: value.userId,
        deviceId: value.deviceId,
        name: value.name,
        sessionId: value.sessionId,
        competitionId: value.competitionId,
        weaponId: value.weaponId,
        disciplineName: value.disciplineName,
        unlimited: value.unlimited,
        maxShots: value.maxShots,
        series: value.series,
        nextPhase: value.nextPhase,
        type: PhaseTypeToJSON(value.type),
        periods: (value.periods as Array<any>).map(PhasePeriodToJSON),
        duration: value.duration,
        scoring: ScoringTypeToJSON(value.scoring),
        targetName: value.targetName,
        day: value.day,
        month: value.month,
        year: value.year,
        created: value.created,
        start: value.start,
        end: value.end,
        paused: value.paused,
        currentShots: value.currentShots,
        decimalScore: value.decimalScore,
        fullRingScore: value.fullRingScore,
        started: value.started,
        notPaused: value.notPaused,
    };
}
