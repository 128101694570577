import React from "react";
import {Button, Grid} from "@material-ui/core";
import styles from "../../components/common/NavigationBar.module.css";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import Typography from "@material-ui/core/Typography";
import {translation} from "../../components/i18n";
import Close from "@material-ui/icons/Close";

export default function HelpMock(props: {help: boolean; setHelp: Setter<boolean>}) {
    const {help, setHelp} = props;

    if (help) {
        return (
            <Grid
                className={styles.helpOverlay}
                onClick={() => {
                    setHelp(false);
                }}
                container
                direction="column"
                justify="center"
                alignItems="center">
                <EmojiPeopleIcon style={{fontSize: 250}} />
                <Typography variant="h1">{translation("title.help.assistance")}</Typography>
                <Typography variant="subtitle1">{translation("label.wait")}</Typography>
                <Button variant="outlined" color="default" startIcon={<Close />} style={{marginTop: 16}} size="large">
                    {translation("button.close")}
                </Button>
            </Grid>
        );
    } else {
        return <></>;
    }
}
