import url from "url";
import {ConstantsStringCommon, EsaDate, User} from "../../api/generated/esa/models";
import {ConstantsNumberCommon} from "../../api/generated/esa";

export const macRegexp = /^[0-9A-Fa-f]{1,2}(?:(?::[0-9A-Fa-f]{0,2}){0,5}|(?:-[0-9A-Fa-f]{0,2}){0,5})$/;
export const hexPrefix = "80:ce:b1";
export const decPrefix = "1416252";

export function numberToMAC(int: number | undefined): string | undefined {
    if (!int || int === ConstantsNumberCommon.DEVICE_UNDEFINED) return undefined;
    return int.toString(16).padStart(12, "0").match(/../g)?.reverse().slice(0, 6).reverse().join(":").toUpperCase();
}

export function isObject(object: Object) {
    return object != null && typeof object === "object";
}

export const deepEqual = (object1: any, object2: any) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key] as string;
        const val2 = object2[key] as string;
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }
    return true;
};

export function macToNumber(mac: string | undefined): number | undefined {
    if (!mac || typeof mac !== "string") return undefined;
    return parseInt(mac.split(":").join(""), 16);
}

export function convertUnits(pixels: number, units: Unit) {
    return `${pixels}${units}`;
}

export function deviceIdentifier(device?: RangeObject) {
    return device?.alias || numberToMAC(device?.deviceId) || device?.type || "";
}

export function deepCopy<T>(target: T): T {
    if (target === null) {
        return target;
    }
    if (target instanceof Date) {
        return new Date(target.getTime()) as any;
    }
    if (target instanceof Array) {
        const cp = [] as any[];
        (target as any[]).forEach(v => {
            cp.push(v);
        });
        return cp.map((n: any) => deepCopy<any>(n)) as any;
    }
    if (typeof target === "object" && target !== {}) {
        const cp = {...(target as {[key: string]: any})} as {[key: string]: any};
        Object.keys(cp).forEach(k => {
            cp[k] = deepCopy<any>(cp[k]);
        });
        return cp as T;
    }
    return target;
}

export function partialState<T>() {
    return (state: T, action: Partial<T> | ((prevState: T) => Partial<T> | null)) => ({
        ...state,
        ...(typeof action === "function" ? action(state) : action),
    });
}

export type SetCallback<T> = (state: Partial<T> | ((prevState: T) => Partial<T> | null)) => void;

export function hashFromUrl(urlString: string = document.URL) {
    return url.parse(urlString).hash?.substr(1) ?? "";
}

export function hash(hash: string): string {
    const location = hash.split("/")[0];
    return location.length <= 0 ? "" : location.replace("#", "");
}

export function camelToTitleCase(text: string) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export function isDefinedOrEmpty(text: string | undefined) {
    return text || text === "";
}

export function emptyToNull(text: string | undefined) {
    return text === "" ? undefined : text;
}

export const capitalize = (s: any) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function intersects(a: [], b: []) {
    return a.find(e => b.includes(e));
}

export function checkResponse(response: Response) {
    if (!response.ok) {
        throw new Error(`Wrong response code ${response.status} ${response.statusText}`);
    } else {
        return response;
    }
}

export function generateUniqueFilename(filenamePattern: string) {
    return filenamePattern.replace("$timestamp", new Date().toLocaleString());
}

export function esaDateToDate(selectedDate: EsaDate) {
    return new Date(selectedDate.year, selectedDate.month, selectedDate.day);
}

export function isAdmin(user: User): boolean {
    return user.roles.find(value => value === ConstantsStringCommon.ROLE_ADMIN) !== undefined;
}

export function isSuperAdmin(user: User): boolean {
    return user.id === ConstantsStringCommon.ADMIN_USER_ID;
}

export function isCloudUser(user: User): boolean {
    return Boolean(user.cloudId);
}

export function isSpectator(user: User): boolean {
    return user.roles.find(value => value === ConstantsStringCommon.ROLE_SPECTATOR) !== undefined;
}

export function getCookie(name: string) {
    return document.cookie
        ?.split("; ")
        ?.find(row => row.startsWith(`${name}=`))
        ?.split("=")[1];
}

export function noop() {}

export function noopPromise() {
    return Promise.resolve();
}

export function isEnded(phase: Phase) {
    return isTimeEnded(phase.end);
}

export function isTimeEnded(end?: number) {
    return end && end < Date.now();
}

export function rectIntersects(rectA: DOMRect, rectB: DOMRect) {
    if (rectB.left >= rectA.right) return "left";
    if (rectB.right <= rectA.left) return "right";
    if (rectB.top >= rectA.bottom) return "top";
    if (rectB.bottom <= rectA.top) return "bottom";
    return "visible";
}
