import React, {useContext, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {AppSettings} from "../AppSettingsProvider";
import {format} from "date-fns";
import classes from "./Clock.module.css";

type Time = {
    h: number;
    m: number;
};

export default function Clock() {
    const {
        Global: {timeFormat},
    } = useContext(AppSettings);
    const date = new Date();
    const now = format(date, timeFormat.split("_")[0]);

    const h = +now.split(":")[0];
    const m = +now.split(":")[1].replace(/\D+/g, "");
    const suffix = timeFormat === "hh:mm aa" ? now.slice(now.length - 2, now.length) : "";

    const [time, setTime] = useState<Time>({...{h, m}});

    function tick() {
        setTime({...{h, m}});
    }

    useEffect(() => {
        const interval = setInterval(tick, 1000);
        return function cleanup() {
            clearInterval(interval);
        };
    });

    function timeFormatter(value: number) {
        return value < 10 ? `0${value}` : value;
    }

    return (
        <div className={classes.container}>
            <Grid container spacing={0}>
                <Grid item xs={suffix ? 3 : 4}>
                    <Paper id="clock_hour" className={classes.paper}>
                        {timeFormatter(time.h)}
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    :
                </Grid>
                <Grid item xs={suffix ? 3 : 4}>
                    <Paper id="clock_minute" className={classes.paper}>
                        {timeFormatter(time.m)}
                    </Paper>
                </Grid>
                {suffix && (
                    <Grid item xs={4}>
                        <Paper id="clock_suffix" className={classes.paper}>
                            {suffix}
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
