/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ConstantsStringEsa {
    EXCEEDED_DEVICE_LIMIT = "exceeded_device_limit",
    FREE_TRAINING_NAME = "0_0_Free_Training_Mode",
    FREE_TRAINING_PHASE_NAME = "Free Training Mode",
    NO_TRAINING_NAME = "No_Training_Mode",
    NO_TRAINING_PHASE_NAME = "No Training",
    PHASE_ID_MAX_HITS = "phase_max_hits",
    PHASE_ID_SHOT_WITHOUT_PHASE = "shot_without_phase",
    PHASE_NAME_QUALIFICATION = "Qualification",
    PHASE_NAME_SIGHTING = "Sighting",
}

export function ConstantsStringEsaFromJSON(json: any): ConstantsStringEsa {
    return ConstantsStringEsaFromJSONTyped(json, false);
}

export function ConstantsStringEsaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConstantsStringEsa {
    return json as ConstantsStringEsa;
}

export function ConstantsStringEsaToJSON(value?: ConstantsStringEsa | null): any {
    return value as any;
}
