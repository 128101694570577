/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PhaseType {
    Regular = "Regular",
    TrafficLight = "TrafficLight",
}

export function PhaseTypeFromJSON(json: any): PhaseType {
    return PhaseTypeFromJSONTyped(json, false);
}

export function PhaseTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhaseType {
    return json as PhaseType;
}

export function PhaseTypeToJSON(value?: PhaseType | null): any {
    return value as any;
}
