import React, {ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {SettingsValues} from "./settings-dialog/adapter/types/settingsTypes";
import settings from "../resources/defaultSettings.json";
import {AppContext} from "./AppContextProvider";
import {SettingsControllerApi} from "../api/generated/esa/apis";
import LoadingScreen from "./LoadingScreen";
import {CssBaseline, MuiThemeProvider, StylesProvider} from "@material-ui/core";
import {IntlProvider} from "react-intl";
import {Messages} from "../Internationalization";
import {deepCopy} from "./common/utils";
import {handleI18nError, translation} from "./i18n";
import {DefaultDeviceCalibration} from "../api/generated/esa";
import _ from "lodash";
import {EnhancedPaletteType, EnhancedTheme} from "./themes/Theme";
import {getTheme, Themes} from "../apps/Components";

const THEME_KEY = "theme";
const settingsControllerApi = new SettingsControllerApi();
const defaultSettings = settings as SettingsValues;

export const AppSettingsControl = React.createContext<AppSettingsControlType>({
    updateSettings: () => {},
    updateDefaults: () => {},
    setLang: () => {},
    setTheme: () => {},
    openSettings: false,
    setOpenSettings: () => {},
    settingsPath: "",
    setSettingsPath: () => {},
    defaultsInUse: true,
    resetSettings: () => {},
    setDeviceCalibration: () => {},
    deviceCalibration: {},
});

export const AppSettings = React.createContext<SettingsValues>(defaultSettings);

export default function AppSettingsProvider(props: {
    children?: ReactNode;
    setSettings?: (settings: any) => Promise<void>;
    getSettings?: () => Promise<string>;
}) {
    const [appSettings, setAppSettings] = useState<SettingsValues>(defaultSettings);
    const [openSettings, setOpenSettings] = useState(false);
    const [settingsPath, setSettingsPath] = useState("");
    const [defaultsInUse, setDefaultsInUse] = useState(true);
    const [deviceCalibration, setDeviceCalibration] = useState<DefaultDeviceCalibration | undefined>();
    const {
        user: {id: contextUserId},
        analytics,
    } = useContext(AppContext);
    const [currentUserId, setCurrentUserId] = useState("");
    const {setSettings, getSettings} = props;
    const lang = appSettings.Global.lang ?? defaultSettings.Global.lang;

    const updateSettings = useMemo(
        () => (settings: SettingsValues) => {
            const settingsSet =
                (setSettings && setSettings(settings)) ||
                settingsControllerApi.setUserSettings({body: settings as any});
            settingsSet.then(() => {
                setAppSettings(settings);
                localStorage.setItem(THEME_KEY, settings.Global.theme);
                setDefaultsInUse(false);
            });
            setAppSettings(settings);
            localStorage.setItem(THEME_KEY, settings.Global.theme);
            setDefaultsInUse(false);
        },
        [setSettings, setAppSettings, setDefaultsInUse],
    );

    const updateDefaults = useMemo(
        () => (settings: SettingsValues) => {
            // only available for admin
            void settingsControllerApi.setDefaults({body: settings as any});
        },
        [],
    );

    const setLang = useMemo(
        () => (lang: string) => {
            const settingsCopy = deepCopy(appSettings);
            settingsCopy.Global.lang = lang;
            updateSettings(settingsCopy);
        },
        [updateSettings, appSettings],
    );
    const setTheme = useMemo(
        () => (theme: EnhancedPaletteType) => {
            const settingsCopy = deepCopy(appSettings);
            settingsCopy.Global.theme = theme;
            updateSettings(settingsCopy);
        },
        [updateSettings, appSettings],
    );
    const resetSettings = useMemo(
        () => () => {
            settingsControllerApi.deleteUserSettings().then(() => {
                setAppSettings(defaultSettings);
                setDefaultsInUse(true);
            });
        },
        [setAppSettings, setDefaultsInUse],
    );

    const settingsControl = useMemo<AppSettingsControlType>(
        () => ({
            ...{
                updateSettings,
                updateDefaults,
                setLang,
                setTheme,
                openSettings,
                setOpenSettings,
                settingsPath,
                setSettingsPath,
                defaultsInUse,
                resetSettings,
                setDeviceCalibration,
                deviceCalibration,
            },
        }),
        [
            updateSettings,
            updateDefaults,
            setLang,
            setTheme,
            openSettings,
            setOpenSettings,
            settingsPath,
            setSettingsPath,
            defaultsInUse,
            resetSettings,
            setDeviceCalibration,
            deviceCalibration,
        ],
    );

    useEffect(() => {
        setAppSettings(defaultSettings);
        setDefaultsInUse(true);
        const settingsSource = getSettings ? getSettings() : settingsControllerApi.getUserSettings();

        settingsSource.then(response => {
            const json = JSON.parse(response);
            if (Object.keys(json).length !== 0) {
                setDefaultsInUse(false);
                const mergedWithDefault = _.cloneDeep(defaultSettings);
                _.merge(mergedWithDefault, json);

                setAppSettings(mergedWithDefault);
            }
            setCurrentUserId(contextUserId);
        });
    }, [contextUserId, getSettings]);

    const getTheme = useMemo(
        () => (themeName: string | null | undefined) => {
            return Themes.get(themeName ?? "default")!;
        },
        [],
    );

    useEffect(() => {
        analytics.track("OpenSettings", {settingsPath});
    }, [settingsPath, analytics]);

    return currentUserId === contextUserId ? (
        <AppSettingsControl.Provider value={settingsControl}>
            <AppSettings.Provider value={appSettings}>{props.children}</AppSettings.Provider>
        </AppSettingsControl.Provider>
    ) : (
        <MuiThemeProvider theme={getTheme(localStorage.getItem(THEME_KEY))}>
            <StylesProvider injectFirst>
                <CssBaseline />
                <IntlProvider onError={handleI18nError} locale={lang} messages={Messages.get(lang)}>
                    <LoadingScreen message={translation("message.loading_settings")} />
                </IntlProvider>
            </StylesProvider>
        </MuiThemeProvider>
    );
}

export function getLastUsedTheme(): EnhancedTheme {
    const themeType = localStorage.getItem(THEME_KEY) ?? defaultSettings.Global.theme;
    return getTheme(themeType);
}
