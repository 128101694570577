import React, {ReactElement, useEffect, useState} from "react";
import Popup, {translateIfString} from "./Popup";
import {TranslationKey} from "../i18n";

export default function Confirmation(props: {
    title: TranslationKey | JSX.Element;
    okButton: TranslationKey | JSX.Element;
    onClick: () => void;
    children: ReactElement;
    text?: TranslationKey | JSX.Element;
    hide?: boolean;
    okClassName?: string;
}) {
    const {title, onClick: userAction, okButton, text, children, hide, okClassName} = props;
    const [show, setShow] = useState(false);
    useEffect(() => {
        window.dispatchEvent(new CustomEvent("confirmation", {detail: show}));
    }, [show]);

    function onClose() {
        setShow(false);
    }

    function onClick() {
        onClose();
        userAction();
    }

    if (hide) {
        return React.cloneElement(children, {onClick: userAction});
    } else {
        return (
            <>
                {React.cloneElement(children, {onClick: () => setShow(true)})}
                <Popup {...{onClick, onClose, show, title, okButton, okClassName}}>
                    {text && translateIfString(text)}
                </Popup>
            </>
        );
    }
}
