import React, {useContext, useEffect, useState} from "react";
import {ButtonBase, Grid, SvgIcon} from "@material-ui/core";
import {trackClick} from "../AnalyticsClickTracker";
import Typography from "@material-ui/core/Typography";
import styles from "../common/NavigationBar.module.css";
import {translationOrName} from "../i18n";
import {hashFromUrl, isAdmin, isSpectator} from "../common/utils";
import {AppContext} from "../AppContextProvider";
import HeaderDivider from "./HeaderDivider";
import {ConstantsStringEsa, User} from "../../api/generated/esa";
import {ReactComponent as CircleTarget} from "../../resources/icons/packs/custom/filled/circle-target.svg";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import {NavigationLocation} from "./NavigationLocation";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {ReactComponent as Score} from "@fortawesome/fontawesome-free/svgs/solid/award.svg";

export function Navigation(props: {locations: NavigationLocation[]}) {
    const {locations} = props;
    const [location, setLocation] = useState(hashFromUrl());
    const {analytics} = useContext(AppContext);

    useEffect(() => {
        function hashChangeListener(event: HashChangeEvent) {
            setLocation(hashFromUrl(event.newURL));
        }
        window.addEventListener("hashchange", hashChangeListener);
        return function cleanup() {
            window.removeEventListener("hashchange", hashChangeListener);
        };
    }, [setLocation]);

    return (
        <Grid
            id="header_navigation"
            style={{width: "fit-content", height: "100%", display: "flex", flexDirection: "row"}}>
            {locations.map(item => (
                <React.Fragment key={item.location}>
                    <ButtonBase
                        id={"navigation_location_" + item.location}
                        key={item.location + "menu"}
                        onClick={() => {
                            trackClick(analytics, item.location);
                            navigate(item);
                        }}>
                        <Typography
                            component="div"
                            color={item.locations.includes(location) ? "primary" : "initial"}
                            noWrap>
                            <Grid container direction="column" alignItems="center" className={styles.menuItem}>
                                {item.icon}
                                <Typography
                                    color={item.locations.includes(location) ? "primary" : "textSecondary"}
                                    variant="caption">
                                    {translationOrName("button", item.location)}
                                </Typography>
                            </Grid>
                        </Typography>
                    </ButtonBase>
                    <HeaderDivider />
                </React.Fragment>
            ))}
        </Grid>
    );
}

export function getStartLocation(phase: Phase) {
    const trainingSelected = phase.disciplineName !== ConstantsStringEsa.NO_TRAINING_NAME;

    if (trainingSelected) {
        return {
            location: "live",
            locations: [""],
            icon: <SvgIcon component={CircleTarget} viewBox="0 0 16 16" />,
            navigate: "",
        };
    } else {
        return {location: "select", locations: [""], icon: <SvgIcon component={PlayCircleOutlineIcon} />, navigate: ""};
    }
}

const reviewLocation = {
    location: "review",
    locations: ["review"],
    icon: <Score className="svgIcon" style={{fontSize: "1.5em"}} />,
    navigate: "review",
};

export function getLocations(user: User, phase: Phase): NavigationLocation[] {
    if (isAdmin(user) || isSpectator(user)) {
        return [
            getStartLocation(phase),
            reviewLocation,
            {
                location: "spectate",
                locations: ["spectate-controls", "spectate", "qualification-controls", "qualification"],
                icon: <VisibilityIcon />,
                navigate: "spectate",
            },
        ];
    } else {
        return [getStartLocation(phase), reviewLocation];
    }
}

export function navigate(item: NavigationLocation) {
    if (typeof item.navigate === "function") {
        item.navigate();
    } else {
        window.location.href = "#" + item.navigate;
    }
}
