import React, {useCallback, useContext, useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
    CompetitionClass,
    CompetitionInfoControllerApi,
    ConstantsStringCommon,
    CountryCodes,
    CountryControllerApi,
    User,
    UsersControllerApi,
} from "../../api/generated/esa";
import {translation} from "../i18n";
import styles from "./UserManagment.module.css";
import {UserView} from "./UserView";
import UserTable from "./UserTable";
import {ArrowBack} from "@material-ui/icons";
import {AppContext} from "../AppContextProvider";
import {deepEqual, isSuperAdmin} from "../common/utils";
import {ValidationError} from "yup";
import Popup from "../common/Popup";
import _ from "lodash";
import {NotificationContext} from "../NotificationContextProvider";

const api = new UsersControllerApi();
const competitionApi = new CompetitionInfoControllerApi();
const countryApi = new CountryControllerApi();

interface ControlProps {
    controlledUpdateUser?: () => void;
}

export default function UserInfo(
    props: ControlProps & {open: boolean; setOpen: Setter<boolean>; selfProfile?: boolean},
) {
    const {open, setOpen, selfProfile, controlledUpdateUser} = props;
    const {user: currentUser, refresh} = useContext(AppContext);
    const [users, setUsers] = useState<User[]>([]);
    const [newUser, setNewUser] = useState<User>(currentUser);
    const [same, setSame] = useState(true);
    const [warningDialog, setWarningDialog] = useState(false);
    const [editableUser, setEditableUser] = useState<User | undefined>();
    const {notify} = useContext(NotificationContext);
    const [password, setPassword] = useState<string>();
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [competitionClasses, setCompetitionClasses] = useState<string[]>([]);
    const [countries, setCountries] = useState<string[]>([]);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

    function loadCompetitionClasses() {
        open &&
            competitionApi.competitionClasses().then((classes: CompetitionClass[]) => {
                return classes.map(comClass => {
                    return setCompetitionClasses(comClass.classes);
                });
            });
    }

    useEffect(loadCompetitionClasses, [open]);

    useEffect(() => {
        selfProfile && setEditableUser(currentUser);
    }, [open, currentUser, selfProfile]);

    function loadCountries() {
        open &&
            countryApi.countryCodes().then((countries: CountryCodes[]) => {
                return countries.map(countryCodes => {
                    return setCountries(countryCodes.countries);
                });
            });
    }

    useEffect(loadCountries, [open]);

    const compare = useCallback((user1, user2) => {
        return deepEqual(user1, user2);
    }, []);

    const isAdmin = useCallback(() => {
        return currentUser.roles.includes(ConstantsStringCommon.ROLE_ADMIN);
    }, [currentUser.roles]);

    useEffect(() => {
        if (isAdmin()) {
            if (editableUser) {
                setSame(compare(editableUser, newUser));
            } else setSame(true);
        } else {
            setSame(compare(newUser, currentUser));
        }
    }, [newUser, currentUser, compare, editableUser, users, isAdmin]);

    function loadUsers() {
        open && api.getAllUsers({includeAdmin: true}).then((users: User[]) => setUsers(users));
    }

    useEffect(loadUsers, [open]);

    function updateUser(user: User, oldUser: User) {
        if (controlledUpdateUser) {
            controlledUpdateUser();
            return;
        }

        const secret = user.secretType && passwordChanged ? password : undefined;
        const rolesChanged = !_.isEqual(user.roles.sort(), oldUser.roles.sort());
        if (passwordChanged || !compare(user, currentUser)) {
            api.updateUser({user, secret})
                .then(() => {
                    if (rolesChanged) {
                        notify("info", "message.user_updated");
                    } else {
                        notify("success", "message.user.user_updated");
                    }
                })
                .finally(() => {
                    setPasswordChanged(false);
                    loadUsers();
                    setEditableUser(undefined);
                    selfProfile && setOpen(false);
                    if (user.id === currentUser.id) {
                        refresh();
                    }
                });
        } else {
            notify("info", "message.nothing_to_update");
        }
    }

    function close() {
        setPasswordChanged(false);
        setValidationErrors([]);
        if (same) {
            setOpen(false);
        } else {
            if (validationErrors.length === 0) {
                setWarningDialog(true);
            } else {
                setOpen(false);
            }
        }
    }
    const adminEditUser = editableUser && isAdmin() && !isSuperAdmin(editableUser);
    const userEditSelf = editableUser && !isSuperAdmin(currentUser);
    const canUpdate = userEditSelf || adminEditUser;

    return (
        <>
            <Popup
                show={open}
                onClose={close}
                title="title.profile_management"
                variant="wide"
                okButton="button.update"
                okButtonElement={
                    canUpdate ? (
                        <Button
                            id="update_action_user_info"
                            disabled={validationErrors.length !== 0}
                            onClick={() => {
                                editableUser && updateUser(newUser, editableUser);
                                setPassword(undefined);
                            }}
                            variant={same ? "outlined" : "contained"}
                            color="primary">
                            {translation("button.update")}
                        </Button>
                    ) : (
                        <></>
                    )
                }>
                <Grid item className={styles.container} xs={12} md={12} xl={12}>
                    {isAdmin && !selfProfile ? (
                        editableUser ? (
                            <>
                                <Grid item container alignItems="center" className={styles.managementToolbar}>
                                    <Button
                                        onClick={() => {
                                            setSame(compare(editableUser, newUser));
                                            if (same) {
                                                setEditableUser(undefined);
                                            } else {
                                                setWarningDialog(true);
                                            }
                                        }}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<ArrowBack />}>
                                        {translation("button.back")}
                                    </Button>
                                </Grid>
                                <UserView
                                    user={editableUser}
                                    {...{
                                        password,
                                        setPassword,
                                        passwordChanged,
                                        setPasswordChanged,
                                        setNewUser,
                                        newUser,
                                        competitionClasses,
                                        countries,
                                        setValidationErrors,
                                        validationErrors,
                                        users,
                                    }}
                                />
                            </>
                        ) : (
                            <UserTable {...{users, setEditableUser, loadUsers}} />
                        )
                    ) : (
                        <UserView
                            user={currentUser}
                            {...{
                                setNewUser,
                                newUser,
                                password,
                                setPassword,
                                passwordChanged,
                                setPasswordChanged,
                                competitionClasses,
                                countries,
                                setValidationErrors,
                                validationErrors,
                                users,
                            }}
                        />
                    )}
                </Grid>
            </Popup>

            <Dialog open={warningDialog} onClose={() => setWarningDialog(false)}>
                <DialogTitle id="alert-dialog-title">{translation("title.prompt.save_before_close")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translation("message.prompt.close_without_save")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setWarningDialog(false);
                        }}>
                        {translation("button.cancel")}
                    </Button>
                    <Button
                        onClick={() => {
                            setWarningDialog(false);
                            setOpen(false);
                            setValidationErrors([]);
                        }}
                        color="primary">
                        {translation("button.discard")}
                    </Button>
                    <Button
                        onClick={() => {
                            setWarningDialog(false);
                            editableUser && updateUser(newUser, editableUser);
                        }}
                        disabled={validationErrors.length !== 0}
                        variant="contained"
                        color="primary"
                        autoFocus>
                        {translation("button.save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
