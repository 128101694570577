/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {Weapon, WeaponCounts, WeaponCountsFromJSON, WeaponFromJSON, WeaponToJSON} from "../models";

export interface CreateWeaponRequest {
    weapon: Weapon;
}

export interface DeleteWeaponRequest {
    weaponId: string;
}

export interface GetWeaponRequest {
    weaponId: string;
}

export interface ImportWeaponsRequest {
    body: string;
}

export interface UpdateWeaponRequest {
    weapon: Weapon;
}

/**
 *
 */
export class WeaponsControllerApi extends runtime.BaseAPI {
    /**
     */
    async createWeaponRaw(requestParameters: CreateWeaponRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.weapon === null || requestParameters.weapon === undefined) {
            throw new runtime.RequiredError(
                "weapon",
                "Required parameter requestParameters.weapon was null or undefined when calling createWeapon.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/weapons/`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: WeaponToJSON(requestParameters.weapon),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createWeapon(requestParameters: CreateWeaponRequest): Promise<void> {
        await this.createWeaponRaw(requestParameters);
    }

    /**
     */
    async deleteWeaponRaw(requestParameters: DeleteWeaponRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.weaponId === null || requestParameters.weaponId === undefined) {
            throw new runtime.RequiredError(
                "weaponId",
                "Required parameter requestParameters.weaponId was null or undefined when calling deleteWeapon.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/weapons/{weaponId}`.replace(
                `{${"weaponId"}}`,
                encodeURIComponent(String(requestParameters.weaponId)),
            ),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteWeapon(requestParameters: DeleteWeaponRequest): Promise<void> {
        await this.deleteWeaponRaw(requestParameters);
    }

    /**
     */
    async getAllWeaponsRaw(): Promise<runtime.ApiResponse<Array<Weapon>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/weapons/`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(WeaponFromJSON));
    }

    /**
     */
    async getAllWeapons(): Promise<Array<Weapon>> {
        const response = await this.getAllWeaponsRaw();
        return await response.value();
    }

    /**
     */
    async getWeaponRaw(requestParameters: GetWeaponRequest): Promise<runtime.ApiResponse<Weapon>> {
        if (requestParameters.weaponId === null || requestParameters.weaponId === undefined) {
            throw new runtime.RequiredError(
                "weaponId",
                "Required parameter requestParameters.weaponId was null or undefined when calling getWeapon.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/weapons/{weaponId}`.replace(
                `{${"weaponId"}}`,
                encodeURIComponent(String(requestParameters.weaponId)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => WeaponFromJSON(jsonValue));
    }

    /**
     */
    async getWeapon(requestParameters: GetWeaponRequest): Promise<Weapon> {
        const response = await this.getWeaponRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getWeaponsCsvRaw(): Promise<runtime.ApiResponse<Array<Weapon>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/weapons/export`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(WeaponFromJSON));
    }

    /**
     */
    async getWeaponsCsv(): Promise<Array<Weapon>> {
        const response = await this.getWeaponsCsvRaw();
        return await response.value();
    }

    /**
     */
    async importWeaponsRaw(requestParameters: ImportWeaponsRequest): Promise<runtime.ApiResponse<WeaponCounts>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError(
                "body",
                "Required parameter requestParameters.body was null or undefined when calling importWeapons.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/weapons/import`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, jsonValue => WeaponCountsFromJSON(jsonValue));
    }

    /**
     */
    async importWeapons(requestParameters: ImportWeaponsRequest): Promise<WeaponCounts> {
        const response = await this.importWeaponsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateWeaponRaw(requestParameters: UpdateWeaponRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.weapon === null || requestParameters.weapon === undefined) {
            throw new runtime.RequiredError(
                "weapon",
                "Required parameter requestParameters.weapon was null or undefined when calling updateWeapon.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/weapons/`,
            method: "PATCH",
            headers: headerParameters,
            query: queryParameters,
            body: WeaponToJSON(requestParameters.weapon),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateWeapon(requestParameters: UpdateWeaponRequest): Promise<void> {
        await this.updateWeaponRaw(requestParameters);
    }
}
