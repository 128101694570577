import React, {useContext} from "react";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import {IconButton, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {AppContext} from "../AppContextProvider";
import {translation} from "../i18n";

export default function LightDarkThemeChanger(props: {
    theme: string;
    setTheme: (theme: string) => void;
    icon?: boolean;
}) {
    const {theme, setTheme, icon} = props;
    const {analytics} = useContext(AppContext);

    function isDarkTheme(): boolean {
        return theme === "dark";
    }

    function themeSelector() {
        const newTheme = isDarkTheme() ? "light" : "dark";
        setTheme(newTheme);
        void analytics.track("ThemeChange", {PaletteType: newTheme});
    }

    return (
        <div id="theme_control_button" onClick={themeSelector}>
            {icon ? (
                <IconButton>{isDarkTheme() ? <WbSunnyIcon /> : <Brightness2Icon />}</IconButton>
            ) : (
                <ListItem button>
                    <ListItemIcon> {isDarkTheme() ? <WbSunnyIcon /> : <Brightness2Icon />}</ListItemIcon>
                    <ListItemText
                        primary={isDarkTheme() ? translation("title.light_theme") : translation("title.dark_theme")}
                    />
                </ListItem>
            )}
        </div>
    );
}
