/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface ClearRequest {
    userName: string;
}

/**
 *
 */
export class HelpControllerApi extends runtime.BaseAPI {
    /**
     */
    async clearRaw(requestParameters: ClearRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userName === null || requestParameters.userName === undefined) {
            throw new runtime.RequiredError(
                "userName",
                "Required parameter requestParameters.userName was null or undefined when calling clear.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/help/{userName}`.replace(
                `{${"userName"}}`,
                encodeURIComponent(String(requestParameters.userName)),
            ),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clear(requestParameters: ClearRequest): Promise<void> {
        await this.clearRaw(requestParameters);
    }

    /**
     */
    async getHelpMessagesRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/help`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getHelpMessages(): Promise<Array<string>> {
        const response = await this.getHelpMessagesRaw();
        return await response.value();
    }

    /**
     */
    async sendHelpMessageRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/help`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendHelpMessage(): Promise<void> {
        await this.sendHelpMessageRaw();
    }
}
