/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface TypeInfo
 */
export interface TypeInfo {
    /**
     *
     * @type {string}
     * @memberof TypeInfo
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TypeInfo
     */
    icon: string;
}

export function TypeInfoFromJSON(json: any): TypeInfo {
    return TypeInfoFromJSONTyped(json, false);
}

export function TypeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeInfo {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: json["name"],
        icon: json["icon"],
    };
}

export function TypeInfoToJSON(value?: TypeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        icon: value.icon,
    };
}
