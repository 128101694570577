import {Divider, Fade, Grid, IconButton, TableCell, TableRow, Typography, TypographyProps} from "@material-ui/core";
import React, {useContext} from "react";
import {Filter, HitToDisplay} from "./HitTable";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {HitRow} from "./HitRow";
import {ScoringType} from "../../../api/generated/esa";
import styles from "./Table.module.css";
import {localizedLabel} from "../../i18n";
import {AppSettings} from "../../AppSettingsProvider";

interface HitSeriesProps {
    onSelect?: (id: string) => void;
    scoringType?: ScoringType;
    hits: HitToDisplay[];
    selected?: string;
    filter: Filter;
    index: number;
    openSeries: number;
    setOpenSeries: (index: number) => void;
    allSeriesOpen?: boolean;
}

export function HitSeries(props: HitSeriesProps) {
    const {scoringType, hits, onSelect, selected, filter, index, setOpenSeries, openSeries, allSeriesOpen} = props;
    const open = index === openSeries || allSeriesOpen;
    const {
        Components: {
            Lomah: {
                Table: {fontLarge},
            },
        },
    } = useContext(AppSettings);

    function Text(props: TypographyProps) {
        return (
            <Typography {...props} style={{fontSize: fontLarge ? "130%" : "initial"}}>
                {props.children}
            </Typography>
        );
    }

    function formatTotalScore() {
        if (hits.length <= 0) {
            return <></>;
        }

        if (scoringType === ScoringType.FullRing) {
            const totalFullRingScore = hits
                .map(hit => hit.score)
                .reduce((total, score) => total + Math.floor(score), 0);

            const totalDecimalScore = hits.map(hit => hit.score).reduce((total, score) => total + score);

            return (
                <>
                    <b className="total">{totalFullRingScore}</b>
                    <span id="totalDecimal" className={styles.margin}>
                        ({totalDecimalScore.toFixed(1)})
                    </span>
                </>
            );
        } else {
            const totalDecimalScore = hits.map(hit => hit.score).reduce((total, score) => total + score);
            return <b className="total">{totalDecimalScore.toFixed(1)}</b>;
        }
    }

    return (
        <React.Fragment>
            <TableRow onClick={() => setOpenSeries(index === openSeries ? 0 : index)}>
                <TableCell component="td" scope="row" colSpan={10} style={{padding: 0}} className={styles.seriesRow}>
                    <span className={styles.seriesRowBackground} />
                    <Grid container alignItems="center" className={styles.padding}>
                        <Text color="primary">
                            <b>{index}. </b>
                        </Text>
                        <Text>{localizedLabel("series")}</Text>
                        <Divider orientation="vertical" flexItem className={styles.margin} />
                        <Text className={styles.margin} color="primary">
                            {localizedLabel("total")}:
                        </Text>
                        <Text className={styles.margin}> {formatTotalScore()} </Text>
                        <IconButton style={{marginLeft: "auto"}} size="small">
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Grid>
                </TableCell>
            </TableRow>
            {open && (
                <Fade in={open}>
                    <>
                        {hits
                            .sort((a, b) => {
                                const asc = a[filter.column] - b[filter.column];
                                const desc = b[filter.column] - a[filter.column];
                                return filter.reverse ? asc : desc;
                            })
                            .map((hit: HitToDisplay, i: number) => (
                                <HitRow
                                    key={`${i}_row_series_key`}
                                    {...{
                                        scoringType,
                                        onSelect,
                                        hit,
                                        selected,
                                    }}
                                />
                            ))}
                    </>
                </Fade>
            )}
        </React.Fragment>
    );
}
