import React, {ErrorInfo, useState} from "react";
import styles from "./ErrorView.module.css";
import {getComponentName} from "./getComponentName";
import {SettingsControllerApi, TrainingSessionControllerApi} from "../api/generated/esa/apis";

const settingsControllerApi = new SettingsControllerApi();
const sessionControllerApi = new TrainingSessionControllerApi();

export function ErrorView(props: {error: Error | any; errorInfo: ErrorInfo | null}) {
    const [log, setLog] = useState(false);
    const {error, errorInfo} = props;

    function resetSettings() {
        localStorage.clear();
        settingsControllerApi.deleteUserSettings().finally(() => {
            window.location.reload();
        });
    }

    function resetPhase() {
        sessionControllerApi.unconditionalRemove().finally(() => {
            window.location.reload();
        });
    }

    function highlightComponent(line: string) {
        const component = getComponentName(line);
        if (component) {
            const wrapper = line.split(component);
            return (
                <span>
                    {wrapper[0]} <span className={styles.component}> {component} </span> {wrapper[1]}
                </span>
            );
        } else return line;
    }

    return (
        <div className={styles.errorContainer}>
            <span className={styles.logButton} onClick={() => setLog(true)}>
                &#9776; Error Log
            </span>
            <div
                className={styles.sideNavigation}
                style={{width: log ? 400 : 0, padding: log ? "60px 8px 40px 8px" : 0, color: log ? "white" : "black"}}>
                <span className={styles.closeButton} onClick={() => setLog(false)}>
                    &times;
                </span>
                <h2>{error?.name}</h2>
                <i>{error?.message}</i>
                <div className={styles.log}>
                    {errorInfo?.componentStack
                        .split("in ")
                        .map(it => it.trim())
                        .map(
                            (it, index) =>
                                it.length >= 1 && (
                                    <p key={index + "_error_line"}>
                                        <span className={styles.purple}>in </span>
                                        {highlightComponent(it)}
                                    </p>
                                ),
                        )}
                </div>
            </div>
            <h1>ERROR</h1>
            <h4>( Something went wrong )</h4>
            <div style={{display: "flex"}}>
                <button onClick={() => window.location.reload()}>RELOAD</button>
                <button onClick={resetSettings}>RESET SETTINGS</button>
                <button onClick={resetPhase}>RESET PHASE</button>
            </div>
        </div>
    );
}
