import React, {useContext, useEffect, useState} from "react";
import {ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Switch} from "@material-ui/core";
import {Highlight, HighlightOutlined} from "@material-ui/icons";
import {ConstantsNumberCommon, EsaControllerApi, PowerStatus} from "../../api/generated/esa";
import {translation} from "../i18n";
import {AppContext} from "../AppContextProvider";

const api = new EsaControllerApi();

export default function EsaLightSwitch() {
    const [powerStatus, setPowerStatus] = useState(PowerStatus.UNKNOWN);
    const {
        userDevice: {deviceId},
    } = useContext(AppContext);

    useEffect(() => {
        api.getLightingPowerStatus({...{deviceId}})
            .then(powerStatus => setPowerStatus(powerStatus))
            .catch();
    }, [deviceId]);

    function switchState() {
        const invertedPowerStatus = powerStatus === PowerStatus.ON ? PowerStatus.OFF : PowerStatus.ON;
        api.setLightingPowerStatus({deviceId: deviceId, powerStatus: invertedPowerStatus})
            .then(() => setPowerStatus(invertedPowerStatus))
            .catch();
    }

    return <EsaLightSwitchButton checked={powerStatus === PowerStatus.ON} onClick={() => switchState()} />;
}

export function EsaLightSwitchButton(props: {checked: boolean; onClick: () => void}) {
    const {checked, onClick} = props;
    const {
        userDevice: {deviceId},
    } = useContext(AppContext);

    return (
        <div style={{position: "relative"}}>
            <span id="light_switch_placeholder_for_bubble" style={{position: "absolute", top: 20, left: -125}} />

            <ListItem
                id="light_switch_control_button"
                button
                disabled={deviceId === ConstantsNumberCommon.DEVICE_UNDEFINED}
                {...{onClick}}>
                <ListItemIcon> {checked ? <Highlight /> : <HighlightOutlined />} </ListItemIcon>
                <ListItemText primary={translation("title.light_switch")} />
                <ListItemSecondaryAction>
                    <Switch
                        disabled={deviceId === ConstantsNumberCommon.DEVICE_UNDEFINED}
                        edge="end"
                        {...{
                            checked,
                            onClick,
                        }}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}
