/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface WebSocketMessageAction
 */
export interface WebSocketMessageAction {
    /**
     *
     * @type {string}
     * @memberof WebSocketMessageAction
     */
    action: WebSocketMessageActionActionEnum;
    /**
     *
     * @type {string}
     * @memberof WebSocketMessageAction
     */
    user: string;
    /**
     *
     * @type {string}
     * @memberof WebSocketMessageAction
     */
    value?: string;
}

export function WebSocketMessageActionFromJSON(json: any): WebSocketMessageAction {
    return WebSocketMessageActionFromJSONTyped(json, false);
}

export function WebSocketMessageActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebSocketMessageAction {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        action: json["action"],
        user: json["user"],
        value: !exists(json, "value") ? undefined : json["value"],
    };
}

export function WebSocketMessageActionToJSON(value?: WebSocketMessageAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        action: value.action,
        user: value.user,
        value: value.value,
    };
}

/**
 * @export
 * @enum {string}
 */
export enum WebSocketMessageActionActionEnum {
    LEAVE = "LEAVE",
    SESSIONEXPIRED = "SESSION_EXPIRED",
    WARNING = "WARNING",
    ERROR = "ERROR",
    INFO = "INFO",
    REQUESTASSISTANCE = "REQUEST_ASSISTANCE",
    REASSIGN = "REASSIGN",
    REASSIGNDECLINED = "REASSIGN_DECLINED",
    TOKENLOGIN = "TOKEN_LOGIN",
}
