/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface EsaDate
 */
export interface EsaDate {
    /**
     *
     * @type {number}
     * @memberof EsaDate
     */
    day: number;
    /**
     *
     * @type {number}
     * @memberof EsaDate
     */
    month: number;
    /**
     *
     * @type {number}
     * @memberof EsaDate
     */
    year: number;
}

export function EsaDateFromJSON(json: any): EsaDate {
    return EsaDateFromJSONTyped(json, false);
}

export function EsaDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EsaDate {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        day: json["day"],
        month: json["month"],
        year: json["year"],
    };
}

export function EsaDateToJSON(value?: EsaDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        day: value.day,
        month: value.month,
        year: value.year,
    };
}
