import React, {useContext, useState} from "react";
import ConnectionStatusEsa from "../../components/header/ConnectionStatusEsa";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import styles from "../../components/common/NavigationBar.module.css";
import {Divider, Grid, Hidden} from "@material-ui/core";
import {ReactComponent as Score} from "@fortawesome/fontawesome-free/svgs/solid/award.svg";
import {AppContext} from "../../components/AppContextProvider";
import Clock from "../../components/clock/Clock";
import {Burger} from "../../components/header/Burger";
import {WeaponInfoButton} from "../../components/weapon-management-dialog/WeaponInfo";
import {ImportExportButton} from "../../components/header/ImportExport";
import {getStartLocation, Navigation} from "../../components/header/Navigation";
import HeaderDivider from "../../components/header/HeaderDivider";
import {NavigationLocation} from "../../components/header/NavigationLocation";
import AppLogo from "../../components/header/Logo";
import {SettingsDialog} from "../../components/settings-dialog/SettingsDialog";
import LanguageChanger from "../../components/header/LanguageChanger";
import {EsaLightSwitchButton} from "../../components/header/EsaLightSwitch";
import UserTag from "../../components/user-management-dialog/UserTag";
import AssistanceButton from "../../components/header/AssistanceButton";
import {ManualButton} from "../../components/header/manual/Manual";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HelpMock from "./Help.mock";
import {AppContextTypeMock} from "./PortalMock";
import {DeviceMapperButton} from "../../components/device-mapper/DeviceMapper";
import JoyrideButton from "../../components/joyride/JoyrideButton";
import LightDarkThemeChanger from "../../components/header/LightDarkThemeChanger";
import {AppSettings, AppSettingsControl} from "../../components/AppSettingsProvider";

export default function ApplicationHeaderMock() {
    const [help, setHelp] = useState(false);
    const {phase, setBookDemoTranslationKey} = useContext(AppContext) as AppContextTypeMock;
    const {
        Global: {theme},
    } = useContext(AppSettings);
    const {setTheme} = useContext(AppSettingsControl);

    const userTag = (
        <UserTag controlledUpdateUser={() => setBookDemoTranslationKey(["title.update_user", "demo.update_user"])} />
    );

    const locations: NavigationLocation[] = [
        getStartLocation(phase),
        {
            location: "review",
            locations: ["review"],
            icon: <Score className="svgIcon" style={{fontSize: "1.5em"}} />,
            navigate: "review",
        },
        {
            location: "spectate",
            locations: [],
            icon: <VisibilityIcon />,
            navigate: () => setBookDemoTranslationKey(["demo.spectate_view.title", "demo.spectate_view"]),
        },
    ];

    return (
        <>
            <AppBar color="default" position="static" elevation={0} className={styles.appBar}>
                <Toolbar className={styles.toolbar}>
                    <Grid
                        id="header_navigation_wrapper"
                        container
                        direction="row"
                        alignItems="center"
                        style={{
                            height: "100%",
                            width: "fit-content",
                            margin: 0,
                            display: "flex",
                            flexDirection: "row",
                        }}>
                        <AppLogo />
                        <HeaderDivider />
                        <Hidden xsDown>
                            <Navigation {...{locations}} />
                        </Hidden>
                    </Grid>

                    <Hidden smDown>
                        <Divider orientation="vertical" flexItem style={{marginLeft: "auto"}} />
                        <div id="header_user_navigation" className={styles.userIcon}>
                            {userTag}
                        </div>

                        <HelpMock {...{help, setHelp}} />
                        <HeaderDivider />
                        <HeaderDivider />
                        <Clock />
                        <HeaderDivider />
                    </Hidden>
                    <Hidden mdUp>
                        <Divider orientation="vertical" flexItem style={{marginLeft: "auto"}} />
                    </Hidden>
                    <ConnectionStatusEsa />
                    <HeaderDivider />

                    <Burger
                        profile={userTag}
                        {...{locations}}
                        controls={[
                            <EsaLightSwitchButton
                                key="lightswitch"
                                checked={true}
                                onClick={() => setBookDemoTranslationKey(["title.light_switch", "demo.light_switch"])}
                            />,
                            <SettingsDialog key="settingsDialog" />,
                            <LightDarkThemeChanger key="ThemeChanger_component" {...{theme, setTheme}} />,
                            <LanguageChanger key="languageChanger" />,
                        ]}
                        tools={[
                            <WeaponInfoButton
                                key="weapon_info_button"
                                onClick={() =>
                                    setBookDemoTranslationKey(["title.weapons_editor", "demo.weapons_editor"])
                                }
                            />,
                            <DeviceMapperButton
                                key="device_mapper_button"
                                onClick={() => setBookDemoTranslationKey(["title.device_mapper", "demo.device_mapper"])}
                            />,
                            <ImportExportButton
                                key="import_export_button"
                                onClick={() => setBookDemoTranslationKey(["title.export", "demo.export"])}
                            />,
                        ]}
                        help={[
                            <AssistanceButton
                                key="assistance_button"
                                onClick={() => setBookDemoTranslationKey(["title.assistance", "demo.assistance"])}
                            />,
                            <ManualButton
                                key="manual_button"
                                onClick={() => setBookDemoTranslationKey(["title.manual", "demo.manual"])}
                            />,
                            <JoyrideButton key="joyride_button" />,
                        ]}
                    />
                </Toolbar>
            </AppBar>
        </>
    );
}
