import React, {ReactNode, useRef} from "react";
import {Typography} from "@material-ui/core";
import style from "./Review.module.css";

export function ReviewSessionsWrapper(props: {children: ReactNode; title: JSX.Element}) {
    const container = useRef<HTMLDivElement | null>(null);
    const title = useRef<HTMLDivElement | null>(null);

    const tHeight = title.current?.clientHeight ?? 0;
    const cHeight = container.current?.clientHeight ?? 0;
    const listHeight = cHeight - tHeight;

    return (
        <div style={{height: "100%"}} ref={container}>
            <Typography className={style.titleBackground} ref={title} variant="subtitle1">
                {props.title}
            </Typography>
            <div style={{overflow: "auto", height: listHeight}}>{props.children}</div>
        </div>
    );
}
