import React, {Component, ErrorInfo} from "react";
import "./App.css";
import "./Utils.css";
import {ErrorView} from "./components/ErrorView";
import {getComponentName} from "./components/getComponentName";
import {LayoutComponents} from "./apps/Components";
import BrowserSupport from "./browser-support";

interface AppProps {}

const chrome = "69";
const firefox = "62";
const safari = "12";
const webview = "69";
const edge = "69";

const minBrowserVersions = {
    chrome: chrome,
    edge: "79",
    firefox: firefox,
    opera: "56",
    safari: safari,
    crios: chrome, // Same as Chrome for iPhones https://chromium.googlesource.com/chromium/src/+/master/docs/ios/user_agent.md
    fxios: firefox, //Firefox | Focus for iOS https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent/Firefox#focus_for_ios
    ios: safari,
    "chromium-webview": webview,
    "edge-chromium": edge,
};

interface AppState {
    hasError: boolean;
    error: null | Error;
    errorInfo: null | ErrorInfo;
}

class App extends Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {hasError: false, error: null, errorInfo: null};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            hasError: true,
            ...{error, errorInfo},
        });
        localStorage.setItem(
            "reactError",
            getComponentName(errorInfo.componentStack.split("in ").map(it => it.trim())[1]) ?? "Error",
        );
    }

    render(): React.ReactNode {
        const {hasError, error, errorInfo} = this.state;
        const ThePortal: React.JSXElementConstructor<{}> = LayoutComponents.get("Portal") ?? React.Fragment;

        return (
            <div className="App">
                <BrowserSupport supported={minBrowserVersions} />
                {hasError ? <ErrorView error={error} {...{errorInfo}} /> : <ThePortal />}
            </div>
        );
    }
}

export default App;
