import {FormattedMessage, IntlShape} from "react-intl";
import React from "react";
import {PrimitiveType} from "intl-messageformat";
import defaultLocale from "../translations/en.json";

export type TranslationType = typeof defaultLocale;
export type TranslationKey = keyof TranslationType;
/** ⚠ Only use if @name in unpredictable (e.g. user input, configuraion or error message.
 * If translations are known to app, `translation` should be used
 */
export type UndesirableTranslationPrefixes =
    | "title.about"
    | "button"
    | "component.target.centering"
    | "settings.weapons_editor"
    | "settings.units.short"
    | "label.config"
    | "label.input.weapons_editor";
/** ⚠ Only use if @name in unpredictable (e.g. user input, configuraion or error message.
 * If translations are known to app, `translation` should be used
 */
export type TranslationPrefix =
    | "error"
    | "warning"
    | "info"
    | "discipline"
    | "target"
    | "roles"
    | "component.timer.phase"
    | "component.timer.status"
    | "label.scoring"
    | "settings.theme"
    | "joyride.application"
    | "joyride.serverless"
    | "timer.period"
    | "settings"
    | UndesirableTranslationPrefixes;

function i18nKey(prefix: string, id: string) {
    try {
        return (prefix + "." + id).toLowerCase().replace(/[)( ]/g, "_").trim();
    } catch (e) {
        return "error";
    }
}

/**
 * Returns React component with translation
 */
export function translation(id: TranslationKey, values: Record<string, PrimitiveType> | undefined = undefined) {
    return <FormattedMessage id={id.toLowerCase()} values={values} />;
}

/**
 * Returns translation string
 */
export function translationString(
    intl: IntlShape,
    id: TranslationKey,
    values: Record<string, PrimitiveType> | undefined = undefined,
) {
    return intl.formatMessage({id: id.toLowerCase()}, values);
}

/**
 * Returns translation string or @param name if translation not found
 * ⚠ Only use if @name in unpredictable (e.g. user input, configuraion or error message.
 * If translations are known to app, `translation` should be used
 */
export function translationOrName(
    prefix: TranslationPrefix,
    name: string,
    values: Record<string, PrimitiveType> | undefined = undefined,
) {
    return <FormattedMessage id={i18nKey(prefix, name)} values={values} defaultMessage={name} />;
}

/**
 * Returns translation string or @param name if translation not found
 * ⚠ Only use if @name in unpredictable (e.g. user input, configuraion or error message.
 * If translations are known to app, `translationOrName` should be used
 */
export function translationStringOrName(
    intl: IntlShape,
    prefix: TranslationPrefix,
    name: string,
    values: Record<string, PrimitiveType> | undefined = undefined,
) {
    return intl.formatMessage({id: i18nKey(prefix, name), defaultMessage: name}, values);
}

/**
 * using default message as fallback. is not an error
 */
export function handleI18nError(e: string) {
    if (!e.endsWith("using default message as fallback.")) {
        console.error(e);
    }
}

/**
 * @deprecated use `translationOrName` instead
 */
function translatedText(prefix: string, id: string, values: any | undefined = undefined) {
    return <FormattedMessage id={i18nKey(prefix, id)} values={values} defaultMessage={id} />;
}

/**
 * @deprecated use `translation` instead
 */
function localizedButtonText(id: string) {
    return translatedText("button", id);
}

/**
 * @deprecated use `translation` instead
 */
function localizedLabel(id: string) {
    return translatedText("label", id);
}

/**
 * @deprecated use `translation` instead
 */
function localizedTitle(id: string) {
    return translatedText("title", id);
}

/**
 * @deprecated use `translationString` instead
 */
function translatedString(intl: IntlShape, prefix: string, id: string) {
    return intl.formatMessage({id: prefix + "." + id.toLowerCase(), defaultMessage: id});
}

export {i18nKey, translatedText, localizedButtonText, localizedLabel, localizedTitle, translatedString};
