import "react-hot-loader";
import {hot} from "react-hot-loader/root";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

/** @jsxImportSource @welldone-software/why-did-you-render */

const HotApp = hot(App);
ReactDOM.render(<HotApp />, document.getElementById("root"));
serviceWorker.unregister();
