/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface PhasePeriod
 */
export interface PhasePeriod {
    /**
     *
     * @type {string}
     * @memberof PhasePeriod
     */
    type: PhasePeriodTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PhasePeriod
     */
    className?: PhasePeriodClassNameEnum;
    /**
     *
     * @type {number}
     * @memberof PhasePeriod
     */
    time: number;
}

export function PhasePeriodFromJSON(json: any): PhasePeriod {
    return PhasePeriodFromJSONTyped(json, false);
}

export function PhasePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhasePeriod {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: json["type"],
        className: !exists(json, "className") ? undefined : json["className"],
        time: json["time"],
    };
}

export function PhasePeriodToJSON(value?: PhasePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        type: value.type,
        className: value.className,
        time: value.time,
    };
}

/**
 * @export
 * @enum {string}
 */
export enum PhasePeriodTypeEnum {
    Shoot = "Shoot",
    Stop = "Stop",
    FirstWarning = "FirstWarning",
    SecondWarning = "SecondWarning",
}
/**
 * @export
 * @enum {string}
 */
export enum PhasePeriodClassNameEnum {
    Error = "Error",
    Success = "Success",
    Warning = "Warning",
    Info = "Info",
    Primary = "Primary",
    Secondary = "Secondary",
}
