import React from "react";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import {translation} from "../i18n";

export default function AssistanceButton(props: {onClick: () => void}) {
    const {onClick} = props;

    return (
        <ListItem id="assistance_control_button" button onClick={onClick}>
            <ListItemIcon>
                <EmojiPeopleIcon />
            </ListItemIcon>
            <ListItemText primary={translation("title.assistance")} />
        </ListItem>
    );
}
