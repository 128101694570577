/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CloudTrainingHit
 */
export interface CloudTrainingHit {
    /**
     *
     * @type {number}
     * @memberof CloudTrainingHit
     */
    n: number;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingHit
     */
    x: number;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingHit
     */
    y: number;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingHit
     */
    score: number;
    /**
     *
     * @type {string}
     * @memberof CloudTrainingHit
     */
    displayScore: string;
    /**
     *
     * @type {Date}
     * @memberof CloudTrainingHit
     */
    timestamp: Date;
}

export function CloudTrainingHitFromJSON(json: any): CloudTrainingHit {
    return CloudTrainingHitFromJSONTyped(json, false);
}

export function CloudTrainingHitFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudTrainingHit {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        n: json["n"],
        x: json["x"],
        y: json["y"],
        score: json["score"],
        displayScore: json["displayScore"],
        timestamp: new Date(json["timestamp"]),
    };
}

export function CloudTrainingHitToJSON(value?: CloudTrainingHit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        n: value.n,
        x: value.x,
        y: value.y,
        score: value.score,
        displayScore: value.displayScore,
        timestamp: value.timestamp.toISOString(),
    };
}
