import React from "react";
import {Typography, TypographyProps} from "@material-ui/core";

export function H1(props: TypographyProps) {
    return (
        <Typography {...props} variant="h1">
            {props.children}
        </Typography>
    );
}

export function H2(props: TypographyProps) {
    return (
        <Typography {...props} variant="h2">
            {props.children}
        </Typography>
    );
}

export function H3(props: TypographyProps) {
    return (
        <Typography {...props} variant="h3">
            {props.children}
        </Typography>
    );
}

export function H4(props: TypographyProps) {
    return (
        <Typography {...props} variant="h4">
            {props.children}
        </Typography>
    );
}

export function H5(props: TypographyProps) {
    return (
        <Typography {...props} variant="h5">
            {props.children}
        </Typography>
    );
}

export function H6(props: TypographyProps) {
    return (
        <Typography {...props} variant="h6">
            {props.children}
        </Typography>
    );
}

export function Subtitle1(props: TypographyProps) {
    return (
        <Typography {...props} variant="subtitle1">
            {props.children}
        </Typography>
    );
}

export function Subtitle2(props: TypographyProps) {
    return (
        <Typography {...props} variant="subtitle2">
            {props.children}
        </Typography>
    );
}

export function Body1(props: TypographyProps) {
    return (
        <Typography {...props} variant="body1">
            {props.children}
        </Typography>
    );
}

export function Body2(props: TypographyProps) {
    return (
        <Typography {...props} variant="body2">
            {props.children}
        </Typography>
    );
}

export function Caption(props: TypographyProps) {
    return (
        <Typography {...props} variant="caption">
            {props.children}
        </Typography>
    );
}

export function ButtonText(props: TypographyProps) {
    return (
        <Typography {...props} variant="button">
            {props.children}
        </Typography>
    );
}

export function Overline(props: TypographyProps) {
    return (
        <Typography {...props} variant="overline">
            {props.children}
        </Typography>
    );
}
