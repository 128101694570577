/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface DefaultDeviceCalibration
 */
export interface DefaultDeviceCalibration {
    /**
     *
     * @type {string}
     * @memberof DefaultDeviceCalibration
     */
    gain: string;
    /**
     *
     * @type {number}
     * @memberof DefaultDeviceCalibration
     */
    timeoutCs: number;
    /**
     *
     * @type {string}
     * @memberof DefaultDeviceCalibration
     */
    threshold: string;
    /**
     *
     * @type {number}
     * @memberof DefaultDeviceCalibration
     */
    paperMovement: number;
    /**
     *
     * @type {string}
     * @memberof DefaultDeviceCalibration
     */
    sensorSensitivity: string;
    /**
     *
     * @type {string}
     * @memberof DefaultDeviceCalibration
     */
    id: string;
}

export function DefaultDeviceCalibrationFromJSON(json: any): DefaultDeviceCalibration {
    return DefaultDeviceCalibrationFromJSONTyped(json, false);
}

export function DefaultDeviceCalibrationFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): DefaultDeviceCalibration {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        gain: json["gain"],
        timeoutCs: json["timeoutCs"],
        threshold: json["threshold"],
        paperMovement: json["paperMovement"],
        sensorSensitivity: json["sensorSensitivity"],
        id: json["id"],
    };
}

export function DefaultDeviceCalibrationToJSON(value?: DefaultDeviceCalibration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        gain: value.gain,
        timeoutCs: value.timeoutCs,
        threshold: value.threshold,
        paperMovement: value.paperMovement,
        sensorSensitivity: value.sensorSensitivity,
        id: value.id,
    };
}
