import React, {useContext} from "react";
import {MenuItem} from "@material-ui/core";
import DropdownButton from "../../../common/DropdownButton";
import {translationOrName} from "../../../i18n";
import {AppContext} from "../../../AppContextProvider";
import {trackClick} from "../../../AnalyticsClickTracker";
import styles from "./Center.module.css";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {CenterFocusStrong} from "@material-ui/icons";
import {AppSettings} from "../../../AppSettingsProvider";

const I18N_PREFIX = "component.target.centering";

export type CenteringOptions = "Center" | "MPI" | "Hit" | "Off";

export default function Center(props: {
    setCenter: () => void;
    style?: React.CSSProperties;
    autoCenter: CenteringOptions;
    setAutoCenter: (option: CenteringOptions) => void;
}) {
    const {setCenter, setAutoCenter, autoCenter, style} = props;
    const {analytics} = useContext(AppContext);
    const {
        Components: {
            Lomah: {mpi},
        },
    } = useContext(AppSettings);
    const options: CenteringOptions[] = mpi ? ["Center", "MPI", "Hit", "Off"] : ["Center", "Hit", "Off"];

    return (
        <ToggleButtonGroup
            id="view_position_control"
            orientation="vertical"
            className={styles.zoomControl}
            {...{style}}>
            <ToggleButton
                value="center"
                color={autoCenter !== "Off" ? "primary" : "default"}
                onClick={() => {
                    trackClick(analytics, "Target.Center");
                    setCenter();
                }}>
                <CenterFocusStrong />
            </ToggleButton>
            <ToggleButton value="set" component="div" className={styles.selectButton}>
                <DropdownButton buttonClass={styles.controlButton} source={translationOrName(I18N_PREFIX, autoCenter)}>
                    {options.map((option: CenteringOptions) => {
                        return (
                            <MenuItem
                                key={"centering_" + option}
                                onClick={() => {
                                    trackClick(analytics, "Target.AutoCenter." + option);
                                    setAutoCenter(option);
                                }}>
                                {translationOrName(I18N_PREFIX, option)}
                            </MenuItem>
                        );
                    })}
                </DropdownButton>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
