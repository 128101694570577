import React, {ReactNode, useContext} from "react";
import "../App.css";
import Typography from "@material-ui/core/Typography";
import {AppContext} from "./AppContextProvider";
import {Grid} from "@material-ui/core";
import {formatScore} from "./lomah/live-scoring/HitRow";
import {localizedLabel} from "./i18n";
import style from "./LastShot.module.css";
import {SingleShooterLiveContext} from "./live-view/SingleShooterLiveContextProvider";
import {AppSettings} from "./AppSettingsProvider";
import {convertMM} from "./lomah/live-scoring/functions/convertMM";
import {Units} from "./lomah/live-scoring/components/Edit";

export default function LastShot(props: {id: string; setHeader: (id: string, header: ReactNode | String) => void}) {
    const {phase} = useContext(AppContext);
    const {
        Global: {units},
        Components: {
            Lomah: {precision},
        },
    } = useContext(AppSettings);
    const {hits} = useContext(SingleShooterLiveContext);
    const hit = hits[0];

    return (
        <Grid container justify="center" alignItems="center" style={{height: "calc( 100% - 40px)"}}>
            {hit ? (
                hit.score > 0 ? (
                    <>
                        <Grid
                            item
                            xs={6}
                            direction="column"
                            container
                            alignItems="flex-end"
                            className={style.container}>
                            <Typography variant="h4" color="primary">
                                {" "}
                                #{hit?.n}{" "}
                            </Typography>
                            <Typography variant="caption">
                                {" "}
                                {"x " + convertMM(hit.x, units as Units, precision)}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            direction="column"
                            container
                            alignItems="flex-start"
                            style={{padding: "0 8px"}}>
                            <Typography variant="h4" color="primary">
                                {formatScore(hit, phase.scoring)}
                            </Typography>
                            <Typography variant="caption">
                                {"y " + convertMM(hit.y, units as Units, precision)}
                            </Typography>
                        </Grid>
                    </>
                ) : (
                    <Typography color="error" variant="h2">
                        {localizedLabel("miss")}
                    </Typography>
                )
            ) : (
                <Typography style={{marginTop: 16}} color="textSecondary" variant="h4">
                    {localizedLabel("no_shots")}
                </Typography>
            )}
        </Grid>
    );
}
