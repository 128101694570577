import React, {useContext} from "react";
import {Breadcrumbs, Grid, Typography} from "@material-ui/core";
import {
    SettingFormatter,
    SettingFormatterOrDefinition,
    SettingsDefinitions,
    SettingsValues,
    Value,
} from "../adapter/types/settingsTypes";
import {definition} from "../adapter/types/settingsDefinition";
import {Description, HelperText, Title} from "../adapter/components/TextComponents";
import {intersects} from "../../common/utils";
import {AppContext} from "../../AppContextProvider";
import {isSettingFormatter} from "./SettingsAdapter";
import {translatedText} from "../../i18n";

function SettingsPanel(props: {
    settingsPath: string;
    stagedSettings: SettingsValues;
    changeSetting: (setting: Value, name: string, path: string[]) => void;
}) {
    const {settingsPath, changeSetting, stagedSettings} = props;

    return settingsPath !== "" ? (
        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <Setting path={settingsPath.slice(1).split("/")} {...{stagedSettings, definition, changeSetting}} />
        </Grid>
    ) : (
        <Grid container direction="column">
            <Grid item>
                <Typography variant="h3" gutterBottom>
                    {translatedText("settings.application", "title")}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {translatedText("settings.application", "welcome")}
                </Typography>
            </Grid>
        </Grid>
    );
}

function Setting(props: {
    changeSetting: (settings: Value, name: string, path: string[]) => void;
    path: string[];
    definition: SettingsDefinitions;
    stagedSettings: SettingsValues;
}) {
    const {path, changeSetting, stagedSettings} = props;
    const {
        user: {roles},
    } = useContext(AppContext);

    let def: SettingsDefinitions = definition;
    let val: SettingsValues = stagedSettings;
    for (const part of path) {
        def = def[part] as SettingsDefinitions;
        val = val[part] as SettingsValues;
    }

    function visibleForCurrentUser([, t]: SettingFormatterOrDefinition) {
        return !t.roles || intersects(t.roles as [], roles);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom: "20px"}}>
                {path.map(it => (
                    <Typography key={it} color="textPrimary" variant="body1">
                        <b>{translatedText("settings.category", it)}</b>
                    </Typography>
                ))}
            </Breadcrumbs>
            {Object.entries(def)
                .filter(isSettingFormatter)
                .filter(visibleForCurrentUser)
                .map(x => x as [string, SettingFormatter])
                .map(([name, formatter], index) => (
                    <div key={index + name} style={{width: "100%"}}>
                        {formatter.title && <Title value={translatedText("settings", name + ".title")} />}
                        {formatter.description && (
                            <Description value={translatedText("settings", name + ".description")} />
                        )}
                        {React.createElement(formatter.render, {
                            selectables: formatter.selectables,
                            max: formatter.max,
                            step: formatter.step,
                            min: formatter.min,
                            disabled: formatter.disabled,
                            value: val[name],
                            setValue: (value: Value) => changeSetting(value, name, path),
                            name: name,
                        })}
                        {formatter.helper && <HelperText value={translatedText("settings", name + ".helper")} />}
                    </div>
                ))}
        </>
    );
}

export default SettingsPanel;
