import {TableRow} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import {HitToDisplay} from "./HitTable";
import {AppSettings} from "../../AppSettingsProvider";
import {AccessTime, Warning} from "@material-ui/icons";
import {Units} from "./components/Edit";
import {convertMM} from "./functions/convertMM";
import NavigationIcon from "@material-ui/icons/Navigation";
import AdjustIcon from "@material-ui/icons/Adjust";
import {hash} from "../../common/utils";
import {Hit, ScoringType} from "../../../api/generated/esa";
import {Cell} from "./HitCell";
import styles from "./Table.module.css";
import clsx from "clsx";
import {timerFormat} from "../../timer/Timer";

interface HitRowProps {
    onSelect?: (id: string) => void;
    scoringType?: ScoringType;
    hit: HitToDisplay;
    selected?: string;
}

export function formatScore(hit: Hit, scoringType?: ScoringType) {
    const {score} = hit;

    if (hit.displayScore === "⏰") {
        return <AccessTime className={styles.error} fontSize="small" />;
    } else if (score <= 0) {
        return <Warning className={styles.warning} fontSize="small" />;
    } else if (scoringType === ScoringType.HitZones) {
        return hit.displayScore;
    } else if (scoringType === ScoringType.FullRing) {
        const scoreTokens = score.toFixed(1).split(".");
        return (
            <>
                <b>{scoreTokens[0]}</b>.<span style={{fontSize: "80%"}}>{scoreTokens[1]}</span>
            </>
        );
    } else {
        return score.toFixed(1);
    }
}

export function formatDelay(delay: number | false) {
    if (delay) {
        return timerFormat(delay * 1000, "ms", true);
    } else {
        return "";
    }
}

export function HitRow(props: HitRowProps) {
    const {
        Global: {units},
        Components: {
            Lomah: {
                Table: {identificationNumber, score, delay, coordinates, directionArrows, toCenter, fontLarge},
                precision,
            },
        },
    } = useContext(AppSettings);
    const [location, setLocation] = useState<string>();
    const {scoringType, hit, onSelect, selected} = props;
    const tableRowHeight = 42;
    const position = 3;

    useEffect(() => {
        setLocation(hash(window.location.hash));
    }, []);

    return (
        <>
            <TableRow
                id={hit.id}
                onClick={() => {
                    onSelect && onSelect(hit.id);
                }}
                className={clsx(styles.tableRow, hit.id === selected && styles.selected)}>
                {identificationNumber && (
                    <Cell>
                        #{hit.n}
                        <span
                            id={`${location}/${hit.id}`}
                            style={{
                                backgroundColor: "transparent",
                                width: 0,
                                height: 0,
                                position: "relative",
                                top: -(tableRowHeight * position),
                            }}
                        />
                    </Cell>
                )}
                {score && <Cell>{formatScore(hit, scoringType)}</Cell>}
                {delay && <Cell>{formatDelay(hit.delay)}</Cell>}
                {toCenter && <Cell>{convertMM(hit.toCenter, units as Units, precision)}</Cell>}
                {directionArrows && (
                    <Cell>
                        {hit.score > 10.4 ? (
                            <AdjustIcon fontSize={fontLarge ? "default" : "small"} color="primary" />
                        ) : (
                            <NavigationIcon
                                fontSize={fontLarge ? "default" : "small"}
                                style={{transform: `rotate(${hit.angle}deg)`}}
                            />
                        )}
                    </Cell>
                )}

                {coordinates && <Cell>{convertMM(hit.x, units as Units, precision)}</Cell>}
                {coordinates && <Cell scope="row">{convertMM(hit.y, units as Units, precision)}</Cell>}
            </TableRow>
        </>
    );
}
