/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
    DefaultDeviceCalibration,
    DefaultDeviceCalibrationFromJSON,
    DefaultDeviceCalibrationToJSON,
    DeviceEsaData,
    DeviceEsaDataFromJSON,
    EsaDeviceConfig,
    EsaDeviceConfigFromJSON,
    EsaDeviceConfigToJSON,
    PowerStatus,
    PowerStatusFromJSON,
    UserDevice,
    UserDeviceFromJSON,
} from "../models";

export interface AskRequest {
    from: string;
    device: number;
}

export interface AssignRequest {
    deviceId: number;
}

export interface DeclineRequest {
    ticket: string;
}

export interface DeleteConfigsRequest {
    requestBody: Array<number>;
}

export interface GetEsaRequest {
    deviceId: number;
}

export interface GetLightingPowerStatusRequest {
    deviceId: number;
}

export interface ReassignRequest {
    from: string;
    device: number;
    ticket: string;
}

export interface ResetAndReloadErrorsRequest {
    deviceId: number;
}

export interface SetDefaultDeviceCalibrationRequest {
    defaultDeviceCalibration: DefaultDeviceCalibration;
}

export interface SetLightingPowerStatusRequest {
    deviceId: number;
    powerStatus: PowerStatus;
}

export interface UpdateConfigRequest {
    esaDeviceConfig: EsaDeviceConfig;
}

export interface UpdateConfigsRequest {
    esaDeviceConfig: Array<EsaDeviceConfig>;
}

/**
 *
 */
export class EsaControllerApi extends runtime.BaseAPI {
    /**
     */
    async askRaw(requestParameters: AskRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError(
                "from",
                "Required parameter requestParameters.from was null or undefined when calling ask.",
            );
        }

        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError(
                "device",
                "Required parameter requestParameters.device was null or undefined when calling ask.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters["from"] = requestParameters.from;
        }

        if (requestParameters.device !== undefined) {
            queryParameters["device"] = requestParameters.device;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/ask`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async ask(requestParameters: AskRequest): Promise<string> {
        const response = await this.askRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async assignRaw(requestParameters: AssignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError(
                "deviceId",
                "Required parameter requestParameters.deviceId was null or undefined when calling assign.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters["deviceId"] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/assign`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assign(requestParameters: AssignRequest): Promise<void> {
        await this.assignRaw(requestParameters);
    }

    /**
     */
    async declineRaw(requestParameters: DeclineRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ticket === null || requestParameters.ticket === undefined) {
            throw new runtime.RequiredError(
                "ticket",
                "Required parameter requestParameters.ticket was null or undefined when calling decline.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.ticket !== undefined) {
            queryParameters["ticket"] = requestParameters.ticket;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/decline`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async decline(requestParameters: DeclineRequest): Promise<void> {
        await this.declineRaw(requestParameters);
    }

    /**
     */
    async deleteConfigsRaw(requestParameters: DeleteConfigsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError(
                "requestBody",
                "Required parameter requestParameters.requestBody was null or undefined when calling deleteConfigs.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/esa/configs`,
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteConfigs(requestParameters: DeleteConfigsRequest): Promise<void> {
        await this.deleteConfigsRaw(requestParameters);
    }

    /**
     */
    async getConfigsRaw(): Promise<runtime.ApiResponse<Array<EsaDeviceConfig>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/config`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EsaDeviceConfigFromJSON));
    }

    /**
     */
    async getConfigs(): Promise<Array<EsaDeviceConfig>> {
        const response = await this.getConfigsRaw();
        return await response.value();
    }

    /**
     */
    async getDefaultDeviceCalibrationRaw(): Promise<runtime.ApiResponse<DefaultDeviceCalibration>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/defaultCalibration`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => DefaultDeviceCalibrationFromJSON(jsonValue));
    }

    /**
     */
    async getDefaultDeviceCalibration(): Promise<DefaultDeviceCalibration> {
        const response = await this.getDefaultDeviceCalibrationRaw();
        return await response.value();
    }

    /**
     */
    async getDevicesRaw(): Promise<runtime.ApiResponse<Array<DeviceEsaData>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(DeviceEsaDataFromJSON));
    }

    /**
     */
    async getDevices(): Promise<Array<DeviceEsaData>> {
        const response = await this.getDevicesRaw();
        return await response.value();
    }

    /**
     */
    async getEsaRaw(requestParameters: GetEsaRequest): Promise<runtime.ApiResponse<DeviceEsaData>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError(
                "deviceId",
                "Required parameter requestParameters.deviceId was null or undefined when calling getEsa.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/{deviceId}`.replace(
                `{${"deviceId"}}`,
                encodeURIComponent(String(requestParameters.deviceId)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => DeviceEsaDataFromJSON(jsonValue));
    }

    /**
     */
    async getEsa(requestParameters: GetEsaRequest): Promise<DeviceEsaData> {
        const response = await this.getEsaRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLaneReservationTimeoutRaw(): Promise<runtime.ApiResponse<number>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/config/laneReservationTimeout`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getLaneReservationTimeout(): Promise<number> {
        const response = await this.getLaneReservationTimeoutRaw();
        return await response.value();
    }

    /**
     */
    async getLightingPowerStatusRaw(
        requestParameters: GetLightingPowerStatusRequest,
    ): Promise<runtime.ApiResponse<PowerStatus>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError(
                "deviceId",
                "Required parameter requestParameters.deviceId was null or undefined when calling getLightingPowerStatus.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/{deviceId}/light`.replace(
                `{${"deviceId"}}`,
                encodeURIComponent(String(requestParameters.deviceId)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => PowerStatusFromJSON(jsonValue));
    }

    /**
     */
    async getLightingPowerStatus(requestParameters: GetLightingPowerStatusRequest): Promise<PowerStatus> {
        const response = await this.getLightingPowerStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUserDeviceRaw(): Promise<runtime.ApiResponse<UserDevice>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/user`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => UserDeviceFromJSON(jsonValue));
    }

    /**
     */
    async getUserDevice(): Promise<UserDevice> {
        const response = await this.getUserDeviceRaw();
        return await response.value();
    }

    /**
     */
    async getUserDevicesRaw(): Promise<runtime.ApiResponse<Array<UserDevice>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/users`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(UserDeviceFromJSON));
    }

    /**
     */
    async getUserDevices(): Promise<Array<UserDevice>> {
        const response = await this.getUserDevicesRaw();
        return await response.value();
    }

    /**
     */
    async reassignRaw(requestParameters: ReassignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError(
                "from",
                "Required parameter requestParameters.from was null or undefined when calling reassign.",
            );
        }

        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError(
                "device",
                "Required parameter requestParameters.device was null or undefined when calling reassign.",
            );
        }

        if (requestParameters.ticket === null || requestParameters.ticket === undefined) {
            throw new runtime.RequiredError(
                "ticket",
                "Required parameter requestParameters.ticket was null or undefined when calling reassign.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters["from"] = requestParameters.from;
        }

        if (requestParameters.device !== undefined) {
            queryParameters["device"] = requestParameters.device;
        }

        if (requestParameters.ticket !== undefined) {
            queryParameters["ticket"] = requestParameters.ticket;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/reassign`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reassign(requestParameters: ReassignRequest): Promise<void> {
        await this.reassignRaw(requestParameters);
    }

    /**
     */
    async resetAndReloadErrorsRaw(requestParameters: ResetAndReloadErrorsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError(
                "deviceId",
                "Required parameter requestParameters.deviceId was null or undefined when calling resetAndReloadErrors.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/{deviceId}/resetAndReloadErrors`.replace(
                `{${"deviceId"}}`,
                encodeURIComponent(String(requestParameters.deviceId)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetAndReloadErrors(requestParameters: ResetAndReloadErrorsRequest): Promise<void> {
        await this.resetAndReloadErrorsRaw(requestParameters);
    }

    /**
     */
    async setDefaultDeviceCalibrationRaw(
        requestParameters: SetDefaultDeviceCalibrationRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (
            requestParameters.defaultDeviceCalibration === null ||
            requestParameters.defaultDeviceCalibration === undefined
        ) {
            throw new runtime.RequiredError(
                "defaultDeviceCalibration",
                "Required parameter requestParameters.defaultDeviceCalibration was null or undefined when calling setDefaultDeviceCalibration.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/esa/defaultCalibration`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: DefaultDeviceCalibrationToJSON(requestParameters.defaultDeviceCalibration),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setDefaultDeviceCalibration(requestParameters: SetDefaultDeviceCalibrationRequest): Promise<void> {
        await this.setDefaultDeviceCalibrationRaw(requestParameters);
    }

    /**
     */
    async setLightingPowerStatusRaw(
        requestParameters: SetLightingPowerStatusRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError(
                "deviceId",
                "Required parameter requestParameters.deviceId was null or undefined when calling setLightingPowerStatus.",
            );
        }

        if (requestParameters.powerStatus === null || requestParameters.powerStatus === undefined) {
            throw new runtime.RequiredError(
                "powerStatus",
                "Required parameter requestParameters.powerStatus was null or undefined when calling setLightingPowerStatus.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/esa/{deviceId}/light/{powerStatus}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId)))
                .replace(`{${"powerStatus"}}`, encodeURIComponent(String(requestParameters.powerStatus))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setLightingPowerStatus(requestParameters: SetLightingPowerStatusRequest): Promise<void> {
        await this.setLightingPowerStatusRaw(requestParameters);
    }

    /**
     */
    async updateConfigRaw(requestParameters: UpdateConfigRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.esaDeviceConfig === null || requestParameters.esaDeviceConfig === undefined) {
            throw new runtime.RequiredError(
                "esaDeviceConfig",
                "Required parameter requestParameters.esaDeviceConfig was null or undefined when calling updateConfig.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/esa/config`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: EsaDeviceConfigToJSON(requestParameters.esaDeviceConfig),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateConfig(requestParameters: UpdateConfigRequest): Promise<void> {
        await this.updateConfigRaw(requestParameters);
    }

    /**
     */
    async updateConfigsRaw(requestParameters: UpdateConfigsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.esaDeviceConfig === null || requestParameters.esaDeviceConfig === undefined) {
            throw new runtime.RequiredError(
                "esaDeviceConfig",
                "Required parameter requestParameters.esaDeviceConfig was null or undefined when calling updateConfigs.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/esa/configs`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.esaDeviceConfig.map(EsaDeviceConfigToJSON),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateConfigs(requestParameters: UpdateConfigsRequest): Promise<void> {
        await this.updateConfigsRaw(requestParameters);
    }
}
