/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface BlockDevice
 */
export interface BlockDevice {
    /**
     *
     * @type {boolean}
     * @memberof BlockDevice
     */
    hotplug: boolean;
    /**
     *
     * @type {string}
     * @memberof BlockDevice
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BlockDevice
     */
    size: string;
    /**
     *
     * @type {string}
     * @memberof BlockDevice
     */
    fsavail?: string;
    /**
     *
     * @type {string}
     * @memberof BlockDevice
     */
    model?: string;
    /**
     *
     * @type {string}
     * @memberof BlockDevice
     */
    mountpoint?: string;
    /**
     *
     * @type {Array<BlockDevice>}
     * @memberof BlockDevice
     */
    children?: Array<BlockDevice>;
    /**
     *
     * @type {string}
     * @memberof BlockDevice
     */
    label?: string;
}

export function BlockDeviceFromJSON(json: any): BlockDevice {
    return BlockDeviceFromJSONTyped(json, false);
}

export function BlockDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockDevice {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        hotplug: json["hotplug"],
        name: json["name"],
        size: json["size"],
        fsavail: !exists(json, "fsavail") ? undefined : json["fsavail"],
        model: !exists(json, "model") ? undefined : json["model"],
        mountpoint: !exists(json, "mountpoint") ? undefined : json["mountpoint"],
        children: !exists(json, "children") ? undefined : (json["children"] as Array<any>).map(BlockDeviceFromJSON),
        label: !exists(json, "label") ? undefined : json["label"],
    };
}

export function BlockDeviceToJSON(value?: BlockDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        hotplug: value.hotplug,
        name: value.name,
        size: value.size,
        fsavail: value.fsavail,
        model: value.model,
        mountpoint: value.mountpoint,
        children: value.children === undefined ? undefined : (value.children as Array<any>).map(BlockDeviceToJSON),
        label: value.label,
    };
}
