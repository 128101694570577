/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MeasurementUnit {
    Metric = "Metric",
    Imperial = "Imperial",
}

export function MeasurementUnitFromJSON(json: any): MeasurementUnit {
    return MeasurementUnitFromJSONTyped(json, false);
}

export function MeasurementUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementUnit {
    return json as MeasurementUnit;
}

export function MeasurementUnitToJSON(value?: MeasurementUnit | null): any {
    return value as any;
}
