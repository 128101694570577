import React, {useState} from "react";
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import {CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import {translatedText} from "../../../i18n";

export function FormatterBool(props: {
    value: boolean;
    setValue: (value: boolean) => void;
    name: string;
    disabled: boolean;
}) {
    const {name, value, setValue, disabled} = props;
    const [state, setState] = useState(value);
    const onChange = () => {
        setState(!state);
        setValue(!state);
    };

    return (
        <FormControlLabel
            id={`checkbox_${name.split(" ").join("")}`}
            control={
                <Checkbox
                    disabled={disabled}
                    checkedIcon={<CheckBox />}
                    icon={<CheckBoxOutlineBlank />}
                    color="primary"
                    checked={state}
                    onChange={onChange}
                    name={name}
                />
            }
            label={<Typography variant="body2">{translatedText("settings", name)}</Typography>}
        />
    );
}
