import React, {useContext, useEffect, useState} from "react";
import {localizedButtonText, localizedLabel, localizedTitle, translation} from "../i18n";
import {
    Backdrop,
    Box,
    Card,
    CircularProgress,
    Grid,
    LinearProgress,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CustomDialog from "../common/CustomDialog";
import styles from "../settings-dialog/adapter/components/Formatters.module.css";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import ButtonBase from "@material-ui/core/ButtonBase";
import UsbIcon from "@material-ui/icons/Usb";
import GetAppIcon from "@material-ui/icons/GetApp";
import {BlockDevice} from "../../api/generated/esa/models";
import {SystemControllerApi} from "../../api/generated/esa/apis";
import {NotificationContext} from "../NotificationContextProvider";

const systemControllerApi = new SystemControllerApi();

export default function ImportExport() {
    const [open, setOpen] = useState(false);
    const [usbList, setUsbList] = useState<BlockDevice[]>([]);
    const [backdrop, setBackdrop] = useState(false);
    const {notify} = useContext(NotificationContext);

    useEffect(() => {
        open && refresh();
    }, [open]);

    function formatBytes(bytes: number, decimals = 2) {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    function refresh() {
        systemControllerApi.getUsb().then(setUsbList);
    }

    function handleExport(name: string) {
        setBackdrop(true);
        systemControllerApi
            .backup({name})
            .then(location => {
                setBackdrop(false);
                notify("success", "message.backup_successful", {
                    translationValues: {location},
                });
            })
            .catch(err => {
                setBackdrop(false);
                notify("error", "error.server", {
                    translationValues: {error: `${err}`},
                });
            });
    }

    return (
        <>
            <ImportExportButton onClick={() => setOpen(true)} />
            <CustomDialog
                {...{open, setOpen}}
                dialogTitle={<Typography variant="h5">{localizedTitle("export.settings")}</Typography>}
                dialogActions={
                    <>
                        <Button onClick={() => setOpen(false)}>{localizedButtonText("close")}</Button>
                    </>
                }>
                <>
                    <Grid item container>
                        <Box component="div" style={{width: "100%"}}>
                            {usbList && usbList?.length ? (
                                <>
                                    <Button
                                        onClick={refresh}
                                        className={styles.exportRefresh}
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<FindReplaceIcon />}>
                                        {localizedButtonText("refresh")}
                                    </Button>
                                    <Backdrop open={backdrop} style={{zIndex: 99}}>
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                    {usbList.map((usb, i) => {
                                        const {name, label, fsavail, size} = usb;

                                        const capacity = parseFloat(size.replace(",", "."));
                                        const available = fsavail ? parseFloat(fsavail.replace(",", ".")) : 0;

                                        return (
                                            <Card key={i} className={styles.exportCard} elevation={5}>
                                                <ButtonBase style={{width: "100%"}}>
                                                    <Grid container onClick={() => handleExport(name)}>
                                                        <Grid
                                                            item
                                                            container
                                                            justify="center"
                                                            alignItems="center"
                                                            className={styles.exportUsbIconContainer}>
                                                            <UsbIcon
                                                                className={styles.exportIcon}
                                                                style={{padding: 10}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={8} className={styles.exportContent}>
                                                            <Typography component="h5" variant="h5">
                                                                {label}
                                                                <Typography
                                                                    color="textSecondary"
                                                                    variant="body1"
                                                                    component="span">
                                                                    ({name})
                                                                </Typography>
                                                            </Typography>
                                                            <Typography variant="subtitle1" color="textSecondary">
                                                                {formatBytes(capacity - available)} /{" "}
                                                                {formatBytes(capacity)}
                                                            </Typography>
                                                            {fsavail && (
                                                                <Box display="flex" alignItems="center">
                                                                    <Box width="100%" mr={1}>
                                                                        <LinearProgress
                                                                            variant="determinate"
                                                                            value={
                                                                                ((capacity - available) * 100) /
                                                                                capacity
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    <Box minWidth={100}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="textSecondary">
                                                                            {`${formatBytes(available)} `}
                                                                            {localizedLabel("free")}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            container
                                                            justify="center"
                                                            alignItems="center"
                                                            className={styles.exportIconContainer}>
                                                            <GetAppIcon className={styles.exportIcon} />
                                                        </Grid>
                                                    </Grid>
                                                </ButtonBase>
                                            </Card>
                                        );
                                    })}
                                </>
                            ) : (
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                    direction="column"
                                    style={{height: "100%"}}
                                    onClick={refresh}>
                                    <FindReplaceIcon color="action" style={{fontSize: 120}} />
                                    <Typography variant="h4"> {localizedTitle("storage_not_found")}</Typography>
                                    <Typography variant="body2">({localizedLabel("storage_not_found")})</Typography>
                                    <Button
                                        onClick={refresh}
                                        className={styles.exportRefresh}
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<FindReplaceIcon />}>
                                        {localizedButtonText("refresh")}
                                    </Button>
                                </Grid>
                            )}
                        </Box>
                    </Grid>
                </>
            </CustomDialog>
        </>
    );
}

export function ImportExportButton(props: {onClick: () => void}) {
    const {onClick} = props;

    return (
        <ListItem id="import_export_control_button" button {...{onClick}}>
            <ListItemIcon>
                <ImportExportIcon />
            </ListItemIcon>
            <ListItemText primary={translation("title.export")} />
        </ListItem>
    );
}
