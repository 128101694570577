import React, {Fragment} from "react";
import {Target} from "../../../api/generated/esa";

enum Horizontal {
    Left = "left",
    Right = "right",
}

enum Vertical {
    Top = "top",
    Bottom = "bottom",
}

export type Directions = Horizontal | Vertical;

export const directions: Directions[] = [Horizontal.Left, Horizontal.Right, Vertical.Bottom, Vertical.Top];
export const verticalDirections: Directions[] = [Vertical.Bottom, Vertical.Top];

export function getLocation(
    target: Target,
    direction: Directions,
    axis: "x" | "y",
    i: number,
    offsetSize?: number,
): number {
    const {targetRadius, ringStep} = target;
    const step = targetRadius - i * ringStep - ringStep / 2;
    const offsetOutput = offsetSize ? offsetSize * 2 : 0;

    if (axis === "x") {
        if (direction === Horizontal.Right) {
            return step + offsetOutput;
        } else if (direction === Horizontal.Left) {
            return -step - offsetOutput;
        } else return 0;
    } else {
        if (direction === Vertical.Top) {
            return step + offsetOutput;
        } else if (direction === Vertical.Bottom) {
            return -step - offsetOutput;
        } else return 0;
    }
}

export function TargetInnerSVG(props: {target: Target; disableNumbers?: boolean}) {
    const {target, disableNumbers} = props;
    const {
        innerTenRadius,
        ringStep,
        targetRadius,
        bullsEye,
        targetCardWidth,
        targetCardHeight,
        whiteCenter,
        horizontalLinesWidth,
        horizontalLinesHeight,
        numberingOffset,
        includeNinthRingNumbering,
    } = target;
    const rings = 10;
    const stepWidth = targetRadius && ringStep && targetRadius / ringStep;
    const ringNumberOffset = includeNinthRingNumbering ? 1 : 2;
    const middleOfTarget = targetCardWidth / 2;
    const spaceBetweenTargetedCardEdge = (targetCardWidth - targetRadius * 2) / 2;
    const displayableNumberDirections = horizontalLinesWidth ? verticalDirections : directions;
    const splitRing = !!(bullsEye % 1);

    return (
        <>
            {
                //TargetRender Fill Color
                targetRadius && <circle cx="50%" cy="50%" r={targetRadius} fill="white" />
            }

            {
                //Black circle thingy
                targetRadius && ringStep && bullsEye && (
                    <circle cx="50%" cy="50%" r={targetRadius - (bullsEye - 1) * ringStep} fill="black" />
                )
            }

            {
                //Rings
                bullsEye &&
                    stepWidth &&
                    targetRadius &&
                    ringStep &&
                    rings &&
                    [...Array(rings)].map((r, i) => {
                        const radius = targetRadius - i * ringStep;
                        return (
                            <Fragment key={`${i}_ring`}>
                                {" "}
                                {radius > 0 ? (
                                    <circle
                                        cx="50%"
                                        cy="50%"
                                        r={radius}
                                        stroke={!Boolean(i === 0 || i === bullsEye - 1) ? "white" : "black"}
                                        style={{
                                            mixBlendMode: !Boolean(i === 0 || i === bullsEye - 1)
                                                ? "difference"
                                                : "initial",
                                        }}
                                        fill="transparent"
                                        strokeWidth={ringStep / 100}
                                    />
                                ) : null}
                            </Fragment>
                        );
                    })
            }

            {
                //Numbers
                stepWidth &&
                    targetRadius &&
                    ringStep &&
                    rings &&
                    !disableNumbers &&
                    [...Array(rings - ringNumberOffset)].map((r, i) => {
                        const maxNumber = 9;
                        const ringNumber = i + 1;
                        const affectedRing = Math.floor(bullsEye);
                        const offsetSize =
                            splitRing && affectedRing === ringNumber ? bullsEye - affectedRing : undefined;
                        const numberToDisplay = i + (numberingOffset || 1);
                        if (numberToDisplay > maxNumber) return null;
                        return (
                            <React.Fragment key={`${i}_ring_number`}>
                                {targetCardWidth &&
                                    targetCardHeight &&
                                    displayableNumberDirections.map(direction => (
                                        <text
                                            key={`${direction}_of_${i}_ring`}
                                            x={
                                                middleOfTarget -
                                                ringStep / 6 +
                                                getLocation(target, direction, "x", i, offsetSize)
                                            }
                                            y={
                                                middleOfTarget +
                                                ringStep / 6 +
                                                getLocation(target, direction, "y", i, offsetSize)
                                            }
                                            fill="white"
                                            style={{
                                                mixBlendMode: "difference",
                                                fontSize: horizontalLinesHeight
                                                    ? horizontalLinesHeight * 2
                                                    : target.ringStep / 2,
                                                textRendering: "geometricPrecision",
                                            }}>
                                            {numberToDisplay}
                                        </text>
                                    ))}
                            </React.Fragment>
                        );
                    })
            }

            {/*/Horizontal lines for rapid shooting targets/*/}
            {horizontalLinesWidth && horizontalLinesHeight && (
                <>
                    <line
                        x1={spaceBetweenTargetedCardEdge}
                        y1={middleOfTarget}
                        x2={spaceBetweenTargetedCardEdge + horizontalLinesWidth}
                        y2={middleOfTarget}
                        stroke="white"
                        strokeWidth={horizontalLinesHeight}
                    />
                    <line
                        x1={targetCardWidth - horizontalLinesWidth - spaceBetweenTargetedCardEdge}
                        y1={middleOfTarget}
                        x2={targetCardWidth - spaceBetweenTargetedCardEdge}
                        y2={middleOfTarget}
                        stroke="white"
                        strokeWidth={horizontalLinesHeight}
                    />
                </>
            )}
            {innerTenRadius && (
                <circle
                    cx="50%"
                    cy="50%"
                    r={innerTenRadius < 0 ? -innerTenRadius : innerTenRadius}
                    stroke={whiteCenter ? "black" : "white"}
                    fill={whiteCenter ? "white" : "black"}
                    strokeWidth={ringStep / 100}
                />
            )}
        </>
    );
}
