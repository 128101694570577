import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import {GridColDef} from "@material-ui/data-grid";
import style from "../SpectatorTarget.module.css";
import {LibraryAdd} from "@material-ui/icons";
import SwapVerticalCircleIcon from "@material-ui/icons/SwapVerticalCircle";
import animations from "../../../styles/Animations.module.css";
import {translation, translationString} from "../../i18n";
import {CustomToolbar} from "../../common/CustomToolbar";
import {CustomNoRowsOverlay} from "../../common/CustomNoRowsOverlay";
import {useIntl} from "react-intl";
import {CompetitionControllerApi, WebSocketMessage} from "../../../api/generated/esa";
import {AppContext} from "../../AppContextProvider";
import clsx from "clsx";
import {SpectatorContext} from "../SpectatorContextProvider";
import {CustomLoadingOverlay} from "../../common/CustomLoadingOverlay";
import {CommonDataGrid} from "../../common/CommonDataGrid";

const competitionApi = new CompetitionControllerApi();

export function DeviceList() {
    const {webSocket} = useContext(AppContext);
    const {
        newCompetition: {deviceIds},
        setNewCompetition,
        allDevices,
    } = useContext(SpectatorContext);
    const canSelect = deviceIds.length < 8;
    const [open, setOpen] = useState(false);
    const [activeCompetitionDeviceIds, setActiveCompetitionDeviceIds] = useState<number[]>([]);
    const [selectedBuffer, setSelectedBuffer] = useState<number[]>([]);
    const largeScreenBreakpoint = 1232;
    const intl = useIntl();

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleAdd() {
        setNewCompetition(old => ({...old, deviceIds: selectedBuffer}));
        handleClose();
    }

    const columns: GridColDef[] = [
        {field: "userName", headerName: translationString(intl, "title.spectator.user"), width: 300},
        {field: "deviceAlias", headerName: translationString(intl, "title.spectator.lane"), width: 300},
        {
            field: "activeCompetition",
            type: "boolean",
            headerName: translationString(intl, "title.spectator.competition"),
            width: 300,
        },
    ];

    const deviceRows = allDevices
        ? allDevices.map((it, index) => {
              return {
                  id: it.deviceId,
                  activeCompetition: activeCompetitionDeviceIds.includes(it.deviceId),
                  ...it,
              };
          })
        : [];

    //todo check if this is triggered
    useEffect(() => {
        function updateListener(event: MessageEvent) {
            const update: WebSocketMessage = JSON.parse(event.data);
            if (update.phase?.competitionId) {
                competitionApi.activeCompetitionDeviceIds().then(setActiveCompetitionDeviceIds);
            }
        }

        webSocket.addEventListener("message", updateListener);
        return function cleanup() {
            webSocket.removeEventListener("message", updateListener);
        };
    }, [webSocket, setActiveCompetitionDeviceIds]);

    useEffect(() => {
        if (open) {
            setSelectedBuffer(deviceIds);
            competitionApi.activeCompetitionDeviceIds().then(setActiveCompetitionDeviceIds);
        }
    }, [deviceIds, open, setSelectedBuffer, setActiveCompetitionDeviceIds]);

    return (
        <>
            <ListItem
                className={clsx(deviceIds.length === 0 && style.accent)}
                id="select_devices"
                button
                onClick={handleClickOpen}>
                <ListItemIcon className={clsx(deviceIds.length === 0 && animations.heartbeat)}>
                    {canSelect ? <LibraryAdd /> : <SwapVerticalCircleIcon />}
                </ListItemIcon>
                <ListItemText
                    primary={translation(
                        canSelect ? "label.spectator.select_devices" : "label.spectator.change_devices",
                    )}
                />
            </ListItem>

            <Dialog open={open} keepMounted onClose={handleClose} maxWidth="lg">
                <DialogTitle id="alert-dialog-slide-title">
                    {canSelect ? (
                        translation("title.spectator.add_devices")
                    ) : (
                        <span className={style.error}> {translation("title.spectator.maximum_devices")}</span>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Grid container style={{width: "80vw", maxWidth: largeScreenBreakpoint}}>
                        <CommonDataGrid
                            components={{
                                Toolbar: CustomToolbar,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                LoadingOverlay: CustomLoadingOverlay,
                            }}
                            rows={deviceRows}
                            columns={columns}
                            selectionModel={selectedBuffer}
                            checkboxSelection
                            autoHeight
                            onSelectionModelChange={e => {
                                setSelectedBuffer(e.selectionModel as number[]);
                            }}
                            hideFooter={true}
                            disableColumnMenu
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="add_devices" variant="contained" onClick={handleAdd} color="primary">
                        {selectedBuffer.length > 0 ? translation("button.update") : translation("button.add")}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        {translation("button.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
