export const APHits = [
    {x: -65, y: -8, score: 8.5, timeStamp: 1632825656817},
    {
        x: -51,
        y: 0,
        score: 9.1,
        timeStamp: 1632825656816,
    },
    {x: -68, y: -75, score: 7.1, timeStamp: 1632825656816},
    {
        x: -2,
        y: -56,
        score: 8.9,
        timeStamp: 1632825656816,
    },
    {x: -51, y: -75, score: 7.5, timeStamp: 1632825656816},
    {
        x: -4,
        y: -44,
        score: 9.3,
        timeStamp: 1632825634650,
    },
    {x: -19, y: -75, score: 8, timeStamp: 1632825634650},
    {
        x: -70,
        y: -55,
        score: 7.6,
        timeStamp: 1632825634649,
    },
    {x: -12, y: -64, score: 8.5, timeStamp: 1632825633702},
    {
        x: -77,
        y: -74,
        score: 6.8,
        timeStamp: 1632825633705,
    },
    {x: -37, y: -20, score: 9.4, timeStamp: 1632825633705},
    {
        x: -3,
        y: -89,
        score: 7.5,
        timeStamp: 1632825633697,
    },
    {x: -98, y: -45, score: 6.8, timeStamp: 1632825633009},
    {
        x: -92,
        y: -43,
        score: 7,
        timeStamp: 1632825632831,
    },
    {x: -88, y: -56, score: 6.9, timeStamp: 1632825632748},
    {
        x: -51,
        y: -66,
        score: 7.8,
        timeStamp: 1632825632749,
    },
    {x: -73, y: -63, score: 7.3, timeStamp: 1632825632752},
    {
        x: -43,
        y: 0,
        score: 9.4,
        timeStamp: 1632825632735,
    },
    {x: -71, y: -69, score: 7.2, timeStamp: 1632825632740},
    {
        x: -62,
        y: -91,
        score: 6.7,
        timeStamp: 1632825632733,
    },
    {x: -19, y: -8, score: 10.3, timeStamp: 1632825631752},
    {
        x: -28,
        y: -8,
        score: 9.9,
        timeStamp: 1632825631752,
    },
    {x: -72, y: -13, score: 8.2, timeStamp: 1632825631752},
    {
        x: -12,
        y: -96,
        score: 7.2,
        timeStamp: 1632825631042,
    },
    {x: -65, y: -52, score: 7.8, timeStamp: 1632825628830},
    {
        x: -16,
        y: -66,
        score: 8.4,
        timeStamp: 1632825604339,
    },
    {x: -11, y: 68, score: 8.4, timeStamp: 1632825592881},
    {x: -2, y: 29, score: 9.9, timeStamp: 1632825592873},
    {
        x: -67,
        y: 30,
        score: 8.2,
        timeStamp: 1632825592862,
    },
    {x: -84, y: 93, score: 6.1, timeStamp: 1632825592851},
    {x: -87, y: 52, score: 7.1, timeStamp: 1632825592840},
    {
        x: -3,
        y: 56,
        score: 8.9,
        timeStamp: 1632825592830,
    },
    {x: -40, y: 37, score: 8.9, timeStamp: 1632825592818},
    {x: -3, y: 43, score: 9.4, timeStamp: 1632825582242},
    {
        x: -41,
        y: 47,
        score: 8.6,
        timeStamp: 1632825572426,
    },
    {x: -97, y: 56, score: 6.6, timeStamp: 1632825572426},
    {
        x: -75,
        y: 95,
        score: 6.3,
        timeStamp: 1632825572426,
    },
    {x: -92, y: 27, score: 7.3, timeStamp: 1632825571474},
    {
        x: -30,
        y: 85,
        score: 7.5,
        timeStamp: 1632825571363,
    },
    {x: -69, y: 43, score: 7.9, timeStamp: 1632825569556},
    {
        x: -38,
        y: 25,
        score: 9.3,
        timeStamp: 1632825569117,
    },
    {x: -29, y: 83, score: 7.6, timeStamp: 1632825569117},
    {
        x: -38,
        y: 34,
        score: 9.1,
        timeStamp: 1632825569117,
    },
    {x: -62, y: 21, score: 8.5, timeStamp: 1632825569100},
    {
        x: -32,
        y: 21,
        score: 9.6,
        timeStamp: 1632825568479,
    },
    {x: -25, y: 19, score: 9.9, timeStamp: 1632825568479},
    {
        x: -44,
        y: 84,
        score: 7.3,
        timeStamp: 1632825563684,
    },
    {x: -11, y: 26, score: 10, timeStamp: 1632825563684},
    {x: -18, y: 95, score: 7.2, timeStamp: 1632825563149},
    {
        x: -19,
        y: 22,
        score: 9.9,
        timeStamp: 1632825563149,
    },
    {x: -37, y: 69, score: 8, timeStamp: 1632825562744},
    {x: -60, y: 70, score: 7.4, timeStamp: 1632825562742},
    {
        x: -81,
        y: 64,
        score: 7,
        timeStamp: 1632825562740,
    },
    {x: -86, y: 77, score: 6.5, timeStamp: 1632825562205},
    {
        x: -59,
        y: 88,
        score: 6.9,
        timeStamp: 1632825562205,
    },
    {x: -53, y: 7, score: 9, timeStamp: 1632825561803},
    {x: -13, y: 37, score: 9.5, timeStamp: 1632825561798},
    {
        x: -4,
        y: 67,
        score: 8.4,
        timeStamp: 1632825561798,
    },
    {x: -81, y: 26, score: 7.7, timeStamp: 1632825561229},
    {x: -72, y: 75, score: 7, timeStamp: 1632825561222},
    {
        x: -55,
        y: 26,
        score: 8.7,
        timeStamp: 1632825561216,
    },
    {x: -6, y: 37, score: 9.6, timeStamp: 1632825560832},
    {x: -78, y: 18, score: 7.9, timeStamp: 1632825560832},
    {
        x: -58,
        y: 26,
        score: 8.6,
        timeStamp: 1632825559890,
    },
    {x: -85, y: 89, score: 6.2, timeStamp: 1632825559258},
    {
        x: -2,
        y: 16,
        score: 10.5,
        timeStamp: 1632825559254,
    },
    {x: -43, y: 10, score: 9.3, timeStamp: 1632825558827},
    {
        x: -21,
        y: 62,
        score: 8.5,
        timeStamp: 1632825558696,
    },
    {x: -17, y: 38, score: 9.4, timeStamp: 1632825558690},
    {
        x: -71,
        y: 39,
        score: 7.9,
        timeStamp: 1632825558684,
    },
    {x: -65, y: 42, score: 8, timeStamp: 1632825558680},
    {x: -93, y: 47, score: 6.9, timeStamp: 1632825556964},
    {
        x: -18,
        y: 33,
        score: 9.6,
        timeStamp: 1632825556113,
    },
    {x: -75, y: 61, score: 7.2, timeStamp: 1632825555890},
    {
        x: -27,
        y: 16,
        score: 9.9,
        timeStamp: 1632825555878,
    },
    {x: -54, y: 66, score: 7.7, timeStamp: 1632825555878},
    {
        x: -20,
        y: 90,
        score: 7.4,
        timeStamp: 1632825555285,
    },
    {x: -76, y: 84, score: 6.6, timeStamp: 1632825555285},
    {
        x: -14,
        y: 60,
        score: 8.6,
        timeStamp: 1632825554814,
    },
    {x: -60, y: 60, score: 7.7, timeStamp: 1632825553709},
    {x: -81, y: 64, score: 7, timeStamp: 1632825553702},
    {
        x: -22,
        y: 4,
        score: 10.2,
        timeStamp: 1632825553694,
    },
    {x: -35, y: 50, score: 8.7, timeStamp: 1632825553685},
    {
        x: -71,
        y: 27,
        score: 8.1,
        timeStamp: 1632825553676,
    },
    {x: -16, y: 24, score: 10, timeStamp: 1632825553670},
    {x: -82, y: 23, score: 7.7, timeStamp: 1632825553615},
    {
        x: -83,
        y: 66,
        score: 6.9,
        timeStamp: 1632825553605,
    },
    {x: -15, y: 10, score: 10.4, timeStamp: 1632825549005},
    {
        x: -31,
        y: 64,
        score: 8.3,
        timeStamp: 1632825547928,
    },
    {x: -15, y: 84, score: 7.7, timeStamp: 1632825547916},
    {x: -57, y: 2, score: 8.8, timeStamp: 1632825546034},
    {
        x: 47,
        y: 55,
        score: 8.2,
        timeStamp: 1632825535882,
    },
    {x: 44, y: 4, score: 9.3, timeStamp: 1632825535107},
    {x: 29, y: 77, score: 7.8, timeStamp: 1632825532069},
    {
        x: 13,
        y: 75,
        score: 8.1,
        timeStamp: 1632825532069,
    },
    {x: 30, y: 44, score: 9, timeStamp: 1632825532069},
    {x: 73, y: 94, score: 6.4, timeStamp: 1632825532069},
    {
        x: 18,
        y: 76,
        score: 8,
        timeStamp: 1632825532067,
    },
    {x: 7, y: 38, score: 9.6, timeStamp: 1632825509458},
    {x: 42, y: 49, score: 8.5, timeStamp: 1632825495508},
    {
        x: 59,
        y: 34,
        score: 8.4,
        timeStamp: 1632825495159,
    },
    {x: 100, y: 89, score: 5.8, timeStamp: 1632825495117},
    {x: 49, y: 65, score: 7.9, timeStamp: 1632825495117},
    {
        x: 35,
        y: 40,
        score: 9,
        timeStamp: 1632825494696,
    },
    {x: 8, y: 12, score: 10.5, timeStamp: 1632825494696},
    {x: 52, y: 90, score: 7, timeStamp: 1632825493693},
    {
        x: 49,
        y: 4,
        score: 9.1,
        timeStamp: 1632825492951,
    },
    {x: 21, y: 24, score: 9.8, timeStamp: 1632825491864},
    {x: 67, y: 63, score: 7.4, timeStamp: 1632825491145},
    {
        x: 26,
        y: 34,
        score: 9.4,
        timeStamp: 1632825490777,
    },
    {x: 21, y: 59, score: 8.6, timeStamp: 1632825490776},
    {x: 25, y: 32, score: 9.5, timeStamp: 1632825490157},
    {
        x: 21,
        y: 78,
        score: 7.9,
        timeStamp: 1632825489767,
    },
    {x: 22, y: 64, score: 8.4, timeStamp: 1632825487167},
    {x: 85, y: 94, score: 6, timeStamp: 1632825486844},
    {
        x: 10,
        y: 71,
        score: 8.2,
        timeStamp: 1632825486844,
    },
    {x: 12, y: 17, score: 10.3, timeStamp: 1632825486473},
    {x: 26, y: 29, score: 9.6, timeStamp: 1632825486473},
    {
        x: 96,
        y: 8,
        score: 7.3,
        timeStamp: 1632825486187,
    },
    {x: 28, y: 72, score: 8, timeStamp: 1632825486179},
    {x: 85, y: 73, score: 6.6, timeStamp: 1632825485880},
    {
        x: 94,
        y: 6,
        score: 7.3,
        timeStamp: 1632825485580,
    },
    {x: 55, y: 79, score: 7.3, timeStamp: 1632825485344},
    {x: 30, y: 68, score: 8.1, timeStamp: 1632825484631},
    {
        x: 94,
        y: -44,
        score: 7,
        timeStamp: 1632825443578,
    },
    {x: 51, y: -32, score: 8.7, timeStamp: 1632825443403},
    {x: 8, y: -19, score: 10.3, timeStamp: 1632825443204},
    {
        x: 25,
        y: -37,
        score: 9.3,
        timeStamp: 1632825443022,
    },
    {x: 67, y: -16, score: 8.4, timeStamp: 1632825443020},
    {x: 89, y: -20, score: 7.5, timeStamp: 1632825442837},
    {
        x: 79,
        y: -67,
        score: 7,
        timeStamp: 1632825442634,
    },
    {x: 18, y: -72, score: 8.1, timeStamp: 1632825442387},
    {x: 9, y: -85, score: 7.7, timeStamp: 1632825442183},
    {
        x: 71,
        y: -98,
        score: 6.3,
        timeStamp: 1632825442163,
    },
    {x: 91, y: -6, score: 7.5, timeStamp: 1632825441991},
    {x: 82, y: -32, score: 7.6, timeStamp: 1632825441819},
    {
        x: 40,
        y: -20,
        score: 9.3,
        timeStamp: 1632825441522,
    },
    {x: 86, y: -71, score: 6.7, timeStamp: 1632825441494},
    {x: 97, y: -90, score: 5.8, timeStamp: 1632825441327},
    {
        x: 8,
        y: -85,
        score: 7.7,
        timeStamp: 1632825441145,
    },
    {x: 19, y: -80, score: 7.8, timeStamp: 1632825440864},
    {x: 60, y: -45, score: 8.1, timeStamp: 1632825440708},
    {
        x: 51,
        y: -88,
        score: 7,
        timeStamp: 1632825440554,
    },
    {x: 75, y: -98, score: 6.2, timeStamp: 1632825440250},
    {x: 71, y: -87, score: 6.6, timeStamp: 1632825440085},
    {
        x: 11,
        y: -18,
        score: 10.3,
        timeStamp: 1632825439913,
    },
    {x: 63, y: -44, score: 8, timeStamp: 1632825439669},
    {x: 65, y: -41, score: 8, timeStamp: 1632825438985},
];
