import React, {useContext} from "react";
import {Grid, Hidden} from "@material-ui/core";
import styles from "./LoginDialog.module.css";
import {LoginContent} from "./components/LoginContent";
import {AppSettings} from "../AppSettingsProvider";
import ServerDisconnected from "../header/ServerDisconnected";
import BuildVersion from "../header/BuildVersion";
import {getTheme} from "../../apps/Components";

export default function LoginDialog(props: {onLogin?: () => void; onCreate?: () => void}) {
    const {Global} = useContext(AppSettings);
    const {onLogin, onCreate} = props;
    const theme = getTheme(Global.theme);

    return (
        <Grid container>
            <ServerDisconnected />
            <Hidden smDown>
                <Grid
                    item
                    container
                    lg={7}
                    md={6}
                    xs={12}
                    className={styles.infoPanel}
                    style={{
                        backgroundColor: theme.extendedPalette.loginScreenSidebarBackground,
                        backgroundImage: `url(${theme.images.loginScreenSidebar})`,
                        backgroundPosition: "center",
                        backgroundSize: "60%",
                        backgroundRepeat: "no-repeat",
                    }}
                />

                <div
                    onClick={() => {
                        window.location.reload();
                    }}
                    className={styles.version}>
                    <BuildVersion />
                </div>
            </Hidden>
            <Grid
                item
                container
                justify="center"
                alignItems="flex-start"
                lg={5}
                md={6}
                xs={12}
                className={styles.formPanel}>
                <LoginContent {...{onLogin, onCreate}} />
            </Grid>
        </Grid>
    );
}
