/*
##########################################################################
# This file is generated automatically 
using: src/components/icons/functions/generateImports.js
## Generator makes .tsx files that include import/export of custom 
icons that are located in : src/resources/icons/packs
## Pack's folder must contain 'pack name' directory => directories
of icon 'types'
##If introduced icon file names with different patterns => Must edit
getImportTitle() function in order to equalize import titles.
##########################################################################
#
# To update this and all other icon import/export files run: npm run icons
#
##########################################################################
*/

import React from "react";
import {SvgIcon, SvgIconProps} from "@material-ui/core";

import {ReactComponent as INTARSOLogoDarkIcon} from "../../../../resources/icons/packs/custom/filled/INTARSO-Logo-Dark.svg";
import {ReactComponent as INTARSOLogoLightIcon} from "../../../../resources/icons/packs/custom/filled/INTARSO-Logo-Light.svg";
import {ReactComponent as INTARSOLogoMainIcon} from "../../../../resources/icons/packs/custom/filled/INTARSO-Logo-Main.svg";
import {ReactComponent as INTARSOLogoSymbolDarkIcon} from "../../../../resources/icons/packs/custom/filled/INTARSO-Logo-Symbol-Dark.svg";
import {ReactComponent as INTARSOLogoSymbolWhiteIcon} from "../../../../resources/icons/packs/custom/filled/INTARSO-Logo-Symbol-White.svg";
import {ReactComponent as INTARSOLogoSymbolYellowIcon} from "../../../../resources/icons/packs/custom/filled/INTARSO-Logo-Symbol-Yellow.svg";
import {ReactComponent as INTARSOLogoWhiteIcon} from "../../../../resources/icons/packs/custom/filled/INTARSO-Logo-White.svg";
import {ReactComponent as MPIIcon} from "../../../../resources/icons/packs/custom/filled/MPI.svg";
import {ReactComponent as BarIcon} from "../../../../resources/icons/packs/custom/filled/bar.svg";
import {ReactComponent as ChevronRightIcon} from "../../../../resources/icons/packs/custom/filled/chevron-right.svg";
import {ReactComponent as CircleTargetIcon} from "../../../../resources/icons/packs/custom/filled/circle-target.svg";
import {ReactComponent as CircleIcon} from "../../../../resources/icons/packs/custom/filled/circle.svg";
import {ReactComponent as LightIcon} from "../../../../resources/icons/packs/custom/filled/light.svg";
import {ReactComponent as MonitorIcon} from "../../../../resources/icons/packs/custom/filled/monitor.svg";
import {ReactComponent as SoundIcon} from "../../../../resources/icons/packs/custom/filled/sound.svg";
import {ReactComponent as SquareIcon} from "../../../../resources/icons/packs/custom/filled/square.svg";
import {ReactComponent as TargetIcon} from "../../../../resources/icons/packs/custom/filled/target.svg";
import {ReactComponent as TemperatureIcon} from "../../../../resources/icons/packs/custom/filled/temperature.svg";

export const INTARSOLogoDark = (props: SvgIconProps) => (
    <SvgIcon {...props} component={INTARSOLogoDarkIcon} viewBox="0 0 512 512" />
);
export const INTARSOLogoLight = (props: SvgIconProps) => (
    <SvgIcon {...props} component={INTARSOLogoLightIcon} viewBox="0 0 512 512" />
);
export const INTARSOLogoMain = (props: SvgIconProps) => (
    <SvgIcon {...props} component={INTARSOLogoMainIcon} viewBox="0 0 512 512" />
);
export const INTARSOLogoSymbolDark = (props: SvgIconProps) => (
    <SvgIcon {...props} component={INTARSOLogoSymbolDarkIcon} viewBox="0 0 512 512" />
);
export const INTARSOLogoSymbolWhite = (props: SvgIconProps) => (
    <SvgIcon {...props} component={INTARSOLogoSymbolWhiteIcon} viewBox="0 0 512 512" />
);
export const INTARSOLogoSymbolYellow = (props: SvgIconProps) => (
    <SvgIcon {...props} component={INTARSOLogoSymbolYellowIcon} viewBox="0 0 512 512" />
);
export const INTARSOLogoWhite = (props: SvgIconProps) => (
    <SvgIcon {...props} component={INTARSOLogoWhiteIcon} viewBox="0 0 512 512" />
);
export const MPI = (props: SvgIconProps) => <SvgIcon {...props} component={MPIIcon} viewBox="0 0 512 512" />;
export const Bar = (props: SvgIconProps) => <SvgIcon {...props} component={BarIcon} viewBox="0 0 512 512" />;
export const ChevronRight = (props: SvgIconProps) => (
    <SvgIcon {...props} component={ChevronRightIcon} viewBox="0 0 512 512" />
);
export const CircleTarget = (props: SvgIconProps) => (
    <SvgIcon {...props} component={CircleTargetIcon} viewBox="0 0 512 512" />
);
export const Circle = (props: SvgIconProps) => <SvgIcon {...props} component={CircleIcon} viewBox="0 0 512 512" />;
export const Light = (props: SvgIconProps) => <SvgIcon {...props} component={LightIcon} viewBox="0 0 512 512" />;
export const Monitor = (props: SvgIconProps) => <SvgIcon {...props} component={MonitorIcon} viewBox="0 0 512 512" />;
export const Sound = (props: SvgIconProps) => <SvgIcon {...props} component={SoundIcon} viewBox="0 0 512 512" />;
export const Square = (props: SvgIconProps) => <SvgIcon {...props} component={SquareIcon} viewBox="0 0 512 512" />;
export const Target = (props: SvgIconProps) => <SvgIcon {...props} component={TargetIcon} viewBox="0 0 512 512" />;
export const Temperature = (props: SvgIconProps) => (
    <SvgIcon {...props} component={TemperatureIcon} viewBox="0 0 512 512" />
);
