import React, {useContext, useState} from "react";
import {Button, ListItem, ListItemIcon, ListItemText, Step, StepLabel, Stepper} from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Popup from "../common/Popup";
import {SelectDiscipline} from "../select-view/SelectDiscipline";
import {SelectOptions} from "../select-view/SelectOptions";
import {SelectTargetContent} from "../select-view/SelectTarget";
import {CompetitionControllerApi} from "../../api/generated/esa";
import {translation} from "../i18n";
import {NEW_COMPETITION_EMPTY, SpectatorContext} from "./SpectatorContextProvider";
import {SelectContext} from "../select-view/SelectContextProvider";
import styles from "./SelectTrainingSession.module.css";
import {NotificationContext} from "../NotificationContextProvider";

const competitionControllerApi = new CompetitionControllerApi();
const userNotDefined = "userNotDefined";

export default function SelectTrainingSession(props: {disabled: boolean}) {
    const [selectPopup, setSelectPopup] = useState(false);
    const [step, setStep] = useState(0);
    const {notify} = useContext(NotificationContext);
    const {newCompetition, setNewCompetition, allDevices, setSelectedCompetitionId} = useContext(SpectatorContext);
    const {discipline, selectedTargetObject, selectedWeapon, saveToLocalStorage, trafficPattern} = useContext(
        SelectContext,
    );
    const {disabled} = props;

    const selectedDevices = allDevices.filter(d => newCompetition.deviceIds.includes(d.deviceId));

    function noUserAssignedError() {
        const deviceNames = selectedDevices
            .filter(d => d.userId === undefined)
            .map(d => d.deviceAlias)
            .join(", ");

        notify("warning", "error.not_all_devices_assigned", {translationValues: {deviceNames: deviceNames}});
    }

    function onStart() {
        const userIds = selectedDevices.map(d => d.userId ?? userNotDefined);
        if (userIds.indexOf(userNotDefined) > -1) {
            noUserAssignedError();
        } else if (discipline && selectedTargetObject) {
            competitionControllerApi
                .selectCompetition({
                    disciplines: discipline,
                    targetName: selectedTargetObject.name,
                    weaponId: selectedWeapon?.id,
                    phaseN: 0,
                    userIds,
                    trafficPattern: trafficPattern ?? undefined,
                })
                .then(id =>
                    setSelectedCompetitionId({
                        id: id,
                        disciplineName: discipline,
                        userCount: userIds.length,
                    }),
                )
                .then(() => setNewCompetition(NEW_COMPETITION_EMPTY))
                .then(saveToLocalStorage);
        }

        setSelectPopup(false);
    }

    function openPopup() {
        if (selectedDevices.find(n => n.userId === undefined)) {
            noUserAssignedError();
        } else {
            setSelectPopup(true);
        }
    }

    return (
        <>
            <ListItem id="select_training_session" {...{disabled}} button onClick={openPopup}>
                <ListItemIcon>
                    <PlayCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={translation("button.spectate.select_session")} />
            </ListItem>

            <Popup
                title={translation("title.spectate.select_session")}
                variant="wide"
                show={selectPopup}
                onClose={() => setSelectPopup(false)}
                onClick={() => {
                    setStep(prevStep => {
                        if (prevStep === 2) {
                            onStart();
                            return 0;
                        } else {
                            return prevStep + 1;
                        }
                    });
                }}
                okButton={step === 2 ? "label.select" : "label.next"}
                extraButtons={
                    <Button disabled={step === 0} onClick={() => setStep(prevActiveStep => prevActiveStep - 1)}>
                        {translation("button.back")}
                    </Button>
                }>
                <Stepper className={styles.stepper} activeStep={step}>
                    <Step>
                        <StepLabel>{translation("label.step.discipline")}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{translation("label.step.options")}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{translation("label.step.target")}</StepLabel>
                    </Step>
                </Stepper>
                <div className={styles.content}>
                    {step === 0 && <SelectDiscipline />}
                    {step === 1 && <SelectOptions />}
                    {step === 2 && <SelectTargetContent />}
                </div>
            </Popup>
        </>
    );
}
