import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    ConstantsStringEsa,
    Discipline,
    FullWeapon,
    Target as TargetType,
    TrafficPattern,
    Weapon,
    WebSocketMessage,
} from "../../api/generated/esa/models";
import {TargetControllerApi, TrainingSessionControllerApi, WeaponInfoControllerApi} from "../../api/generated/esa";
import {AppContext} from "../AppContextProvider";

export interface SelectContextContextI {
    discipline: string;
    setDiscipline: Setter<string>;
    disciplineTargets: Discipline[];
    selectedTarget: string;
    setSelectedTarget: Setter<string>;
    selectedTargetObject: TargetType | null;
    setSelectedTargetObject: Setter<TargetType | null>;
    targets: TargetType[];
    selectedWeapon: Weapon | null;
    setSelectedWeapon: Setter<Weapon | null>;
    trafficPattern: TrafficPattern | null;
    setTrafficPattern: Setter<TrafficPattern | null>;
    weapons: FullWeapon[];
    saveToLocalStorage: () => void;
    selectedDiscipline: Discipline;
}

const empty = () => {};

export const SelectContext = React.createContext<SelectContextContextI>({
    discipline: ConstantsStringEsa.FREE_TRAINING_NAME,
    setDiscipline: empty,
    disciplineTargets: [],
    selectedTarget: "",
    setSelectedTarget: empty,
    selectedWeapon: null,
    setSelectedWeapon: empty,
    selectedTargetObject: null,
    setSelectedTargetObject: empty,
    targets: [],
    weapons: [],
    saveToLocalStorage: empty,
    trafficPattern: null,
    setTrafficPattern: empty,
    selectedDiscipline: {} as Discipline,
});

const targetControllerApi = new TargetControllerApi();
const trainingSessionApi = new TrainingSessionControllerApi();
const weaponInfoControllerApi = new WeaponInfoControllerApi();
const LOCAL_SESSION = "selected_session";

type LocalSession = {
    discipline: string | null;
    weapon: string | null;
    target: string;
};

export function saveTrainingSessionToLocalStorage(session: LocalSession) {
    window.localStorage.setItem(LOCAL_SESSION, JSON.stringify(session));
}

export default function SelectContextProvider(props: React.PropsWithChildren<any>) {
    const {children} = props;
    const [discipline, setDiscipline] = useState<string>(ConstantsStringEsa.FREE_TRAINING_NAME);
    const [disciplineTargets, setDisciplineTargets] = useState<Discipline[]>([]);
    const [selectedTarget, setSelectedTarget] = useState<string>("");
    const [selectedWeapon, setSelectedWeapon] = useState<Weapon | null>(null);
    const [weapons, setWeapons] = useState<FullWeapon[]>([]);
    const [selectedTargetObject, setSelectedTargetObject] = useState<TargetType | null>(null);
    const [targets, setTargets] = useState<TargetType[]>([]);
    const selectedDiscipline: Discipline = disciplineTargets.filter(it => it.name === discipline)[0];
    const {connected, webSocket} = useContext(AppContext);
    const [trafficPattern, setTrafficPattern] = useState<TrafficPattern | null>(null);

    useEffect(() => {
        const pattern = selectedDiscipline?.phases[0].trafficPattern;
        setTrafficPattern(pattern || null);
    }, [selectedDiscipline]);

    useEffect(() => {
        if (selectedDiscipline) {
            const arr = selectedDiscipline.targets.map(it => targetControllerApi.get({targetName: it}));
            Promise.all(arr).then(setTargets);
        }
    }, [selectedDiscipline]);

    useEffect(() => {
        setSelectedTarget(selectedDiscipline?.targets[0]);
    }, [selectedDiscipline]);

    useEffect(() => {
        const result = targets.filter(it => it.name === selectedTarget)[0];
        setSelectedTargetObject(result);
    }, [selectedTarget, targets]);

    const saveToLocalStorage = useCallback(() => {
        saveTrainingSessionToLocalStorage({
            discipline: discipline,
            weapon: selectedWeapon?.id ?? null,
            target: selectedTarget,
        });
    }, [discipline, selectedWeapon, selectedTarget]);

    useEffect(() => {
        function updateListener(event: MessageEvent) {
            const update: WebSocketMessage = JSON.parse(event.data);
            if (update.weapon) {
                weaponInfoControllerApi.weapons().then(allWeapons => {
                    setWeapons(allWeapons);
                    setSelectedWeapon(old => allWeapons.find(it => it.id === old?.id) ?? null);
                });
            }
        }

        webSocket.addEventListener("message", updateListener);

        return function cleanup() {
            webSocket.removeEventListener("message", updateListener);
        };
    }, [webSocket, setWeapons, setSelectedWeapon]);

    useEffect(() => {
        if (connected) {
            Promise.all([trainingSessionApi.disciplines(), weaponInfoControllerApi.weapons()]).then(
                weaponsAndTargets => {
                    const [allDisciplines, allWeapons] = weaponsAndTargets;

                    setDisciplineTargets(allDisciplines);
                    setWeapons(allWeapons);

                    const localString = window.localStorage.getItem(LOCAL_SESSION);
                    const saved: LocalSession = localString ? JSON.parse(localString) : null;

                    if (saved) {
                        const {target: savedTarget, discipline: savedDiscipline, weapon: savedWeapon} = saved;

                        const maybeDiscipline = allDisciplines.find(it => it.name === savedDiscipline);
                        maybeDiscipline && setDiscipline(maybeDiscipline.name);

                        const maybeWeapon = allWeapons.find(it => it.id === savedWeapon);
                        maybeWeapon && setSelectedWeapon(maybeWeapon);

                        const targetExistsInDiscipline = maybeDiscipline?.targets.includes(savedTarget);
                        targetExistsInDiscipline && setSelectedTarget(savedTarget);
                    }
                },
            );
        }
    }, [connected, setSelectedTarget, setWeapons, setDisciplineTargets, setDiscipline, setSelectedWeapon]);

    const context = useMemo<SelectContextContextI>(() => {
        return {
            ...{
                disciplineTargets,
                discipline,
                setDiscipline,
                selectedTarget,
                setSelectedTarget,
                selectedWeapon,
                setSelectedWeapon,
                selectedDiscipline,
                selectedTargetObject,
                setSelectedTargetObject,
                targets,
                weapons,
                saveToLocalStorage,
                trafficPattern,
                setTrafficPattern,
            },
        };
    }, [
        disciplineTargets,
        discipline,
        setDiscipline,
        selectedTarget,
        setSelectedTarget,
        selectedDiscipline,
        selectedTargetObject,
        setSelectedTargetObject,
        selectedWeapon,
        setSelectedWeapon,
        targets,
        weapons,
        saveToLocalStorage,
        trafficPattern,
        setTrafficPattern,
    ]);

    return <SelectContext.Provider value={context}>{children}</SelectContext.Provider>;
}
