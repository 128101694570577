/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface Hit
 */
export interface Hit {
    /**
     *
     * @type {number}
     * @memberof Hit
     */
    n: number;
    /**
     *
     * @type {number}
     * @memberof Hit
     */
    deviceId: number;
    /**
     *
     * @type {number}
     * @memberof Hit
     */
    x: number;
    /**
     *
     * @type {number}
     * @memberof Hit
     */
    y: number;
    /**
     *
     * @type {number}
     * @memberof Hit
     */
    timeStamp: number;
    /**
     *
     * @type {string}
     * @memberof Hit
     */
    phaseId: string;
    /**
     *
     * @type {number}
     * @memberof Hit
     */
    score: number;
    /**
     *
     * @type {string}
     * @memberof Hit
     */
    displayScore: string;
    /**
     *
     * @type {number}
     * @memberof Hit
     */
    speed?: number;
    /**
     *
     * @type {string}
     * @memberof Hit
     */
    id: string;
}

export function HitFromJSON(json: any): Hit {
    return HitFromJSONTyped(json, false);
}

export function HitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hit {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        n: json["n"],
        deviceId: json["deviceId"],
        x: json["x"],
        y: json["y"],
        timeStamp: json["timeStamp"],
        phaseId: json["phaseId"],
        score: json["score"],
        displayScore: json["displayScore"],
        speed: !exists(json, "speed") ? undefined : json["speed"],
        id: json["id"],
    };
}

export function HitToJSON(value?: Hit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        n: value.n,
        deviceId: value.deviceId,
        x: value.x,
        y: value.y,
        timeStamp: value.timeStamp,
        phaseId: value.phaseId,
        score: value.score,
        displayScore: value.displayScore,
        speed: value.speed,
        id: value.id,
    };
}
