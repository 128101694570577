/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";
import {
    WebSocketMessageAction,
    WebSocketMessageActionFromJSON,
    WebSocketMessageActionToJSON,
    WebSocketMessageDeviceEsaData,
    WebSocketMessageDeviceEsaDataFromJSON,
    WebSocketMessageDeviceEsaDataToJSON,
    WebSocketMessageHit,
    WebSocketMessageHitFromJSON,
    WebSocketMessageHitToJSON,
    WebSocketMessagePhase,
    WebSocketMessagePhaseFromJSON,
    WebSocketMessagePhasePeriod,
    WebSocketMessagePhasePeriodFromJSON,
    WebSocketMessagePhasePeriodToJSON,
    WebSocketMessagePhaseToJSON,
    WebSocketMessageUserDevice,
    WebSocketMessageUserDeviceFromJSON,
    WebSocketMessageUserDeviceToJSON,
    WebSocketMessageWeapon,
    WebSocketMessageWeaponFromJSON,
    WebSocketMessageWeaponToJSON,
} from "./";

/**
 *
 * @export
 * @interface WebSocketMessage
 */
export interface WebSocketMessage {
    /**
     *
     * @type {WebSocketMessageDeviceEsaData}
     * @memberof WebSocketMessage
     */
    deviceEsaData?: WebSocketMessageDeviceEsaData;
    /**
     *
     * @type {WebSocketMessagePhase}
     * @memberof WebSocketMessage
     */
    phase?: WebSocketMessagePhase;
    /**
     *
     * @type {WebSocketMessagePhasePeriod}
     * @memberof WebSocketMessage
     */
    phasePeriod?: WebSocketMessagePhasePeriod;
    /**
     *
     * @type {string}
     * @memberof WebSocketMessage
     */
    phaseType?: WebSocketMessagePhaseTypeEnum;
    /**
     *
     * @type {string}
     * @memberof WebSocketMessage
     */
    scoringType?: WebSocketMessageScoringTypeEnum;
    /**
     *
     * @type {WebSocketMessageUserDevice}
     * @memberof WebSocketMessage
     */
    userDevice?: WebSocketMessageUserDevice;
    /**
     *
     * @type {WebSocketMessageAction}
     * @memberof WebSocketMessage
     */
    action?: WebSocketMessageAction;
    /**
     *
     * @type {WebSocketMessageHit}
     * @memberof WebSocketMessage
     */
    hit?: WebSocketMessageHit;
    /**
     *
     * @type {string}
     * @memberof WebSocketMessage
     */
    measurementUnit?: WebSocketMessageMeasurementUnitEnum;
    /**
     *
     * @type {WebSocketMessageWeapon}
     * @memberof WebSocketMessage
     */
    weapon?: WebSocketMessageWeapon;
}

export function WebSocketMessageFromJSON(json: any): WebSocketMessage {
    return WebSocketMessageFromJSONTyped(json, false);
}

export function WebSocketMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebSocketMessage {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        deviceEsaData: !exists(json, "DeviceEsaData")
            ? undefined
            : WebSocketMessageDeviceEsaDataFromJSON(json["DeviceEsaData"]),
        phase: !exists(json, "Phase") ? undefined : WebSocketMessagePhaseFromJSON(json["Phase"]),
        phasePeriod: !exists(json, "PhasePeriod")
            ? undefined
            : WebSocketMessagePhasePeriodFromJSON(json["PhasePeriod"]),
        phaseType: !exists(json, "PhaseType") ? undefined : json["PhaseType"],
        scoringType: !exists(json, "ScoringType") ? undefined : json["ScoringType"],
        userDevice: !exists(json, "UserDevice") ? undefined : WebSocketMessageUserDeviceFromJSON(json["UserDevice"]),
        action: !exists(json, "Action") ? undefined : WebSocketMessageActionFromJSON(json["Action"]),
        hit: !exists(json, "Hit") ? undefined : WebSocketMessageHitFromJSON(json["Hit"]),
        measurementUnit: !exists(json, "MeasurementUnit") ? undefined : json["MeasurementUnit"],
        weapon: !exists(json, "Weapon") ? undefined : WebSocketMessageWeaponFromJSON(json["Weapon"]),
    };
}

export function WebSocketMessageToJSON(value?: WebSocketMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        DeviceEsaData: WebSocketMessageDeviceEsaDataToJSON(value.deviceEsaData),
        Phase: WebSocketMessagePhaseToJSON(value.phase),
        PhasePeriod: WebSocketMessagePhasePeriodToJSON(value.phasePeriod),
        PhaseType: value.phaseType,
        ScoringType: value.scoringType,
        UserDevice: WebSocketMessageUserDeviceToJSON(value.userDevice),
        Action: WebSocketMessageActionToJSON(value.action),
        Hit: WebSocketMessageHitToJSON(value.hit),
        MeasurementUnit: value.measurementUnit,
        Weapon: WebSocketMessageWeaponToJSON(value.weapon),
    };
}

/**
 * @export
 * @enum {string}
 */
export enum WebSocketMessagePhaseTypeEnum {
    Regular = "Regular",
    TrafficLight = "TrafficLight",
}
/**
 * @export
 * @enum {string}
 */
export enum WebSocketMessageScoringTypeEnum {
    FullRing = "FullRing",
    Decimal = "Decimal",
    DecimalIgnore = "DecimalIgnore",
    HitZones = "HitZones",
    Unknown = "Unknown",
}
/**
 * @export
 * @enum {string}
 */
export enum WebSocketMessageMeasurementUnitEnum {
    Metric = "Metric",
    Imperial = "Imperial",
}
