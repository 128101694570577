import React, {useContext} from "react";
import {Grid, List, ListItem, ListItemSecondaryAction} from "@material-ui/core";
import {translation, translationOrName} from "../../i18n";
import TextWithIcon from "../../common/TextWithIcon";
import TimerIcon from "@material-ui/icons/Timer";
import {timerFormat} from "../../timer/Timer";
import {ScoringType} from "../../../api/generated/esa/models";
import {ReviewContext} from "../ReviewContextProvider";
import {AppContext} from "../../AppContextProvider";
import {ReviewSessionsWrapper} from "./ReviewSessionsWrapper";
import {Skeleton} from "@material-ui/lab";
import {Body1} from "../../common/TypographyVariants";
import NoData from "../../common/NoData";
import {ReviewScore} from "./ReviewSessions";

export function ReviewPhases() {
    const {phase, setPhase, session, isLoading} = useContext(ReviewContext);
    const {getServerTime} = useContext(AppContext);
    const now = getServerTime();
    const phasesExist = session && session.phases && session.phases.length > 0;

    if (isLoading) {
        return (
            <ReviewSessionsWrapper title={translation("title.training_session_phases")}>
                <div style={{padding: 8}}>
                    <Skeleton variant="rect" style={{width: "100%", marginBottom: 8}} height={65} />
                </div>
            </ReviewSessionsWrapper>
        );
    } else if (!Boolean(phasesExist)) {
        return (
            <ReviewSessionsWrapper title={translation("title.training_session_phases")}>
                <NoData />
            </ReviewSessionsWrapper>
        );
    } else {
        return (
            <ReviewSessionsWrapper title={translation("title.training_session_phases")}>
                <List id="training_session_phases" component="ul" style={{padding: 0}}>
                    {session?.phases
                        .sort((p1, p2) => p1.created - p2.created)
                        .map(p => (
                            <ListItem key={p.id} selected={phase?.id === p.id} onClick={() => setPhase(p)} button>
                                <Grid style={{width: "100%"}}>
                                    <Grid container direction="column" wrap="nowrap">
                                        <Body1 noWrap>
                                            {p.name ? translationOrName("component.timer.phase", p.name) : ""}
                                        </Body1>
                                        <TextWithIcon className="duration" startIcon={<TimerIcon />}>
                                            {(p.end && p.start && p.end <= now && timerFormat(p.end - p.start)) ||
                                                translation("label.not_completed")}
                                        </TextWithIcon>
                                    </Grid>
                                </Grid>

                                {p.scoring !== ScoringType.DecimalIgnore && (
                                    <ListItemSecondaryAction>
                                        <ReviewScore decimalScore={p.decimalScore} fullRingScore={p.fullRingScore} />
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        ))}
                </List>
            </ReviewSessionsWrapper>
        );
    }
}
