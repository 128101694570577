import React, {useContext, useRef, useState} from "react";
import styles from "./SelectView.module.css";
import {
    Button,
    ButtonGroup,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    InputBase,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core";
import {translation, translationOrName, translationString, translationStringOrName} from "../i18n";
import {IntlShape, useIntl} from "react-intl";
import {
    ConstantsNumberEsa,
    ConstantsStringEsa,
    Discipline,
    FullWeapon,
    MeasurementUnit,
    PhaseType,
    ScoringType,
    Target as TargetType,
    Weapon,
} from "../../api/generated/esa";
import Menu from "@material-ui/core/Menu";
import {Close, Search} from "@material-ui/icons";
import {weaponEmpty} from "../weapon-management-dialog/WeaponInfo";
import {SelectContext} from "./SelectContextProvider";
import {Body2, H6} from "../common/TypographyVariants";
import {timerFormat} from "../timer/Timer";

function WeaponListItem(props: {weapon: FullWeapon}) {
    const {weapon} = props;
    const imageSize = 48;

    return (
        <Grid container>
            <Grid
                justify="center"
                alignItems="center"
                container
                style={{position: "relative", width: imageSize, height: imageSize, marginRight: 8}}>
                {weapon.typeIcon && (
                    <img style={{height: "100%", width: "100%"}} src={weapon.typeIcon} alt={weapon.typeIcon} />
                )}
            </Grid>

            <Grid>
                <Typography className="data-weapon-name">{weapon.name}</Typography>
                <Typography variant="caption" color="textSecondary">
                    {caliberString(weapon)}
                </Typography>
            </Grid>
        </Grid>
    );
}

function TrafficPatternPanel(props: {
    changeButtonRef: React.MutableRefObject<null>;
    trafficPatternPanel: boolean;
    setTrafficPatternPanel: Setter<boolean>;
}) {
    const {changeButtonRef, setTrafficPatternPanel, trafficPatternPanel} = props;
    const {trafficPattern, setTrafficPattern} = useContext(SelectContext);

    if (!trafficPattern) return null;

    const {repeat, shootingTime, waitingTime} = trafficPattern;

    function noZero(val: number) {
        return val === 0 ? "" : val;
    }

    return (
        <Menu
            id="traffic-panel-options"
            anchorEl={changeButtonRef.current}
            keepMounted
            onClose={() => setTrafficPatternPanel(false)}
            open={trafficPatternPanel}>
            <Grid container direction="column" style={{padding: 16}}>
                <Typography variant="h6" color="primary">
                    {translation("title.discipline_options")}
                </Typography>

                <Grid item style={{padding: "8px 0"}}>
                    <TextField
                        label="Shooting time"
                        variant="outlined"
                        type="number"
                        value={noZero(shootingTime)}
                        onChange={e => setTrafficPattern({...trafficPattern, shootingTime: +e.target.value})}
                    />
                </Grid>

                <Grid item style={{padding: "8px 0"}}>
                    <TextField
                        label="Wait time"
                        variant="outlined"
                        type="number"
                        value={noZero(waitingTime)}
                        onChange={e => setTrafficPattern({...trafficPattern, waitingTime: +e.target.value})}
                    />
                </Grid>

                <Grid item style={{padding: "8px 0"}}>
                    <TextField
                        label="Rounds"
                        variant="outlined"
                        type="number"
                        value={noZero(repeat)}
                        onChange={e => setTrafficPattern({...trafficPattern, repeat: +e.target.value})}
                    />
                </Grid>
                <Grid item style={{padding: "16px 0"}}>
                    <Body2>
                        {translation("label.info.estimated_duration")}{" "}
                        <b>{timerFormat((waitingTime + shootingTime) * repeat * 1000)}</b>
                    </Body2>
                </Grid>

                <Grid item style={{padding: "16px 0 8px"}}>
                    <Button onClick={() => setTrafficPatternPanel(false)} variant="contained" color="primary">
                        {translation("button.close")}
                    </Button>
                </Grid>
            </Grid>
        </Menu>
    );
}

function DisciplinePanel(props: {
    changeButtonRef: React.MutableRefObject<null>;
    scoring: ScoringType;
    setScoring: Setter<ScoringType>;
    disciplinePanel: boolean;
    setDisciplinePanel: Setter<boolean>;
}) {
    const {changeButtonRef, setDisciplinePanel, disciplinePanel, scoring, setScoring} = props;
    return (
        <Menu
            id="discipline-option"
            anchorEl={changeButtonRef.current}
            keepMounted
            onClose={() => setDisciplinePanel(false)}
            open={disciplinePanel}>
            <Grid container direction="column" style={{padding: 8}}>
                <Typography variant="h6" color="primary">
                    {translation("title.discipline_options")}
                </Typography>
                <Grid item style={{padding: "8px 0"}}>
                    <TextField
                        label={translation("label.input.duration")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    {translation("label.input.duration.time_unit")}
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item style={{padding: "8px 0"}}>
                    <TextField label={translation("label.input.hits")} variant="outlined" fullWidth />
                </Grid>

                <Grid item style={{padding: "8px 0"}}>
                    <ButtonGroup color="primary">
                        {[ScoringType.Decimal, ScoringType.FullRing].map(it => (
                            <Button
                                variant={scoring === it ? "contained" : "outlined"}
                                onClick={() => setScoring(it)}
                                key={it}>
                                {translationOrName("label.scoring", it)}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Grid>

                <Grid item style={{padding: "16px 0 8px"}}>
                    <Button variant="contained" color="primary">
                        {translation("button.apply")}
                    </Button>
                </Grid>
            </Grid>
        </Menu>
    );
}

export function SelectOptions() {
    const {
        disciplineTargets,
        discipline,
        setSelectedWeapon,
        selectedWeapon,
        selectedTargetObject,
        weapons,
        trafficPattern,
    } = useContext(SelectContext);
    const [showWeaponList, setShowWeaponList] = useState(false);
    const targetListAnchor = useRef(null);
    const changeButtonRef = useRef(null);
    const selectedDiscipline: Discipline = disciplineTargets.filter(it => it.name === discipline)[0];
    const changeableWeapons = weapons.length <= 1;
    const disciplineObject = disciplineTargets.filter(it => it.name === discipline)[0];
    const qualification = disciplineObject?.phases.filter(
        it => it.name === ConstantsStringEsa.PHASE_NAME_QUALIFICATION,
    )[0];
    const [filter, setFilter] = useState("");

    const toDisplay = qualification ? qualification : disciplineObject?.phases[0];
    const unlimited = ConstantsNumberEsa.UNLIMITED_DURATION;
    const intl = useIntl();
    const [disciplinePanel, setDisciplinePanel] = useState(false);
    const [trafficPatternPanel, setTrafficPatternPanel] = useState(false);

    const [scoring, setScoring] = useState<ScoringType>(ScoringType.Decimal);
    const isTrafficLight =
        selectedDiscipline?.phases.filter(it => it.type === PhaseType.TrafficLight).length ===
        selectedDiscipline?.phases.length;

    const displayWeapon = selectedWeapon ?? defaultWeapon(intl, selectedTargetObject);

    return (
        <>
            <Grid container style={{height: "100%"}}>
                <Grid id="discipline_details_change" md={6} sm={12} item container className={styles.optionContainer}>
                    <Grid item container xs={9} direction="column" justify="flex-start" alignItems="flex-start">
                        <H6 color={!isTrafficLight ? "textSecondary" : "primary"}>
                            {disciplineObject?.name && translationOrName("discipline", disciplineObject.name)}
                        </H6>
                        <Body2 color="textSecondary">
                            {translation("label.discipline.scoring")}
                            {toDisplay?.scoring && translationOrName("label.scoring", toDisplay.scoring)}
                        </Body2>
                        <Body2 color="textSecondary">
                            {translation("label.discipline.distance", {
                                distance: disciplineObject?.shootingDistance,
                            })}
                        </Body2>

                        {isTrafficLight && trafficPattern ? (
                            <>
                                <Body2 color="textSecondary">
                                    {translation("label.input.duration")}:{" "}
                                    {timerFormat(
                                        (trafficPattern.waitingTime + trafficPattern.shootingTime) *
                                            trafficPattern.repeat *
                                            1000,
                                    )}
                                </Body2>

                                <Body2 color="textSecondary">
                                    {translation("label.discipline.shooting_time")}: {trafficPattern.shootingTime} s
                                </Body2>
                                <Body2 color="textSecondary">
                                    {translation("label.discipline.wait_time")}: {trafficPattern.waitingTime} s
                                </Body2>
                                <Body2 color="textSecondary">
                                    {translation("label.discipline.repeat")}: X {trafficPattern.repeat}
                                </Body2>
                            </>
                        ) : (
                            <>
                                <Body2 color="textSecondary">
                                    {translation("label.discipline.duration", {
                                        duration:
                                            toDisplay?.duration === unlimited
                                                ? translationString(intl, "component.timer.status.unlimited")
                                                : toDisplay?.duration + " min",
                                    })}
                                </Body2>
                            </>
                        )}

                        <Button
                            ref={changeButtonRef}
                            variant="outlined"
                            color="primary"
                            style={{marginTop: "var(--devSpacing2)"}}
                            disabled={!isTrafficLight}
                            onClick={() => {
                                if (isTrafficLight) {
                                    setTrafficPatternPanel(true);
                                } else {
                                    setDisciplinePanel(true);
                                }
                            }}>
                            {translation("button.change")}
                        </Button>

                        <DisciplinePanel
                            {...{disciplinePanel, setDisciplinePanel, scoring, setScoring, changeButtonRef}}
                        />
                        <TrafficPatternPanel {...{setTrafficPatternPanel, trafficPatternPanel, changeButtonRef}} />
                    </Grid>
                </Grid>
                <Grid
                    id="discipline_weapon_change"
                    item
                    md={6}
                    sm={12}
                    container
                    className={styles.optionContainer}
                    style={{borderLeft: "1px solid rgba(0,0,0, 0.16)"}}>
                    <Grid item container xs={9} direction="column" justify="flex-start" alignItems="flex-start">
                        <Typography variant="h6" color={changeableWeapons ? "textSecondary" : "primary"}>
                            {displayWeapon.name}
                        </Typography>
                        <Typography color="textSecondary">
                            {translationOrName("settings.weapons_editor", displayWeapon.type)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {caliberString(displayWeapon)}
                        </Typography>
                        <Button
                            id="select_weapon_action"
                            ref={targetListAnchor}
                            style={{marginTop: "var(--devSpacing2)"}}
                            onClick={() => setShowWeaponList(true)}
                            color="primary"
                            variant="outlined">
                            {translation("button.change")}
                        </Button>
                    </Grid>

                    <Menu
                        id="weapon_list"
                        onClose={() => {
                            setShowWeaponList(false);
                        }}
                        anchorEl={targetListAnchor.current}
                        keepMounted
                        open={showWeaponList}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                margin: `0 16px`,
                            }}>
                            <InputBase
                                id="weapon_search_input"
                                onChange={e => setFilter(e.target.value)}
                                autoFocus
                                placeholder={translationString(intl, "label.input.weapon.search_weapons")}
                            />
                            <Search />
                            <IconButton
                                onClick={() => {
                                    setShowWeaponList(false);
                                }}>
                                <Close />
                            </IconButton>
                            <Divider orientation="vertical" />
                        </div>
                        <Divider />
                        <MenuItem
                            key="default_weapon"
                            onClick={() => {
                                setSelectedWeapon(null);
                                setShowWeaponList(false);
                            }}>
                            <WeaponListItem weapon={defaultWeapon(intl, selectedTargetObject)} />
                        </MenuItem>
                        {weapons
                            .filter(it => {
                                return translationStringOrName(intl, "target", it.name)
                                    .toLowerCase()
                                    .includes(filter.toLowerCase());
                            })
                            .map(it => (
                                <MenuItem
                                    key={it.name}
                                    onClick={() => {
                                        setSelectedWeapon(it);
                                        setShowWeaponList(false);
                                    }}>
                                    <WeaponListItem weapon={it} />
                                </MenuItem>
                            ))}
                    </Menu>
                </Grid>
            </Grid>
        </>
    );
}

export function caliberString(weapon: Weapon) {
    return (
        weapon.displayCaliber ??
        `${weapon.caliberMm.toFixed(1)}mm (${weapon.caliberInch.toFixed(3).replace(/^0+/, "")})`
    );
}

function defaultWeapon(intl: IntlShape, selectedTargetObject: TargetType | null) {
    return {
        ...weaponEmpty,
        name: translationString(intl, "label.default"),
        type: selectedTargetObject?.weaponType ?? "None",
        displayCaliber: selectedTargetObject?.defaultCaliber,
        measurementUnit: MeasurementUnit.Metric,
        caliberMm: selectedTargetObject?.defaultCaliberMm ?? 0,
    };
}
