import React, {ReactNode, useContext, useEffect} from "react";
import {Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Switch} from "@material-ui/core";
import styles from "../LayoutManager.module.css";

import {ReactComponent as Score} from "@fortawesome/fontawesome-free/svgs/solid/flag-checkered.svg";
import {ReactComponent as Flag} from "@fortawesome/fontawesome-free/svgs/solid/flag.svg";
import {DeviceList} from "./components/DeviceList";
import {translation} from "../i18n";
import SpectatorTimer from "./SpectatorTimer";
import SelectTrainingSession from "./SelectTrainingSession";
import {NEW_COMPETITION_ID, SpectatorContext} from "./SpectatorContextProvider";
import SelectContextProvider from "../select-view/SelectContextProvider";
import {AppContext} from "../AppContextProvider";
import {isAdmin} from "../common/utils";

export default function SpectatorMenu(props: {
    id: string;
    setHeader: (id: string, header: ReactNode | String) => void;
}) {
    const {
        competition: {id: competitionId, deviceIds},
        setFinals,
        finals,
    } = useContext(SpectatorContext);
    const {user} = useContext(AppContext);
    const disciplineSelected = competitionId !== NEW_COMPETITION_ID;
    const {id, setHeader} = props;

    useEffect(() => {
        setHeader(id, translation("title.spectator_menu"));
    }, [id, setHeader]);

    const admin = isAdmin(user);
    return (
        <Grid container justify="space-between" direction="column" className={styles.spectatorControlsContainer}>
            <Grid>
                <List component="nav">
                    <ListItem id="finals_switch" button onClick={() => setFinals(!finals)}>
                        <ListItemIcon>
                            {finals ? <Score className="svgIcon" /> : <Flag className="svgIcon" />}{" "}
                        </ListItemIcon>
                        <ListItemText
                            primary={translation(
                                finals ? "label.spectator.finals_view" : "label.spectator.qualification_view",
                            )}
                        />
                        <ListItemSecondaryAction>
                            <Switch edge="end" checked={finals} onClick={() => setFinals(!finals)} />
                        </ListItemSecondaryAction>
                    </ListItem>

                    {!disciplineSelected && <DeviceList />}
                    {!disciplineSelected && admin && (
                        <SelectContextProvider>
                            <SelectTrainingSession disabled={deviceIds.length === 0} />
                        </SelectContextProvider>
                    )}
                </List>

                {disciplineSelected && <SpectatorTimer allowControls={admin} {...props} />}
            </Grid>
        </Grid>
    );
}
