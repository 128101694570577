import React, {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {createHeartbeatSocket, placeholderHeartbeatSocket} from "./HeartbeatSocket";
import {
    ConstantsNumberCommon,
    ConstantsStringEsa,
    EsaControllerApi,
    Phase,
    PhasePeriod,
    PhaseType,
    ScoringType,
    SystemControllerApi,
    TrainingSessionControllerApi,
    User,
    UserDevice,
    UsersControllerApi,
    WebSocketMessage,
} from "../api/generated/esa";
import LoadingScreen from "./LoadingScreen";
import Analytics from "analytics";
import WebsocketAnalytics from "./WebsocketAnalytics";
import {CssBaseline, MuiThemeProvider, StylesProvider} from "@material-ui/core";
import {getLastUsedTheme} from "./AppSettingsProvider";
import NoSleep from "nosleep.js";
import {noop, noopPromise} from "./common/utils";

const usersControllerApi = new UsersControllerApi();
const trainingSessionApi = new TrainingSessionControllerApi();
const systemControllerApi = new SystemControllerApi();
const esaController = new EsaControllerApi();

export const userPlaceholder = {
    id: "none",
    name: "none",
    description: "none",
    secretType: "none",
    roles: [],
    meta: {},
    firstName: "",
    lastName: "",
    yearOfBirth: 0,
    competitiveClass: "",
    country: "",
};

export const userDevicePlaceholder = {
    deviceId: ConstantsNumberCommon.DEVICE_UNDEFINED,
    deviceAlias: "",
    userId: "none",
    userName: "none",
};

export const phasePlaceholder: Phase = {
    id: "none",
    userId: "none",
    deviceId: ConstantsNumberCommon.DEVICE_UNDEFINED,
    sessionId: "none",
    disciplineName: ConstantsStringEsa.NO_TRAINING_NAME,
    weaponId: "none",
    unlimited: false,
    type: PhaseType.Regular,
    maxShots: 0,
    series: 0,
    duration: 0,
    scoring: ScoringType.Decimal,
    targetName: "none",
    created: 0,
    day: 0,
    month: 0,
    year: 0,
    start: 1,
    end: 2,
    currentShots: 0,
    decimalScore: 0,
    periods: new Array<PhasePeriod>(),
    started: false,
    notPaused: false,
};

export const noopAnalytics = {
    identify: noopPromise,
    track: noopPromise,
    page: noopPromise,
    user: noopPromise,
    reset: noopPromise,
    ready: noopPromise,
    on: noopPromise,
    once: noopPromise,
    getState: noopPromise,
    storage: noopPromise,
    plugins: noopPromise,
};

export const AppContext = React.createContext<AppContextType>({
    webSocket: placeholderHeartbeatSocket(),
    analytics: noopAnalytics,
    user: userPlaceholder,
    userDevice: userDevicePlaceholder,
    phase: phasePlaceholder,
    refresh: noop,
    selectedDeviceIds: [],
    setSelectedDeviceIds: noop,
    connected: false,
    setConnected: noop,
    getServerTime: () => new Date().getTime(),
});

export default function AppContextProvider(props: {children?: ReactNode}) {
    const [user, setUser] = useState<User>(userPlaceholder);
    const [phase, setPhase] = useState<Phase>(phasePlaceholder);
    const [userDevice, setUserDevice] = useState<UserDevice>(userDevicePlaceholder);
    const [selectedDeviceIds, setSelectedDeviceIds] = useState<DeviceId[]>([]);
    const [connected, setConnected] = useState(false);
    const [timeDelta, setTimeDelta] = useState<number | undefined>();
    const [enabledSleep, setEnabledSleep] = useState(false);

    const noSleep = useMemo(() => new NoSleep(), []);

    const webSocket = useMemo(() => createHeartbeatSocket(setConnected), [setConnected]);

    const refresh = useCallback(() => {
        usersControllerApi.whoAmI().then(setUser);
        esaController.getUserDevice().then(setUserDevice);
        trainingSessionApi.getDiscipline({}).then(setPhase);

        systemControllerApi.now().then(serverTime => {
            setTimeDelta(Date.now() - serverTime);
        });
    }, [setUser, setTimeDelta]);

    useEffect(() => {
        try {
            if (enabledSleep) {
                return;
            } else {
                if (process.env.NODE_ENV !== "development") {
                    //in development mode there could be some issues with hot reloading
                    try {
                        noSleep.enable(); // keep the screen on!
                    } catch (error) {
                        console.error(error);
                    }
                    setEnabledSleep(true);
                    return noSleep.disable();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [noSleep, enabledSleep]);

    useEffect(() => {
        function updateListener(event: MessageEvent) {
            const update: WebSocketMessage = JSON.parse(event.data);
            const newPhase = update.phase;
            if (newPhase && newPhase.userId === user.id) {
                setPhase(newPhase);
            }
            const userDevice = update.userDevice;
            if (userDevice && userDevice.userId === user.id) {
                setUserDevice(userDevice);
            }
        }

        webSocket.addEventListener("message", updateListener);

        return function cleanup() {
            webSocket.removeEventListener("message", updateListener);
        };
    }, [webSocket, setPhase, setUserDevice, user.id]);

    const analytics = useMemo(
        () =>
            Analytics({
                app: "esa",
                plugins: [WebsocketAnalytics({webSocket})],
            }),
        [webSocket],
    );

    const getServerTime = useMemo(() => () => Date.now() - (timeDelta ?? 0), [timeDelta]);

    useEffect(() => {
        const error = localStorage.getItem("reactError");

        if (connected && error) {
            analytics.track("ReactError", {error}).then(done => localStorage.removeItem("reactError"));
        }
    }, [connected, analytics]);

    const context = useMemo<AppContextType>(
        () => ({
            ...{
                webSocket,
                analytics,
                user,
                userDevice,
                phase,
                refresh,
                selectedDeviceIds,
                setSelectedDeviceIds,
                connected,
                setConnected,
                getServerTime,
            },
        }),
        [
            webSocket,
            analytics,
            user,
            userDevice,
            phase,
            refresh,
            selectedDeviceIds,
            setSelectedDeviceIds,
            connected,
            setConnected,
            getServerTime,
        ],
    );

    const lastUsedTheme = useMemo(() => getLastUsedTheme(), []);

    useEffect(() => {
        if (connected) {
            refresh();
        }
    }, [connected, refresh]);

    useEffect(() => {
        analytics.identify(user.id).then();
    }, [user, analytics]);

    return !timeDelta || user === userPlaceholder || phase === phasePlaceholder ? (
        <MuiThemeProvider theme={lastUsedTheme}>
            <StylesProvider injectFirst>
                <CssBaseline />
                <LoadingScreen message="Connecting to server..." />
            </StylesProvider>
        </MuiThemeProvider>
    ) : (
        <AppContext.Provider value={context}>{props.children}</AppContext.Provider>
    );
}
