import {SettingsDefinitions} from "./settingsTypes";
import {FormatterBool} from "../components/FormatterCheckbox";
import {FormatterSelect} from "../components/FormatterSelect";
import {FormatterInput} from "../components/FormatterInput";
import {FormatterRadio} from "../components/FormatterRadio";
import {ShotColors, shotDistinctions} from "./targetTypes";
import {ShotLimit} from "../components/ShotLimit";
import {FormatterColor} from "../components/FormatterColor";
import {CustomKeyFormatter} from "../components/CustomKeyFormatter";
import {dateFormats, timeFormats} from "./customSelectables";
import {FormatterSlider} from "../components/FormatterSlider";
import {FormatterDeviceCalibration} from "../components/FormatterDeviceCalibration";
import {ConstantsStringCommon} from "../../../../api/generated/esa";
import {FormatterThemes} from "../components/FormatterThemes";

export const definition: SettingsDefinitions = {
    Global: {
        app: {
            render: FormatterInput,
            roles: [],
        },
        lang: {
            render: FormatterSelect,
            selectables: ["en", "de"],
            description: true,
        },
        theme: {
            render: FormatterThemes,
            description: true,
        },
        sounds: {
            render: FormatterBool,
            roles: [],
        },
        units: {
            render: FormatterRadio,
            selectables: ["Metric", "Imperial"],
            description: true,
        },
        dateFormat: {
            render: CustomKeyFormatter,
            selectables: dateFormats,
            description: true,
        },

        timeFormat: {
            render: CustomKeyFormatter,
            selectables: timeFormats,
            description: true,
        },
    },
    Components: {
        Lomah: {
            precision: {
                render: FormatterSlider,
                max: 3,
                step: 1,
                min: 0,
                description: true,
            },
            mpi: {render: FormatterBool, description: true},
            Table: {
                identificationNumber: {
                    render: FormatterBool,
                    title: true,
                    description: true,
                    disabled: true,
                },
                score: {render: FormatterBool, disabled: true},
                delay: {render: FormatterBool},
                toCenter: {render: FormatterBool},
                directionArrows: {render: FormatterBool},
                coordinates: {render: FormatterBool},
                measurementUnit: {
                    render: FormatterBool,
                    description: true,
                },
                fontLarge: {
                    render: FormatterBool,
                    description: true,
                },
            },
            Target: {
                shotDistinction: {
                    render: FormatterSelect,
                    title: true,
                    selectables: shotDistinctions,
                    description: true,
                },
                shotColor: {render: FormatterColor, selectables: ShotColors, description: true},
                shotNumbers: {render: FormatterBool, description: true},
                shotLimit: {render: ShotLimit, description: true},
                shotBlink: {render: FormatterBool, roles: []},
            },
        },
        Timer: {
            allowPause: {render: FormatterBool, roles: [ConstantsStringCommon.ROLE_ADMIN]},
            allowStop: {render: FormatterBool, roles: [ConstantsStringCommon.ROLE_ADMIN]},
            warmUpSec: {render: FormatterSlider, max: 10, step: 0.2, min: 0, description: true},
        },
    },
    Sidebar: {
        position: {
            render: FormatterSelect,
            selectables: ["none", "left", "right"],
            roles: ["RANGEADMIN"],
        },
    },
    DeviceCalibration: {
        config: {
            render: FormatterDeviceCalibration,
            roles: [ConstantsStringCommon.ROLE_ADMIN],
        },
    },
};
