import React from "react";
import Typography from "@material-ui/core/Typography";
import styles from "../common/NavigationBar.module.css";
import {translation} from "../i18n";
import buildVersion from "../../resources/build-version.json";
import {LIFECYCLE} from "../../apps/Components";

export default function BuildVersion() {
    return (
        <Typography id="build_version" variant="caption" noWrap className={styles.version}>
            <Typography variant="caption" color="primary" noWrap style={{textTransform: "uppercase"}}>
                {LIFECYCLE}
            </Typography>
            <span className={styles.toolbarSubtitle}>
                {translation("title.build_version", {version: buildVersion.id})}
            </span>
        </Typography>
    );
}
