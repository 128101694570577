import {ConstantsStringCommon, Hit, PhaseType, ScoringType} from "../../../api/generated/esa";
import {ARHits} from "./hitsMock/AR/hits";
import {APHits} from "./hitsMock/AP/hits";
import {DJV2CHits} from "./hitsMock/DJV2C/hits";

export const userMock = {
    id: ConstantsStringCommon.EMPTY_USER_ID,
    name: "demo",
    description: "none",
    roles: ["USER"],
    meta: {},
    firstName: "Demo",
    lastName: "User",
    yearOfBirth: 1970,
    competitiveClass: "Senioren I männlich (70)",
    country: "EU",
};

export const deviceMock = {
    deviceId: 1,
    deviceAlias: "Lane 1",
    userId: userMock.id,
    userName: userMock.name,
};

export type MockHit = {
    x: number;
    y: number;
    score: number;
};

export interface FullMockHit extends MockHit {
    timeStamp: number;
}

export interface ParsedMockHit extends MockHit {
    displayScore: string;
}

const AR_hits = ARHits.map((it: MockHit) => {
    return {x: it.x, y: it.y, score: it.score, displayScore: it.score.toFixed(2)};
});

const AP_hits = APHits.map((it: MockHit) => {
    return {x: it.x, y: it.y, score: it.score, displayScore: it.score.toFixed(2)};
});

const DJV2C_hits = DJV2CHits.map((it: MockHit) => {
    return {x: it.x, y: it.y, score: it.score, displayScore: it.score.toFixed(2)};
});

export const hitsMock: {[key: string]: ParsedMockHit[]} = {
    AR: AR_hits,
    p25_precision: AP_hits,
    DJV2C: DJV2C_hits,
};

const sightingHits = 5;
const qualificationHits = 20;

export const hitsHistory1: Hit[] = ARHits.slice(0, sightingHits).map((it: FullMockHit, index, arr) => {
    const n = arr.length - index;
    return createHits("9b326409-62db-48c0-b853-3d01f9416dac", it, n);
});

export const hitsHistory2: Hit[] = ARHits.slice(sightingHits, sightingHits * 2).map((it: FullMockHit, index, arr) => {
    const n = arr.length - index;
    return createHits("9b326409-62db-48c0-b853-3d01f9416dac", it, n);
});

export const hitsHistory3: Hit[] = ARHits.slice(sightingHits * 2, sightingHits * 3).map(
    (it: FullMockHit, index, arr) => {
        const n = arr.length - index;
        return createHits("9b326409-62db-48c0-b853-3d01f9416dac", it, n);
    },
);

export const hitsHistory1Qualification: Hit[] = ARHits.slice(0, qualificationHits).map(
    (it: FullMockHit, index, arr) => {
        const n = arr.length - index;
        return createHits("e6b678bd-5b74-42fe-8129-38a985b02e72", it, n);
    },
);

export const hitsHistory2Qualification: Hit[] = ARHits.slice(qualificationHits, qualificationHits * 2).map(
    (it: FullMockHit, index, arr) => {
        const n = arr.length - index;
        return createHits("e6b678bd-5b74-42fe-8129-38a985b02e72", it, n);
    },
);

export const hitsHistory3Qualification: Hit[] = ARHits.slice(qualificationHits * 2, qualificationHits * 3).map(
    (it: FullMockHit, index, arr) => {
        const n = arr.length - index;
        return createHits("e6b678bd-5b74-42fe-8129-38a985b02e72", it, n);
    },
);

function createHits(phaseId: string, hit: FullMockHit, n: number): Hit {
    return {
        ...hit,
        displayScore: hit.score.toFixed(2),
        n: n,
        phaseId: phaseId,
        deviceId: 141625223282689,
        id: phaseId + `_${n}`,
    };
}

export const phaseHistory1 = {
    id: "9b326409-62db-48c0-b853-3d01f9416dac",
    userId: "db3a8db5-b2df-4ae5-b055-6e00c22022f3",
    deviceId: 141625223282689,
    name: "Sighting",
    sessionId: "5fa79ca1-9d7a-4df7-b51f-d436467d5d88",
    disciplineName: "AR60M",
    unlimited: false,
    type: PhaseType.Regular,
    maxShots: 2147483647,
    series: 1,
    nextPhase: 1,
    firstWarningAt: undefined,
    secondWarningAt: undefined,
    duration: 15000,
    scoring: ScoringType.DecimalIgnore,
    targetName: "AR",
    targetImage: "generated",
    targetRadius: 22.75,
    defaultCaliberMm: 4.5,
    ringStep: 2.5,
    innerTenRadius: -0.25,
    targetCardWidth: 80,
    targetCardHeight: 80,
    bullsEye: 4.0,
    day: 11,
    month: 2,
    year: 2021,
    created: 1615464033879,
    start: 1615464033879,
    end: 1615464933879,
    paused: undefined,
    currentShots: sightingHits,
    decimalScore: 75.4,
    fullRingScore: undefined,
    started: true,
    notPaused: true,
    weaponId: "b405cd1e-1830-4d6f-96d8-8de573219893",
    periods: [],
};

export const phaseHistory2 = {
    id: "e6b678bd-5b74-42fe-8129-38a985b02e72",
    userId: "db3a8db5-b2df-4ae5-b055-6e00c22022f3",
    deviceId: 141625223282689,
    name: "Qualification",
    sessionId: "5fa79ca1-9d7a-4df7-b51f-d436467d5d88",
    disciplineName: "AR60M",
    unlimited: false,
    type: PhaseType.Regular,
    maxShots: 40,
    series: 4,
    nextPhase: 1,
    firstWarningAt: undefined,
    secondWarningAt: undefined,
    duration: 15000,
    scoring: ScoringType.DecimalIgnore,
    targetName: "AR",
    targetImage: "generated",
    targetRadius: 22.75,
    defaultCaliberMm: 4.5,
    ringStep: 2.5,
    innerTenRadius: -0.25,
    targetCardWidth: 80,
    targetCardHeight: 80,
    bullsEye: 4.0,
    day: 11,
    month: 2,
    year: 2021,
    created: 1615464933879,
    start: 1615464933879,
    end: 1615467933879,
    paused: undefined,
    currentShots: qualificationHits,
    decimalScore: 75.4,
    fullRingScore: undefined,
    started: true,
    notPaused: true,
    weaponId: "b405cd1e-1830-4d6f-96d8-8de573219893",
    periods: [],
};

export const trainingSessionsHistory1 = [
    {
        id: "5fa79ca1-9d7a-4df7-b51f-d436467d5d88",
        deviceId: 141625223282689,
        deviceAlias: "Lane 1",
        disciplineName: "AR60M",
        startTime: 1615464033879,
        decimalScore: 0,
        fullRingScore: undefined,
        phases: [phaseHistory1, phaseHistory2],
    },
];
