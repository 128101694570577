/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";
import {Path, PathFromJSON, PathToJSON} from "./";

/**
 *
 * @export
 * @interface WeaponIcon
 */
export interface WeaponIcon {
    /**
     *
     * @type {Array<Path>}
     * @memberof WeaponIcon
     */
    g?: Array<Path>;
}

export function WeaponIconFromJSON(json: any): WeaponIcon {
    return WeaponIconFromJSONTyped(json, false);
}

export function WeaponIconFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeaponIcon {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        g: !exists(json, "g") ? undefined : (json["g"] as Array<any>).map(PathFromJSON),
    };
}

export function WeaponIconToJSON(value?: WeaponIcon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        g: value.g === undefined ? undefined : (value.g as Array<any>).map(PathToJSON),
    };
}
