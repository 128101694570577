import {Hit} from "../../../../api/generated/esa/models";

export function scrollToBottom(hit: Hit, table: HTMLElement | null) {
    hit &&
        window.requestAnimationFrame(() => {
            if (document.getElementById(`/${hit.id}`)) {
                table?.scrollTo(0, table.scrollHeight);
            } else {
                setTimeout(() => scrollToBottom(hit, table), 100);
            }
        });
}
