/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface CloudTarget
 */
export interface CloudTarget {
    /**
     *
     * @type {string}
     * @memberof CloudTarget
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof CloudTarget
     */
    targetImage: string;
    /**
     *
     * @type {string}
     * @memberof CloudTarget
     */
    targetImageMd5?: string;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    targetRadius: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    targetCardWidth: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    targetCardHeight: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    ringStep: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    scoreOnFirstRing: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    innerTenRadius: number;
    /**
     *
     * @type {boolean}
     * @memberof CloudTarget
     */
    disableInnerTen: boolean;
    /**
     *
     * @type {string}
     * @memberof CloudTarget
     */
    weaponType: string;
    /**
     *
     * @type {string}
     * @memberof CloudTarget
     */
    defaultCaliber: string;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    defaultCaliberMm: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    bullsEye: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    dpmX: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    dpmY: number;
    /**
     *
     * @type {boolean}
     * @memberof CloudTarget
     */
    hitZones: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CloudTarget
     */
    includeNinthRingNumbering: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CloudTarget
     */
    whiteCenter: boolean;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    horizontalLinesHeight?: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    horizontalLinesWidth?: number;
    /**
     *
     * @type {number}
     * @memberof CloudTarget
     */
    numberingOffset?: number;
}

export function CloudTargetFromJSON(json: any): CloudTarget {
    return CloudTargetFromJSONTyped(json, false);
}

export function CloudTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudTarget {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: json["name"],
        targetImage: json["targetImage"],
        targetImageMd5: !exists(json, "targetImageMd5") ? undefined : json["targetImageMd5"],
        targetRadius: json["targetRadius"],
        targetCardWidth: json["targetCardWidth"],
        targetCardHeight: json["targetCardHeight"],
        ringStep: json["ringStep"],
        scoreOnFirstRing: json["scoreOnFirstRing"],
        innerTenRadius: json["innerTenRadius"],
        disableInnerTen: json["disableInnerTen"],
        weaponType: json["weaponType"],
        defaultCaliber: json["defaultCaliber"],
        defaultCaliberMm: json["defaultCaliberMm"],
        bullsEye: json["bullsEye"],
        dpmX: json["dpmX"],
        dpmY: json["dpmY"],
        hitZones: json["hitZones"],
        includeNinthRingNumbering: json["includeNinthRingNumbering"],
        whiteCenter: json["whiteCenter"],
        horizontalLinesHeight: !exists(json, "horizontalLinesHeight") ? undefined : json["horizontalLinesHeight"],
        horizontalLinesWidth: !exists(json, "horizontalLinesWidth") ? undefined : json["horizontalLinesWidth"],
        numberingOffset: !exists(json, "numberingOffset") ? undefined : json["numberingOffset"],
    };
}

export function CloudTargetToJSON(value?: CloudTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        targetImage: value.targetImage,
        targetImageMd5: value.targetImageMd5,
        targetRadius: value.targetRadius,
        targetCardWidth: value.targetCardWidth,
        targetCardHeight: value.targetCardHeight,
        ringStep: value.ringStep,
        scoreOnFirstRing: value.scoreOnFirstRing,
        innerTenRadius: value.innerTenRadius,
        disableInnerTen: value.disableInnerTen,
        weaponType: value.weaponType,
        defaultCaliber: value.defaultCaliber,
        defaultCaliberMm: value.defaultCaliberMm,
        bullsEye: value.bullsEye,
        dpmX: value.dpmX,
        dpmY: value.dpmY,
        hitZones: value.hitZones,
        includeNinthRingNumbering: value.includeNinthRingNumbering,
        whiteCenter: value.whiteCenter,
        horizontalLinesHeight: value.horizontalLinesHeight,
        horizontalLinesWidth: value.horizontalLinesWidth,
        numberingOffset: value.numberingOffset,
    };
}
