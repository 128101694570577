import React, {useContext, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {Grid, ListItem, ListItemIcon, ListItemText, SvgIcon} from "@material-ui/core";
import CustomDialog from "../common/CustomDialog";
import styles from "./Support.module.css";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import {AppSettings} from "../AppSettingsProvider";
import {ReactComponent as Logo} from "../../resources/icons/packs/custom/filled/INTARSO-Logo-White.svg";
import {translation} from "../i18n";
import enSupportLogo from "./support_en.png";
import deSupportLogo from "./support_de.png";

export function Support() {
    const {Global} = useContext(AppSettings);

    const [open, setOpen] = useState(false);

    let supportUrl = `https://www.intarso.com/${Global.lang}/technical-support/`;

    return (
        <>
            <ListItem id="about_control_button" button onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <ContactSupportIcon />
                </ListItemIcon>
                <ListItemText primary={translation("title.support")} />
            </ListItem>
            <CustomDialog
                dialogTitle={<Typography variant="h5">{translation("title.support")}</Typography>}
                {...{setOpen, open}}>
                <Grid className={styles.container}>
                    <Grid item className={styles.overviewLogoContainer}>
                        <SvgIcon component={Logo} viewBox="0 0 793 206.2" />
                    </Grid>
                    <Grid item className={styles.bodyText}>
                        <div>
                            {translation("message.support_text", {
                                supportUrl: supportUrl,
                            })}
                        </div>
                    </Grid>
                    <Grid item className={styles.qrCode}>
                        <div className={styles.qrCode}>
                            <img src={Global.lang === "en" ? enSupportLogo : deSupportLogo} alt="Logo" />
                        </div>
                    </Grid>
                </Grid>
            </CustomDialog>
        </>
    );
}
