import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Divider, LinearProgress, List, ListItem, ListItemText} from "@material-ui/core";
import clsx from "clsx";
import style from "./SelectView.module.css";
import {
    ConstantsNumberEsa,
    EsaControllerApi,
    UserDevice,
    WebSocketMessage,
    WebSocketMessageActionActionEnum,
} from "../../api/generated/esa";
import {AppContext} from "../AppContextProvider";
import styles from "../login-dialog/LoginDialog.module.css";
import {Aim} from "../icons/generated/military/outlined";
import {ReactComponent as Question} from "@fortawesome/fontawesome-free/svgs/solid/question.svg";
import {Alert} from "@material-ui/lab";
import {translation} from "../i18n";
import {NotificationContext} from "../NotificationContextProvider";

const esaControllerApi = new EsaControllerApi();

const LAST_SELECTED_DEVICE_KEY = "lastSelectedDevice";
export const DEFAULT_DEVICE_KEY = "defaultDevice";
export const DISABLE_DEVICE_SELECTION = "disableDeviceSelection";
export const TABLET_NAME = "shootersMonitorName";

export function SelectLane() {
    const [devices, setDevices] = useState<UserDevice[]>([]);
    const [device, setDevice] = useState<UserDevice | null>(null);
    const {user, webSocket, connected} = useContext(AppContext);
    const [awaitingDevice, setAwaitingDevice] = useState<UserDevice | null>(null);
    const {notify} = useContext(NotificationContext);
    const disableDeviceSelection = localStorage.getItem(DISABLE_DEVICE_SELECTION) !== null;
    const mounted = React.useRef(true);
    const [reservationTime, setReservationTime] = useState<number>(ConstantsNumberEsa.LANE_RESERVATION_TIMEOUT_SEC);
    const reservationTimeOutDisabled = reservationTime === 0;

    useEffect(() => {
        esaControllerApi.getLaneReservationTimeout().then(output => setReservationTime(Number(output)));
    }, []);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const loadDevices = useCallback(() => {
        esaControllerApi.getUserDevices().then(updatedDevices => {
            if (!mounted.current) return;

            const defaultDeviceId = parseInt(
                localStorage.getItem(DEFAULT_DEVICE_KEY) ?? localStorage.getItem(LAST_SELECTED_DEVICE_KEY) ?? "-1",
            );
            const defaultDevice = updatedDevices.find(d => d.deviceId === defaultDeviceId) ?? null;
            if (defaultDevice && disableDeviceSelection) {
                select(defaultDevice);
            }
            setDevices(updatedDevices);
            setDevice(device => {
                if (device !== null) {
                    return device;
                } else {
                    return defaultDevice;
                }
            });
        });
    }, [setDevices, setDevice, disableDeviceSelection]);

    useEffect(() => {
        function messageListener(messageEvent: MessageEvent) {
            const json: WebSocketMessage = JSON.parse(messageEvent.data);

            if (json.deviceEsaData || json.userDevice) {
                loadDevices();
            }
            if (
                awaitingDevice !== null &&
                json.userDevice?.deviceId === awaitingDevice?.deviceId &&
                !json.userDevice?.userId
            ) {
                setAwaitingDevice(null);
                select(device);
            }
            if (
                json.action?.action === WebSocketMessageActionActionEnum.REASSIGNDECLINED &&
                json.action.user === user.id
            ) {
                notify("error", "message.lane_request_declined", {translationValues: {user: awaitingDevice?.userName}});
                setAwaitingDevice(null);
            }
        }

        webSocket.addEventListener("message", messageListener);
        return function cleanup() {
            webSocket.removeEventListener("message", messageListener);
        };
    }, [device, notify, user.id, webSocket, awaitingDevice, setAwaitingDevice, loadDevices, setDevices]);

    useEffect(() => {
        if (connected) {
            loadDevices();
        }
    }, [connected, loadDevices]);

    if (disableDeviceSelection) {
        return <Alert severity="info">{translation("message.wait_device_connection")}</Alert>;
    } else {
        return (
            <>
                {awaitingDevice && !reservationTimeOutDisabled && (
                    <WaitingForUser {...{awaitingDevice, setAwaitingDevice, reservationTime}} />
                )}
                <List id="lane_list">
                    {devices.map(it => {
                        return (
                            <React.Fragment key={it.deviceId}>
                                <ListItem
                                    button
                                    className={clsx(device === it && style.listItemSelected)}
                                    onClick={() => setDevice(it)}>
                                    <ListItemText primary={it.deviceAlias} />
                                    <ListItemText
                                        style={{textAlign: "right"}}
                                        secondary={(it?.userId && it.userId !== user.id && it?.userName) || ""}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
                </List>
                <SelectButton {...{device, awaitingDevice, setAwaitingDevice, reservationTimeOutDisabled}} />
            </>
        );
    }
}

function select(device: UserDevice | null) {
    return (
        device &&
        esaControllerApi.assign({deviceId: device.deviceId}).then(() => {
            device && localStorage.setItem(LAST_SELECTED_DEVICE_KEY, device.deviceId.toString());
        })
    );
}

function SelectButton(props: {
    device: UserDevice | null;
    awaitingDevice: UserDevice | null;
    setAwaitingDevice: Setter<UserDevice | null>;
    reservationTimeOutDisabled: boolean;
}) {
    const {user} = useContext(AppContext);
    const {device, awaitingDevice, setAwaitingDevice, reservationTimeOutDisabled} = props;

    const laneBusy = device?.userId && device.userId !== user.id;

    if (laneBusy && reservationTimeOutDisabled && awaitingDevice === null) {
        return <></>;
    } else if (laneBusy && awaitingDevice === null) {
        return (
            <Button
                id="ask_for_lane"
                size="large"
                onClick={() => setAwaitingDevice(device)}
                disabled={device === null}
                startIcon={<Question className="svgIcon" />}
                variant="contained"
                color="primary">
                <b>{translation("button.ask")}</b>
            </Button>
        );
    } else if (laneBusy) {
        return (
            <Button
                id="ask_for_lane_cancel"
                size="large"
                onClick={() => setAwaitingDevice(null)}
                disabled={device === null}
                startIcon={<Question className="svgIcon" />}
                variant="outlined"
                color="primary">
                <b>{translation("button.cancel")}</b>
            </Button>
        );
    } else {
        return (
            <Button
                id="select_lane"
                size="large"
                onClick={() => select(device)}
                disabled={device === null}
                className={styles.startButton}
                startIcon={<Aim />}
                variant="contained"
                color="secondary">
                <b>{translation("button.select_lane")}</b>
            </Button>
        );
    }
}

function WaitingForUser(props: {
    awaitingDevice: UserDevice;
    setAwaitingDevice: Setter<UserDevice | null>;
    reservationTime: number;
}) {
    const {awaitingDevice, setAwaitingDevice, reservationTime} = props;
    const [progress, setProgress] = useState(0);
    const [ticket, setTicket] = useState<string | null>();

    useEffect(() => {
        awaitingDevice.userId &&
            awaitingDevice.deviceId &&
            esaControllerApi
                .ask({from: awaitingDevice.userId, device: awaitingDevice.deviceId})
                .then(setTicket)
                .catch(() => setAwaitingDevice(null));
    }, [awaitingDevice, setAwaitingDevice]);

    useEffect(() => {
        const started = Date.now();
        const timer = setInterval(async () => {
            const now = Date.now();
            const delta = now - started;
            const time = (reservationTime + 1) * 1000;
            setProgress(Math.min((delta / time) * 100, 100));
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [reservationTime]);

    useEffect(() => {
        if (progress === 100) {
            awaitingDevice.userId &&
                awaitingDevice.deviceId &&
                ticket &&
                esaControllerApi.reassign({from: awaitingDevice.userId, device: awaitingDevice.deviceId, ticket});
            setAwaitingDevice(null);
        }
    }, [progress, awaitingDevice, ticket, setAwaitingDevice]);

    return (
        <>
            <Alert severity="info">
                {translation("message.wait_device_release", {
                    user: awaitingDevice.userName,
                    device: awaitingDevice.deviceAlias,
                })}
            </Alert>
            <LinearProgress variant="determinate" value={progress} />
        </>
    );
}
