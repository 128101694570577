import convert from "convert-units";
import {Units} from "../components/Edit";

export function convertMM(value: string | number, units: Units, fixed?: number): number | string {
    if (units === "Metric") {
        return fixed ? Number(value).toFixed(fixed) : +value;
    } else
        return Number(
            convert(+value)
                .from("mm")
                .to("in"),
        ).toFixed(fixed);
}
