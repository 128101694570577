import React, {ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {LayoutManager} from "../../components/LayoutManager";
import {Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {LayoutComponents, Themes} from "../Components";
import {AppContext, phasePlaceholder} from "../../components/AppContextProvider";
import AppSettingsProvider, {AppSettings, AppSettingsControl} from "../../components/AppSettingsProvider";
import logo from "../../resources/icons/packs/custom/filled/INTARSO-Logo-Symbol-Yellow.svg";
import AppErrorHandler from "../../components/AppErrorHandler";
import JoyrideContextProvider from "../../components/joyride/JoyrideContext";
import {Placeholder} from "../../components/Placeholder";
import {
    ConstantsNumberEsa,
    ConstantsStringCommon,
    Discipline,
    Hit,
    Phase,
    PhaseDescription,
    PhasePeriod,
    PhasePeriodClassNameEnum,
    PhasePeriodTypeEnum,
    PhaseType,
    Target,
    TrainingSession,
    User,
} from "../../api/generated/esa";
import LoginDialog from "../../components/login-dialog/LoginDialog";
import {IntlProvider} from "react-intl";
import {ThemeWrapper} from "../../components/themes/ThemeWarpper";
import {placeholderHeartbeatSocket} from "../../components/HeartbeatSocket";
import {v4 as uuid} from "uuid";
import {deviceMock, hitsMock, userMock} from "./mockdata/DataMock";
import Popup from "../../components/common/Popup";
import {handleI18nError, translation, TranslationKey} from "../../components/i18n";
import NotificationContextProvider from "../../components/NotificationContextProvider";
import {EnhancedStep} from "../../components/joyride/Joyride.utils";
import {isTimeEnded} from "../../components/common/utils";
import AppLayoutProviderEsa from "../../components/AppLayoutProvider.esa";
import {Messages} from "../../Internationalization";
import {ServerlessRide} from "../../components/joyride/rides/ServerlessRide";
import mockSettings from "./mockdata/api/settings/mockSettings.json";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

const BOUNCER_URL = `https://demo.intarso.com/.netlify/functions/bouncer`;

export interface TrainingSessionMock extends TrainingSession {
    phases: Phase[];
}

type Phases = Record<string, Record<string, Phase>>;

export interface AppContextTypeMock extends AppContextType {
    setUser: Setter<User>;
    setPhase: Setter<Phase>;
    select: (disciplineName: string, phaseN: number, targetName: string, weaponId: string) => void;
    hit: () => void;
    hits: Hit[];
    trainingSessions: TrainingSessionMock[];
    setTrainingSessions: Setter<TrainingSessionMock[]>;
    bookDemoTranslationKey: [TranslationKey, TranslationKey] | null;
    setBookDemoTranslationKey: Setter<[TranslationKey, TranslationKey] | null>;
    setPhases: Setter<Phases>;
}

export default function PortalMock(props: {children?: ReactNode}) {
    const [bookDemoTranslationKey, setBookDemoTranslationKey] = useState<[TranslationKey, TranslationKey] | null>(null);
    const [user, setUser] = useState<User>(userMock);
    const [phase, setPhase] = useState<Phase>(phasePlaceholder);
    const [hits, setHits] = useState<Hit[]>([]);
    const [phases, setPhases] = useState<Phases>({});

    const [trainingSessions, setTrainingSessions] = useState<TrainingSessionMock[]>([]);
    const select = useMemo(
        () => (disciplineName: string, phaseN: number, targetName: string, weaponId: string) => {
            Promise.all([
                fetch(`${window.location.origin}/api/disciplines/${disciplineName}.json`).then(response =>
                    response.json(),
                ),
                fetch(`${window.location.origin}/api/targets/${targetName}`).then(response => response.json()),
            ]).then(([discipline, target]: [Discipline, Target]) => {
                setPhase(currentPhase => {
                    let trainingId;
                    if (
                        currentPhase.disciplineName === disciplineName &&
                        phaseN > 0 &&
                        currentPhase.disciplineName === discipline.name
                    ) {
                        trainingId = currentPhase.sessionId;
                    } else {
                        trainingId = uuid();
                    }

                    const description = discipline.phases[phaseN];
                    let nextPhase;
                    if (discipline.phases.length - 1 > phaseN) {
                        nextPhase = phaseN + 1;
                    } else {
                        nextPhase = undefined;
                    }
                    const duration = description.duration * 60 * 1000;
                    return {
                        id: uuid(),
                        userId: userMock.id,
                        deviceId: deviceMock.deviceId,
                        name: description.name,
                        sessionId: trainingId,
                        disciplineName: discipline.name,
                        unlimited: duration === ConstantsNumberEsa.UNLIMITED_DURATION,
                        type: PhaseType.Regular,
                        maxShots: description.shots ?? 999,
                        series: description.series,
                        nextPhase: nextPhase,
                        duration,
                        scoring: description.scoring,

                        targetName: targetName,
                        targetImage: target.targetImage,
                        targetRadius: target.targetRadius,
                        defaultCaliberMm: target.defaultCaliberMm,

                        ringStep: target.ringStep,
                        innerTenRadius: target.innerTenRadius,
                        targetCardWidth: target.targetCardWidth,
                        targetCardHeight: target.targetCardHeight,
                        bullsEye: target.bullsEye,

                        day: 0,
                        month: 0,
                        year: 0,

                        created: Date.now(),
                        start: undefined,
                        end: undefined,
                        paused: duration,

                        currentShots: 0,
                        decimalScore: 0,

                        periods: generateWarningList(description, duration),

                        started: false,
                        notPaused: false,

                        weaponId,
                    };
                });
            });
        },
        [setPhase],
    );

    const addUp = (previousValue: number, currentValue: number) => previousValue + currentValue;

    useEffect(() => {
        setTrainingSessions(() => {
            return Object.entries(phases).map(([sessionId, phaseMap]) => {
                const phaseList = Object.values(phaseMap);

                const parsedFullRingScores = phaseList.map(_ => _.fullRingScore).filter(_ => _) as number[];
                const fullRingScore = parsedFullRingScores.length && parsedFullRingScores.reduce(addUp);
                const decimalScore = phaseList.map(_ => _.decimalScore).reduce(addUp);

                return {
                    id: sessionId,
                    deviceId: deviceMock.deviceId,
                    deviceAlias: deviceMock.deviceAlias,
                    disciplineName: phaseList[phaseList.length - 1].disciplineName,
                    startTime: phaseList[0].start,
                    decimalScore: decimalScore,
                    fullRingScore: fullRingScore,
                    phases: phaseList,
                } as TrainingSession;
            });
        });
    }, [phase?.disciplineName, phases, setTrainingSessions]);

    const hit = useMemo(
        () => () => {
            if (!canHit(phase.start, phase.paused, phase.end)) {
                console.error("warning.shot_without_phase");
            } else {
                setHits(hits => {
                    const currentShots = hits.filter(hit => hit.phaseId === phase.id).length + 1;
                    const phaseMockHits = hitsMock[phase.targetName];
                    const newHit = {
                        ...phaseMockHits[Math.floor(Math.random() * phaseMockHits.length)],
                        n: currentShots,
                        deviceId: deviceMock.deviceId,
                        timeStamp: +new Date(),
                        phaseId: phase.id,
                        id: `${phase.id}_${currentShots}`,
                    };

                    setPhase(phase => {
                        const decimalScore = Number((phase.decimalScore + newHit.score).toFixed(1));
                        const end = currentShots === phase.maxShots ? Date.now() : phase.end;

                        if (phase.start) {
                            return {
                                ...phase,
                                currentShots,
                                decimalScore,
                                end,
                            };
                        } else {
                            // Start on first hit
                            const shouldStart = Date.now();
                            const endAt = shouldStart + (phase.paused ?? 0);
                            return {
                                ...startedPhase(phase, shouldStart, endAt),
                                currentShots,
                                decimalScore,
                            };
                        }
                    });

                    return [newHit, ...hits];
                });
            }
        },
        [phase.targetName, phase.id, phase.end, phase.start, phase.paused],
    );

    const developmentUA = "UA-212817126-1";
    const UA = process.env.REACT_APP_SERVERLESS_ANALYTICS_UA || developmentUA;

    const analytics = Analytics({
        app: "serverless",
        plugins: [
            googleAnalytics({
                trackingId: UA.toString(),
            }),
        ],
    });

    const mockContext = useMemo<AppContextTypeMock>(
        () => ({
            webSocket: placeholderHeartbeatSocket(),
            analytics: analytics,
            user: user,
            userDevice: deviceMock,
            phase: phase,
            refresh: () => {},
            selectedDeviceIds: [],
            setSelectedDeviceIds: () => {},
            connected: true,
            setConnected: () => {},
            getServerTime: () => new Date().getTime(),
            setTrainingSessions: setTrainingSessions,
            setPhases: setPhases,
            bookDemoTranslationKey,
            setBookDemoTranslationKey,
            ...{setUser, setPhase: setPhase, select, hit, hits, trainingSessions},
        }),
        [
            user,
            phase,
            select,
            hit,
            hits,
            trainingSessions,
            bookDemoTranslationKey,
            setBookDemoTranslationKey,
            setPhases,
            analytics,
        ],
    );

    const LocalSettingsKey = "localSettings";

    function setLocalSettings(settings: any) {
        sessionStorage.setItem(LocalSettingsKey, JSON.stringify(settings));
        return Promise.resolve();
    }
    function getLocalSettings(): Promise<string> {
        const settings = sessionStorage.getItem(LocalSettingsKey);
        if (settings) {
            return Promise.resolve(settings);
        } else {
            return Promise.resolve(JSON.stringify(mockSettings));
        }
    }

    return (
        <AppContext.Provider value={mockContext}>
            <AppSettingsProvider getSettings={getLocalSettings} setSettings={setLocalSettings}>
                <PageWrappers />
            </AppSettingsProvider>
        </AppContext.Provider>
    );
}

function PageWrappers() {
    const {
        Global: {lang, theme},
    } = useContext(AppSettings);

    const {bookDemoTranslationKey, setBookDemoTranslationKey} = useContext(AppContext) as AppContextTypeMock;

    return (
        <ThemeWrapper prefix="dev" theme={Themes.get(theme)}>
            <IntlProvider onError={handleI18nError} locale={lang} messages={Messages.get(lang)}>
                <NotificationContextProvider>
                    <AppErrorHandler />
                    <CssBaseline />
                    <BookDemo {...{bookDemoTranslationKey, setBookDemoTranslationKey}} />
                    <PageContent />
                </NotificationContextProvider>
            </IntlProvider>
        </ThemeWrapper>
    );
}

function BookDemo(props: {
    bookDemoTranslationKey: [TranslationKey, TranslationKey] | null;
    setBookDemoTranslationKey: Setter<[TranslationKey, TranslationKey] | null>;
}) {
    const {bookDemoTranslationKey, setBookDemoTranslationKey} = props;
    const {
        Global: {lang},
    } = useContext(AppSettings);

    if (bookDemoTranslationKey) {
        const [titleKey, textKey] = bookDemoTranslationKey;
        return (
            <Popup
                onClick={() => {
                    if (lang === "de") {
                        window.open("https://intarso.activehosted.com/f/44");
                    } else {
                        window.open("https://intarso.activehosted.com/f/43");
                    }
                }}
                onClose={() => setBookDemoTranslationKey(null)}
                show={true}
                title={titleKey}
                okButton="label.action.book_demo">
                {translation(textKey)}
                <p>
                    <i>{translation("demo.request")}</i>
                </p>
            </Popup>
        );
    } else {
        return <></>;
    }
}

function PageContent() {
    const ApplicationHeader: React.JSXElementConstructor<{}> = LayoutComponents.get("ApplicationHeader") ?? Placeholder;
    const {
        user: {id: userId},
        setUser,
        setBookDemoTranslationKey,
    } = useContext(AppContext) as AppContextTypeMock;
    const [admitted, setAdmitted] = useState<boolean | null>(null);
    const steps: EnhancedStep[] = ServerlessRide;

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const ticket = params.get("ticket") ?? sessionStorage.getItem("ticket");
        ticket && sessionStorage.setItem("ticket", ticket);
        fetch(`${BOUNCER_URL}?ticket=${ticket}`).then(response => {
            setAdmitted(response.ok);
        });
    }, []);

    const notLoggedIn = userId === ConstantsStringCommon.EMPTY_USER_ID;
    const {setLang} = useContext(AppSettingsControl);
    const {
        Global: {lang},
    } = useContext(AppSettings);

    useEffect(() => {
        const {location} = window;
        const {search} = location;
        const params = new URLSearchParams(search);
        const urlLang = params.get("lang");
        const permittedLanguages = ["en", "de"];
        if (urlLang && permittedLanguages.includes(urlLang) && notLoggedIn) {
            setLang(urlLang);
        }
        // eslint-disable-next-line
    }, []);

    //if user not logged in redirect to start URL
    useEffect(() => {
        if (notLoggedIn) {
            window.location.href = "#";
        }
    }, [notLoggedIn]);

    if (admitted === false) {
        return (
            <Dialog id="confirmation_dialog" disableEnforceFocus open={true}>
                <DialogTitle>{translation("demo.book_title")}</DialogTitle>
                <DialogContent>
                    <img
                        src={logo}
                        alt="INTARSO"
                        style={{paddingRight: "var(--devSpacing3)", maxWidth: 80, float: "left", margin: "auto"}}
                    />
                    {translation("demo.book_content")}
                </DialogContent>
                <DialogActions>
                    <Button
                        id="popup_ok_action"
                        variant="contained"
                        href={
                            lang === "de"
                                ? "https://www.intarso.com/de/buch-demo-basic/"
                                : "https://www.intarso.com/en/book-demo-basic/"
                        }
                        color="primary">
                        {translation("demo.book_button")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else if (notLoggedIn) {
        return (
            <>
                <LoginDialog
                    onLogin={() => {
                        const params = new URLSearchParams(window.location.search);
                        const ticket = params.get("ticket") ?? sessionStorage.getItem("ticket");
                        ticket && sessionStorage.setItem("ticket", ticket);
                        fetch(`${BOUNCER_URL}?ticket=${ticket}&punch=true`).then(response => {
                            setAdmitted(response.ok);
                            if (!response.ok) {
                                window.location.href = "/";
                            }
                        });

                        setUser(user => ({...user, id: "demo", secretType: undefined}));
                    }}
                    onCreate={() => {
                        setBookDemoTranslationKey(["title.create_user", "demo.create_user"]);
                    }}
                />
            </>
        );
    } else if (admitted === true) {
        return (
            <JoyrideContextProvider started {...{steps}}>
                <AppLayoutProviderEsa>
                    <ApplicationHeader />
                    <LayoutManager
                        componentsWithSettings={["Timer", "LiveTable", "LiveTarget", "ReviewTable", "ReviewTarget"]}
                    />
                </AppLayoutProviderEsa>
            </JoyrideContextProvider>
        );
    } else {
        return <></>;
    }
}

export function canHit(start?: number, paused?: number, end?: number) {
    return !start || (start <= Date.now() && !paused && !isTimeEnded(end));
}

export function startedPhase(it: Phase, startAt: number, endAt: number) {
    return {...it, paused: undefined, start: startAt, end: endAt, started: true, notPaused: true};
}

/**
 * copied from DisciplineService#generateWarningList
 */
function generateWarningList(description: PhaseDescription, duration: number): Array<PhasePeriod> {
    const firstWarning = {
        className: PhasePeriodClassNameEnum.Warning,
        type: PhasePeriodTypeEnum.FirstWarning,
        time: description.firstWarningAt ? duration - description.firstWarningAt : 0,
    };

    const secondWarning = {
        className: PhasePeriodClassNameEnum.Error,
        type: PhasePeriodTypeEnum.SecondWarning,
        time: description.secondWarningAt ? duration - description.secondWarningAt : 0,
    };

    return [firstWarning, secondWarning].filter(it => it.time > 0);
}
