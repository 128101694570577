import React, {ReactNode} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {translation, TranslationKey} from "../i18n";
import styles from "./CustomDialog.module.css";
import clsx from "clsx";
import {PrimitiveType} from "intl-messageformat";

interface Props {
    title: TranslationKey | JSX.Element;
    show: boolean;
    onClose: () => void;
    closeButton?: TranslationKey;
    okButton: TranslationKey | JSX.Element;
    okButtonElement?: ReactNode;
    onClick?: () => void;
    okIcon?: ReactNode;
    okDisabled?: boolean;
    okClassName?: string;
    children?: ReactNode;
    extraButtons?: ReactNode;
    variant?: "wide" | "dialog";
}

export default function Popup(props: Props) {
    const {
        title,
        okClassName,
        onClose,
        closeButton,
        show,
        onClick,
        children,
        extraButtons,
        okButton,
        okButtonElement,
        okIcon,
        okDisabled,
        variant,
    } = props;

    const className = clsx(variant === "wide" && styles.widePopup, variant === "dialog" && styles.dialog);

    return (
        <Dialog
            id="confirmation_dialog"
            disableEnforceFocus
            onClose={onClose}
            open={show}
            PaperProps={{...{className}}}>
            <DialogTitle>{translateIfString(title)}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button id="popup_close_action" onClick={onClose}>
                    {translation(closeButton ?? "button.close")}
                </Button>

                {extraButtons}

                {okButtonElement ? (
                    okButtonElement
                ) : (
                    <Button
                        id="popup_ok_action"
                        variant="contained"
                        disabled={okDisabled}
                        onClick={onClick}
                        endIcon={okIcon}
                        className={okClassName}
                        color="primary">
                        {translateIfString(okButton)}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export function translateIfString(
    translationKeyOrElement: TranslationKey | JSX.Element,
    values: Record<string, PrimitiveType> | undefined = undefined,
) {
    if (typeof translationKeyOrElement === "object") {
        return translationKeyOrElement;
    } else {
        return translation(translationKeyOrElement, values);
    }
}
