import {Value} from "../types/settingsTypes";
import React, {ReactNode, useState} from "react";
import DropdownButton from "../../../common/DropdownButton";
import MenuItem from "@material-ui/core/MenuItem";
import {Grid} from "@material-ui/core";
import styles from "./styles.module.css";
import {translationOrName} from "../../../i18n";

export type Keys = {
    name: string;
    value: string;
};

export function CustomKeyFormatter<T extends Value>(props: {
    value: T;
    selectables: Keys[];
    setValue: (value: T) => void;
    name: string;
    renderIcon?: (color: string) => ReactNode;
    translate?: boolean;
}) {
    const currentKey = props.selectables.filter((key: Keys) => key.value === props.value);
    const [key, setKey] = useState<Keys>(currentKey[0]);
    const {translate, renderIcon} = props;
    const handleChange = (key: Keys) => {
        setKey(key);
        props.setValue(key.value as T);
    };

    function translatedKeyName(keyName: string) {
        return translate ? translationOrName("settings", props.name + "." + keyName) : keyName;
    }

    return (
        <>
            <DropdownButton
                others={{variant: "outlined"}}
                showDropdownIcon
                icon={renderIcon && renderIcon(`rgb(${key.value})`)}
                source={translatedKeyName(key.name)}>
                {props.selectables.map((option: Keys, index) => (
                    <MenuItem key={`${option}_${index}`} onClick={() => handleChange(option)}>
                        <Grid container spacing={1}>
                            {renderIcon && <Grid item>{renderIcon(`rgb(${option.value})`)}</Grid>}

                            <Grid item>{translatedKeyName(option.name)}</Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </DropdownButton>
            <span className={styles.spacer} />
        </>
    );
}
