import React, {ReactNode, useRef, useState} from "react";
import {Icon} from "./Icon";
import Button, {ButtonProps} from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import {ExpandMore} from "@material-ui/icons";

export default function DropdownButton(props: {
    source: ReactNode;
    icon?: ReactNode;
    children?: ReactNode;
    hideArrow?: boolean;
    showDropdownIcon?: boolean;
    others?: ButtonProps;
    buttonClass?: string;
}) {
    const [show, setShow] = useState(false);
    const anchorEl = useRef(null);
    const {source, icon, children, others, showDropdownIcon, buttonClass} = props;

    return (
        <>
            <Button
                className={buttonClass}
                ref={anchorEl}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => setShow(true)}
                color="inherit"
                startIcon={icon && <Icon icon={icon} />}
                size="large"
                endIcon={showDropdownIcon && <ExpandMore />}
                {...others}>
                {source}
            </Button>
            <Menu onClick={() => setShow(false)} id="simple-menu" anchorEl={anchorEl.current} keepMounted open={show}>
                {children}
            </Menu>
        </>
    );
}
