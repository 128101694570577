import {Keys} from "../components/CustomKeyFormatter";

export enum ShotDistinction {
    Fade = "Fade",
    Gradient = "Gradient",
    None = "None",
}

export const shotDistinctions = [ShotDistinction.Fade, ShotDistinction.None];

export const ShotColors: Keys[] = [
    {
        name: "Default",
        value: "235, 160, 10",
    },
    {
        name: "Green",
        value: "26, 184, 128",
    },
    {
        name: "Orange",
        value: "255, 105, 90",
    },
    {
        name: "Yellow",
        value: "255, 206, 72",
    },
    {
        name: "Purple",
        value: "174, 99, 254",
    },
    {
        name: "Blue",
        value: "108, 222, 255",
    },
];
