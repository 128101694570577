import * as yup from "yup";

export const weaponSchema = yup.object().shape({
    weaponCaliber: yup
        .number()
        .typeError("validation_number_invalid_type")
        .when("measurementUnit", {
            is: (measurementUnit: string) => measurementUnit === "Imperial",
            then: yup.number().min(0.1, "validation_caliber_too_small"),
        })
        .when("measurementUnit", {
            is: (measurementUnit: string) => measurementUnit === "Metric",
            then: yup.number().min(2, ".validation_caliber_too_small"),
        })
        .max(200, ".validation_caliber_too_large"),
    nchsGain: yup
        .number()
        .typeError("validation_number_invalid_type")
        .min(1, "validation_nchs_gain_too_small")
        .max(7, "validation_nchs_gain_too_large"),
    nchsTimeout: yup
        .number()
        .typeError("validation_number_invalid_type")
        .min(1, "validation_nchs_timeout_too_small")
        .max(2000, "validation_nchs_timeout_too_large"),
    nchsThreshold: yup
        .number()
        .typeError("validation_number_invalid_type")
        .min(10, "validation_nchs_threshold_too_small")
        .max(127, "validation_nchs_threshold_too_large"),
    measurementUnit: yup.string(),
    weaponName: yup.string().min(1, "validation_no_name"),
});
