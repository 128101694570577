import React, {ReactNode, useContext, useEffect} from "react";
import {AppContext} from "../AppContextProvider";
import {ContainerProps} from "../lomah/Lomah";
import TextWithIcon from "../common/TextWithIcon";
import {Gun} from "../icons/generated/warfare/filled";
import {translationOrName} from "../i18n";
import {SingleShooterLiveContext} from "./SingleShooterLiveContextProvider";
import {ConstantsStringCommon, Target, Weapon} from "../../api/generated/esa";
import {caliberString} from "../select-view/SelectOptions";
import PhaseTarget from "../target/PhaseTarget";

export function HeaderContainer(props: {children: ReactNode}) {
    return <div style={{height: `calc(100% - 40px)`, width: "100%"}}>{props.children}</div>;
}

export function LiveTarget(props: ContainerProps) {
    const {phase} = useContext(AppContext);
    const {hits, selectedHit, setSelectedHit, openSeries, target, weapon, tableSeries} = useContext(
        SingleShooterLiveContext,
    );
    const {id, setHeader} = props;

    useEffect(() => {
        setHeader(
            id,
            <TextWithIcon id="discipline_name" startIcon={<Gun />}>
                {translationOrName("discipline", phase.disciplineName)}
            </TextWithIcon>,
        );
    }, [id, setHeader, phase]);

    return (
        <HeaderContainer>
            <PhaseTarget
                onSelect={setSelectedHit}
                selected={selectedHit}
                series={tableSeries ? phase.series : 1}
                {...{
                    hits,
                    weapon,
                    phase,
                    openSeries,
                    tableSeries,
                    target,
                }}
            />
        </HeaderContainer>
    );
}

export function weaponCaliberOrDefault(weapon: Weapon, target: Target) {
    if (weapon.id !== ConstantsStringCommon.NO_WEAPON && weapon.caliberMm) {
        return weapon.caliberMm;
    } else {
        return target.defaultCaliberMm;
    }
}

export function weaponCaliberStringOrDefault(weapon: Weapon, target: Target) {
    return weapon.id !== ConstantsStringCommon.NO_WEAPON ? caliberString(weapon) : target.defaultCaliber;
}
