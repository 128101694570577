/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface SetDefaultsRequest {
    body: string;
}

export interface SetUserSettingsRequest {
    body: string;
}

/**
 *
 */
export class SettingsControllerApi extends runtime.BaseAPI {
    /**
     */
    async deleteUserSettingsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings/`,
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserSettings(): Promise<void> {
        await this.deleteUserSettingsRaw();
    }

    /**
     */
    async getUserSettingsRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings/`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getUserSettings(): Promise<string> {
        const response = await this.getUserSettingsRaw();
        return await response.value();
    }

    /**
     */
    async setDefaultsRaw(requestParameters: SetDefaultsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError(
                "body",
                "Required parameter requestParameters.body was null or undefined when calling setDefaults.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/settings/defaults`,
            method: "PATCH",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setDefaults(requestParameters: SetDefaultsRequest): Promise<void> {
        await this.setDefaultsRaw(requestParameters);
    }

    /**
     */
    async setUserSettingsRaw(requestParameters: SetUserSettingsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError(
                "body",
                "Required parameter requestParameters.body was null or undefined when calling setUserSettings.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/settings/`,
            method: "PATCH",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setUserSettings(requestParameters: SetUserSettingsRequest): Promise<void> {
        await this.setUserSettingsRaw(requestParameters);
    }
}
