import React, {useContext, useEffect, useState} from "react";
import {translation} from "../../i18n";
import {Grid, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CustomDialog from "../../common/CustomDialog";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ReactMarkdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";
import styles from "./Manual.module.css";
import {AppSettings} from "../../AppSettingsProvider";

export default function Manual() {
    const [open, setOpen] = useState(false);
    const [md, setMd] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const {Global} = useContext(AppSettings);
    const imageUri = `http:${window.location.origin}/manuals/`;

    const parseHtml = htmlParser({
        isValidNode: (node: any) => node.type !== "script",
        processingInstructions: [],
    });

    useEffect(() => {
        open &&
            fetch(Global.lang === "en" ? "/manuals/manual_en.md" : "/manuals/manual_de.md")
                .then(response => response.text())
                .then(data => {
                    setMd(
                        data
                            .split(" ")
                            .filter((res: string) => !res.includes("#"))
                            .join(" "),
                    );
                    setLoading(false);
                });
    }, [open, Global.lang]);

    return (
        <>
            <ManualButton onClick={() => setOpen(true)} />
            <CustomDialog
                {...{open, setOpen}}
                dialogTitle={<Typography variant="h5">{translation("title.manual.main")}</Typography>}
                dialogActions={
                    <>
                        <Button onClick={() => setOpen(false)}>{translation("button.cancel")}</Button>
                    </>
                }>
                <>
                    <Grid style={{overflowY: "auto", height: "inherit", width: "inherit"}}>
                        <Grid
                            className={styles.markdown}
                            item
                            container
                            direction="column"
                            xs={12}
                            style={{padding: 8, maxWidth: "inherit"}}>
                            {!loading && (
                                <ReactMarkdown
                                    source={md}
                                    escapeHtml={false}
                                    astPlugins={[parseHtml]}
                                    transformImageUri={(uri: string) => imageUri + uri}
                                />
                            )}
                        </Grid>
                    </Grid>
                </>
            </CustomDialog>
        </>
    );
}

export function ManualButton(props: {onClick: () => void}) {
    const {onClick} = props;

    return (
        <ListItem id="manual_control_button" button {...{onClick}}>
            <ListItemIcon>
                <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary={translation("title.manual")} />
        </ListItem>
    );
}
