/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserCounts
 */
export interface UserCounts {
    /**
     *
     * @type {number}
     * @memberof UserCounts
     */
    readUserCount: number;
    /**
     *
     * @type {number}
     * @memberof UserCounts
     */
    updatedUserCount: number;
    /**
     *
     * @type {number}
     * @memberof UserCounts
     */
    noPasswordUserCount: number;
}

export function UserCountsFromJSON(json: any): UserCounts {
    return UserCountsFromJSONTyped(json, false);
}

export function UserCountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCounts {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        readUserCount: json["readUserCount"],
        updatedUserCount: json["updatedUserCount"],
        noPasswordUserCount: json["noPasswordUserCount"],
    };
}

export function UserCountsToJSON(value?: UserCounts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        readUserCount: value.readUserCount,
        updatedUserCount: value.updatedUserCount,
        noPasswordUserCount: value.noPasswordUserCount,
    };
}
