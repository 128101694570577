import {Value} from "../types/settingsTypes";
import React from "react";
import {FormatterSelect} from "./FormatterSelect";
import {Themes} from "../../../../apps/Components";

export function FormatterThemes<T extends Value>(props: {value: T; setValue: (value: T) => void; name: string}) {
    const {name, value, setValue} = props;
    const selectables = Array.from(Themes.keys());

    return <FormatterSelect {...{name, value, setValue, selectables}} />;
}
