/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeToJSON,
    EsaData,
    EsaDataFromJSON,
    EsaDataToJSON,
    PowerStatus,
    PowerStatusFromJSON,
    PowerStatusToJSON,
} from "./";

/**
 *
 * @export
 * @interface DeviceEsaData
 */
export interface DeviceEsaData {
    /**
     *
     * @type {number}
     * @memberof DeviceEsaData
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof DeviceEsaData
     */
    mac: string;
    /**
     *
     * @type {string}
     * @memberof DeviceEsaData
     */
    alias: string;
    /**
     *
     * @type {DeviceType}
     * @memberof DeviceEsaData
     */
    type: DeviceType;
    /**
     *
     * @type {PowerStatus}
     * @memberof DeviceEsaData
     */
    powerStatus?: PowerStatus;
    /**
     *
     * @type {number}
     * @memberof DeviceEsaData
     */
    timeStamp: number;
    /**
     *
     * @type {EsaData}
     * @memberof DeviceEsaData
     */
    data: EsaData;
    /**
     *
     * @type {{ [key: string]: number; }}
     * @memberof DeviceEsaData
     */
    errors: {[key: string]: number};
}

export function DeviceEsaDataFromJSON(json: any): DeviceEsaData {
    return DeviceEsaDataFromJSONTyped(json, false);
}

export function DeviceEsaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceEsaData {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json["id"],
        mac: json["mac"],
        alias: json["alias"],
        type: DeviceTypeFromJSON(json["type"]),
        powerStatus: !exists(json, "powerStatus") ? undefined : PowerStatusFromJSON(json["powerStatus"]),
        timeStamp: json["timeStamp"],
        data: EsaDataFromJSON(json["data"]),
        errors: json["errors"],
    };
}

export function DeviceEsaDataToJSON(value?: DeviceEsaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        mac: value.mac,
        alias: value.alias,
        type: DeviceTypeToJSON(value.type),
        powerStatus: PowerStatusToJSON(value.powerStatus),
        timeStamp: value.timeStamp,
        data: EsaDataToJSON(value.data),
        errors: value.errors,
    };
}
