import {Units} from "./components/Edit";

export type tableData = {
    columnName: string;
    display: boolean;
    measurement?: string;
    objKeyName: string;
    filter: boolean;
};

type columns =
    | "score"
    | "toCenter"
    | "x"
    | "y"
    | "directionArrows"
    | "edit"
    | "timeStamp"
    | "delay"
    | "identificationNumber"
    | "n";

type columnRender = {
    columnName: string;
    display: boolean;
    objKeyName: columns;
    measurement?: string;
    filter: boolean;
};

export function tableContent(
    units: Units,
    identificationNumber: boolean,
    score: boolean,
    delay: boolean,
    coordinates: boolean,
    directionArrows: boolean,
    toCenter: boolean,
): columnRender[] {
    const distanceUnit = units === "Metric" ? "mm" : "in";

    return [
        {
            columnName: "id",
            display: identificationNumber,
            objKeyName: "n",
            filter: true,
        },
        {
            columnName: "Score",
            display: score,
            measurement: "pts",
            objKeyName: "score",
            filter: true,
        },
        {
            columnName: "Delay",
            measurement: "sec",
            display: delay,
            objKeyName: "delay",
            filter: true,
        },
        {
            columnName: "To Center",
            display: toCenter,
            measurement: distanceUnit,
            objKeyName: "toCenter",
            filter: true,
        },
        {
            columnName: "Direction",
            display: directionArrows,
            objKeyName: "directionArrows",
            filter: false,
        },
        {
            columnName: "X",
            display: coordinates,
            measurement: distanceUnit,
            objKeyName: "x",
            filter: true,
        },
        {
            columnName: "Y",
            display: coordinates,
            measurement: distanceUnit,
            objKeyName: "y",
            filter: true,
        },
    ];
}
