import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import ZoomControl from "../common/ZoomControl";
import Center, {CenteringOptions} from "../lomah/target/components/Center";
import {TargetView} from "./TargetView";
import {centerHit, distanceOfPoints} from "../lomah/live-scoring/functions/distanceOfPoints";
import {debounce, floor} from "lodash";
import {Hit, Target} from "../../api/generated/esa";
import {AppSettings} from "../AppSettingsProvider";
import classes from "../lomah/target/Target.module.css";

/**
 * TODO Small Zoom control
 */

export type TargetControlProps = {
    target: Target;
    caliberMm: number;
    hits?: Hit[];
    centerControls?: boolean;
    disableNumbers?: boolean;
    onSelect?: (n: string) => void;
    selected?: string;
    disableLatestHitHighlight?: boolean;
    shotBlink?: boolean;
    openSeries: number;
    series: number;
    maxShots: number;
    corner?: boolean;
};

export default function TargetControl(props: TargetControlProps) {
    const {
        target: {targetRadius, ringStep, targetCardWidth, targetCardHeight, numberingOffset},
        hits,
        selected,
        openSeries,
        series,
        maxShots,
        disableLatestHitHighlight,
        corner,
    } = props;

    const [autoCenter, setAutoCenter] = useState<CenteringOptions>("Center");
    const [zoom, setZoom] = useState<number>(1);
    const [autoZoom, setAutoZoom] = useState(false);

    const focusedShot = useRef<SVGCircleElement | null>(null);
    const mpiRef = useRef<SVGCircleElement | null>(null);
    const center = useRef<SVGCircleElement | null>(null);

    const focusAt = pickFocus(autoCenter, focusedShot, center, mpiRef, autoZoom, selected);

    const targetSize = Math.max(targetCardHeight, targetCardWidth);

    const {
        Components: {
            Lomah: {
                Target: {shotLimit},
            },
        },
    } = useContext(AppSettings);

    const shotsPerSeries = useMemo(() => (series > 1 ? maxShots / series : undefined), [series, maxShots]);
    const [filteredHits, lastHitId] = useMemo(() => {
        if (!hits) return [undefined, undefined];
        const sortedHits = [...hits].sort((h1, h2) => h1.n - h2.n);
        const lastHitId = sortedHits[sortedHits.length - 1]?.id;

        if (shotLimit >= 1) {
            return [
                includeSelected(
                    sortedHits.slice(Math.max(sortedHits.length - shotLimit, 0), sortedHits.length),
                    hits,
                    selected,
                ),
                lastHitId,
            ];
        } else if (shotLimit === 0 && shotsPerSeries) {
            const start = shotsPerSeries * (openSeries - 1);
            return [includeSelected(sortedHits.slice(start, start + shotsPerSeries), hits, selected), lastHitId];
        } else return [includeSelected(sortedHits, hits, selected), lastHitId];
    }, [hits, shotLimit, openSeries, shotsPerSeries, selected]);

    const zoomLevels = useMemo(() => {
        const levels = [...Array(11 - (numberingOffset ?? 1))]
            .map((x, index) => floor(targetSize / ((targetRadius - index * ringStep) * 2), 1))
            .filter(it => it >= 0.9)
            .map(it => (it < 1 ? 1 : it)); //for boundary targets like 25RP
        setZoom(levels[0]);

        return levels;
    }, [targetSize, targetRadius, ringStep, numberingOffset]);

    function centerTarget() {
        scrollIntoView(focusAt, "smooth");
    }

    useLayoutEffect(() => {
        const debouncedUpdateSize = debounce(() => scrollIntoView(focusAt, "smooth"), 300);
        window.addEventListener("resize", debouncedUpdateSize);
        return () => window.removeEventListener("resize", debouncedUpdateSize);
    }, [focusAt]);

    useLayoutEffect(() => {
        scrollIntoView(focusAt, "auto");
    }, [focusAt, zoom]);

    function setFocusedShotRef(instance: SVGCircleElement | null) {
        if (focusedShot.current?.id !== instance?.id) {
            focusedShot.current = instance;

            if (selected || autoCenter === "Hit") {
                scrollIntoView(focusedShot, "smooth");
            }
        }
    }

    function setCenterRef(instance: SVGCircleElement | null) {
        if (instance != null) {
            const wasNull = center.current === null;
            center.current = instance;
            if (wasNull && autoCenter === "Center") {
                scrollIntoView(center, "smooth");
            }
        }
    }

    function setMpiRef(instance: SVGCircleElement | null) {
        if (instance != null) {
            const wasNull = mpiRef.current === null;
            mpiRef.current = instance;
            if (wasNull && autoCenter === "MPI") {
                scrollIntoView(center, "smooth");
            }
        }
    }

    useEffect(() => {
        if (autoZoom && filteredHits && filteredHits.length > 1) {
            const maxFromCenter = Math.max(...filteredHits.map(h => distanceOfPoints(centerHit, h)));
            setZoom(targetSize / 2 / maxFromCenter);
        }
    }, [autoZoom, filteredHits, setZoom, targetSize]);

    const highlightHitId = !disableLatestHitHighlight ? lastHitId : undefined;

    return (
        <>
            {corner && <span id="target_corner" className={classes.targetCorner} />}
            <TargetView
                {...props}
                {...{zoom, setCenterRef, setMpiRef, setFocusedShotRef, highlightHitId, hits: filteredHits}}
            />
            <ZoomControl
                {...{zoom, setZoom, autoZoom, setAutoZoom, autoCenter, zoomLevels, numberingOffset}}
                style={{bottom: 16, right: 16}}
            />
            <Center {...{autoCenter, setAutoCenter}} setCenter={centerTarget} style={{bottom: 16, left: 16}} />
        </>
    );
}

function pickFocus(
    autoCenter: "Center" | "MPI" | "Hit" | "Off",
    focusedShot: React.MutableRefObject<SVGCircleElement | null>,
    center: React.MutableRefObject<SVGCircleElement | null>,
    mpiRef: React.MutableRefObject<SVGCircleElement | null>,
    autoZoom: boolean,
    selected: string | undefined,
) {
    if ((!selected && autoZoom) || autoCenter === "MPI") {
        return mpiRef;
    } else if (selected || autoCenter === "Hit") {
        return focusedShot;
    } else if (autoCenter === "Center") {
        return center;
    } else {
        return undefined;
    }
}

function scrollIntoView(
    centerAt: React.MutableRefObject<SVGCircleElement | null> | undefined,
    behavior: "smooth" | "auto",
) {
    doThenRetry(() => {
        const targetContainer = centerAt?.current?.parentElement?.parentElement;
        const targetContainerBox = targetContainer?.getBoundingClientRect();
        const focusElementBox = centerAt?.current?.getBoundingClientRect();

        if (targetContainer && targetContainerBox && focusElementBox) {
            targetContainer.scrollTo({
                left:
                    focusElementBox.left +
                    focusElementBox.width / 2 -
                    targetContainerBox.left +
                    targetContainer.scrollLeft -
                    targetContainer.clientWidth / 2,
                top:
                    focusElementBox.top +
                    focusElementBox.height / 2 -
                    targetContainerBox.top +
                    targetContainer.scrollTop -
                    targetContainer.clientHeight / 2,
                behavior,
            });
        }
    });
}

/**
 * React behaviour with requestAnimationFrame can be really unpredictable,
 * so best we can do is apply calculation on next frame, then after 5 frames
 * https://stackoverflow.com/questions/26556436/react-after-render-cod
 */
function doThenRetry(f: () => void) {
    f();
    skipAnimationFrames(5, f);
}

function skipAnimationFrames(n: number, f: () => void) {
    if (n <= 0) {
        f();
    } else {
        window.requestAnimationFrame(() => skipAnimationFrames(n - 1, f));
    }
}

function includeSelected(filteredHits: Hit[], allHits: Hit[], selected?: string) {
    const selectedHit = selected ? allHits.find(h => h.id === selected) : undefined;
    if (!selectedHit) {
        return filteredHits;
    } else {
        const withoutSelected = filteredHits.filter(h => h.id !== selected);
        const putOnTop = [...withoutSelected, selectedHit];
        return putOnTop;
    }
}
