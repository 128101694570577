import {Value} from "../types/settingsTypes";
import React from "react";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {translatedText} from "../../../i18n";

export function FormatterRadio<T extends Value>(props: {
    value: T;
    selectables: string[];
    setValue: (value: T) => void;
    name: string;
}) {
    return (
        <FormControl component="fieldset">
            <RadioGroup
                value={props.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setValue((event.target as HTMLInputElement).value as T)
                }>
                {props.selectables.map((option: string, i) => (
                    <FormControlLabel
                        key={`radio_${option}_${i}`}
                        style={{fontSize: 15}}
                        value={option}
                        control={<Radio color="primary" size="small" />}
                        label={translatedText("settings." + props.name, option)}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
