import React from "react";
import {DataGrid, DataGridProps} from "@material-ui/data-grid";
import {translationString} from "../i18n";
import {useIntl} from "react-intl";
import {DataGridTranslations} from "./DataGridTranslations";

export const CommonDataGrid = (props: DataGridProps) => {
    const intl = useIntl();

    return (
        <DataGrid
            {...props}
            localeText={DataGridTranslations(intl)}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: translationString(intl, "data_grid_component.rows_per_page"),
                },
            }}
        />
    );
};
