/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
    FullWeapon,
    FullWeaponFromJSON,
    WeaponCategory,
    WeaponCategoryFromJSON,
    WeaponIcon,
    WeaponIconFromJSON,
    WeaponType,
    WeaponTypeFromJSON,
} from "../models";

export interface IconRequest {
    name: string;
}

/**
 *
 */
export class WeaponInfoControllerApi extends runtime.BaseAPI {
    /**
     */
    async categoriesRaw(): Promise<runtime.ApiResponse<Array<WeaponCategory>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/weapons/info/categories`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(WeaponCategoryFromJSON));
    }

    /**
     */
    async categories(): Promise<Array<WeaponCategory>> {
        const response = await this.categoriesRaw();
        return await response.value();
    }

    /**
     */
    async iconRaw(requestParameters: IconRequest): Promise<runtime.ApiResponse<WeaponIcon>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError(
                "name",
                "Required parameter requestParameters.name was null or undefined when calling icon.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/weapons/info/icons/{name}`.replace(
                `{${"name"}}`,
                encodeURIComponent(String(requestParameters.name)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => WeaponIconFromJSON(jsonValue));
    }

    /**
     */
    async icon(requestParameters: IconRequest): Promise<WeaponIcon> {
        const response = await this.iconRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async typesRaw(): Promise<runtime.ApiResponse<Array<WeaponType>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/weapons/info/types`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(WeaponTypeFromJSON));
    }

    /**
     */
    async types(): Promise<Array<WeaponType>> {
        const response = await this.typesRaw();
        return await response.value();
    }

    /**
     */
    async weaponsRaw(): Promise<runtime.ApiResponse<Array<FullWeapon>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/weapons/info/`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FullWeaponFromJSON));
    }

    /**
     */
    async weapons(): Promise<Array<FullWeapon>> {
        const response = await this.weaponsRaw();
        return await response.value();
    }
}
