import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import {Grid, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import CustomDialog from "../common/CustomDialog";
import InfoIcon from "@material-ui/icons/Info";
import {Overview} from "../header/Overview";
import LicensesBackend from "./LicensesBackend";
import styles from "./About.module.css";
import LicensesFrontend from "./LicensesFrontend";
import clsx from "clsx";
import {translation, translationOrName} from "../i18n";

export function About() {
    enum Views {
        Frontend = "frontend",
        Backend = "backend",
        Overview = "overview",
    }

    const [open, setOpen] = useState(false);
    const [view, setView] = useState<Views>(Views.Overview);
    const views = [Views.Overview, Views.Frontend, Views.Backend];

    function getView(view: Views) {
        switch (view) {
            case Views.Overview:
                return <Overview />;
            case Views.Backend:
                return <LicensesBackend />;
            case Views.Frontend:
                return <LicensesFrontend />;
        }
    }

    return (
        <>
            <ListItem id="about_control_button" button onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={translation("title.about")} />
            </ListItem>
            <CustomDialog
                dialogTitle={<Typography variant="h5">{translation("title.about")}</Typography>}
                {...{setOpen, open}}>
                <Grid container className={styles.container}>
                    <Grid item xs={4} md={3} className={styles.sidebar}>
                        <List style={{width: "100%"}} component="nav">
                            {views.map(it => (
                                <ListItem
                                    key={`${it}_menu_item`}
                                    onClick={() => setView(it)}
                                    className={clsx(styles.listItem, view === it && styles.selected)}
                                    button>
                                    {translationOrName("title.about", it)}
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid container item xs={8} md={9} className={styles.licencesView}>
                        {getView(view)}
                    </Grid>
                </Grid>
            </CustomDialog>
        </>
    );
}
