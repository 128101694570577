import React, {ReactNode} from "react";
import {Grid, Typography} from "@material-ui/core";
import {Variant} from "@material-ui/core/styles/createTypography";

export default function TextWithIcon(props: {
    id?: string;
    className?: string;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    children?: React.ReactNode;
    variant?: Variant;
    textColor?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
}) {
    const {id, className, startIcon, endIcon, children, variant, textColor} = props;

    return (
        <Grid {...{id, className}} container wrap="nowrap" alignItems="center" spacing={2}>
            <Grid item>{startIcon}</Grid>
            <Grid item xs zeroMinWidth>
                <Typography {...{variant}} color={textColor || "textSecondary"} noWrap>
                    {children}
                </Typography>
            </Grid>
            <Grid item>{endIcon}</Grid>
        </Grid>
    );
}
