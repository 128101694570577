import React, {Component} from "react";
import Popup from "./Popup";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import {TranslationKey} from "../i18n";

interface Props {
    title: TranslationKey;
    show: boolean;
    options: string[];
    onClick: (value: string) => void;
    onClose: () => void;
}

class SelectPopup extends Component<Props, {value: string}> {
    constructor(props: Props) {
        super(props);
        this.state = {value: this.props.options[0]};
    }

    render() {
        const props = this.props;

        if (!props.show) return <></>;

        return (
            <Popup
                title={props.title}
                show={props.show}
                okButton="button.add"
                onClick={() => {
                    props.onClick(this.state.value);
                    props.onClose();
                    this.setState({value: this.props.options[0]});
                }}
                onClose={props.onClose}>
                <FormControl>
                    <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={this.state.value}
                        onChange={e => {
                            this.setState({value: e.target.value as string});
                        }}
                        className="form-control">
                        {this.props.options.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Popup>
        );
    }
}

export default SelectPopup;
