/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface WeaponCounts
 */
export interface WeaponCounts {
    /**
     *
     * @type {number}
     * @memberof WeaponCounts
     */
    readWeaponCount: number;
    /**
     *
     * @type {number}
     * @memberof WeaponCounts
     */
    updatedWeaponCount: number;
}

export function WeaponCountsFromJSON(json: any): WeaponCounts {
    return WeaponCountsFromJSONTyped(json, false);
}

export function WeaponCountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeaponCounts {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        readWeaponCount: json["readWeaponCount"],
        updatedWeaponCount: json["updatedWeaponCount"],
    };
}

export function WeaponCountsToJSON(value?: WeaponCounts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        readWeaponCount: value.readWeaponCount,
        updatedWeaponCount: value.updatedWeaponCount,
    };
}
