import {GridLocaleText} from "@material-ui/data-grid";
import {translationString} from "../i18n";
import {IntlShape} from "react-intl";

export const DataGridTranslations = (intl: IntlShape): Partial<GridLocaleText> => {
    return {
        toolbarColumns: translationString(intl, "data_grid_component.toolbarcolumns"),
        toolbarFilters: translationString(intl, "data_grid_component.toolbarfilters"),
        columnsPanelShowAllButton: translationString(intl, "data_grid_component.columnspanelshowallbutton"),
        columnsPanelHideAllButton: translationString(intl, "data_grid_component.columnspanelhideallbutton"),
        checkboxSelectionHeaderName: translationString(intl, "data_grid_component.checkboxselectionheadername"),
        columnsPanelTextFieldPlaceholder: translationString(
            intl,
            "data_grid_component.columnspaneltextfieldplaceholder",
        ),
        filterPanelInputPlaceholder: translationString(intl, "data_grid_component.filterpanelinputplaceholder"),
        filterOperatorContains: translationString(intl, "data_grid_component.filteroperatorcontains"),
        columnsPanelTextFieldLabel: translationString(intl, "data_grid_component.columnspaneltextfieldlabel"),
        filterOperatorEquals: translationString(intl, "data_grid_component.filteroperatorequals"),
        filterOperatorStartsWith: translationString(intl, "data_grid_component.filteroperatorstartswith"),
        filterOperatorEndsWith: translationString(intl, "data_grid_component.filteroperatorendswith"),
        filterPanelColumns: translationString(intl, "data_grid_component.filterpanelcolumns"),
        filterPanelOperators: translationString(intl, "data_grid_component.filterpaneloperators"),
        filterPanelInputLabel: translationString(intl, "data_grid_component.filterpanelinputlabel"),
        footerRowSelected: count => {
            const suffix = translationString(
                intl,
                count > 1 ? "data_grid_component.rows_selected" : "data_grid_component.row_selected",
            );
            return `${count} ${suffix}`;
        },
    };
};
