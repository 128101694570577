import React, {useEffect, useState} from "react";
import cmp from "semver-compare";
import {BotInfo, BrowserInfo, detect, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo} from "detect-browser";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

export default function BrowserSupport(props: {supported: {[key: string]: string}}) {
    const [supported, setSupported] = useState(true);
    const [message, setMessage] = useState("");
    type DetectedBrowser = BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null;

    useEffect(() => {
        const browser: DetectedBrowser = detect();
        const {supported} = props;
        function setAsUnsupported(browser: DetectedBrowser) {
            setSupported(false);
            browser &&
                setMessage(
                    `Your browser | ${browser.name} : version ${browser.version} | is not currently tested or supported.`,
                );
        }
        function setAsSupported(browser: DetectedBrowser) {
            setSupported(true);
            browser && setMessage(`${browser.name} version ${browser.version} is supported`);
        }

        if (!browser) {
            setMessage("Could not detect browser");
            setSupported(false);
        } else {
            if (!supported[browser.name]) {
                setAsUnsupported(browser);
            } else {
                const browserVersion = supported[browser.name];
                if (browser.version && cmp(browser.version, browserVersion) < 0) {
                    setAsUnsupported(browser);
                } else {
                    setAsSupported(browser);
                }
            }
        }
    }, [props]);

    return (
        <>
            {!supported ? (
                <Dialog open={!supported}>
                    <DialogTitle>Your browser is unsupported</DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>{message}</Typography>

                        <Typography gutterBottom color="primary">
                            We recommend latest <i>Google Chrome</i> browser.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            color="primary"
                            variant="contained"
                            onClick={() => window.open("https://www.google.com/chrome/", "_blank")}>
                            Download
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
        </>
    );
}
