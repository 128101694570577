/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ScoringType {
    FullRing = "FullRing",
    Decimal = "Decimal",
    DecimalIgnore = "DecimalIgnore",
    HitZones = "HitZones",
    Unknown = "Unknown",
}

export function ScoringTypeFromJSON(json: any): ScoringType {
    return ScoringTypeFromJSONTyped(json, false);
}

export function ScoringTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoringType {
    return json as ScoringType;
}

export function ScoringTypeToJSON(value?: ScoringType | null): any {
    return value as any;
}
