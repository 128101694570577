/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface UserCsv
 */
export interface UserCsv {
    /**
     *
     * @type {string}
     * @memberof UserCsv
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof UserCsv
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof UserCsv
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof UserCsv
     */
    lastName?: string;
    /**
     *
     * @type {number}
     * @memberof UserCsv
     */
    yearOfBirth?: number;
    /**
     *
     * @type {string}
     * @memberof UserCsv
     */
    competitiveClass?: string;
    /**
     *
     * @type {string}
     * @memberof UserCsv
     */
    country?: string;
    /**
     *
     * @type {string}
     * @memberof UserCsv
     */
    secretType?: string;
    /**
     *
     * @type {string}
     * @memberof UserCsv
     */
    secret?: string;
}

export function UserCsvFromJSON(json: any): UserCsv {
    return UserCsvFromJSONTyped(json, false);
}

export function UserCsvFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCsv {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: json["name"],
        description: !exists(json, "description") ? undefined : json["description"],
        firstName: !exists(json, "firstName") ? undefined : json["firstName"],
        lastName: !exists(json, "lastName") ? undefined : json["lastName"],
        yearOfBirth: !exists(json, "yearOfBirth") ? undefined : json["yearOfBirth"],
        competitiveClass: !exists(json, "competitiveClass") ? undefined : json["competitiveClass"],
        country: !exists(json, "country") ? undefined : json["country"],
        secretType: !exists(json, "secretType") ? undefined : json["secretType"],
        secret: !exists(json, "secret") ? undefined : json["secret"],
    };
}

export function UserCsvToJSON(value?: UserCsv | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        description: value.description,
        firstName: value.firstName,
        lastName: value.lastName,
        yearOfBirth: value.yearOfBirth,
        competitiveClass: value.competitiveClass,
        country: value.country,
        secretType: value.secretType,
        secret: value.secret,
    };
}
