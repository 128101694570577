/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     *
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    secretType?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof User
     */
    roles: Array<string>;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof User
     */
    meta: {[key: string]: string};
    /**
     *
     * @type {string}
     * @memberof User
     */
    cloudId?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     *
     * @type {number}
     * @memberof User
     */
    yearOfBirth?: number;
    /**
     *
     * @type {string}
     * @memberof User
     */
    competitiveClass: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    country: string;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json["id"],
        name: json["name"],
        description: json["description"],
        secretType: !exists(json, "secretType") ? undefined : json["secretType"],
        roles: json["roles"],
        meta: json["meta"],
        cloudId: !exists(json, "cloudId") ? undefined : json["cloudId"],
        firstName: json["firstName"],
        lastName: json["lastName"],
        yearOfBirth: !exists(json, "yearOfBirth") ? undefined : json["yearOfBirth"],
        competitiveClass: json["competitiveClass"],
        country: json["country"],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        description: value.description,
        secretType: value.secretType,
        roles: value.roles,
        meta: value.meta,
        cloudId: value.cloudId,
        firstName: value.firstName,
        lastName: value.lastName,
        yearOfBirth: value.yearOfBirth,
        competitiveClass: value.competitiveClass,
        country: value.country,
    };
}
