import {HitTable} from "../../lomah/live-scoring/HitTable";
import React, {useContext, useEffect} from "react";
import {getMPI as MPI} from "../../lomah/target/functions/getMPI";
import {ReviewContext} from "../ReviewContextProvider";
import {ContainerProps} from "../../lomah/Lomah";
import {useIntl} from "react-intl";
import {translation} from "../../i18n";
import {Binoculars} from "../../icons/generated/warfare/outlined";
import {Skeleton} from "@material-ui/lab";
import {FormControlLabel, Switch, Typography} from "@material-ui/core";
import NoData from "../../common/NoData";

export function ReviewTable(props: ContainerProps) {
    const {
        phase,
        hits,
        selectedHit,
        hitsInSeries,
        setSelectedHit,
        openSeries,
        setOpenSeries,
        target,
        isLoading,
        tableSeries,
        setTableSeries,
    } = useContext(ReviewContext);
    const targetSize = Math.max(target.targetCardWidth, target.targetCardHeight);
    const mPIX = MPI(hits, "x", targetSize);
    const mPIY = MPI(hits, "y", targetSize);
    const seriesAvailable = phase?.series && phase.series > 1;

    const {id, setHeader} = props;
    const intl = useIntl();

    useEffect(() => {
        setHeader(
            id,
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                <Binoculars />
                <Typography noWrap>{translation("title.review_table")}</Typography>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={!seriesAvailable}
                            checked={tableSeries}
                            color="primary"
                            onChange={() => setTableSeries(!tableSeries)}
                        />
                    }
                    label={translation("component.hits_table.series_switch")}
                />
            </div>,
        );
    }, [id, setHeader, intl, tableSeries, seriesAvailable, setTableSeries]);

    if (isLoading) {
        return <TableSkeleton />;
    } else if (phase && hits.length > 0) {
        return (
            <HitTable
                onSelect={setSelectedHit}
                {...{
                    phase,
                    hits,
                    selected: selectedHit,
                    mPIY,
                    mPIX,
                    tableSeries,
                    hitsInSeries,
                    openSeries,
                    setOpenSeries,
                }}
            />
        );
    } else {
        return <NoData />;
    }
}

function TableSkeleton() {
    return (
        <div>
            {[...Array.from(Array(10).keys())].map(i => (
                <div key={`skeleton_${i}`} style={{padding: 4}}>
                    <Skeleton variant="rect" style={{width: "100%"}} height={25} />
                </div>
            ))}
        </div>
    );
}
