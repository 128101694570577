/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface CompetitionTitle
 */
export interface CompetitionTitle {
    /**
     *
     * @type {string}
     * @memberof CompetitionTitle
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof CompetitionTitle
     */
    disciplineName: string;
    /**
     *
     * @type {string}
     * @memberof CompetitionTitle
     */
    phaseName?: string;
    /**
     *
     * @type {number}
     * @memberof CompetitionTitle
     */
    userCount: number;
    /**
     *
     * @type {number}
     * @memberof CompetitionTitle
     */
    startTime?: number;
}

export function CompetitionTitleFromJSON(json: any): CompetitionTitle {
    return CompetitionTitleFromJSONTyped(json, false);
}

export function CompetitionTitleFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompetitionTitle {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json["id"],
        disciplineName: json["disciplineName"],
        phaseName: !exists(json, "phaseName") ? undefined : json["phaseName"],
        userCount: json["userCount"],
        startTime: !exists(json, "startTime") ? undefined : json["startTime"],
    };
}

export function CompetitionTitleToJSON(value?: CompetitionTitle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        disciplineName: value.disciplineName,
        phaseName: value.phaseName,
        userCount: value.userCount,
        startTime: value.startTime,
    };
}
