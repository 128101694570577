import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    ListItemIcon,
    MenuItem,
} from "@material-ui/core";
import React, {MutableRefObject, useContext, useEffect, useRef, useState} from "react";
import {
    ConstantsStringCommon,
    Hit,
    HitControllerApi,
    Phase,
    SessionComment,
    TrainingSession,
    TrainingSessionControllerApi,
    UsersControllerApi,
} from "../../../../api/generated/esa";
import {Print} from "@material-ui/icons";
import {translation, translationOrName} from "../../../i18n";
import Button from "@material-ui/core/Button";
import styles from "./Print.module.css";
import {Body1, H4} from "../../../common/TypographyVariants";
import {AppContext, userPlaceholder} from "../../../AppContextProvider";

import {AppSettings} from "../../../AppSettingsProvider";
import {format} from "date-fns";
import ReactToPrint from "react-to-print";
import {useTarget, useWeapon} from "../../../../customHooks";
import Popup from "../../../common/Popup";
import {ReviewContext} from "../../ReviewContextProvider";
import {HitTable} from "../../../lomah/live-scoring/HitTable";
import {usePrevious} from "../../../common/ReactUtils";
import {isEqual} from "lodash";
import PrintableTarget from "../../../target/PrintableTarget";
import {weaponCaliberOrDefault, weaponCaliberStringOrDefault} from "../../../live-view/LiveTarget";

const hitControllerApi = new HitControllerApi();
const userControllerApi = new UsersControllerApi();
const trainingSessionControllerApi = new TrainingSessionControllerApi();

export function SessionPrintButton(props: {onClick: () => void}) {
    const {session} = useContext(ReviewContext);
    const {onClick} = props;
    const printPreviewRef = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(false);
    const [includeTarget, setIncludeTarget] = useState(true);
    const [includeTable, setIncludeTable] = useState(true);
    const [excludePhases, setExcludePhases] = useState<string[]>([]);
    const phaseNames = session?.phases.filter(p => Boolean(p.name)).map(p => p.name ?? "") ?? [];
    return (
        <>
            <MenuItem
                id="review_print_button"
                onClick={() => {
                    onClick();
                    setOpen(true);
                }}>
                <ListItemIcon>
                    <Print />
                </ListItemIcon>
                {translation("button.print")}
            </MenuItem>
            {open && session && (
                <Popup
                    title="title.print_session"
                    show={open}
                    onClose={() => setOpen(false)}
                    variant="wide"
                    okButton={<></>}
                    okButtonElement={
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Button startIcon={<Print />} variant="contained" color="primary">
                                        {translation("button.print")}
                                    </Button>
                                );
                            }}
                            pageStyle={printOverride}
                            content={() => printPreviewRef.current}
                        />
                    }>
                    <PrintOptions
                        {...{
                            includeTarget,
                            setIncludeTarget,
                            includeTable,
                            setIncludeTable,
                            phaseNames,
                            excludePhases,
                            setExcludePhases,
                        }}
                    />
                    <PrintPreview
                        {...{
                            open,
                            session,
                            printPreviewRef,
                            includeTarget,
                            includeTable,
                            phaseNames,
                            excludePhases,
                            setExcludePhases,
                        }}
                    />
                </Popup>
            )}
        </>
    );
}

function PrintPreview(props: {
    session: TrainingSession;
    printPreviewRef: MutableRefObject<HTMLDivElement | null>;
    includeTarget: boolean;
    includeTable: boolean;
    excludePhases: string[];
}) {
    const {session, printPreviewRef, includeTarget, includeTable, excludePhases} = props;
    const {user: currentUser} = useContext(AppContext);
    const phaseIds = session.phases.map(p => p.id);
    const prevPhaseIds = usePrevious(phaseIds);

    const [user, setUser] = useState(currentUser);
    const [hits, setHits] = useState<Hit[]>([]);
    const [comment, setComment] = useState<SessionComment>({
        id: "",
        value: "",
    });

    useEffect(() => {
        trainingSessionControllerApi.sessionComment({id: session.id}).then(setComment);
    }, [session.id, setComment]);

    useEffect(() => {
        if (!isEqual(phaseIds, prevPhaseIds)) {
            hitControllerApi.getHits({phase: phaseIds}).then(setHits);
        }
    }, [phaseIds, prevPhaseIds]);

    const {
        Global: {dateFormat},
    } = useContext(AppSettings);

    const firstPhase = session.phases[0];
    const weapon = useWeapon(firstPhase.weaponId);

    useEffect(() => {
        if (firstPhase.userId === currentUser.id) {
            setUser(currentUser);
        } else {
            userControllerApi
                .getAllUsers({includeAdmin: true, includeGuest: false})
                .then(users => setUser(users.find(u => u.id === firstPhase.userId) ?? userPlaceholder));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstPhase.userId, setUser]);

    return (
        <div ref={printPreviewRef} className={styles.phaseSection}>
            <H4 className={styles.detailsTitle}>{translation("title.print_session_details")}</H4>

            <Body1>
                {translation("label.print_discipline")}
                <span className={styles.primaryColor}>{translationOrName("discipline", session.disciplineName)}</span>
            </Body1>
            <Body1>
                {translation("label.print_user")}
                <span className={styles.primaryColor}>
                    {user.firstName} {user.lastName} {user.name}
                </span>
            </Body1>
            {weapon.id !== ConstantsStringCommon.NO_WEAPON && (
                <Body1>
                    {translation("label.print_weapon")}
                    <span className={styles.primaryColor}>{weapon?.name}</span>
                </Body1>
            )}
            <Body1>
                {translation("label.print_date")}
                <span className={styles.primaryColor}>{format(session.startTime, dateFormat)}</span>
            </Body1>
            {comment.value.length > 0 && (
                <Body1>
                    <b>
                        {translation("label.comment")}:<br />
                    </b>
                    {comment.value}
                </Body1>
            )}
            {session.phases
                .filter(p => p.name && !excludePhases.includes(p.name))
                .map(phase => (
                    <PrintablePhase
                        key={`printable_phase_${phase.id}`}
                        hits={hits.filter(h => h.phaseId === phase.id)}
                        {...{phase, includeTarget, includeTable}}
                    />
                ))}
        </div>
    );
}

function PrintablePhase(props: {phase: Phase; includeTarget: boolean; includeTable: boolean; hits: Hit[]}) {
    const {phase, includeTable, includeTarget, hits} = props;
    const target = useTarget(phase.targetName);
    const weapon = useWeapon(phase.weaponId);

    return (
        <Box mt={5}>
            <H4 color="primary">{phase.name && translationOrName("component.timer.phase", phase.name)}</H4>
            <Box mt={1} mb={1}>
                <Body1 color="textSecondary">
                    {translation("column.table.hit.score")}
                    {": "}
                    {phase.fullRingScore ?? ""}({phase.decimalScore})
                </Body1>
                <Body1>
                    {translation("label.input.weapons_editor.caliber")}
                    {": "}
                    {weaponCaliberStringOrDefault(weapon, target)}
                </Body1>
            </Box>
            {includeTarget && (
                <PrintableTarget caliberMm={weaponCaliberOrDefault(weapon, target)} {...{target, hits}} />
            )}
            {includeTable && (
                <HitTable
                    openSeries={0}
                    setOpenSeries={() => null}
                    allSeriesOpen
                    hits={hits}
                    tableSeries={false}
                    phase={phase}
                />
            )}
        </Box>
    );
}

function PrintOptions(props: {
    includeTarget: boolean;
    setIncludeTarget: Setter<boolean>;
    includeTable: boolean;
    setIncludeTable: Setter<boolean>;
    phaseNames: string[];
    excludePhases: string[];
    setExcludePhases: Setter<string[]>;
}) {
    const {
        includeTarget,
        setIncludeTarget,
        includeTable,
        setIncludeTable,
        phaseNames,
        excludePhases,
        setExcludePhases,
    } = props;

    return (
        <Grid container className={styles.printDialogContainer}>
            <Grid container className={styles.optionsContainer}>
                <Grid item md={6}>
                    <H4>{translation("title.print_options")}</H4>
                    <FormControl component="fieldset" className={styles.formControl}>
                        <FormLabel component="legend">{translation("label.print_includes")}</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={includeTarget} onChange={() => setIncludeTarget(old => !old)} />
                                }
                                label={translation("settings.category.target")}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={includeTable} onChange={() => setIncludeTable(old => !old)} />
                                }
                                label={translation("settings.category.table")}
                            />
                        </FormGroup>
                        <FormLabel component="legend">{translation("label.print_phases_include")}</FormLabel>
                        <FormGroup>
                            {phaseNames.map(it => {
                                return (
                                    <FormControlLabel
                                        key={`phase_to_print_${it}`}
                                        control={
                                            <Checkbox
                                                checked={!excludePhases.includes(it)}
                                                onChange={() => {
                                                    const checked = excludePhases.includes(it);
                                                    if (checked) {
                                                        setExcludePhases(old => old.filter(phase => phase !== it));
                                                    } else setExcludePhases(old => [...old, it]);
                                                }}
                                            />
                                        }
                                        label={translationOrName("component.timer.phase", it)}
                                    />
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
}

/**
 * Because reconfiguring webpack to include css contents or loading file via fetch is too big overhead.
 */
const printOverride = `
div {
    height: unset !important;
    box-shadow: none !important;
    border: none !important;
}`;
