import React, {ReactNode, useCallback, useContext, useEffect, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Typography} from "@material-ui/core";
import {translation} from "../i18n";
import {JoyrideProvider} from "./JoyrideControler";
import {EnhancedStep} from "./Joyride.utils";
import {AppContext} from "../AppContextProvider";
import {ConstantsNumberCommon, UsersControllerApi} from "../../api/generated/esa";
import {isCloudUser, isSuperAdmin} from "../common/utils";
import {NotificationContext} from "../NotificationContextProvider";

const usersControllerApi = new UsersControllerApi();
const minimumJoyrideScreenWidth = 900;

export const JoyrideContext = React.createContext<JoyrideContextType>({
    joyride: false,
    setJoyride: () => {},
    steps: [],
    setSteps: () => {},
    index: 0,
    setIndex: () => {},
    animatedHide: false,
    joyrideAvailable: true,
});

export default function JoyrideContextProvider(props: {
    steps: EnhancedStep[];
    children?: ReactNode;
    started?: boolean;
}) {
    const {started} = props;
    const [steps, setSteps] = useState(props.steps);
    const [joyride, setJoyride] = useState(false);
    const [index, setIndex] = useState(0);
    const [welcomeDialog, setWelcomeDialog] = useState(false);
    const [animatedHide, setAnimatedHide] = useState(false);
    const [joyrideAvailable, setJoyrideAvailable] = useState(true);
    const {notify} = useContext(NotificationContext);

    const {
        userDevice: {deviceId},
        user,
    } = useContext(AppContext);

    const deviceUndefined = deviceId === ConstantsNumberCommon.DEVICE_UNDEFINED;

    const context = useMemo<JoyrideContextType>(
        () => ({
            ...{
                joyride,
                setJoyride,
                steps,
                setSteps,
                index,
                setIndex,
                animatedHide,
                joyrideAvailable,
            },
        }),
        [joyride, setJoyride, steps, setSteps, index, setIndex, animatedHide, joyrideAvailable],
    );

    function initiateJoyride() {
        setWelcomeDialog(false);
        setJoyride(true);
    }

    const cancelJoyride = useCallback(() => {
        setJoyride(false);
        notify("warning", "joyride.application.select_device");
    }, [notify]);

    useEffect(() => {
        if (joyride) {
            deviceUndefined && cancelJoyride();
        }
    }, [joyride, deviceUndefined, cancelJoyride]);

    useEffect(() => {
        if (!started && !isSuperAdmin(user) && !isCloudUser(user) && !user.meta.viewedWelcomeDialog) {
            const {meta} = user;
            const userMeta: {[key: string]: string} = {
                ...meta,
                viewedWelcomeDialog: "true",
            };
            !deviceUndefined &&
                usersControllerApi
                    .updateUser({user: {...user, meta: userMeta}, secret: ""})
                    .then(() => setWelcomeDialog(true))
                    .catch(() => {
                        // ignore unsuccessful update
                    });
        }
    }, [deviceUndefined, user, started]);

    useEffect(() => {
        function goNext(e: MouseEvent) {
            const selector = steps[index].animationElement;
            const currentStep = steps[index];
            const expectedID = currentStep?.target || "";
            const currentClick = e.composedPath() as HTMLElement[];
            const involvedIds = currentClick.map(it => it.id);
            const next = involvedIds.includes(expectedID.replace("#", ""));

            if (next) {
                if (selector) {
                    const animated = document.querySelector(selector);
                    if (animated) {
                        setAnimatedHide(true);
                        Promise.all(animated.getAnimations().map(it => it.finished)).then(_ => {
                            setAnimatedHide(false);
                            setIndex(i => ++i);
                        });
                    } else {
                        setIndex(i => ++i);
                    }
                } else {
                    setIndex(i => ++i);
                }
            }
        }

        if (joyride && steps[index]?.awaitClick) {
            window.addEventListener("click", goNext);
            return () => {
                window.removeEventListener("click", goNext);
            };
        }
    }, [joyride, steps, index]);

    const checkForAvailability = useCallback(() => {
        const client = window.document.getElementById("root")?.clientWidth;
        const screenBigEnough = client && client > minimumJoyrideScreenWidth;
        setJoyrideAvailable(Boolean(screenBigEnough));
        if (!screenBigEnough) {
            setWelcomeDialog(false);
            setJoyride(false);
        }
    }, []);

    useEffect(() => {
        checkForAvailability();
        started && joyrideAvailable && setJoyride(true);
        window.addEventListener("resize", checkForAvailability);
        return () => {
            window.removeEventListener("resize", checkForAvailability);
        };
    }, [started, joyrideAvailable, checkForAvailability]);

    return (
        <JoyrideContext.Provider value={context}>
            {props.children}

            {joyride && <JoyrideProvider />}
            <Dialog open={welcomeDialog} onClose={() => setWelcomeDialog(false)}>
                <DialogTitle>{translation("joyride.title.take_tour")} </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translation("joyride.take_tour")}{" "}
                        <Typography component="span" color="primary">
                            {translation("title.introduction")}
                        </Typography>
                        .
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="joyride_skip" onClick={() => setWelcomeDialog(false)} color="primary">
                        {translation("button.skip")}
                    </Button>
                    <Button onClick={initiateJoyride} color="primary" variant="contained" autoFocus>
                        {translation("button.proceed")}
                    </Button>
                </DialogActions>
            </Dialog>
        </JoyrideContext.Provider>
    );
}
