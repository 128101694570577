import React, {useContext, useEffect, useState} from "react";
import {
    Avatar,
    Badge,
    Button,
    Divider,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@material-ui/core";
import {Error, FiberManualRecord as StatusCircle} from "@material-ui/icons";
import {
    ConstantsNumberCommon,
    DeviceEsaData,
    DeviceType,
    EsaControllerApi,
    PowerStatus,
    SystemControllerApi,
    SystemInfo,
    WebSocketMessage,
} from "../../api/generated/esa";
import styles from "./ConnectionStatus.module.css";
import {Temperature} from "../icons/generated/custom/filled";
import {AppContext} from "../AppContextProvider";
import {translation, translationOrName} from "../i18n";
import commonStyles from "../common/Common.module.css";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import AnalyticsClickTracker from "../AnalyticsClickTracker";
import FiberSmartRecordIcon from "@material-ui/icons/FiberSmartRecord";
import CloseIcon from "@material-ui/icons/Close";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {deepEqual, emptyToNull, isAdmin} from "../common/utils";
import {NotificationContext} from "../NotificationContextProvider";
import {LeaveDialog} from "./LeaveDialog";
import SettingsPowerIcon from "@material-ui/icons/SettingsPower";

const esa = new EsaControllerApi();
const system = new SystemControllerApi();

export default function ConnectionStatusEsa() {
    const [systemInfo, setSystemInfo] = useState<SystemInfo>({networkInterfaces: []});
    const [device, setDevice] = useState<DeviceEsaData>(defaultDevice());
    const [open, setOpen] = useState(false);
    const {
        userDevice: {deviceId},
        webSocket,
        connected,
        user,
    } = useContext(AppContext);
    const {notify} = useContext(NotificationContext);
    const [errors, setErrors] = useState<{[p: string]: number}>();
    const [lastUpdatedDevice, setLastUpdatedDevice] = useState<DeviceEsaData | undefined>();
    const [connectedDevices, setConnectedDevices] = useState<number[]>([]);

    useEffect(() => {
        const deviceErrors = device.errors;

        if (errors && deepEqual(deviceErrors, errors)) {
            return;
        } else {
            setErrors(deviceErrors);
            if (Object.keys(deviceErrors).length > 0) {
                notify("error", "error.new_error", {
                    action: (
                        <Button onClick={() => setOpen(true)} startIcon={<FiberSmartRecordIcon />}>
                            {translation("button.status")}
                        </Button>
                    ),
                });
            }
        }
    }, [device, errors, notify]);

    useEffect(() => {
        if (connected && deviceId !== ConstantsNumberCommon.DEVICE_UNDEFINED) {
            esa.getEsa({deviceId}).then(setDevice);
        } else {
            setDevice(defaultDevice);
        }

        if (connected) {
            system.info().then(setSystemInfo);
        }
    }, [connected, deviceId, setDevice, setSystemInfo]);

    useEffect(() => {
        function messageListener(messageEvent: MessageEvent) {
            const json: WebSocketMessage = JSON.parse(messageEvent.data);
            const deviceUpd = json.deviceEsaData;

            if (deviceUpd && deviceUpd.id === deviceId) {
                setDevice(deviceUpd);
            }

            if (deviceUpd) {
                setLastUpdatedDevice(deviceUpd);
                setConnectedDevices(prevState => {
                    if (deviceUpd.powerStatus === PowerStatus.ON && !prevState.includes(deviceUpd.id)) {
                        return prevState.concat(deviceUpd.id);
                    } else if (deviceUpd.powerStatus === PowerStatus.OFF && prevState.includes(deviceUpd.id)) {
                        return prevState.filter(i => i !== deviceUpd.id);
                    } else {
                        return prevState;
                    }
                });
            }
        }

        webSocket.addEventListener("message", messageListener);
        return function cleanup() {
            webSocket.removeEventListener("message", messageListener);
        };
    }, [deviceId, webSocket, setDevice, setConnectedDevices, setLastUpdatedDevice]);

    function onConnectedDeviceChange() {
        if (lastUpdatedDevice && isAdmin(user)) {
            const translationValues = {
                alias: emptyToNull(lastUpdatedDevice.alias) ?? lastUpdatedDevice.mac,
            };

            if (lastUpdatedDevice.powerStatus === PowerStatus.ON) {
                notify("success", "component.connection_status.device_connected", {...{translationValues}});
            } else {
                notify("warning", "component.connection_status.device_disconnected", {...{translationValues}});
            }
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onConnectedDeviceChange, [connectedDevices, user]);

    return (
        <AnalyticsClickTracker componentId={"ConnectionStatus." + (open ? "Close" : "Open")}>
            <Grid
                id="connection_status"
                container
                style={{width: "fit-content", margin: 10}}
                onClick={() => setOpen(!open)}>
                <IconButton>
                    <Icon {...{connected, device, systemInfo}} />
                </IconButton>
            </Grid>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <Title {...{connected, device, systemInfo, setOpen}} />
            </Drawer>
        </AnalyticsClickTracker>
    );
}

function translateMessage(message: string) {
    if (message.includes("Time")) {
        return translation(message.includes("wrong") ? "error.time_wrong" : "error.time_difference", {
            value: message.split("#")[1],
        });
    } else {
        return message.includes("Unknown")
            ? translation("error.unknown", {value: message.split("#")[1]})
            : translationOrName("error", message.split(" ").join("_"));
    }
}

function Icon(props: {connected: boolean; device: DeviceEsaData}) {
    const {connected, device} = props;
    const deviceConnected = device.powerStatus === PowerStatus.ON;

    if (!connected) {
        return <OfflineBoltIcon id="status" className={styles.backendOffline} />;
    } else if (!deviceConnected) {
        return <StatusCircle id="status" className={styles.deviceDisconnected} />;
    } else if (Object.entries(device.errors).length > 0) {
        return <Error id="status" className={commonStyles.textError} />;
    } else {
        return <StatusCircle id="status" className={styles.deviceConnected} />;
    }
}

function Title(props: {connected: boolean; device: DeviceEsaData; systemInfo: SystemInfo; setOpen: Setter<boolean>}) {
    const {connected, device, systemInfo, setOpen} = props;
    const deviceConnected = device.powerStatus === PowerStatus.ON;
    const {user} = useContext(AppContext);
    const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);

    const deviceStatus = [
        {
            title: `${device.data.temperature}`,
            icon: <Temperature />,
            unit: "°C",
        },
    ];

    if (!connected) {
        return (
            <Grid
                container
                style={{width: 400, padding: 16, maxHeight: "100vh", height: "100vh"}}
                direction="column"
                alignItems="center"
                justify="center">
                {translation("component.connection_status.backend_offline")}
            </Grid>
        );
    } else {
        return (
            <Grid
                container
                style={{width: 400, padding: 16, maxHeight: "100vh", height: "100vh", maxWidth: "100vW"}}
                direction="column"
                alignItems="flex-start"
                justify="flex-start">
                <IconButton onClick={() => setOpen(false)} style={{position: "absolute", right: 16, top: 16}}>
                    <CloseIcon fontSize="large" />
                </IconButton>
                <Grid
                    item
                    container
                    alignItems="flex-start"
                    justify="flex-start"
                    direction="column"
                    style={{height: "fit-content"}}>
                    <Grid item>
                        <Typography variant="h6">
                            <b>{translation("title.device_information")}</b>
                        </Typography>
                    </Grid>
                    <Grid item container>
                        {deviceStatus.map((it, i) => (
                            <Grid key={i} item container justify="flex-start" alignItems="center">
                                {it.icon}
                                <Typography variant="h4" color="secondary">
                                    {it.title}
                                </Typography>
                                <Typography variant="caption">{it.unit}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                            {translation("label.mac_colon", {mac: device.mac})}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item style={{maxHeight: "35%", overflowY: "auto", width: "100%"}}>
                    {(deviceConnected && <EsaPopup {...{device}} />) ||
                        translation("component.connection_status.device_disconnected", {alias: ""})}
                </Grid>
                <SystemPopup {...{systemInfo}} />
                {isAdmin(user) && (
                    <Grid container justify="center">
                        <IconButton onClick={() => setLeaveDialogOpen(true)}>
                            <SettingsPowerIcon fontSize="large" />
                        </IconButton>
                        <LeaveDialog {...{leaveDialogOpen, setLeaveDialogOpen}} />
                    </Grid>
                )}
            </Grid>
        );
    }
}

function EsaPopup(props: {device: DeviceEsaData}) {
    const {
        device: {errors},
    } = props;
    const {notify} = useContext(NotificationContext);
    const deviceErrors = Object.entries(errors);

    const {
        userDevice: {deviceId},
    } = useContext(AppContext);

    function reset() {
        esa.resetAndReloadErrors({deviceId}).then(() => notify("info", "message.errors_reset"));
    }

    return (
        <Grid container direction="row">
            <List style={{maxHeight: 500, width: "100%", overflow: "auto", marginBottom: "auto"}}>
                {deviceErrors.map((e, i) => (
                    <React.Fragment key={`${i}_${e}`}>
                        {i === 0 && <Divider />}
                        <ListItem>
                            <ListItemAvatar>
                                <Badge badgeContent={e[1]} color="primary">
                                    <Avatar className={styles.errorIcon}>
                                        <PriorityHighIcon />
                                    </Avatar>
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText secondary={translateMessage(e[0])} />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
                <Grid container item justify="center" alignItems="center">
                    <IconButton style={{visibility: deviceErrors.length > 0 ? "visible" : "hidden"}} onClick={reset}>
                        <HighlightOffIcon fontSize="large" color="error" />
                    </IconButton>
                </Grid>
            </List>
        </Grid>
    );
}

function SystemPopup(props: {systemInfo: SystemInfo}) {
    const {systemInfo} = props;

    return (
        <Grid container style={{marginTop: "auto", overflowY: "auto", maxHeight: "50%"}}>
            <Grid container justify="space-between" alignItems="center">
                <Typography variant="h6" className={styles.spacer}>
                    <b>{translation("title.system_information")}</b>
                </Typography>
            </Grid>
            <Grid container direction="column" item>
                {systemInfo.networkInterfaces.map(i => (
                    <Grid item key={i.mac}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {translation("label.name_colon", {name: i.name})}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {translation("label.mac_colon", {mac: i.mac})}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {translation("label.ip_colon", {ip: i.ip.join(",")})}
                        </Typography>
                        <Divider />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export function defaultDevice(): DeviceEsaData {
    return {
        id: ConstantsNumberCommon.DEVICE_UNDEFINED,
        type: DeviceType.Esa,
        mac: "",
        alias: "",
        timeStamp: 0,
        errors: {},
        data: {
            onOff: true,
            gain: 0,
            timeout: 0,
            threshold: "0",
            burstSeparation: 0,
            temperature: 0,
            paperFeed: 0,
            sensor: 0,
            voltage: 0,
            lightPercentage: 0,
            lightStatus: PowerStatus.OFF,
            fwMajorVersion: 1,
            fwMinorVersion: 0,
        },
    };
}
