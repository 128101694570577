import React, {useContext, useMemo} from "react";
import {translation, translationOrName} from "./i18n";
import {NotificationContext} from "./NotificationContextProvider";

const DEFAULT_SPRING_ERROR_MESSAGE = "No message available";

export default function AppErrorHandler() {
    const {notify} = useContext(NotificationContext);

    useMemo(() => {
        function reportError(source: "error.client" | "error.server", error: string) {
            notify(
                "error",
                <>
                    {translation(source)}
                    <br />
                    {translationOrName("error", error)}
                </>,
            );
        }

        function getText(e: any): string {
            console.error("Error recovery ", e);
            return "" + (e.reason?.statusText ?? e.message ?? e.error?.text ?? e.error ?? e);
        }

        function errorHandler(e: ErrorEvent) {
            reportError("error.client", getText(e));
        }

        function promiseRejectionHandler(e: PromiseRejectionEvent) {
            try {
                e.reason
                    .clone()
                    .text()
                    .then((springErrorJson: string) => {
                        try {
                            const springError = JSON.parse(springErrorJson);
                            const error =
                                springError.message === DEFAULT_SPRING_ERROR_MESSAGE
                                    ? springError.error
                                    : springError.message;

                            if (error === "") {
                                reportError("error.server", getText(e));
                            } else {
                                reportError("error.server", error);
                            }
                        } catch (other) {
                            console.error("Error while processing Promise Rejection 1 ", other);
                            reportError("error.server", springErrorJson);
                        }
                    })
                    .catch((other: any) => {
                        console.error("Error while processing Promise Rejection 2 ", other);
                        reportError("error.server", getText(e));
                    });
            } catch (other) {
                console.error("Error while processing Promise Rejection 3", other);
                reportError("error.server", getText(e));
            }
        }

        window.addEventListener("error", errorHandler);
        window.addEventListener("unhandledrejection", promiseRejectionHandler);

        return function cleanup() {
            window.removeEventListener("error", errorHandler);
            window.removeEventListener("unhandledrejection", promiseRejectionHandler);
        };
    }, [notify]);

    return <></>;
}
