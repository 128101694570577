/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface UserDevice
 */
export interface UserDevice {
    /**
     *
     * @type {number}
     * @memberof UserDevice
     */
    deviceId: number;
    /**
     *
     * @type {string}
     * @memberof UserDevice
     */
    deviceAlias: string;
    /**
     *
     * @type {string}
     * @memberof UserDevice
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof UserDevice
     */
    userName?: string;
    /**
     *
     * @type {string}
     * @memberof UserDevice
     */
    countryCode?: string;
}

export function UserDeviceFromJSON(json: any): UserDevice {
    return UserDeviceFromJSONTyped(json, false);
}

export function UserDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDevice {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        deviceId: json["deviceId"],
        deviceAlias: json["deviceAlias"],
        userId: !exists(json, "userId") ? undefined : json["userId"],
        userName: !exists(json, "userName") ? undefined : json["userName"],
        countryCode: !exists(json, "countryCode") ? undefined : json["countryCode"],
    };
}

export function UserDeviceToJSON(value?: UserDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        deviceId: value.deviceId,
        deviceAlias: value.deviceAlias,
        userId: value.userId,
        userName: value.userName,
        countryCode: value.countryCode,
    };
}
