import * as React from "react";
import {ReactNode, useEffect, useState} from "react";
import {
    Button,
    createMuiTheme,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    StylesProvider,
    ThemeProvider,
} from "@material-ui/core";
import {camelCase, kebabCase, snakeCase} from "lodash";
import {GetApp} from "@material-ui/icons";
import {EnhancedTheme} from "./Theme";

const download = (filename: string, text: string) => {
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(new Blob([text], {type: "text/csv"}));
    a.download = filename;

    // Append anchor to body.
    document.body.appendChild(a);
    a.click();

    // Remove anchor from body
    document.body.removeChild(a);
};

type Casings = "Camel" | "Snake" | "Kebab";

const format = (variant: Casings, value: string) => {
    switch (variant) {
        case "Camel":
            return camelCase(value);
        case "Snake":
            return snakeCase(value);
        case "Kebab":
            return kebabCase(value);
    }
};

export const ThemeWrapper = (props: {
    children: ReactNode;
    theme?: EnhancedTheme;
    prefix?: string;
    includeCssBaseline?: boolean;
    downloadCssFile?: boolean;
    casing?: Casings;
}) => {
    const {children, includeCssBaseline, theme, prefix} = props;
    const [open, setOpen] = useState(Boolean(props.downloadCssFile));
    const casing = props.casing ?? "Camel";
    const defaultTheme = createMuiTheme();
    const currentTheme = theme ? theme : defaultTheme;
    const style = document.documentElement.style;

    type Entry = string | null;

    function getPropertyName(value: string) {
        return "--" + format(casing, value);
    }

    function getKeys(object: object, prefix?: string): Entry[] {
        const exclude = ["mixins", "images"];
        return Object.entries(object)
            .map(([key, value]) => {
                if (exclude.includes(key)) {
                    return null;
                }
                if (Array.isArray(value)) {
                    return value.map((it, index) => {
                        const result = getPropertyName((prefix ? `${prefix} ${key}` : key) + index);
                        style.setProperty(result, it);
                        return result;
                    });
                } else if (typeof value === "object") {
                    return getKeys(value, prefix ? `${prefix} ${key}` : key);
                } else if (typeof value === "function") {
                    return null;
                } else {
                    const result = prefix ? getPropertyName(`${prefix} ${key}`) : getPropertyName(key);
                    style.setProperty(result, value);
                    return result;
                }
            })
            .flat(10);
    }

    const getSpacings = () => {
        const prefixString = prefix ?? "";
        return [...Array(10)]
            .map((it, index) => index + 1)
            .concat([0.5])
            .map(it => {
                const propertyName = `--${prefixString}Spacing${it.toString().replace(".", "")}`;
                style.setProperty(propertyName, currentTheme.spacing(it).toString() + "px");
                return propertyName;
            });
    };

    const getCustom = () => {
        type Properties = {
            propertyName: string;
            value: string;
        };
        const customValues: Properties[] = [
            {
                propertyName: "--devPaletteSelected",
                value: `rgba(${currentTheme.palette.secondary.dark.replace("rgb(", "").replace(")", "")}, 0.3)`!,
            },
            {
                propertyName: "--devPaletteBackgroundSemiTransparentPaper",
                value: currentTheme.palette.background.paper + "F2",
            },
        ];
        customValues.map(it => style.setProperty(it.propertyName, it.value));

        return customValues.map(it => it.propertyName);
    };

    const setTheme = () => {
        getKeys(currentTheme, prefix);
        getSpacings();
        getCustom();
    };

    useEffect(setTheme);

    function downloadCSS() {
        download(
            "Variables.css",
            `:root {\n${[...getKeys(currentTheme, prefix), ...getSpacings()]
                .filter(it => it !== null)
                .map(
                    it => `${it}:unset;
    `,
                )
                .join("")}}`,
        );
    }

    function close() {
        setOpen(false);
    }

    return (
        <ThemeProvider theme={currentTheme}>
            <StylesProvider injectFirst>
                <Dialog {...{open}} onClose={close}>
                    <DialogTitle id="alert-dialog-title">Download CSS variable file ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Downloading and placing thi file in your <i>./src</i> directory should activate css autofill
                            option, for faster development. If you want disable rendering this dialog on every reload,
                            you should remove <i>downloadCssFile</i> property from wrapper <b>ThemeWrapper</b>{" "}
                            component.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={close} color="primary">
                            Cancel
                        </Button>

                        <Button onClick={downloadCSS} variant="contained" color="primary" startIcon={<GetApp />}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {includeCssBaseline && <CssBaseline />}
                {children}
            </StylesProvider>
        </ThemeProvider>
    );
};
