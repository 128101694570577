import React, {useContext, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Settings} from "@material-ui/icons";
import {Grid, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import SettingsPanel from "./components/SettingsPanel";
import {SettingsAdapterTree} from "./components/SettingsAdapter";
import {SettingsValues, Value} from "./adapter/types/settingsTypes";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {usePrevious} from "../common/ReactUtils";
import {deepCopy, isAdmin} from "../common/utils";
import {AppSettings, AppSettingsControl} from "../AppSettingsProvider";
import {translation} from "../i18n";
import {EsaControllerApi} from "../../api/generated/esa/apis";
import {AppContext} from "../AppContextProvider";
import AnalyticsClickTracker from "../AnalyticsClickTracker";
import CustomDialog from "../common/CustomDialog";
import styles from "../about-dialog/About.module.css";
import {NotificationContext} from "../NotificationContextProvider";
import {AppLayout} from "../LayoutManager";

export const api = new EsaControllerApi();

export function SettingsDialog() {
    const appSettings = useContext(AppSettings);
    const {user, analytics} = useContext(AppContext);
    const {setBurgerOpen} = useContext(AppLayout);

    const {
        updateSettings,
        updateDefaults,
        setOpenSettings,
        settingsPath,
        deviceCalibration,
        setDeviceCalibration,
        setSettingsPath,
        openSettings,
        defaultsInUse,
    } = useContext(AppSettingsControl);
    const [stagedSettings, setStagedSettings] = useState(deepCopy(appSettings));
    const prevOpen = usePrevious(openSettings);
    const {notify} = useContext(NotificationContext);

    useEffect(() => {
        if (prevOpen !== openSettings && openSettings) {
            setStagedSettings(deepCopy(appSettings));
        }
    }, [openSettings, appSettings, setStagedSettings, prevOpen]);

    useEffect(() => {
        if (defaultsInUse) {
            setStagedSettings(deepCopy(appSettings));
        }
    }, [defaultsInUse, appSettings, setStagedSettings]);

    function changeSetting(value: Value, name: string, path: string[]) {
        setStagedSettings(settings => {
            const property = path.reduce(
                (settings: SettingsValues, property: string) => settings[property] as SettingsValues,
                settings,
            );
            property[name] = value;
            analytics.track("SettingsChange", {path: path.join("."), name, value});
            return {...settings};
        });
    }

    function closeDialog() {
        setOpenSettings(false);
    }

    function setAsDefaults() {
        updateDefaults(deepCopy(stagedSettings));
        notify("success", "message.settings_updated");
    }

    function apply() {
        updateSettings(deepCopy(stagedSettings));
        notify("success", "message.settings_updated");

        if (settingsPath === "/DeviceCalibration" && deviceCalibration) {
            api.setDefaultDeviceCalibration({defaultDeviceCalibration: deviceCalibration}).then(() => {
                setDeviceCalibration(undefined);
                notify("success", "message.device_updated");
            });
        }
    }

    function handleOkClick() {
        apply();
        closeDialog();
        setBurgerOpen(false);
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                <ListItem button id="settings_button" onClick={() => setOpenSettings(true)}>
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                    <ListItemText primary={translation("title.settings")} />
                </ListItem>

                <CustomDialog
                    dialogTitle={<Typography variant="h5">{translation("title.settings")}</Typography>}
                    dialogActions={
                        <>
                            {isAdmin(user) && (
                                <Button
                                    onClick={setAsDefaults}
                                    variant="contained"
                                    color="primary"
                                    style={{marginRight: "auto"}}>
                                    {translation("button.set_default_for_all")}
                                </Button>
                            )}
                            <AnalyticsClickTracker componentId="SettingsDialog.Cancel" elementType="span">
                                <Button id="settings_action_cancel" onClick={closeDialog}>
                                    {translation("button.cancel")}
                                </Button>
                            </AnalyticsClickTracker>

                            <AnalyticsClickTracker componentId="SettingsDialog.Ok" elementType="span">
                                <Button
                                    id="settings_action_ok"
                                    onClick={handleOkClick}
                                    variant="contained"
                                    color="primary">
                                    {translation("button.ok")}
                                </Button>
                            </AnalyticsClickTracker>
                            <AnalyticsClickTracker componentId="SettingsDialog.Apply" elementType="span">
                                <Button id="settings_action_apply" onClick={apply} color="primary">
                                    {translation("button.apply")}
                                </Button>
                            </AnalyticsClickTracker>
                        </>
                    }
                    open={openSettings}
                    setOpen={setOpenSettings}>
                    <Grid container className={styles.container}>
                        <Grid item container xs={4} md={3} className={styles.sidebar}>
                            <SettingsAdapterTree {...{setSettingsPath, stagedSettings}} defaultPath={settingsPath} />
                        </Grid>
                        <Grid id="settings_controls_view" item xs={8} md={9} className={styles.licencesView}>
                            <SettingsPanel {...{settingsPath, changeSetting, stagedSettings}} />
                        </Grid>
                    </Grid>
                </CustomDialog>
            </div>
        </MuiPickersUtilsProvider>
    );
}
