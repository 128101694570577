import {Layout, Layouts, Responsive, WidthProvider} from "react-grid-layout";
import React, {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {UserComponents, UserComponentType} from "../apps/Components";
import SelectPopup from "./common/SelectPopup";
import {FormattedMessage} from "react-intl";
import Card from "@material-ui/core/Card";
import {CardHeader, IconButton} from "@material-ui/core";
import {Close, Settings} from "@material-ui/icons";
import styles from "./LayoutManager.module.css";
import {v4 as uuid} from "uuid";
import {i18nKey} from "./i18n";
import {Placeholder} from "./Placeholder";
import {AppSettingsControl} from "./AppSettingsProvider";
import clsx from "clsx";

const ResponsiveGridLayout = WidthProvider(Responsive);

export const placeholderScreen = {layout: {}, components: {}, config: {}, name: ""};

export type ScreenElementConfig = Record<string, string | number | boolean | any>;

export interface ScreenElement {
    component: UserComponentType;
    config?: ScreenElementConfig;
}

export type SaveComponentConfigCallback = (id: string, conf: ScreenElementConfig) => void;

export type Components = Record<string, ScreenElement>;

export type Screen = {
    layout: Layouts;
    components: Components;
    config?: ScreenElementConfig;
    name: string;
};

export type AppLayoutType = {
    screen: Screen;
    setScreen: Setter<Screen>;
    screenEditable: boolean;
    setScreenEditable: Setter<boolean>;
    addComponentShow: boolean;
    setAddComponentShow: Setter<boolean>;
    location: string;
    burgerOpen: boolean;
    setBurgerOpen: Setter<boolean>;
};

export const AppLayout = React.createContext<AppLayoutType>({
    screen: placeholderScreen,
    setScreen: () => {},
    screenEditable: false,
    setScreenEditable: () => {},
    addComponentShow: false,
    setAddComponentShow: () => {},
    location: "",
    burgerOpen: false,
    setBurgerOpen: () => {},
});

function addComponent(component: UserComponentType, screen: Screen, setScreen: Setter<Screen>) {
    const id = uuid();

    let newLayouts: Layouts = {};
    Object.entries(screen.layout).forEach(([key, value]) => {
        let newLayout = Array.from(value);
        newLayout.push({
            i: id,
            x: 0,
            y: 0,
            w: 3,
            h: 6,
            minW: 1,
            maxW: 16,
        });
        newLayouts[key] = newLayout;
    });

    setScreen(oldValue => {
        const newValue = {...oldValue};
        newValue.layout = newLayouts;
        newValue.components[id] = {component: component};
        return newValue;
    });
}

function deleteComponent(componentId: string, setScreen: Setter<Screen>) {
    setScreen(oldValue => {
        const newValue = {...oldValue};
        delete newValue.components[componentId];
        return newValue;
    });
}

function saveConfig(id: string, config: ScreenElementConfig, setScreen: Setter<Screen>) {
    setScreen(oldValue => {
        const newValue = {...oldValue};
        newValue.components[id].config = config;
        return newValue;
    });
}

export function LayoutManager(props: {children?: ReactNode; componentsWithSettings: string[]}) {
    const {
        screen,
        screen: {layout, components, config},
        setScreen,
        screenEditable,
        addComponentShow,
        setAddComponentShow,
    } = useContext(AppLayout);
    const [header, setHeader] = useState<Map<String, String | ReactNode>>(new Map<String, String | ReactNode>());
    const {setOpenSettings, setSettingsPath} = useContext(AppSettingsControl);
    const {setBurgerOpen} = useContext(AppLayout);
    const [rowHeight, setRowHeight] = useState(30);
    const {componentsWithSettings} = props;

    const setHeaderCallback = useCallback(
        (id: string, title: ReactNode | String) => {
            setHeader(header => {
                header.set(id, title);
                return new Map(header);
            });
        },
        [setHeader],
    );

    function openSettingsPath(path: string) {
        setBurgerOpen(true);
        setOpenSettings(true);
        setSettingsPath(path);
    }

    function showSettings(component: UserComponentType) {
        return componentsWithSettings.includes(component);
    }

    const contexts = Object.entries(components)
        .map(([, element]) => UserComponents.get(element.component))
        .flatMap(c => c?.contexts ?? [])
        .filter((item, i, ar) => ar.indexOf(item) === i);

    function resizeHandler(e?: UIEvent) {
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const ratio = vh / 24;
        const decimal = Math.round(ratio / 10) * 10;
        setRowHeight(Math.max(30, decimal));
    }

    useEffect(() => {
        resizeHandler();
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    return (
        <>
            <Contexts contexts={contexts}>
                <ResponsiveGridLayout
                    layouts={layout}
                    onLayoutChange={(current: Layout[], layout: Layouts) =>
                        setScreen(oldValue => ({...oldValue, layout}))
                    }
                    compactType={screenEditable ? null : "horizontal"}
                    breakpoints={{lg: 1200, md: 800, sm: 400}}
                    cols={{lg: 96, md: 48, sm: 48}}
                    preventCollision={true}
                    {...{rowHeight}}
                    width={1200}
                    /**
                     * css transforms promise to improve performance, but unfortunately it is a cause of multiple errors
                     * like blurry text, not working position:absolute and wrong getBoundingClientRect in Chrome
                     */
                    useCSSTransforms={false}
                    draggableHandle={"." + styles.handle}
                    isDraggable={screenEditable}
                    isDroppable={screenEditable}
                    isResizable={screenEditable}>
                    {Object.entries(components).map(([key, element]) => {
                        const Component = UserComponents.get(element.component)?.constructor ?? Placeholder;
                        const componentName = element.component.replace("Live", "").replace("Review", "");

                        return (
                            <Card id={element.component + "_component_wrapper"} key={key} className={styles.toolbox}>
                                {((!config?.removeHeader && !element.config?.removeHeader) || screenEditable) && (
                                    <CardHeader
                                        id={element.component + "_header"}
                                        action={
                                            <div className={styles.headerIcon}>
                                                {showSettings(element.component) && (
                                                    <IconButton
                                                        id={`quick_settings_${element.component}${
                                                            element.config?.view ? "_" + element.config.view : ""
                                                        }`.toLowerCase()}
                                                        size="small"
                                                        onClick={() =>
                                                            openSettingsPath(
                                                                `/Components/${
                                                                    ["Target", "Table"].includes(componentName)
                                                                        ? `Lomah/${componentName}`
                                                                        : componentName
                                                                }${
                                                                    element.config?.view
                                                                        ? "/" + element.config.view
                                                                        : ""
                                                                }`,
                                                            )
                                                        }>
                                                        <Settings fontSize="inherit" />
                                                    </IconButton>
                                                )}
                                                {screenEditable && (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => deleteComponent(key, setScreen)}>
                                                        <Close fontSize="inherit" />
                                                    </IconButton>
                                                )}
                                            </div>
                                        }
                                        subheader={
                                            <div style={{width: "100%", maxWidth: "100%"}}>
                                                {header.get(key) ? (
                                                    <div style={{width: "100%", maxWidth: "100%"}}>
                                                        {header.get(key)}
                                                    </div>
                                                ) : (
                                                    <FormattedMessage
                                                        defaultMessage={element.component}
                                                        id={i18nKey("components", element.component)}
                                                    />
                                                )}
                                            </div>
                                        }
                                        className={clsx(screenEditable && styles.editable, styles.handle)}
                                        style={{
                                            textAlign: "left",
                                            backgroundColor: "var(--devPaletteBackgroundDefault)",
                                            height: 40,
                                            maxWidth: "100%",
                                        }}
                                    />
                                )}

                                <Component
                                    id={key}
                                    setHeader={setHeaderCallback}
                                    config={element.config}
                                    saveConfig={(id: string, conf: ScreenElementConfig) =>
                                        saveConfig(id, conf, setScreen)
                                    }
                                />
                            </Card>
                        );
                    })}
                </ResponsiveGridLayout>
            </Contexts>
            <SelectPopup
                title="popup.name.add_component"
                show={addComponentShow}
                options={Array.from(UserComponents.keys())}
                onClick={value => addComponent(value as UserComponentType, screen, setScreen)}
                onClose={() => setAddComponentShow(false)}
            />
        </>
    );
}

export default function Contexts(props: {
    contexts: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
    children: React.ReactNode;
}) {
    const {contexts = [], children} = props;

    return (
        <>
            {contexts.reduceRight((acc, Comp) => {
                return <Comp>{acc}</Comp>;
            }, children)}
        </>
    );
}
