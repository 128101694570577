/*
##########################################################################
# This file is generated automatically 
using: src/components/icons/functions/generateImports.js
## Generator makes .tsx files that include import/export of custom 
icons that are located in : src/resources/icons/packs
## Pack's folder must contain 'pack name' directory => directories
of icon 'types'
##If introduced icon file names with different patterns => Must edit
getImportTitle() function in order to equalize import titles.
##########################################################################
#
# To update this and all other icon import/export files run: npm run icons
#
##########################################################################
*/

import React from "react";
import {SvgIcon, SvgIconProps} from "@material-ui/core";

import {ReactComponent as CarIcon} from "../../../../resources/icons/packs/military/outlined/001-car.svg";
import {ReactComponent as TruckIcon} from "../../../../resources/icons/packs/military/outlined/002-truck.svg";
import {ReactComponent as CruiserIcon} from "../../../../resources/icons/packs/military/outlined/003-cruiser.svg";
import {ReactComponent as HelicopterIcon} from "../../../../resources/icons/packs/military/outlined/004-helicopter.svg";
import {ReactComponent as SubmarineIcon} from "../../../../resources/icons/packs/military/outlined/005-submarine.svg";
import {ReactComponent as TankIcon} from "../../../../resources/icons/packs/military/outlined/006-tank.svg";
import {ReactComponent as JetPlaneIcon} from "../../../../resources/icons/packs/military/outlined/007-jet-plane.svg";
import {ReactComponent as Plane1Icon} from "../../../../resources/icons/packs/military/outlined/008-plane-1.svg";
import {ReactComponent as PlaneIcon} from "../../../../resources/icons/packs/military/outlined/009-plane.svg";
import {ReactComponent as TentIcon} from "../../../../resources/icons/packs/military/outlined/010-tent.svg";
import {ReactComponent as MilitaryHatIcon} from "../../../../resources/icons/packs/military/outlined/011-military-hat.svg";
import {ReactComponent as MolotovCocktailIcon} from "../../../../resources/icons/packs/military/outlined/012-molotov-cocktail.svg";
import {ReactComponent as BulletIcon} from "../../../../resources/icons/packs/military/outlined/013-bullet.svg";
import {ReactComponent as SignalIcon} from "../../../../resources/icons/packs/military/outlined/014-signal.svg";
import {ReactComponent as ArmyDogTagIcon} from "../../../../resources/icons/packs/military/outlined/015-army-dog-tag.svg";
import {ReactComponent as Chevron1Icon} from "../../../../resources/icons/packs/military/outlined/016-chevron-1.svg";
import {ReactComponent as ChevronIcon} from "../../../../resources/icons/packs/military/outlined/017-chevron.svg";
import {ReactComponent as TechnologyIcon} from "../../../../resources/icons/packs/military/outlined/018-technology.svg";
import {ReactComponent as RadiationIcon} from "../../../../resources/icons/packs/military/outlined/019-radiation.svg";
import {ReactComponent as GasMaskIcon} from "../../../../resources/icons/packs/military/outlined/020-gas-mask.svg";
import {ReactComponent as BootsIcon} from "../../../../resources/icons/packs/military/outlined/021-boots.svg";
import {ReactComponent as BackpackIcon} from "../../../../resources/icons/packs/military/outlined/022-backpack.svg";
import {ReactComponent as HandcuffsIcon} from "../../../../resources/icons/packs/military/outlined/023-handcuffs.svg";
import {ReactComponent as RadarIcon} from "../../../../resources/icons/packs/military/outlined/024-radar.svg";
import {ReactComponent as Medal2Icon} from "../../../../resources/icons/packs/military/outlined/025-medal-2.svg";
import {ReactComponent as BinocularsIcon} from "../../../../resources/icons/packs/military/outlined/026-binoculars.svg";
import {ReactComponent as PointerIcon} from "../../../../resources/icons/packs/military/outlined/027-pointer.svg";
import {ReactComponent as KnifeIcon} from "../../../../resources/icons/packs/military/outlined/028-knife.svg";
import {ReactComponent as SwissArmyIcon} from "../../../../resources/icons/packs/military/outlined/029-swiss-army.svg";
import {ReactComponent as SmokeIcon} from "../../../../resources/icons/packs/military/outlined/030-smoke.svg";
import {ReactComponent as Rifle1Icon} from "../../../../resources/icons/packs/military/outlined/031-rifle-1.svg";
import {ReactComponent as RifleIcon} from "../../../../resources/icons/packs/military/outlined/032-rifle.svg";
import {ReactComponent as TorpedoIcon} from "../../../../resources/icons/packs/military/outlined/033-torpedo.svg";
import {ReactComponent as BulletproofIcon} from "../../../../resources/icons/packs/military/outlined/034-bulletproof.svg";
import {ReactComponent as HelmetIcon} from "../../../../resources/icons/packs/military/outlined/035-helmet.svg";
import {ReactComponent as WalkieTalkieIcon} from "../../../../resources/icons/packs/military/outlined/036-walkie-talkie.svg";
import {ReactComponent as Aim1Icon} from "../../../../resources/icons/packs/military/outlined/037-aim-1.svg";
import {ReactComponent as BadgeIcon} from "../../../../resources/icons/packs/military/outlined/038-badge.svg";
import {ReactComponent as Medal1Icon} from "../../../../resources/icons/packs/military/outlined/039-medal-1.svg";
import {ReactComponent as MilitaryRankIcon} from "../../../../resources/icons/packs/military/outlined/040-military-rank.svg";
import {ReactComponent as MedalIcon} from "../../../../resources/icons/packs/military/outlined/041-medal.svg";
import {ReactComponent as DinamiteIcon} from "../../../../resources/icons/packs/military/outlined/042-dinamite.svg";
import {ReactComponent as Bomb1Icon} from "../../../../resources/icons/packs/military/outlined/043-bomb-1.svg";
import {ReactComponent as ParachuteIcon} from "../../../../resources/icons/packs/military/outlined/044-parachute.svg";
import {ReactComponent as BombIcon} from "../../../../resources/icons/packs/military/outlined/045-bomb.svg";
import {ReactComponent as GrenadeIcon} from "../../../../resources/icons/packs/military/outlined/046-grenade.svg";
import {ReactComponent as BulletsIcon} from "../../../../resources/icons/packs/military/outlined/047-bullets.svg";
import {ReactComponent as RevolverIcon} from "../../../../resources/icons/packs/military/outlined/048-revolver.svg";
import {ReactComponent as GunIcon} from "../../../../resources/icons/packs/military/outlined/049-gun.svg";
import {ReactComponent as AimIcon} from "../../../../resources/icons/packs/military/outlined/050-aim.svg";

export const Car = (props: SvgIconProps) => <SvgIcon {...props} component={CarIcon} viewBox="0 0 512 512" />;
export const Truck = (props: SvgIconProps) => <SvgIcon {...props} component={TruckIcon} viewBox="0 0 512 512" />;
export const Cruiser = (props: SvgIconProps) => <SvgIcon {...props} component={CruiserIcon} viewBox="0 0 512 512" />;
export const Helicopter = (props: SvgIconProps) => (
    <SvgIcon {...props} component={HelicopterIcon} viewBox="0 0 512 512" />
);
export const Submarine = (props: SvgIconProps) => (
    <SvgIcon {...props} component={SubmarineIcon} viewBox="0 0 512 512" />
);
export const Tank = (props: SvgIconProps) => <SvgIcon {...props} component={TankIcon} viewBox="0 0 512 512" />;
export const JetPlane = (props: SvgIconProps) => <SvgIcon {...props} component={JetPlaneIcon} viewBox="0 0 512 512" />;
export const Plane1 = (props: SvgIconProps) => <SvgIcon {...props} component={Plane1Icon} viewBox="0 0 512 512" />;
export const Plane = (props: SvgIconProps) => <SvgIcon {...props} component={PlaneIcon} viewBox="0 0 512 512" />;
export const Tent = (props: SvgIconProps) => <SvgIcon {...props} component={TentIcon} viewBox="0 0 512 512" />;
export const MilitaryHat = (props: SvgIconProps) => (
    <SvgIcon {...props} component={MilitaryHatIcon} viewBox="0 0 512 512" />
);
export const MolotovCocktail = (props: SvgIconProps) => (
    <SvgIcon {...props} component={MolotovCocktailIcon} viewBox="0 0 512 512" />
);
export const Bullet = (props: SvgIconProps) => <SvgIcon {...props} component={BulletIcon} viewBox="0 0 512 512" />;
export const Signal = (props: SvgIconProps) => <SvgIcon {...props} component={SignalIcon} viewBox="0 0 512 512" />;
export const ArmyDogTag = (props: SvgIconProps) => (
    <SvgIcon {...props} component={ArmyDogTagIcon} viewBox="0 0 512 512" />
);
export const Chevron1 = (props: SvgIconProps) => <SvgIcon {...props} component={Chevron1Icon} viewBox="0 0 512 512" />;
export const Chevron = (props: SvgIconProps) => <SvgIcon {...props} component={ChevronIcon} viewBox="0 0 512 512" />;
export const Technology = (props: SvgIconProps) => (
    <SvgIcon {...props} component={TechnologyIcon} viewBox="0 0 512 512" />
);
export const Radiation = (props: SvgIconProps) => (
    <SvgIcon {...props} component={RadiationIcon} viewBox="0 0 512 512" />
);
export const GasMask = (props: SvgIconProps) => <SvgIcon {...props} component={GasMaskIcon} viewBox="0 0 512 512" />;
export const Boots = (props: SvgIconProps) => <SvgIcon {...props} component={BootsIcon} viewBox="0 0 512 512" />;
export const Backpack = (props: SvgIconProps) => <SvgIcon {...props} component={BackpackIcon} viewBox="0 0 512 512" />;
export const Handcuffs = (props: SvgIconProps) => (
    <SvgIcon {...props} component={HandcuffsIcon} viewBox="0 0 512 512" />
);
export const Radar = (props: SvgIconProps) => <SvgIcon {...props} component={RadarIcon} viewBox="0 0 512 512" />;
export const Medal2 = (props: SvgIconProps) => <SvgIcon {...props} component={Medal2Icon} viewBox="0 0 512 512" />;
export const Binoculars = (props: SvgIconProps) => (
    <SvgIcon {...props} component={BinocularsIcon} viewBox="0 0 512 512" />
);
export const Pointer = (props: SvgIconProps) => <SvgIcon {...props} component={PointerIcon} viewBox="0 0 512 512" />;
export const Knife = (props: SvgIconProps) => <SvgIcon {...props} component={KnifeIcon} viewBox="0 0 512 512" />;
export const SwissArmy = (props: SvgIconProps) => (
    <SvgIcon {...props} component={SwissArmyIcon} viewBox="0 0 512 512" />
);
export const Smoke = (props: SvgIconProps) => <SvgIcon {...props} component={SmokeIcon} viewBox="0 0 512 512" />;
export const Rifle1 = (props: SvgIconProps) => <SvgIcon {...props} component={Rifle1Icon} viewBox="0 0 512 512" />;
export const Rifle = (props: SvgIconProps) => <SvgIcon {...props} component={RifleIcon} viewBox="0 0 512 512" />;
export const Torpedo = (props: SvgIconProps) => <SvgIcon {...props} component={TorpedoIcon} viewBox="0 0 512 512" />;
export const Bulletproof = (props: SvgIconProps) => (
    <SvgIcon {...props} component={BulletproofIcon} viewBox="0 0 512 512" />
);
export const Helmet = (props: SvgIconProps) => <SvgIcon {...props} component={HelmetIcon} viewBox="0 0 512 512" />;
export const WalkieTalkie = (props: SvgIconProps) => (
    <SvgIcon {...props} component={WalkieTalkieIcon} viewBox="0 0 512 512" />
);
export const Aim1 = (props: SvgIconProps) => <SvgIcon {...props} component={Aim1Icon} viewBox="0 0 512 512" />;
export const Badge = (props: SvgIconProps) => <SvgIcon {...props} component={BadgeIcon} viewBox="0 0 512 512" />;
export const Medal1 = (props: SvgIconProps) => <SvgIcon {...props} component={Medal1Icon} viewBox="0 0 512 512" />;
export const MilitaryRank = (props: SvgIconProps) => (
    <SvgIcon {...props} component={MilitaryRankIcon} viewBox="0 0 512 512" />
);
export const Medal = (props: SvgIconProps) => <SvgIcon {...props} component={MedalIcon} viewBox="0 0 512 512" />;
export const Dinamite = (props: SvgIconProps) => <SvgIcon {...props} component={DinamiteIcon} viewBox="0 0 512 512" />;
export const Bomb1 = (props: SvgIconProps) => <SvgIcon {...props} component={Bomb1Icon} viewBox="0 0 512 512" />;
export const Parachute = (props: SvgIconProps) => (
    <SvgIcon {...props} component={ParachuteIcon} viewBox="0 0 512 512" />
);
export const Bomb = (props: SvgIconProps) => <SvgIcon {...props} component={BombIcon} viewBox="0 0 512 512" />;
export const Grenade = (props: SvgIconProps) => <SvgIcon {...props} component={GrenadeIcon} viewBox="0 0 512 512" />;
export const Bullets = (props: SvgIconProps) => <SvgIcon {...props} component={BulletsIcon} viewBox="0 0 512 512" />;
export const Revolver = (props: SvgIconProps) => <SvgIcon {...props} component={RevolverIcon} viewBox="0 0 512 512" />;
export const Gun = (props: SvgIconProps) => <SvgIcon {...props} component={GunIcon} viewBox="0 0 512 512" />;
export const Aim = (props: SvgIconProps) => <SvgIcon {...props} component={AimIcon} viewBox="0 0 512 512" />;
