import {
    Button,
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ConstantsNumberCommon, ConstantsStringCommon, User, UsersControllerApi} from "../../../api/generated/esa";
import clsx from "clsx";
import styles from "../LoginDialog.module.css";
import {translation, translationOrName, translationString} from "../../i18n";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {AppContext} from "../../AppContextProvider";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {Cloud} from "./Cloud";
import {emptyToNull} from "../../common/utils";
import {v4 as uuid} from "uuid";
import {NotificationContext} from "../../NotificationContextProvider";

const usersControllerApi = new UsersControllerApi();

export const SESSION_SYNC_ID = "sessionSyncId";

export function LoginContent(props: {onLogin?: () => void; onCreate?: () => void}) {
    const {refresh, connected} = useContext(AppContext);
    const {onLogin, onCreate} = props;

    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [users, setUsers] = useState<User[]>([]);
    const [login, setLogin] = useState(true);
    const intl = useIntl();
    const style = {maxWidth: 400, margin: 8, width: "100%", paddingBottom: "var(--devSpacing1)"};

    const [userName, setUserName] = useState<string>("");
    const [userAlreadyExists, setUserAlreadyExists] = useState(false);
    const [userNameEntered, setUserNameEntered] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordNeeded, setPasswordNeeded] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const {notify} = useContext(NotificationContext);

    function loadUsers() {
        usersControllerApi.getAllUsers({includeAdmin: true}).then(allUsers => {
            if (allUsers.length === 1) {
                setSelectedUser(allUsers[0]);
            }
            setUsers(allUsers);
        });
    }

    useEffect(() => {
        setPassword("");
    }, [login]);

    useEffect(() => {
        if (connected) {
            loadUsers();
        }
    }, [connected]);

    const handleUsernameChange = useCallback(
        (usernameUpdate: string) => {
            setUserName(usernameUpdate);
            if (users.find(value => value.name.toLowerCase() === usernameUpdate.toLowerCase())) {
                setUserAlreadyExists(true);
            } else {
                setUserAlreadyExists(false);
            }
        },
        [users],
    );

    useEffect(() => {
        if (!userNameEntered) {
            handleUsernameChange(firstName.slice(0, 1).concat(lastName.replace(" ", "").trim()).toLowerCase());
        }
    }, [firstName, lastName, handleUsernameChange, userNameEntered]);

    function handleUsernameChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
        setUserNameEntered(true);
        handleUsernameChange(event.target.value);
    }

    function handlePasswordChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value.trim();
        if (value.length < ConstantsNumberCommon.PASSWORD_MAX_LENGTH) {
            setPassword(value);
            setPasswordError(false);
        }
    }

    function handleFirstnameChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setFirstName(value);
    }

    function handleLastnameChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setLastName(value);
    }

    function createUser() {
        if (onCreate) {
            onCreate();
            return;
        }

        if (passwordNeeded && password.length <= 0) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
            usersControllerApi
                .createUser({
                    userCreationRequest: {
                        name: userName,
                        secret: password,
                        firstName: firstName,
                        lastName: lastName,
                    },
                })
                .then(rookie => {
                    loadUsers();
                    setSelectedUser(rookie);
                    setPassword("");
                    setLogin(true);
                    setUserNameEntered(false);
                });
        }
    }

    function getUserLabel(user: User) {
        switch (user.id) {
            case ConstantsStringCommon.GUEST_USER_ID:
                return intl.formatMessage({id: "user.guest"});
            case ConstantsStringCommon.ADMIN_USER_ID:
                return intl.formatMessage({id: "user.admin"});
        }
        return user.name;
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === "Enter") {
            performLogin();
        }
    }

    function performLogin() {
        if (onLogin) {
            onLogin();
            return;
        }

        if (selectedUser !== null) {
            const formData = new URLSearchParams();
            formData.append("username", selectedUser.id);
            formData.append("password", password);

            const requestOptions: RequestInit = {
                method: "POST",
                credentials: "include",
                mode: "no-cors",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                body: formData,
            };

            fetch(`${window.location.origin}/login`, requestOptions)
                .then(response => {
                    if (response.status === 0 || (response.redirected && response.url.endsWith("error"))) {
                        setPassword("");
                        setPasswordError(true);
                    } else if (response.ok) {
                        setAvoidMultipleOpenTabs();
                        refresh();
                    } else {
                        response.text().then(error => notify("error", translationOrName("error", error)));
                    }
                })
                .finally();
        }
    }

    function switchToCreate() {
        setLogin(false);
        setPassword("");
        setUserName("");
        setFirstName("");
        setLastName("");
        setUserNameEntered(false);
    }

    return (
        <div className={styles.formPanelContainer}>
            <Grid item container className={styles.title}>
                <Typography variant="h3" component="h3">
                    <span
                        id="login_user"
                        onClick={() => {
                            setLogin(true);
                        }}
                        className={clsx(login && styles.active)}>
                        {translation("button.login")}
                    </span>

                    <span className={styles.separator}>|</span>
                    <span id="create_user" className={clsx(!login && styles.active)} onClick={switchToCreate}>
                        {translation("button.create")}
                    </span>
                </Typography>
            </Grid>

            {login ? (
                <Grid id="login_wrapper" item container direction="column" alignItems="flex-start" justify="flex-start">
                    <Autocomplete
                        id="username_select"
                        {...{style}}
                        options={users}
                        value={selectedUser}
                        className={styles.autocomplete}
                        getOptionLabel={getUserLabel}
                        fullWidth
                        {...{onKeyDown}}
                        getOptionSelected={(option: User, value: User) => {
                            return option.id === value.id;
                        }}
                        onChange={(event: object, user: User | null) => setSelectedUser(user)}
                        renderInput={params => {
                            return <TextField {...params} label={translation("label.input.user")} variant="outlined" />;
                        }}
                    />

                    <Collapse {...{style}} in={Boolean(selectedUser && selectedUser?.secretType)}>
                        <TextField
                            id="login_password_input"
                            fullWidth
                            error={passwordError}
                            value={password}
                            type={passwordVisible ? "text" : "password"}
                            label={translation("label.input.password")}
                            helperText={passwordError && translation("error.wrong_password")}
                            variant="outlined"
                            onFocus={() => setPasswordError(false)}
                            onChange={handlePasswordChangeEvent}
                            {...{onKeyDown}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {" "}
                                        <IconButton onClick={() => setPasswordVisible(old => !old)}>
                                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>{" "}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Collapse>

                    <Button
                        id="select_action"
                        disabled={selectedUser === null}
                        {...{style}}
                        onClick={performLogin}
                        size="large"
                        variant="contained"
                        color="primary">
                        {translation("button.select")}
                    </Button>

                    {emptyToNull(process.env.REACT_APP_CLOUD_URL) && <Cloud {...{style}} />}

                    <Button {...{style}} onClick={switchToCreate} size="large" variant="outlined" color="primary">
                        {translation("button.create")}
                    </Button>
                </Grid>
            ) : (
                <>
                    <Grid item container direction="column" alignItems="flex-start" justify="flex-start">
                        <Grid container {...{style}}>
                            <Grid id="firstname_input_container" container {...{style}}>
                                <TextField
                                    id="firstname_input"
                                    label={translation("label.input.firstname", {
                                        addition: translationString(intl, "label.input.optional_add"),
                                    })}
                                    fullWidth
                                    variant="outlined"
                                    value={firstName}
                                    onChange={handleFirstnameChangeEvent}
                                />
                            </Grid>
                            <Grid id="lastname_input_container" container {...{style}}>
                                <TextField
                                    id="lastname_input"
                                    label={translation("label.input.lastname", {
                                        addition: translationString(intl, "label.input.optional_add"),
                                    })}
                                    fullWidth
                                    variant="outlined"
                                    value={lastName}
                                    onChange={handleLastnameChangeEvent}
                                />
                            </Grid>
                            <Grid id="username_input_container" container {...{style}}>
                                <TextField
                                    required
                                    id="username_input"
                                    label={translation("label.input.username")}
                                    fullWidth
                                    variant="outlined"
                                    value={userName}
                                    error={userAlreadyExists}
                                    onBlur={() => {
                                        const trimmedUsername = userName.trim();
                                        setUserName(trimmedUsername);
                                        handleUsernameChange(trimmedUsername);
                                    }}
                                    helperText={userAlreadyExists ? translation("warning.user_already_exists") : ""}
                                    onChange={handleUsernameChangeEvent}
                                />
                            </Grid>
                            <Collapse {...{style}} in={passwordNeeded}>
                                <TextField
                                    id="create_password_input"
                                    fullWidth
                                    error={passwordError}
                                    value={password}
                                    type={passwordVisible ? "text" : "password"}
                                    label={translation("label.input.password")}
                                    helperText={passwordError && translation("error.wrong_password")}
                                    variant="outlined"
                                    onFocus={() => setPasswordError(false)}
                                    onChange={handlePasswordChangeEvent}
                                    {...{onKeyDown}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                {" "}
                                                <IconButton onClick={() => setPasswordVisible(old => !old)}>
                                                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>{" "}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Collapse>
                            <FormControl>
                                <FormControlLabel
                                    id="password_checkbox"
                                    style={{margin: "8px auto 8px 0"}}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={passwordNeeded}
                                            onChange={() => {
                                                setPasswordNeeded(prev => !prev);
                                                if (passwordNeeded) setPassword("");
                                            }}
                                            name="checkedA"
                                        />
                                    }
                                    label={translation("label.input.password")}
                                />
                                {!passwordNeeded && (
                                    <FormHelperText
                                        style={{
                                            color: "var(--devPaletteWarningDark)",
                                            backgroundColor: "var(--devPaletteBackgroundDefault)",
                                            padding: 8,
                                            borderRadius: 4,
                                        }}>
                                        {translation("message.prompt.remove_password")}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setLogin(true);
                                }}
                                color="primary">
                                {translation("button.cancel")}
                            </Button>
                            <Button
                                variant="contained"
                                id="create_user_action"
                                onClick={createUser}
                                color="primary"
                                disabled={userName.length === 0 || userAlreadyExists}>
                                {translation("button.create")}
                            </Button>
                        </div>
                    </Grid>
                </>
            )}
        </div>
    );
}

export function setAvoidMultipleOpenTabs() {
    const sessionSyncId = uuid();
    localStorage.setItem(SESSION_SYNC_ID, sessionSyncId);
    sessionStorage.setItem(SESSION_SYNC_ID, sessionSyncId);
}
