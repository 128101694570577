import React, {useContext} from "react";
import {Divider, Grid, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {translation, translationOrName, translationString} from "../i18n";
import clsx from "clsx";
import {useIntl} from "react-intl";

import style from "./SelectView.module.css";
import {ConstantsNumberEsa, ConstantsStringEsa, Discipline, PhaseType} from "../../api/generated/esa";
import {SelectContext} from "./SelectContextProvider";
import {timerFormat} from "../timer/Timer";

const second = 1000;
const durationMultiplier = 60 * second;

function getQualification(it: Discipline) {
    const firstPhase = it.phases[0];
    const phasesWithoutSighting = it.phases.filter(it => it.name !== ConstantsStringEsa.PHASE_NAME_SIGHTING);

    if (phasesWithoutSighting.length > 0) {
        const generatedDurations = phasesWithoutSighting.map(phase => {
            if (phase.trafficPattern && phase.type === PhaseType.TrafficLight) {
                const {shootingTime, repeat, waitingTime} = phase.trafficPattern;
                return {...phase, duration: (shootingTime + waitingTime) * repeat * second};
            } else
                return {
                    ...phase,
                    duration:
                        phase.duration === ConstantsNumberEsa.UNLIMITED_DURATION
                            ? phase.duration
                            : phase.duration * durationMultiplier,
                };
        });
        const allPhases = generatedDurations.reduce((acc, val) => {
            return {
                ...val,
                series: acc.series + val.series,
                shots: (acc.shots || 0) + (val.shots || 0),
                duration: val.duration + acc.duration,
            };
        });

        return {
            ...allPhases,
            duration:
                allPhases.duration === ConstantsNumberEsa.UNLIMITED_DURATION
                    ? allPhases.duration
                    : timerFormat(allPhases.duration),
        };
    }
    return firstPhase;
}

export function SelectDiscipline() {
    const {disciplineTargets, discipline, setDiscipline} = useContext(SelectContext);
    const intl = useIntl();

    return (
        <Grid id="discipline_list_wrapper" style={{height: "100%", overflow: "auto"}}>
            <List id="discipline_list">
                {disciplineTargets
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(it => {
                        const toDisplay = getQualification(it);
                        const unlimited = ConstantsNumberEsa.UNLIMITED_DURATION;
                        const {series, shots, duration} = toDisplay;
                        const detailsShots = intl.formatMessage(
                            {id: "label.discipline.details_shots." + (shots ? "limited" : "unlimited")},
                            {shots: shots},
                        );
                        const detailsDuration =
                            duration === unlimited
                                ? translationString(intl, "label.discipline.details_duration.unlimited")
                                : duration;
                        const detailsSeries = intl.formatMessage(
                            {
                                id:
                                    "label.discipline.details_series." +
                                    (series && series !== 1 ? "series" : "no_series"),
                            },
                            {series: series},
                        );

                        return (
                            <React.Fragment key={it.name}>
                                <ListItem
                                    id={`discipline_${it.name}`}
                                    component="li"
                                    onClick={() => setDiscipline(it.name)}
                                    button
                                    autoFocus={discipline === it.name}
                                    className={clsx(discipline === it.name && style.listItemSelected)}>
                                    <Grid item>
                                        <Typography variant="caption" component="p" color="textSecondary">
                                            {it.targets.length > 1
                                                ? translation("label.multiple_targets")
                                                : translationOrName("target", it.targets[0])}
                                        </Typography>
                                        {toDisplay && (
                                            <ListItemText
                                                primary={translationOrName("discipline", it.name)}
                                                secondary={detailsDuration + detailsShots + detailsSeries}
                                            />
                                        )}
                                    </Grid>
                                    <Typography
                                        variant="caption"
                                        component="p"
                                        color="textSecondary"
                                        style={{width: "fit-content", marginLeft: "auto"}}>
                                        {it.governingBody}
                                    </Typography>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
            </List>
        </Grid>
    );
}
