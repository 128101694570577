import React, {useContext, useEffect, useState} from "react";
import {Button, Grid, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import WeaponsIcon from "@material-ui/icons/SportsOutlined";
import {translation, translationOrName} from "../i18n";
import WeaponTable from "./WeaponTable";
import CustomDialog from "../common/CustomDialog";
import {WeaponView} from "./WeaponView";
import {Add, ArrowBack} from "@material-ui/icons";
import styles from "./WeaponManagement.module.css";
import {
    MeasurementUnit,
    TypeInfo,
    Weapon,
    WeaponCategory,
    WeaponInfoControllerApi,
    WeaponsControllerApi,
    WeaponType,
} from "../../api/generated/esa";
import {deepEqual} from "../common/utils";
import Confirmation from "../common/Confirmation";
import {ValidationError} from "yup";
import {NotificationContext} from "../NotificationContextProvider";

export const weaponEmpty: Weapon = {
    id: "",
    name: "Name",
    type: "None",
    category: "None",
    displayCaliber: undefined,
    caliberMm: 5.0,
    caliberInch: 0.2,
    measurementUnit: MeasurementUnit.Metric,
    chsGain: 7,
    nchsGain: 7,
    nchsTimeout: 70,
    nchsThreshold: 30,
};

export const weaponsControllerApi = new WeaponsControllerApi();
const weaponInfoControllerApi = new WeaponInfoControllerApi();

export default function WeaponInfo() {
    const [open, setOpen] = useState(false);
    const [editableWeapon, setEditableWeapon] = useState<Weapon | undefined>();
    const [weapons, setWeapons] = useState<Weapon[]>([]);
    const [loading, setLoading] = useState(true);
    const updateWeaponExists = editableWeapon && weapons.filter(weapon => weapon.id === editableWeapon.id)[0];
    const same = updateWeaponExists && editableWeapon && deepEqual(editableWeapon, updateWeaponExists);
    const {notify} = useContext(NotificationContext);
    const [showButton, setShowButton] = useState(true);
    const [weaponCategories, setWeaponCategories] = useState<string[]>([]);
    const [weaponTypes, setWeaponTypes] = useState<string[]>([]);
    const [weaponTypesRaw, setWeaponTypesRaw] = useState<WeaponType[]>([]);
    const [weaponTypesInfo, setWeaponTypesInfo] = useState<TypeInfo[]>([]);
    const [actionButton, setActionButton] = useState<"create" | "update">("create");
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

    function loadTypesRaw() {
        weaponInfoControllerApi.types().then((t: WeaponType[]) => setWeaponTypesRaw(t));
    }

    useEffect(() => {
        open && loadTypesRaw();
    }, [open]);

    function loadCategories() {
        open &&
            weaponInfoControllerApi.categories().then((categories: WeaponCategory[]) => {
                return categories.map(cat => {
                    return setWeaponCategories(cat.categories);
                });
            });
    }

    useEffect(loadCategories, [open]);

    function loadTypeInfo() {
        open && weaponTypesRaw.map((type: WeaponType) => setWeaponTypesInfo(type.types));
    }

    useEffect(loadTypeInfo, [weaponTypesRaw, open]);

    function loadTypes() {
        const typesList: string[] = [];
        weaponTypesInfo.map(t => typesList.push(t.name));
        setWeaponTypes(typesList);
    }

    useEffect(loadTypes, [weaponTypesInfo]);

    function loadWeapons() {
        setLoading(true);
        open &&
            weaponsControllerApi
                .getAllWeapons()
                .then((weapons: Weapon[]) => setWeapons(weapons))
                .finally(() => setLoading(false));
    }

    useEffect(loadWeapons, [open]);

    function updateWeapon(weapon: Weapon) {
        weaponsControllerApi
            .updateWeapon({weapon})
            .then(() => {
                notify("success", "message.weapons_editor.weapon_updated");
                discardChanges();
            })
            .finally(loadWeapons);
    }

    function createWeapon(weaponCreate: Weapon) {
        weaponsControllerApi.createWeapon({weapon: weaponCreate}).then(discardChanges).finally(loadWeapons);
    }

    function handleClose() {
        setOpen(false);
        setEditableWeapon(undefined);
        setShowButton(true);
        setValidationErrors([]);
    }

    function openWeaponSetting() {
        setOpen(true);
    }

    useEffect(() => {
        if (editableWeapon) {
            if (weapons.map(weapon => weapon.id).includes(editableWeapon.id)) {
                setActionButton("update");
            } else {
                setActionButton("create");
            }
        }
    }, [editableWeapon, weapons]);

    function discardChanges() {
        setEditableWeapon(undefined);
        setShowButton(true);
    }

    return (
        <Grid>
            <WeaponInfoButton onClick={openWeaponSetting} />
            <CustomDialog
                {...{open}}
                setOpen={handleClose}
                dialogTitle={
                    <Typography>
                        <b>{translation("title.weapons_editor")}</b>
                    </Typography>
                }
                dialogActions={
                    <>
                        <Grid container alignItems="center">
                            <Button
                                id="weapons_edit_new_weapon_input"
                                style={{visibility: showButton ? "visible" : "hidden", marginLeft: "auto"}}
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => {
                                    const weaponNew = {
                                        ...weaponEmpty,
                                        name: weaponEmpty.name,
                                    };
                                    setEditableWeapon(weaponNew);
                                }}>
                                {translation("button.weapons_editor.new_weapon")}
                            </Button>
                            {editableWeapon && (
                                <Button
                                    id="weapons_edit_update_action"
                                    disabled={validationErrors.length !== 0}
                                    onClick={() => {
                                        actionButton === "create"
                                            ? createWeapon(editableWeapon)
                                            : updateWeapon(editableWeapon);
                                    }}
                                    variant={same ? "outlined" : "contained"}
                                    color="primary">
                                    {translationOrName("button", actionButton)}
                                </Button>
                            )}
                            <Button id="weapons_edit_close_action" onClick={handleClose}>
                                {translation("button.close")}
                            </Button>
                        </Grid>
                    </>
                }>
                <Grid item xs={12} md={12} xl={12} className={styles.container}>
                    {editableWeapon ? (
                        <Grid item container direction="column" style={{overflow: "auto"}}>
                            <Grid item container>
                                <Confirmation
                                    hide={same}
                                    title="title.unsaved_changes"
                                    text="label.discard_changes"
                                    okButton="button.discard"
                                    okClassName={styles.redButton}
                                    onClick={discardChanges}>
                                    <Button
                                        id="weapons_edit_back_butn_action"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<ArrowBack />}>
                                        {translation("button.back")}
                                    </Button>
                                </Confirmation>
                            </Grid>
                            <WeaponView
                                {...{
                                    editableWeapon,
                                    setEditableWeapon,
                                    setShowButton,
                                    weaponCategories,
                                    weaponTypes,
                                    setValidationErrors,
                                    validationErrors,
                                }}
                            />
                        </Grid>
                    ) : (
                        <WeaponTable
                            {...{weapons, setEditableWeapon, weaponTypesInfo, loadWeapons, showButton, loading}}
                        />
                    )}
                </Grid>
            </CustomDialog>
        </Grid>
    );
}

export function WeaponInfoButton(props: {onClick: () => void}) {
    const {onClick} = props;

    return (
        <ListItem id="weapons_editor_control_button" button {...{onClick}}>
            <ListItemIcon>
                <WeaponsIcon />
            </ListItemIcon>
            <ListItemText primary={translation("title.weapons_editor")} />
        </ListItem>
    );
}
