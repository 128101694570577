/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface SessionComment
 */
export interface SessionComment {
    /**
     *
     * @type {string}
     * @memberof SessionComment
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof SessionComment
     */
    value: string;
}

export function SessionCommentFromJSON(json: any): SessionComment {
    return SessionCommentFromJSONTyped(json, false);
}

export function SessionCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionComment {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json["id"],
        value: json["value"],
    };
}

export function SessionCommentToJSON(value?: SessionComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        value: value.value,
    };
}
