import React, {useContext, useEffect, useMemo, useRef} from "react";
import {ReviewContext} from "../ReviewContextProvider";
import {translationOrName} from "../../i18n";
import {ContainerProps} from "../../lomah/Lomah";
import {Skeleton} from "@material-ui/lab";
import {Chip, Grid, IconButton, Menu} from "@material-ui/core";
import {HeaderContainer, weaponCaliberStringOrDefault} from "../../live-view/LiveTarget";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {Body1} from "../../common/TypographyVariants";
import {Adjust, Info, Room} from "@material-ui/icons";
import {Gun} from "../../icons/generated/warfare/filled";
import styles from "./Review.module.css";
import {ReactComponent as Caliber} from "@fortawesome/fontawesome-free/svgs/solid/dot-circle.svg";
import {ConstantsStringCommon, ConstantsStringEsa} from "../../../api/generated/esa";
import PhaseTarget from "../../target/PhaseTarget";

export function ReviewTarget(props: ContainerProps) {
    const {
        phase,
        selectedHit,
        setSelectedHit,
        hits,
        openSeries,
        tableSeries,
        target,
        weapon,
        isLoading,
        session,
        rangeName,
    } = useContext(ReviewContext);
    const targetContainer = useRef<HTMLDivElement>(null);
    const heightContainer = targetContainer.current?.clientHeight ?? 1;
    const widthContainer = targetContainer.current?.clientWidth ?? 1;
    const size = (heightContainer > widthContainer ? widthContainer : heightContainer) - 96;
    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
    const {id, setHeader} = props;
    const phaseName = phase?.name;
    const phaseDisciplineName = phase?.disciplineName;
    const sessionDeviceAlias = session?.deviceAlias;
    const realPhase = phaseDisciplineName !== ConstantsStringEsa.NO_TRAINING_NAME;

    const caliber = weaponCaliberStringOrDefault(weapon, target);

    const headerContent = useMemo(
        () => [
            rangeName && {id: "range", icon: <Room />, label: rangeName},
            sessionDeviceAlias && {
                id: "device",
                icon: <Adjust />,
                label: sessionDeviceAlias,
            },
            phaseDisciplineName &&
                realPhase && {
                    id: "discipline",
                    icon: <AccessTimeIcon />,
                    label: translationOrName("discipline", phaseDisciplineName),
                },
            phaseName && {
                id: "phase",
                icon: <AccessTimeIcon />,
                label: translationOrName("component.timer.phase", phaseName),
            },
            weapon.id !== ConstantsStringCommon.NO_WEAPON && {id: "weapon", icon: <Gun />, label: <>{weapon.name}</>},
            caliber && {id: "caliber", icon: <Caliber />, label: <>{caliber}</>},
        ],

        [phaseName, sessionDeviceAlias, phaseDisciplineName, caliber, weapon.id, weapon.name, rangeName, realPhase],
    );

    useEffect(() => {
        setHeader(
            id,
            <Grid style={{overflow: "hidden"}}>
                <Grid
                    container
                    direction="row"
                    style={{
                        margin: 0,
                        maxWidth: "100%",
                        width: "100%",
                        display: "flex",
                        flexWrap: "nowrap",
                        position: "relative",
                    }}>
                    {headerContent.map(item => {
                        return (
                            item && (
                                <Chip
                                    key={item.id + "_info"}
                                    size="small"
                                    className="score"
                                    icon={item.icon}
                                    label={<Body1>{item.label} </Body1>}
                                    variant="outlined"
                                    style={{border: "none", flex: "none"}}
                                />
                            )
                        );
                    })}
                    <div className={styles.moreInfoButton}>
                        <IconButton
                            onClick={e => e && setAnchorEl(e.currentTarget)}
                            style={{padding: 0, margin: "0 0 0 46px"}}
                            id="comment_button"
                            edge="end">
                            <Info />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                            {headerContent.map(item => {
                                return (
                                    item && (
                                        <div key={item.id + "_info_modal"}>
                                            <Chip
                                                size="small"
                                                className="score"
                                                icon={item.icon}
                                                label={<Body1>{item.label} </Body1>}
                                                variant="outlined"
                                                style={{border: "none", flex: "none"}}
                                            />
                                        </div>
                                    )
                                );
                            })}
                        </Menu>
                    </div>
                </Grid>
            </Grid>,
        );
    }, [id, setHeader, anchorEl, headerContent]);

    return (
        <>
            {phase && realPhase && !isLoading ? (
                <HeaderContainer>
                    <PhaseTarget
                        onSelect={setSelectedHit}
                        selected={selectedHit}
                        centerControls={false}
                        disableLatestHitHighlight
                        shotBlink={false}
                        series={tableSeries ? phase.series : 1}
                        {...{
                            phase,
                            weapon,
                            hits,
                            openSeries,
                            target,
                        }}
                    />
                </HeaderContainer>
            ) : (
                <Grid
                    ref={targetContainer}
                    container
                    justify="center"
                    alignItems="center"
                    style={{height: "100%", paddingBottom: 48}}>
                    <Skeleton
                        variant="circle"
                        height={size}
                        width={size}
                        style={{position: "absolute", top: `${size}`}}
                        animation={false}
                    />
                </Grid>
            )}
        </>
    );
}
