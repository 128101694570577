/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {CloudTraining, CloudTrainingFromJSON, User, UserFromJSON} from "../models";

export interface ExternalLoginRequest {
    token: string;
}

/**
 *
 */
export class CloudControllerApi extends runtime.BaseAPI {
    /**
     */
    async externalLoginRaw(requestParameters: ExternalLoginRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError(
                "token",
                "Required parameter requestParameters.token was null or undefined when calling externalLogin.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters["token"] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cloud/login`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => UserFromJSON(jsonValue));
    }

    /**
     */
    async externalLogin(requestParameters: ExternalLoginRequest): Promise<User> {
        const response = await this.externalLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async openApiDescriptorRaw(): Promise<runtime.ApiResponse<CloudTraining>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cloud/openApiDescriptor`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => CloudTrainingFromJSON(jsonValue));
    }

    /**
     */
    async openApiDescriptor(): Promise<CloudTraining> {
        const response = await this.openApiDescriptorRaw();
        return await response.value();
    }
}
