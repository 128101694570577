import React, {JSXElementConstructor, ReactNode} from "react";
import Lomah from "../components/lomah/Lomah";
import Score from "../components/Score";
import LastShot from "../components/LastShot";
import SpectatorTable from "../components/spectate-view/SpectatorTable";
import SpectatorTarget from "../components/spectate-view/SpectatorTarget";
import SpectatorMenu from "../components/spectate-view/SpectatorMenu";
import {ReviewTable} from "../components/review-view/components/ReviewTable";
import {ReviewCalendar} from "../components/review-view/components/ReviewCalendar";
import {ReviewTarget} from "../components/review-view/components/ReviewTarget";
import {ReviewPhases} from "../components/review-view/components/ReviewPhases";
import {SelectOptions} from "../components/select-view/SelectOptions";
import {SelectDiscipline} from "../components/select-view/SelectDiscipline";
import {SelectLane} from "../components/select-view/SelectLane";
import TimerMock from "./mock/TimerMock";
import SelectTargetMock from "./mock/SelectTargetMock";
import SingleShooterLiveContextProviderMock from "./mock/SingleShooterLiveContextProviderMock";
import ReviewContextProviderMock from "./mock/ReviewContextProviderMock";
import PortalMock from "./mock/PortalMock";
import {ReviewSessionsMock} from "./mock/ReviewSessionsMock";
import ApplicationHeaderMock from "./mock/ApplicationHeader.mock";
import SpectatorContextProvider from "../components/spectate-view/SpectatorContextProvider";
import {SaveComponentConfigCallback} from "../components/LayoutManager";
import {LiveTable} from "../components/live-view/LiveTable";
import {LiveTarget} from "../components/live-view/LiveTarget";
import SelectContextProvider from "../components/select-view/SelectContextProvider";
import {DarkTheme, LightTheme} from "../components/themes/Themes";
import AppLayoutProviderEsa from "../components/AppLayoutProvider.esa";
import {EnhancedPaletteType, EnhancedTheme} from "../components/themes/Theme";

export type LayoutComponentType = "ApplicationHeader" | "Portal" | "LayoutProvider";
export type UserComponentType =
    | "Lomah"
    | "Timer"
    | "Review"
    | "Score"
    | "SelectLane"
    | "LastShot"
    | "LiveTable"
    | "LiveTarget"
    | "SpectatorTable"
    | "SpectatorTarget"
    | "SpectatorMenu"
    | "ReviewTable"
    | "ReviewTarget"
    | "ReviewSessions"
    | "ReviewPhases"
    | "ReviewCalendar"
    | "SelectOptions"
    | "SelectTarget"
    | "SelectDiscipline";

export interface ComponentProps<T> {
    id: string;
    saveConfig: SaveComponentConfigCallback;
    config: T;
    setHeader: (id: string, header: ReactNode | String) => void;
}

export type Element = {
    constructor: JSXElementConstructor<ComponentProps<any>>;
    contexts: JSXElementConstructor<ComponentProps<React.PropsWithChildren<any>>>[];
};

export const LayoutComponents = new Map<LayoutComponentType, JSXElementConstructor<{}>>([
    ["ApplicationHeader", ApplicationHeaderMock],
    ["Portal", PortalMock],
    ["LayoutProvider", AppLayoutProviderEsa],
]);

export const UserComponents = new Map<UserComponentType, Element>([
    //Live view
    ["LiveTable", {constructor: LiveTable, contexts: [SingleShooterLiveContextProviderMock]}],
    ["LiveTarget", {constructor: LiveTarget, contexts: [SingleShooterLiveContextProviderMock]}],
    //Spectate view
    ["SpectatorTarget", {constructor: SpectatorTarget, contexts: [SpectatorContextProvider]}],
    ["SpectatorTable", {constructor: SpectatorTable, contexts: [SpectatorContextProvider]}],
    ["SpectatorMenu", {constructor: SpectatorMenu, contexts: [SpectatorContextProvider]}],
    //Review view
    ["ReviewTable", {constructor: ReviewTable, contexts: [ReviewContextProviderMock]}],
    ["ReviewTarget", {constructor: ReviewTarget, contexts: [ReviewContextProviderMock]}],
    ["ReviewSessions", {constructor: ReviewSessionsMock, contexts: [ReviewContextProviderMock]}],
    ["ReviewPhases", {constructor: ReviewPhases, contexts: [ReviewContextProviderMock]}],
    ["ReviewCalendar", {constructor: ReviewCalendar, contexts: [ReviewContextProviderMock]}],
    //Select view
    ["SelectLane", {constructor: SelectLane, contexts: []}],
    ["SelectDiscipline", {constructor: SelectDiscipline, contexts: [SelectContextProvider]}],
    ["SelectTarget", {constructor: SelectTargetMock, contexts: [SelectContextProvider]}],
    ["SelectOptions", {constructor: SelectOptions, contexts: [SelectContextProvider]}],
    //Components
    ["Lomah", {constructor: Lomah, contexts: []}],
    ["Timer", {constructor: TimerMock, contexts: []}],
    ["Score", {constructor: Score, contexts: []}],
    ["LastShot", {constructor: LastShot, contexts: []}],
]);

export const Themes = new Map<string, EnhancedTheme>([
    ["default", LightTheme],
    ["light", LightTheme],
    ["dark", DarkTheme],
]);

export function getTheme(themeType?: string | EnhancedPaletteType): EnhancedTheme {
    return Themes.get(themeType || "default")!;
}

export const LIFECYCLE = "demo";
