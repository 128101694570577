/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CountryCodes
 */
export interface CountryCodes {
    /**
     *
     * @type {Array<string>}
     * @memberof CountryCodes
     */
    countries: Array<string>;
}

export function CountryCodesFromJSON(json: any): CountryCodes {
    return CountryCodesFromJSONTyped(json, false);
}

export function CountryCodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryCodes {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        countries: json["countries"],
    };
}

export function CountryCodesToJSON(value?: CountryCodes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        countries: value.countries,
    };
}
