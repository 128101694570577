import React from "react";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {Copyright, Link} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import licences from "../../resources/licenses.json";
import {Title} from "../settings-dialog/adapter/components/TextComponents";

interface BackendModuleDescriptor {
    moduleName: string;
    moduleVersion: string;
    moduleUrls?: string[];
    moduleLicenses?: Array<{moduleLicense: string | null; moduleLicenseUrl: string}>;
}

export default function LicensesBackend() {
    function BackendModuleCard(props: {moduleDescriptor: BackendModuleDescriptor}) {
        const {moduleDescriptor} = props;
        return (
            <Card variant="outlined" style={{margin: "8px 0"}} id={moduleDescriptor.moduleName}>
                <CardHeader title={moduleDescriptor.moduleName} subheader={moduleDescriptor.moduleVersion} />
                <CardContent>
                    {moduleDescriptor.moduleUrls
                        ?.filter(moduleUrl => {
                            return moduleUrl.startsWith("http");
                        })
                        .map(moduleUrl => (
                            <Typography variant="h6" key={moduleUrl + "_url_" + +Math.random() * 420}>
                                <Link /> {moduleUrl}
                            </Typography>
                        ))}
                    {moduleDescriptor.moduleLicenses
                        ?.filter(value => {
                            return value.moduleLicense !== null;
                        })
                        .map(value => (
                            <Typography variant="h5" key={"licence_" + value.moduleLicenseUrl + Math.random() * 999}>
                                <Copyright /> {value.moduleLicense}
                            </Typography>
                        ))}
                </CardContent>
            </Card>
        );
    }

    return (
        <Grid container direction="column" style={{margin: 0}}>
            <Title value="Backend Licences" />
            {licences.dependencies.map(x => (
                <BackendModuleCard key={x.moduleName + "_backend_" + Math.random() * 969} moduleDescriptor={x} />
            ))}
        </Grid>
    );
}
