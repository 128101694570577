/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface NetworkInfo
 */
export interface NetworkInfo {
    /**
     *
     * @type {string}
     * @memberof NetworkInfo
     */
    name: string;
    /**
     *
     * @type {Array<string>}
     * @memberof NetworkInfo
     */
    ip: Array<string>;
    /**
     *
     * @type {string}
     * @memberof NetworkInfo
     */
    mac: string;
}

export function NetworkInfoFromJSON(json: any): NetworkInfo {
    return NetworkInfoFromJSONTyped(json, false);
}

export function NetworkInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkInfo {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: json["name"],
        ip: json["ip"],
        mac: json["mac"],
    };
}

export function NetworkInfoToJSON(value?: NetworkInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        ip: value.ip,
        mac: value.mac,
    };
}
