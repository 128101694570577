import React, {useContext, useEffect, useState} from "react";
import style from "./SpectatorTarget.module.css";
import {Avatar, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import {translation} from "../i18n";
import {SpectatorContext} from "./SpectatorContextProvider";
import {clearHelp, helpRequested, highlightLastHit} from "./SpectatorTable";
import clsx from "clsx";
import {usePrevious} from "../common/ReactUtils";
import {useTarget, useWeapon} from "../../customHooks";
import PhaseTarget from "../target/PhaseTarget";

export default function SpectatorTarget(props: {config: {index: number}}) {
    const {range, totalHits, lastHitDevice, helpRequests, setHelpRequests} = useContext(SpectatorContext);
    const [containerStyle, setContainerStyle] = useState<string>();
    const {
        config: {index},
    } = props;

    const prevTotalHits = usePrevious(totalHits);

    const lane = range[index];
    const phase = lane?.phase;
    const target = useTarget(phase?.targetName);
    const weapon = useWeapon(phase?.weaponId);

    useEffect(() => {
        if (phase) {
            setContainerStyle(
                helpRequested(lane.userName, helpRequests) ??
                    highlightLastHit(totalHits, prevTotalHits, phase.deviceId, lastHitDevice),
            );
        }
    }, [helpRequests, lane, lastHitDevice, phase, prevTotalHits, totalHits]);

    return (
        <>
            {phase ? (
                <div className={clsx(style.targetContainer, containerStyle)}>
                    <PhaseTarget
                        onSelect={() => {
                            clearHelp(lane.userName, helpRequests, setHelpRequests);
                        }}
                        hits={lane.hits.sort((a, b) => b.timeStamp - a.timeStamp)}
                        openSeries={Math.round(lane.phase.currentShots / lane.phase.maxShots / lane.phase.series) + 1}
                        series={phase.series}
                        {...{
                            phase,
                            weapon,
                            target,
                        }}
                    />

                    <Avatar className={style.firePoint}>{lane.fp}</Avatar>

                    <Grid container className={clsx(style.shootersInfo, containerStyle)}>
                        {lane.countryCode && getUnicodeFlagIcon(lane.countryCode)}
                        <Typography component="p" variant="body2">
                            {lane.laneName} {lane.userName && ` | ${lane.userName}`}
                        </Typography>
                    </Grid>
                </div>
            ) : (
                <Grid container className={style.placeholderForDevice} justify="center" alignItems="center">
                    <Typography variant="h4">{translation("label.no_device")}</Typography>
                </Grid>
            )}
        </>
    );
}
