import React, {useContext} from "react";
import {Grid, SvgIcon} from "@material-ui/core";
import {trackClick} from "../AnalyticsClickTracker";
import Typography from "@material-ui/core/Typography";
import styles from "../common/NavigationBar.module.css";
import {translation} from "../i18n";
import {AppContext} from "../AppContextProvider";
import {AppSettings} from "../AppSettingsProvider";
import BuildVersion from "./BuildVersion";
import {getTheme} from "../../apps/Components";

export default function Logo() {
    const {analytics} = useContext(AppContext);
    const {Global} = useContext(AppSettings);
    const theme = getTheme(Global.theme);

    return (
        <Grid
            id="header_reload"
            style={{width: "fit-content", margin: "0 8px"}}
            onClick={() => {
                trackClick(analytics, "AppLogo");
                window.location.reload();
            }}>
            <Typography variant="h6" color="primary" noWrap className={styles.toolbarTitle}>
                <SvgIcon
                    component={theme.images.applicationHeaderLogo}
                    viewBox="0 0 793 206.2"
                    style={{height: "24px", width: "auto", margin: "0 5px 0 0 "}}
                />
                {translation("title.main")}
                <sub style={{fontSize: "50%", margin: "0 0 auto 0"}}>{translation("title.sub")}</sub>
            </Typography>
            <div className={styles.toolbarSubtitleContainer}>
                <BuildVersion />
            </div>
        </Grid>
    );
}
