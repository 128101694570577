import {Grid, SvgIcon} from "@material-ui/core";
import React from "react";

export const IconWithLabel = (props: {title: string; icon?: any; customIcon?: any}) => {
    const {title, icon, customIcon} = props;
    return (
        <Grid container direction="row" alignItems="center" justify="center">
            <Grid item>
                {icon ? <div style={{marginRight: "5px"}}>{icon}</div> : ""}
                {customIcon ? <SvgIcon style={{marginRight: "5px"}} component={customIcon} color="inherit" /> : ""}
            </Grid>
            <Grid item>{title}</Grid>
        </Grid>
    );
};
