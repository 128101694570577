/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";
import {
    CloudTarget,
    CloudTargetFromJSON,
    CloudTargetToJSON,
    CloudTrainingHit,
    CloudTrainingHitFromJSON,
    CloudTrainingHitToJSON,
} from "./";

/**
 *
 * @export
 * @interface CloudTrainingPhase
 */
export interface CloudTrainingPhase {
    /**
     *
     * @type {string}
     * @memberof CloudTrainingPhase
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof CloudTrainingPhase
     */
    name?: string;
    /**
     *
     * @type {boolean}
     * @memberof CloudTrainingPhase
     */
    unlimited: boolean;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingPhase
     */
    maxShots: number;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingPhase
     */
    series: number;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingPhase
     */
    nextPhase?: number;
    /**
     *
     * @type {Date}
     * @memberof CloudTrainingPhase
     */
    created: Date;
    /**
     *
     * @type {Date}
     * @memberof CloudTrainingPhase
     */
    startTime?: Date;
    /**
     *
     * @type {Date}
     * @memberof CloudTrainingPhase
     */
    endTime?: Date;
    /**
     *
     * @type {Date}
     * @memberof CloudTrainingPhase
     */
    paused?: Date;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingPhase
     */
    duration: number;
    /**
     *
     * @type {string}
     * @memberof CloudTrainingPhase
     */
    scoring: string;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingPhase
     */
    currentShots: number;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingPhase
     */
    decimalScore: number;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingPhase
     */
    fullRingScore?: number;
    /**
     *
     * @type {CloudTarget}
     * @memberof CloudTrainingPhase
     */
    target: CloudTarget;
    /**
     *
     * @type {string}
     * @memberof CloudTrainingPhase
     */
    weaponName: string;
    /**
     *
     * @type {string}
     * @memberof CloudTrainingPhase
     */
    weaponDisplayCaliber: string;
    /**
     *
     * @type {number}
     * @memberof CloudTrainingPhase
     */
    weaponCaliberMm: number;
    /**
     *
     * @type {Array<CloudTrainingHit>}
     * @memberof CloudTrainingPhase
     */
    hits: Array<CloudTrainingHit>;
}

export function CloudTrainingPhaseFromJSON(json: any): CloudTrainingPhase {
    return CloudTrainingPhaseFromJSONTyped(json, false);
}

export function CloudTrainingPhaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudTrainingPhase {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json["id"],
        name: !exists(json, "name") ? undefined : json["name"],
        unlimited: json["unlimited"],
        maxShots: json["maxShots"],
        series: json["series"],
        nextPhase: !exists(json, "nextPhase") ? undefined : json["nextPhase"],
        created: new Date(json["created"]),
        startTime: !exists(json, "startTime") ? undefined : new Date(json["startTime"]),
        endTime: !exists(json, "endTime") ? undefined : new Date(json["endTime"]),
        paused: !exists(json, "paused") ? undefined : new Date(json["paused"]),
        duration: json["duration"],
        scoring: json["scoring"],
        currentShots: json["currentShots"],
        decimalScore: json["decimalScore"],
        fullRingScore: !exists(json, "fullRingScore") ? undefined : json["fullRingScore"],
        target: CloudTargetFromJSON(json["target"]),
        weaponName: json["weaponName"],
        weaponDisplayCaliber: json["weaponDisplayCaliber"],
        weaponCaliberMm: json["weaponCaliberMm"],
        hits: (json["hits"] as Array<any>).map(CloudTrainingHitFromJSON),
    };
}

export function CloudTrainingPhaseToJSON(value?: CloudTrainingPhase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        unlimited: value.unlimited,
        maxShots: value.maxShots,
        series: value.series,
        nextPhase: value.nextPhase,
        created: value.created.toISOString(),
        startTime: value.startTime === undefined ? undefined : value.startTime.toISOString(),
        endTime: value.endTime === undefined ? undefined : value.endTime.toISOString(),
        paused: value.paused === undefined ? undefined : value.paused.toISOString(),
        duration: value.duration,
        scoring: value.scoring,
        currentShots: value.currentShots,
        decimalScore: value.decimalScore,
        fullRingScore: value.fullRingScore,
        target: CloudTargetToJSON(value.target),
        weaponName: value.weaponName,
        weaponDisplayCaliber: value.weaponDisplayCaliber,
        weaponCaliberMm: value.weaponCaliberMm,
        hits: (value.hits as Array<any>).map(CloudTrainingHitToJSON),
    };
}
