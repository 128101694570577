import {ConstantsNumberCommon, User} from "../../api/generated/esa";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    Button,
    Chip,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
} from "@material-ui/core";
import {Description} from "../settings-dialog/adapter/components/TextComponents";
import {Close, Visibility, VisibilityOff} from "@material-ui/icons";
import styles from "./UserManagment.module.css";
import {AddRoles, Role} from "./components/AddRoles";
import {AppContext} from "../AppContextProvider";
import {translation, translationOrName, translationStringOrName} from "../i18n";
import {FormatterSelect} from "./components/FormatterSelect";
import {isAdmin, isSuperAdmin} from "../common/utils";
import {SuperAdminPanel} from "./SuperAdminPanel";
import Confirmation from "../common/Confirmation";
import {ValidationError} from "yup";
import {userSchema} from "./UserValidationSchema";
import {useIntl} from "react-intl";
import {NotificationContext} from "../NotificationContextProvider";

export function UserView(props: {
    user: User;
    setNewUser: Setter<User>;
    newUser: User;
    password?: string;
    setPassword: Setter<string | undefined>;
    passwordChanged: boolean;
    setPasswordChanged: Setter<boolean>;
    competitionClasses: string[];
    countries: string[];
    setValidationErrors: Setter<ValidationError[]>;
    validationErrors: ValidationError[];
    users: User[];
}) {
    const {user: currentUser} = useContext(AppContext);
    const {
        name,
        roles,
        description,
        id,
        secretType,
        firstName,
        lastName,
        yearOfBirth,
        competitiveClass,
        country,
    } = props.user;
    const {
        setNewUser,
        user,
        password,
        setPassword,
        passwordChanged,
        setPasswordChanged,
        competitionClasses,
        countries,
        setValidationErrors,
        validationErrors,
        users,
    } = props;

    const [userName, setUserName] = useState<string>(name);
    const [userYearOfBirth, setUserYearOfBirth] = useState(yearOfBirth);
    const [userRoles, setUserRoles] = useState(roles as Role[]);
    const [userDescription, setUserDescription] = useState(description);
    const [userFirstName, setUserFirstName] = useState(firstName);
    const [userLastName, setUserLastName] = useState(lastName);
    const admin = isAdmin(currentUser);
    const [hasPassword, setHasPassword] = useState(Boolean(secretType));
    const [userCompetitionClass, setUserCompetitionClass] = useState(competitiveClass);
    const [userCountry, setUserCountry] = useState(country);
    const {notify} = useContext(NotificationContext);
    const intl = useIntl();
    const [viewPassword, setViewPassword] = useState(false);

    useEffect(() => {
        setNewUser({
            ...user,
            name: userName,
            roles: userRoles,
            description: userDescription,
            firstName: userFirstName,
            lastName: userLastName,
            yearOfBirth: userYearOfBirth && +userYearOfBirth,
            competitiveClass: userCompetitionClass,
            country: userCountry,
            secretType: hasPassword ? "password" : undefined,
        });
    }, [
        userName,
        userRoles,
        userDescription,
        userFirstName,
        userLastName,
        userYearOfBirth,
        userCompetitionClass,
        userCountry,
        user,
        setNewUser,
        id,
        firstName,
        lastName,
        yearOfBirth,
        competitiveClass,
        country,
        hasPassword,
    ]);

    function handlePasswordChange(passwordUpdate: string) {
        if (passwordUpdate.length < ConstantsNumberCommon.PASSWORD_MAX_LENGTH) {
            setPassword(passwordUpdate);
        }
    }

    function removeRole(role: Role) {
        setUserRoles(userRoles.filter(it => it !== role));
        notify("info", "message.role_removed");
    }

    const validateInput = useCallback(
        (didPasswordChanged?: boolean) => {
            userSchema
                .validate(
                    {userName: userName, yearOfBirth: userYearOfBirth ? userYearOfBirth : null, password: password},
                    {
                        abortEarly: false,
                        context: {
                            exist: didPasswordChanged || passwordChanged,
                            users: users,
                            name: name,
                        },
                    },
                )
                .then(() => {
                    setValidationErrors([]);
                })
                .catch((err: ValidationError) => {
                    setValidationErrors(err.inner);
                });
        },
        [userName, userYearOfBirth, password, passwordChanged, setValidationErrors, name, users],
    );

    useEffect(() => {
        if (validationErrors.length > 0) {
            validateInput();
        }
    }, [userYearOfBirth, password, userName, validateInput, validationErrors.length]);

    function findErrorMessage(input: string): string | undefined {
        const message = validationErrors.find(it => it.path === input)?.message;

        if (!message) {
            return "";
        }

        return translationStringOrName(intl, "error", message);
    }

    function findErrorExists(input: string): boolean {
        return Boolean(validationErrors.find(it => it.path === input));
    }

    function yearOfBirthChange(value: string) {
        if (value !== "" && value) {
            setUserYearOfBirth(+value.slice(0, 4));
        } else {
            setUserYearOfBirth(undefined);
        }
    }

    return (
        <>
            {isSuperAdmin(props.user) ? (
                <SuperAdminPanel />
            ) : (
                <Grid container>
                    <Grid item container xs={12} md={6} lg={6}>
                        <Grid container direction="column" item spacing={3} style={{padding: 32}}>
                            <Description value={translation("title.user.basic")} />
                            <TextField
                                id="username_input_user_view"
                                onBlur={() => validateInput()}
                                onChange={e => {
                                    const res = e.target.value;
                                    res.length < ConstantsNumberCommon.USERNAME_MAX_LENGTH && setUserName(res);
                                }}
                                className={styles.userManagementInput}
                                fullWidth
                                value={userName}
                                label={translation("label.input.username")}
                                variant="outlined"
                                error={findErrorExists("userName")}
                                helperText={findErrorMessage("userName")}
                            />
                            <TextField
                                id="description_input_user_view"
                                onChange={e => setUserDescription(e.target.value)}
                                className={styles.userManagementInput}
                                fullWidth
                                value={userDescription}
                                label={translation("label.input.description")}
                                multiline
                                variant="outlined"
                            />

                            <Description value={translation("title.user.roles")} />
                            <Grid container spacing={2} justify="flex-start">
                                {userRoles.map(role => (
                                    <Grid item id={`role_${role}`} key={`role_${role}`} style={{width: "fit-content"}}>
                                        <Chip
                                            style={{margin: "0 8px"}}
                                            label={translationOrName("roles", role)}
                                            onDelete={() => removeRole(role)}
                                            deleteIcon={admin ? <Close /> : <></>}
                                        />
                                    </Grid>
                                ))}
                                {admin && <AddRoles {...{setUserRoles, userRoles}} />}
                            </Grid>
                            <Description value={translation("title.user.security")} />

                            {hasPassword && passwordChanged && (
                                <TextField
                                    id="change_password_input_user_view"
                                    fullWidth
                                    type={viewPassword ? "text" : "password"}
                                    onBlur={() => validateInput()}
                                    value={password || ""}
                                    onChange={e => handlePasswordChange(e.target.value.trim())}
                                    label={translation("label.input.change_password")}
                                    variant="outlined"
                                    error={findErrorExists("password")}
                                    helperText={findErrorMessage("password")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setViewPassword(state => !state)}
                                                    edge="end">
                                                    {viewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}

                            {hasPassword && !passwordChanged && (
                                <Button
                                    id="change_password_button_user_view"
                                    variant="outlined"
                                    onClick={() => {
                                        setPasswordChanged(true);
                                        setPassword(undefined);
                                        validateInput(true);
                                    }}>
                                    {translation("label.input.change_password")}
                                </Button>
                            )}

                            {hasPassword && (
                                <Confirmation
                                    title="title.prompt.remove_password"
                                    text="message.prompt.remove_password"
                                    okButton="button.remove_password"
                                    onClick={() => {
                                        setPassword(undefined);
                                        setPasswordChanged(false);
                                        setHasPassword(false);
                                        validateInput(false);
                                    }}>
                                    <Button
                                        id="remove_password_dialog_user_view"
                                        style={{margin: "16px 0"}}
                                        variant="outlined">
                                        {translation("button.remove_password")}
                                    </Button>
                                </Confirmation>
                            )}

                            {!hasPassword && (
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        {translation("label.user.no_password_protection")}
                                    </FormLabel>
                                    <FormGroup>
                                        <Button
                                            id="add_password_input_user_view"
                                            variant="outlined"
                                            onClick={() => {
                                                setPasswordChanged(true);
                                                setHasPassword(true);
                                                validateInput(true);
                                            }}>
                                            {translation("label.input.add_password")}
                                        </Button>
                                    </FormGroup>
                                </FormControl>
                            )}
                            <div style={{opacity: "0.4"}}>
                                <Description value={translation("title.user.user_identification")} />
                                <Grid container alignItems="center">
                                    <b>{translation("label.id")} </b> {id}{" "}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6} lg={6}>
                        <Grid container item style={{padding: 32}} spacing={3} direction="column">
                            <Description value={translation("title.user.personal_data")} />
                            <TextField
                                id="name_input_user_view"
                                onChange={e => setUserFirstName(e.target.value)}
                                className={styles.userManagementInput}
                                label={translation("label.input.firstname", {addition: ""})}
                                fullWidth
                                variant="outlined"
                                value={userFirstName}
                            />
                            <TextField
                                id="surname_input_user_view"
                                onChange={e => setUserLastName(e.target.value)}
                                className={styles.userManagementInput}
                                label={translation("label.input.lastname", {addition: ""})}
                                fullWidth
                                variant="outlined"
                                value={userLastName}
                            />
                            <TextField
                                id="birth_year_input_user_view"
                                onBlur={() => validateInput()}
                                onChange={e => yearOfBirthChange(e.target.value)}
                                type="number"
                                className={styles.userManagementInput}
                                label={translation("label.input.year_of_birth")}
                                fullWidth
                                variant="outlined"
                                value={userYearOfBirth}
                                error={findErrorExists("yearOfBirth")}
                                helperText={findErrorMessage("yearOfBirth")}
                            />
                            {countries.length > 0 && (
                                <Grid>
                                    <Description value={translation("title.user.country")} />

                                    <FormatterSelect
                                        source={userCountry}
                                        setSource={setUserCountry}
                                        prefix="settings"
                                        selectables={countries}
                                    />
                                </Grid>
                            )}
                            {competitionClasses.length > 0 && (
                                <Grid>
                                    <Description value={translation("title.user.competition_class")} />
                                    <FormatterSelect
                                        source={userCompetitionClass}
                                        setSource={setUserCompetitionClass}
                                        prefix="settings"
                                        selectables={competitionClasses}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
