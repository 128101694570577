import React from "react";
import {useIntl} from "react-intl";
import ReactMarkdown from "react-markdown";
import {TranslationKey, translationString} from "../i18n";

export default function TranslatedMarkdown(props: {translationKey: TranslationKey}) {
    const intl = useIntl();
    const {translationKey} = props;

    return <ReactMarkdown source={translationString(intl, translationKey)} />;
}
