import React, {ReactNode, useMemo} from "react";

import {OptionsObject, SnackbarKey, SnackbarProvider, useSnackbar, VariantType} from "notistack";
import {PrimitiveType} from "intl-messageformat";
import {translateIfString} from "./common/Popup";
import {TranslationKey} from "./i18n";
import {omit} from "lodash";

const notificationSpacer = 40;

type NotificationOptions = OptionsObject & {
    translationValues?: Record<string, PrimitiveType>;
    closeOnClick?: boolean;
};

export interface NotificationContextType {
    notify: (
        type: VariantType,
        translationKeyOrElement: TranslationKey | JSX.Element,
        options?: NotificationOptions,
    ) => void;
    unNotify: (key?: SnackbarKey) => void;
}

export const NotificationContext = React.createContext<NotificationContextType>({
    notify: (type: VariantType, translationKeyOrElement: string | JSX.Element) =>
        alert(`notify function is called without the context!\n[${type}] ${translationKeyOrElement}`),
    unNotify: () => {},
});

function Notifications(props: {children?: ReactNode}) {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const notify = useMemo(
        () => (
            variant: VariantType,
            translationKeyOrElement: TranslationKey | JSX.Element,
            options?: NotificationOptions,
        ) => {
            const key = enqueueSnackbar(translateIfString(translationKeyOrElement, options?.translationValues), {
                persist: variant === "error",
                ...omit(options, "translationValues"),
                variant,
                onClick: e => {
                    options?.onClick && options?.onClick(e);
                    if (options?.closeOnClick !== false) {
                        closeSnackbar(key);
                    }
                },
            });
        },
        [enqueueSnackbar, closeSnackbar],
    );

    const context = useMemo<NotificationContextType>(
        () => ({
            ...{notify, unNotify: closeSnackbar},
        }),
        [notify, closeSnackbar],
    );

    return <NotificationContext.Provider value={context}>{props.children}</NotificationContext.Provider>;
}

export default function NotificationContextProvider(props: {children?: ReactNode}) {
    return (
        <SnackbarProvider
            style={{marginTop: notificationSpacer}}
            maxSnack={3}
            hideIconVariant
            preventDuplicate={true}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}>
            <Notifications>{props.children}</Notifications>
        </SnackbarProvider>
    );
}
