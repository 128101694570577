import React, {ReactNode, useContext} from "react";
import {Chip, IconButton, List, ListItem, Menu} from "@material-ui/core";

import {translation} from "../../i18n";
import {ReviewContext} from "../ReviewContextProvider";
import {ReactComponent as Score} from "@fortawesome/fontawesome-free/svgs/solid/award.svg";
import {ReviewSessionsWrapper} from "./ReviewSessionsWrapper";
import ReviewSessionsText from "./ReviewSessionsText";
import {SessionPrintButton} from "../review/components/PrintButton";
import {TrainingSession} from "../../../api/generated/esa";
import {ExportButton} from "../review/components/ExportButton";
import {Skeleton} from "@material-ui/lab";
import {ReactComponent as MenuIcon} from "@fortawesome/fontawesome-free/svgs/solid/caret-square-down.svg";
import RemoveButton from "../review/components/RemoveButton";
import {SessionCommentButton} from "../review/components/CommentButton";
import styles from "./Review.module.css";
import NoData from "../../common/NoData";
import {SaveShotsButton} from "../review/components/SaveShotsButton";

export function ReviewSessions() {
    return (
        <ReviewSessionsContent
            actions={s => (
                <ReviewSessionsText session={s}>
                    <SessionCommentButton sessionId={s.id} />
                    <ReviewSessionsMenu session={s} />
                </ReviewSessionsText>
            )}
        />
    );
}

export function ReviewSessionsContent(props: {actions: (session: TrainingSession) => ReactNode}) {
    const {setPhase, session, setSession, sessions, isLoading} = useContext(ReviewContext);
    const {actions} = props;

    function selectSession(session: TrainingSession) {
        setSession(session);
        setPhase(session.phases[0]);
    }

    if (isLoading) {
        return (
            <ReviewSessionsWrapper title={translation("title.training_sessions")}>
                <div style={{padding: 8}}>
                    <Skeleton variant="rect" style={{width: "100%", marginBottom: 8}} height={65} />
                    <Skeleton variant="rect" style={{width: "100%"}} height={65} />
                </div>
            </ReviewSessionsWrapper>
        );
    } else if (sessions.length === 0) {
        return (
            <ReviewSessionsWrapper title={translation("title.training_sessions")}>
                <NoData />
            </ReviewSessionsWrapper>
        );
    } else {
        return (
            <ReviewSessionsWrapper title={translation("title.training_sessions")}>
                <List id="training_sessions" component="ul" style={{padding: 0}}>
                    {sessions
                        .sort((s1, s2) => s2.startTime - s1.startTime)
                        .map(s => {
                            return (
                                <ListItem
                                    key={s.id}
                                    selected={session?.id === s.id}
                                    onClick={() => selectSession(s)}
                                    ContainerProps={
                                        {"data-training-session-id": s.id} as React.HTMLAttributes<HTMLDivElement>
                                    }
                                    button>
                                    {actions(s)}
                                </ListItem>
                            );
                        })}
                </List>
            </ReviewSessionsWrapper>
        );
    }
}

export function ReviewScore(props: {decimalScore: number; fullRingScore?: number}) {
    const {decimalScore, fullRingScore} = props;
    if (decimalScore > 0) {
        return (
            <Chip
                size="small"
                className="score"
                icon={<Score className="svgIcon" />}
                label={fullRingScore ? fullRingScore + " (" + decimalScore.toFixed(1) + ")" : decimalScore.toFixed(1)}
                color="primary"
            />
        );
    } else {
        return <></>;
    }
}

export function ReviewSessionsMenu(props: {session: TrainingSession; children?: ReactNode}) {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const {session} = props;

    function closeMenu() {
        setMenuAnchorEl(null);
    }

    return (
        <>
            <IconButton
                className={styles.sessionMenuButton}
                onClick={e => {
                    setMenuAnchorEl(e.currentTarget);
                }}>
                <MenuIcon className="svgIcon" />
            </IconButton>

            <Menu anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                <div>
                    {" "}
                    {/*https://github.com/mui-org/material-ui/issues/15903*/}
                    <ExportButton {...{session, closeMenu}} />
                    <SaveShotsButton {...{session, closeMenu}} />
                    <SessionPrintButton onClick={closeMenu} />
                    <RemoveButton {...{session, closeMenu}} />
                </div>
            </Menu>
        </>
    );
}
