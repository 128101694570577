/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
    Competition,
    CompetitionFromJSON,
    CompetitionTitle,
    CompetitionTitleFromJSON,
    TrafficPattern,
    TrafficPatternToJSON,
} from "../models";

export interface AssignUserToLaneRequest {
    userId: string;
    deviceId: number;
}

export interface CompetitionDaysRequest {
    month: number;
    year: number;
}

export interface CompetitionsByDateRequest {
    day: number;
    month: number;
    year: number;
}

export interface CompleteCompetitionRequest {
    competitionId: string;
}

export interface GetCompetitionRequest {
    id: string;
    phaseName?: string;
}

export interface PauseCompetitionRequest {
    competitionId: string;
}

export interface RemoveCompetitionRequest {
    competitionId: string;
}

export interface SelectCompetitionRequest {
    disciplines: string;
    phaseN: number;
    targetName: string;
    userIds: Array<string>;
    weaponId?: string;
    competitionId?: string;
    trafficPattern?: TrafficPattern;
}

export interface StartCompetitionRequest {
    competitionId: string;
    warmup?: number;
}

export interface TokenLoginRequest {
    token: string;
}

/**
 *
 */
export class CompetitionControllerApi extends runtime.BaseAPI {
    /**
     */
    async activeCompetitionDeviceIdsRaw(): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/devices`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async activeCompetitionDeviceIds(): Promise<Array<number>> {
        const response = await this.activeCompetitionDeviceIdsRaw();
        return await response.value();
    }

    /**
     */
    async activeCompetitionsRaw(): Promise<runtime.ApiResponse<Array<CompetitionTitle>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(CompetitionTitleFromJSON));
    }

    /**
     */
    async activeCompetitions(): Promise<Array<CompetitionTitle>> {
        const response = await this.activeCompetitionsRaw();
        return await response.value();
    }

    /**
     */
    async assignUserToLaneRaw(requestParameters: AssignUserToLaneRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                "userId",
                "Required parameter requestParameters.userId was null or undefined when calling assignUserToLane.",
            );
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError(
                "deviceId",
                "Required parameter requestParameters.deviceId was null or undefined when calling assignUserToLane.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters["userId"] = requestParameters.userId;
        }

        if (requestParameters.deviceId !== undefined) {
            queryParameters["deviceId"] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/assignUserToLane`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assignUserToLane(requestParameters: AssignUserToLaneRequest): Promise<void> {
        await this.assignUserToLaneRaw(requestParameters);
    }

    /**
     */
    async competitionDaysRaw(requestParameters: CompetitionDaysRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError(
                "month",
                "Required parameter requestParameters.month was null or undefined when calling competitionDays.",
            );
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError(
                "year",
                "Required parameter requestParameters.year was null or undefined when calling competitionDays.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.month !== undefined) {
            queryParameters["month"] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters["year"] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/dates`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async competitionDays(requestParameters: CompetitionDaysRequest): Promise<Array<number>> {
        const response = await this.competitionDaysRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async competitionsByDateRaw(
        requestParameters: CompetitionsByDateRequest,
    ): Promise<runtime.ApiResponse<Array<CompetitionTitle>>> {
        if (requestParameters.day === null || requestParameters.day === undefined) {
            throw new runtime.RequiredError(
                "day",
                "Required parameter requestParameters.day was null or undefined when calling competitionsByDate.",
            );
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError(
                "month",
                "Required parameter requestParameters.month was null or undefined when calling competitionsByDate.",
            );
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError(
                "year",
                "Required parameter requestParameters.year was null or undefined when calling competitionsByDate.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.day !== undefined) {
            queryParameters["day"] = requestParameters.day;
        }

        if (requestParameters.month !== undefined) {
            queryParameters["month"] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters["year"] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/titles`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(CompetitionTitleFromJSON));
    }

    /**
     */
    async competitionsByDate(requestParameters: CompetitionsByDateRequest): Promise<Array<CompetitionTitle>> {
        const response = await this.competitionsByDateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async completeCompetitionRaw(requestParameters: CompleteCompetitionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.competitionId === null || requestParameters.competitionId === undefined) {
            throw new runtime.RequiredError(
                "competitionId",
                "Required parameter requestParameters.competitionId was null or undefined when calling completeCompetition.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/{competitionId}/complete`.replace(
                `{${"competitionId"}}`,
                encodeURIComponent(String(requestParameters.competitionId)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async completeCompetition(requestParameters: CompleteCompetitionRequest): Promise<void> {
        await this.completeCompetitionRaw(requestParameters);
    }

    /**
     */
    async getCompetitionRaw(requestParameters: GetCompetitionRequest): Promise<runtime.ApiResponse<Competition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getCompetition.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.phaseName !== undefined) {
            queryParameters["phaseName"] = requestParameters.phaseName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => CompetitionFromJSON(jsonValue));
    }

    /**
     */
    async getCompetition(requestParameters: GetCompetitionRequest): Promise<Competition> {
        const response = await this.getCompetitionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pauseCompetitionRaw(requestParameters: PauseCompetitionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.competitionId === null || requestParameters.competitionId === undefined) {
            throw new runtime.RequiredError(
                "competitionId",
                "Required parameter requestParameters.competitionId was null or undefined when calling pauseCompetition.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/{competitionId}/pause`.replace(
                `{${"competitionId"}}`,
                encodeURIComponent(String(requestParameters.competitionId)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pauseCompetition(requestParameters: PauseCompetitionRequest): Promise<void> {
        await this.pauseCompetitionRaw(requestParameters);
    }

    /**
     */
    async removeCompetitionRaw(requestParameters: RemoveCompetitionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.competitionId === null || requestParameters.competitionId === undefined) {
            throw new runtime.RequiredError(
                "competitionId",
                "Required parameter requestParameters.competitionId was null or undefined when calling removeCompetition.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/{competitionId}`.replace(
                `{${"competitionId"}}`,
                encodeURIComponent(String(requestParameters.competitionId)),
            ),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeCompetition(requestParameters: RemoveCompetitionRequest): Promise<void> {
        await this.removeCompetitionRaw(requestParameters);
    }

    /**
     */
    async selectCompetitionRaw(requestParameters: SelectCompetitionRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.disciplines === null || requestParameters.disciplines === undefined) {
            throw new runtime.RequiredError(
                "disciplines",
                "Required parameter requestParameters.disciplines was null or undefined when calling selectCompetition.",
            );
        }

        if (requestParameters.phaseN === null || requestParameters.phaseN === undefined) {
            throw new runtime.RequiredError(
                "phaseN",
                "Required parameter requestParameters.phaseN was null or undefined when calling selectCompetition.",
            );
        }

        if (requestParameters.targetName === null || requestParameters.targetName === undefined) {
            throw new runtime.RequiredError(
                "targetName",
                "Required parameter requestParameters.targetName was null or undefined when calling selectCompetition.",
            );
        }

        if (requestParameters.userIds === null || requestParameters.userIds === undefined) {
            throw new runtime.RequiredError(
                "userIds",
                "Required parameter requestParameters.userIds was null or undefined when calling selectCompetition.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.disciplines !== undefined) {
            queryParameters["disciplines"] = requestParameters.disciplines;
        }

        if (requestParameters.phaseN !== undefined) {
            queryParameters["phaseN"] = requestParameters.phaseN;
        }

        if (requestParameters.weaponId !== undefined) {
            queryParameters["weaponId"] = requestParameters.weaponId;
        }

        if (requestParameters.targetName !== undefined) {
            queryParameters["targetName"] = requestParameters.targetName;
        }

        if (requestParameters.userIds) {
            queryParameters["userIds"] = requestParameters.userIds;
        }

        if (requestParameters.competitionId !== undefined) {
            queryParameters["competitionId"] = requestParameters.competitionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/competitions/select`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: TrafficPatternToJSON(requestParameters.trafficPattern),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async selectCompetition(requestParameters: SelectCompetitionRequest): Promise<string> {
        const response = await this.selectCompetitionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async startCompetitionRaw(requestParameters: StartCompetitionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.competitionId === null || requestParameters.competitionId === undefined) {
            throw new runtime.RequiredError(
                "competitionId",
                "Required parameter requestParameters.competitionId was null or undefined when calling startCompetition.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.warmup !== undefined) {
            queryParameters["warmup"] = requestParameters.warmup;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/{competitionId}/start`.replace(
                `{${"competitionId"}}`,
                encodeURIComponent(String(requestParameters.competitionId)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async startCompetition(requestParameters: StartCompetitionRequest): Promise<void> {
        await this.startCompetitionRaw(requestParameters);
    }

    /**
     */
    async tokenLoginRaw(requestParameters: TokenLoginRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError(
                "token",
                "Required parameter requestParameters.token was null or undefined when calling tokenLogin.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters["token"] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/competitions/tokenLogin`,
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tokenLogin(requestParameters: TokenLoginRequest): Promise<void> {
        await this.tokenLoginRaw(requestParameters);
    }
}
