import React, {useEffect, useState} from "react";
import {
    Checkbox,
    FormControlLabel,
    IconButton,
    Input,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styles from "./DeviceMapper.module.css";
import {EsaDeviceConfig} from "../../api/generated/esa";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import {EsaControllerApi} from "../../api/generated/esa";
import {macToNumber, numberToMAC} from "../common/utils";
import {DEFAULT_DEVICE_KEY, DISABLE_DEVICE_SELECTION, TABLET_NAME} from "../select-view/SelectLane";
import CustomDialog from "../common/CustomDialog";
import Typography from "@material-ui/core/Typography";
import {ConstantsNumberCommon} from "../../api/generated/esa";
import Popup from "../common/Popup";
import DeleteIcon from "@material-ui/icons/Delete";
import Confirmation from "../common/Confirmation";
import {translation, translationString} from "../i18n";
import {FormattedMessage, useIntl} from "react-intl";

const esaControllerApi = new EsaControllerApi();

export default function DeviceMapper() {
    const [open, setOpen] = useState(false);
    const [initialDevices, setInitialDevices] = useState<EsaDeviceConfig[]>([]);
    const [devices, setDevices] = useState<EsaDeviceConfig[]>([]);
    const [deletedDevices, setDeletedDevices] = useState<DeviceId[]>([]);
    const [defaultDevice, setDefaultDevice] = useState<string | null>(localStorage.getItem(DEFAULT_DEVICE_KEY));
    const [disableDeviceSelection, setDisableDeviceSelection] = useState(
        localStorage.getItem(DISABLE_DEVICE_SELECTION) !== null,
    );
    const [tabletName, setTabletName] = useState(localStorage.getItem(TABLET_NAME));
    const [showMac, setShowMac] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (open) {
            esaControllerApi
                .getConfigs()
                .then(devices => {
                    setInitialDevices(devices);
                    setDevices(devices);
                })
                .then(() => setDeletedDevices([]));
            setTabletName(localStorage.getItem(TABLET_NAME));
        }
    }, [open]);

    function save() {
        const needRefresh =
            localStorage.getItem(TABLET_NAME) !== tabletName ||
            localStorage.getItem(DEFAULT_DEVICE_KEY) !== defaultDevice;
        defaultDevice
            ? localStorage.setItem(DEFAULT_DEVICE_KEY, defaultDevice)
            : localStorage.removeItem(DEFAULT_DEVICE_KEY);
        disableDeviceSelection
            ? localStorage.setItem(DISABLE_DEVICE_SELECTION, "true")
            : localStorage.removeItem(DISABLE_DEVICE_SELECTION);
        const esaDeviceConfig = devices.filter(device => !initialDevices.includes(device));

        tabletName && localStorage.setItem(TABLET_NAME, tabletName);

        if (esaDeviceConfig.length > 0) {
            esaControllerApi.updateConfigs({esaDeviceConfig}).finally();
        }

        if (deletedDevices.length > 0) {
            esaControllerApi.deleteConfigs({requestBody: deletedDevices}).finally();
        }

        if (needRefresh) {
            window.location.reload();
        } else {
            setOpen(false);
        }
    }

    function updateDevice(id: number, mac: string | undefined, alias: string, ignoreDeviceTime: boolean) {
        setDevices(devices => {
            return devices.map(device => {
                if (device.id === id) {
                    return {
                        ...device,
                        id: macToNumber(mac) ?? ConstantsNumberCommon.DEVICE_UNDEFINED,
                        alias,
                        ignoreDeviceTime,
                    };
                } else {
                    return device;
                }
            });
        });
    }

    return (
        <>
            <DeviceMapperButton onClick={() => setOpen(true)} />
            <CustomDialog
                dialogTitle={
                    <Typography gutterBottom variant="h6">
                        {translation("title.device_mapper")}
                    </Typography>
                }
                dialogActions={
                    <>
                        <Button onClick={() => setShowMac(true)} variant="contained">
                            {translation("button.add")}
                        </Button>
                        <Button onClick={() => setOpen(false)} color="primary">
                            {translation("button.close")}
                        </Button>
                        <Button id="device_mapper_save" onClick={save} color="primary" variant="contained">
                            {translation("button.save")}
                        </Button>
                    </>
                }
                {...{open, setOpen}}>
                <div className={styles.dialog}>
                    <Typography variant="h6">{translation("title.device_mapper.options")}</Typography>
                    <TextField
                        placeholder={translationString(intl, "title.shooter_monitor_name")}
                        value={tabletName}
                        onChange={e => setTabletName(e.target.value)}
                        className={styles.monitorName}
                    />
                    <br />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="enable_device_selection"
                                checked={!disableDeviceSelection}
                                onChange={(_, checked) => {
                                    setDisableDeviceSelection(!checked);
                                }}
                                color="primary"
                            />
                        }
                        label={translationString(intl, "label.device_mapper.allow_users")}
                    />
                    <Typography gutterBottom variant="h6">
                        {translation("title.devices")}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table id="device_mapper_table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{translation("label.mac")}</TableCell>
                                    <TableCell>{translation("label.alias")}</TableCell>
                                    <TableCell>{translation("label.time")}</TableCell>
                                    <TableCell>{translation("label.default")}</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {devices
                                    .filter(it => !deletedDevices.includes(it.id))
                                    .map(it => (
                                        <TableRow key={it.id} data-device-id={it.id}>
                                            <TableCell>
                                                <Typography>{numberToMAC(it.id)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Input
                                                    value={it.alias}
                                                    onChange={e =>
                                                        updateDevice(
                                                            it.id,
                                                            numberToMAC(it.id),
                                                            e.target.value,
                                                            it.ignoreDeviceTime,
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={it.ignoreDeviceTime}
                                                    onChange={(_, checked) => {
                                                        updateDevice(it.id, numberToMAC(it.id), it.alias, checked);
                                                    }}
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    className="default_device"
                                                    checked={it.id.toString() === defaultDevice}
                                                    onChange={(_, checked) => {
                                                        if (checked) {
                                                            setDefaultDevice(it.id.toString());
                                                        } else {
                                                            setDefaultDevice(null);
                                                        }
                                                    }}
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Confirmation
                                                    title={
                                                        <FormattedMessage
                                                            id="title.device_mapper.delete_device"
                                                            values={{alias: it.alias}}
                                                        />
                                                    }
                                                    okButton="button.delete"
                                                    onClick={() => {
                                                        setDeletedDevices(prevValue => [...prevValue, it.id]);
                                                    }}>
                                                    <IconButton id="stop">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Confirmation>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <AddNewDevice {...{showMac, setShowMac, setDevices}} />
                </div>
            </CustomDialog>
        </>
    );
}

function AddNewDevice(props: {showMac: boolean; setShowMac: Setter<boolean>; setDevices: Setter<EsaDeviceConfig[]>}) {
    const {showMac, setShowMac, setDevices} = props;
    const [mac, setMac] = useState("");

    const invalid = !mac.match("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$");
    return (
        <Popup
            title="title.device_mapper.add_device"
            show={showMac}
            onClose={() => setShowMac(false)}
            okButton={
                <Button
                    variant="outlined"
                    disabled={invalid}
                    onClick={() => {
                        setDevices(devices => [
                            ...devices,
                            {
                                id: macToNumber(mac) ?? ConstantsNumberCommon.DEVICE_UNDEFINED,
                                alias: "",
                                ignoreDeviceTime: false,
                            },
                        ]);
                        setShowMac(false);
                        setMac("");
                    }}>
                    {translation("button.add")}
                </Button>
            }>
            <TextField
                value={mac}
                label={translation("label.device_mapper.mac_address")}
                onChange={e => setMac(e.target.value)}
                error={invalid}
                helperText={invalid && translation("tooltip.device_mapper.mac_address")}
            />
        </Popup>
    );
}

export function DeviceMapperButton(props: {onClick: () => void}) {
    const {onClick} = props;

    return (
        <ListItem id="device_mapper" button {...{onClick}}>
            <ListItemIcon>
                <DeviceHubIcon />
            </ListItemIcon>
            <ListItemText primary={translation("title.device_mapper")} />
        </ListItem>
    );
}
