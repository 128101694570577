/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {CountryCodes, CountryCodesFromJSON} from "../models";

/**
 *
 */
export class CountryControllerApi extends runtime.BaseAPI {
    /**
     */
    async countryCodesRaw(): Promise<runtime.ApiResponse<Array<CountryCodes>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/country/codes`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(CountryCodesFromJSON));
    }

    /**
     */
    async countryCodes(): Promise<Array<CountryCodes>> {
        const response = await this.countryCodesRaw();
        return await response.value();
    }
}
