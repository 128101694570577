import {TooltipRenderProps} from "react-joyride";
import React, {useContext, useEffect} from "react";
import {Button, Grid, Paper} from "@material-ui/core";
import styles from "../Joyride.module.css";
import {translation} from "../../i18n";
import {JoyrideContext} from "../JoyrideContext";
import classes from "./Tooltip.module.css";
import {EnhancedStep} from "../Joyride.utils";

export function Tooltip(props: TooltipRenderProps & {children?: React.ReactNode; end: () => void}) {
    const {index, backProps, primaryProps, tooltipProps, end} = props;
    const step = props.step as EnhancedStep;
    const {steps} = useContext(JoyrideContext);
    const previousStep = index > 0 && steps[index - 1];
    const showNext = !step.awaitClick;
    const showBack = !previousStep.awaitClick && previousStep;

    useEffect(() => {
        const node = document.createElement("span");
        node.className = styles.spotlightClick;
        node.id = "spotlight_click";

        const spotlight = document?.getElementsByClassName("react-joyride__spotlight")[0];

        if (step.awaitClick) {
            if (spotlight) {
                if (spotlight.getElementsByTagName("span").length < 1) {
                    spotlight.appendChild(node);
                }
            }
            return;
        }
    }, [step]);

    return (
        <Paper id="step_container" {...tooltipProps} className={styles.tooltipContainer}>
            <div id="step_content" style={{marginBottom: 16}}>
                {step.title && <div>{step.title}</div>}
                <div>{step.content}</div>
            </div>
            {!step.hideFooter && (
                <Grid container className={styles.tooltipActions}>
                    {showBack && (
                        <Button {...backProps} variant="outlined">
                            {translation("button.back")}
                        </Button>
                    )}
                    {showNext && (
                        <Button id="next_slide" {...primaryProps} variant="contained" color="primary">
                            {steps.length - 1 !== index ? translation("button.next") : translation("button.finish")}
                        </Button>
                    )}
                </Grid>
            )}
            <div onClick={end} id="end_joyride" className={classes.exit}>
                {translation("button.exit_tutorial")}
            </div>
        </Paper>
    );
}
