import React, {useContext, useState} from "react";
import {Avatar, ListItem} from "@material-ui/core";
import {AppContext} from "../AppContextProvider";
import {ConstantsStringCommon} from "../../api/generated/esa";
import {trackClick} from "../AnalyticsClickTracker";
import UserInfo from "./UserInfo";
import {Cloud} from "@material-ui/icons";
import {UserButton} from "./UserButton";

interface ControlProps {
    controlledUpdateUser?: () => void;
}

export default function UserTag(props: ControlProps) {
    const {
        user,
        userDevice: {deviceAlias},
        analytics,
    } = useContext(AppContext);
    const {controlledUpdateUser} = props;

    const [open, setOpen] = useState(false);

    const guest = user.id === ConstantsStringCommon.GUEST_USER_ID;

    function openUsersSettings() {
        trackClick(analytics, "UserInfo");
        setOpen(true);
    }

    return (
        <ListItem dense style={{height: "100%", padding: 0}} disabled={guest}>
            <UserButton
                onClick={() => {
                    !guest && openUsersSettings();
                }}
                onLogout={() => fetch(`${window.location.origin}/logout`).then(() => (window.location.href = "/"))}
                avatar={<Avatar sizes="small">{user.cloudId ? <Cloud color="primary" /> : user.name[0]}</Avatar>}
                primary={user.name}
                secondary={deviceAlias}
            />

            <UserInfo {...{open, setOpen}} selfProfile controlledUpdateUser={controlledUpdateUser} />
        </ListItem>
    );
}
