/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {NetworkInfo, NetworkInfoFromJSON, NetworkInfoToJSON} from "./";

/**
 *
 * @export
 * @interface SystemInfo
 */
export interface SystemInfo {
    /**
     *
     * @type {Array<NetworkInfo>}
     * @memberof SystemInfo
     */
    networkInterfaces: Array<NetworkInfo>;
}

export function SystemInfoFromJSON(json: any): SystemInfo {
    return SystemInfoFromJSONTyped(json, false);
}

export function SystemInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemInfo {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        networkInterfaces: (json["networkInterfaces"] as Array<any>).map(NetworkInfoFromJSON),
    };
}

export function SystemInfoToJSON(value?: SystemInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        networkInterfaces: (value.networkInterfaces as Array<any>).map(NetworkInfoToJSON),
    };
}
