import {Box} from "@material-ui/core";
import React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const {children} = props;

    return <Box style={{padding: 0, display: "flex", justifyContent: "center", height: "100%"}}>{children}</Box>;
}
