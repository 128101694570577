import React, {useContext, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import {JoyrideContext} from "./JoyrideContext";
import {translation} from "../i18n";
import {AppLayout} from "../LayoutManager";
import {Timeline} from "@material-ui/icons";

export default function JoyrideButton() {
    const {setJoyride, joyrideAvailable} = useContext(JoyrideContext);
    const {setBurgerOpen} = useContext(AppLayout);
    const [warningDialogOpen, setWarningDialogOpen] = useState(false);
    return (
        <>
            <ListItem
                id="joyride_control_button"
                button
                style={{color: !joyrideAvailable ? "var(--devPaletteTextSecondary)" : "inherit"}}
                onClick={() => {
                    if (!joyrideAvailable) {
                        setWarningDialogOpen(true);
                    } else {
                        setBurgerOpen(false);
                        setJoyride(true);
                    }
                }}>
                <ListItemIcon>
                    <Timeline color={!joyrideAvailable ? "disabled" : "inherit"} />
                </ListItemIcon>
                <ListItemText primary={translation("title.introduction")} />
            </ListItem>
            <Dialog
                open={warningDialogOpen}
                keepMounted
                onClose={() => {
                    setWarningDialogOpen(false);
                }}>
                <DialogTitle> {translation("joyride.dialog.small_screen.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{translation("joyride.dialog.small_screen.content")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setWarningDialogOpen(false)}>{translation("button.close")} </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
