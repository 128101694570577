import React from "react";
import {Value} from "../../settings-dialog/adapter/types/settingsTypes";
import DropdownButton from "../../common/DropdownButton";
import {MenuItem} from "@material-ui/core";
import {translatedText} from "../../i18n";

export function FormatterSelect<T extends Value>(props: {
    source: T;
    setSource: (source: T) => void;
    prefix: string;
    selectables: string[];
}) {
    const {source, setSource, prefix, selectables} = props;
    const items = selectables as string[];

    function translatedItem(itemId: T) {
        return translatedText(prefix, itemId === "" ? "select" : (itemId as string));
    }

    return (
        <>
            <DropdownButton others={{variant: "outlined"}} source={translatedItem(source)} showDropdownIcon>
                {items.map(item => (
                    <MenuItem
                        key={item}
                        onClick={() => {
                            setSource(item as T);
                        }}>
                        {translatedItem(item as T)}
                    </MenuItem>
                ))}
            </DropdownButton>
        </>
    );
}
