import React, {ReactNode, useContext} from "react";
import {Box} from "@material-ui/core";
import {AnalyticsInstance} from "analytics";
import {AppContext} from "./AppContextProvider";

export default function AnalyticsClickTracker(props: {
    componentId: string;
    children: ReactNode;
    elementType?: React.ElementType;
}) {
    const {componentId, children, elementType} = props;
    const {analytics} = useContext(AppContext);

    function clickHandle() {
        trackClick(analytics, componentId);
        analytics.track(`${componentId}_component`, analytics);
    }

    return (
        <Box
            key={componentId + "AnalyticsClickTracker"}
            component={elementType ? elementType : "div"}
            onClick={clickHandle}>
            {children}
        </Box>
    );
}

export function trackClick(analytics: AnalyticsInstance, componentId: string) {
    analytics.track("Click", {componentId}).then();
}
