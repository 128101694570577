import React from "react";
import {Hit, ScoringType, Target, Weapon} from "../../api/generated/esa";
import TargetControl from "./TargetControl";
import {weaponCaliberOrDefault} from "../live-view/LiveTarget";

export type PhaseTargetProps = {
    target: Target;
    weapon: Weapon;
    hits?: Hit[];
    centerControls?: boolean;
    onSelect?: (n: string) => void;
    selected?: string;
    disableLatestHitHighlight?: boolean;
    shotBlink?: boolean;
    openSeries: number;
    series: number;
    phase: Phase;
};

export default function PhaseTarget(props: PhaseTargetProps) {
    const {
        onSelect,
        selected,
        centerControls,
        disableLatestHitHighlight,
        shotBlink,
        target,
        weapon,
        series,
        openSeries,
        hits,
        phase: {maxShots, scoring},
    } = props;

    return (
        <TargetControl
            {...{
                onSelect,
                selected,
                centerControls,
                disableLatestHitHighlight,
                shotBlink,
                target,
                openSeries,
                hits,
                maxShots,
                series,
            }}
            centerControls={centerControls}
            caliberMm={weaponCaliberOrDefault(weapon, target)}
            corner={scoring === ScoringType.DecimalIgnore}
        />
    );
}
