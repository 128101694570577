import React from "react";
import {HitVisibility, MAX_PCNT} from "./TargetView";
import {ArrowDropDownCircle} from "@material-ui/icons";

export default function HitArrow(props: {hitVisibility: HitVisibility; pcnt: number; fill: string}) {
    const {hitVisibility, pcnt, fill} = props;

    return <ArrowDropDownCircle style={hitArrow(hitVisibility, pcnt, fill)} />;
}

/**
 * Rotate 45 if at corner
 */
function getAngle(start: number, pcnt: number, op: number) {
    if (pcnt === MAX_PCNT) {
        return start - 45 * op;
    } else if (pcnt === 0) {
        return start + 45 * op;
    } else {
        return start;
    }
}

function hitArrow(hitVisibility: HitVisibility, pcnt: number, fill: string): React.CSSProperties {
    const common = {fontSize: "50px", position: "absolute", color: `rgba(${fill}`};
    if (hitVisibility === "left") {
        return {
            ...common,
            transform: `rotate(${getAngle(90, pcnt, 1)}deg)`,
            top: `${pcnt * 100}%`,
            left: "0",
            transition: "top 0.1s ease-out",
        } as React.CSSProperties;
    } else if (hitVisibility === "right") {
        return {
            ...common,
            transform: `rotate(${getAngle(-90, pcnt, -1)}deg)`,
            top: `${pcnt * 100}%`,
            right: "0",
            transition: "top 0.1s ease-out",
        } as React.CSSProperties;
    }
    if (hitVisibility === "top") {
        return {
            ...common,
            transform: `rotate(${getAngle(180, pcnt, 1)}deg)`,
            top: "0",
            left: `${pcnt * 100}%`,
            transition: "left 0.1s ease-out",
        } as React.CSSProperties;
    } else if (hitVisibility === "bottom") {
        return {
            ...common,
            transform: `rotate(${getAngle(0, pcnt, 1)}deg)`,
            bottom: "0",
            left: `${pcnt * 100}%`,
            transition: "left 0.1s ease-out",
        } as React.CSSProperties;
    } else {
        return {};
    }
}
