import React, {ChangeEvent, useContext, useRef} from "react";
import {Button} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import {translation} from "../../i18n";
import {UsersControllerApi} from "../../../api/generated/esa";
import {NotificationContext} from "../../NotificationContextProvider";

export function ImportButtonUser(props: {name: string; loadUsers: () => void}) {
    const {name, loadUsers} = props;
    const button = useRef<HTMLInputElement>(null);

    const {notify} = useContext(NotificationContext);

    function upload(e: ChangeEvent<HTMLInputElement>) {
        const api = new UsersControllerApi();
        const file: any = e.target.files?.[0];

        if (file) {
            api.importUsers({body: file})
                .then(
                    (result: {
                        readUserCount: React.ReactNode;
                        updatedUserCount: React.ReactNode;
                        noPasswordUserCount: React.ReactNode;
                    }) => {
                        notify("default", "message.user_management.import", {
                            translationValues: {
                                readCount: result.readUserCount as string,
                                updatedCount: result.updatedUserCount as string,
                                noPasswordUserCount: result.noPasswordUserCount as string,
                            },
                        });
                    },
                )
                .finally(loadUsers);
        }
    }

    return (
        <>
            <Button
                id="user_management_import_action_select"
                style={{marginRight: "10"}}
                color="primary"
                name={name}
                innerRef={button}
                onClick={() => button.current?.click()}
                endIcon={<PublishIcon />}>
                {translation("button.user_management.import")}
            </Button>
            <input style={{display: "none"}} ref={button} type="file" accept=".csv" onChange={upload} />
        </>
    );
}
