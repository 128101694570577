/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ConstantsNumberCommon {
    DESCRIPTION_MAX_LENGTH = 32,
    DEVICE_UNDEFINED = -1,
    PASSWORD_MAX_LENGTH = 16,
    USERNAME_MAX_LENGTH = 16,
}

export function ConstantsNumberCommonFromJSON(json: any): ConstantsNumberCommon {
    return ConstantsNumberCommonFromJSONTyped(json, false);
}

export function ConstantsNumberCommonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConstantsNumberCommon {
    return json as ConstantsNumberCommon;
}

export function ConstantsNumberCommonToJSON(value?: ConstantsNumberCommon | null): any {
    return value as any;
}
