import React, {ReactElement} from "react";
import {ButtonBase, Divider, Grid, ListItemAvatar, ListItemText} from "@material-ui/core";
import styles from "./UserManagment.module.css";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Typography from "@material-ui/core/Typography";
import {translation} from "../i18n";

export function UserButton(props: {
    primary: string;
    secondary?: string;
    avatar: ReactElement;
    onClick: () => void;
    onLogout: () => void;
}) {
    const {primary, secondary, onClick, avatar, onLogout} = props;
    return (
        <>
            <ButtonBase style={{margin: 12}} id="user_profile_management" {...{onClick}}>
                <ListItemAvatar>{avatar}</ListItemAvatar>
                <ListItemText
                    disableTypography
                    className={styles.userInfo}
                    primary={
                        <div id="current_user" className={styles.currentUser}>
                            {primary}
                        </div>
                    }
                    secondary={
                        secondary && (
                            <div id="current_device" data-device-id={secondary} className={styles.currentDevice}>
                                {displayAlias(secondary)}
                            </div>
                        )
                    }
                />
            </ButtonBase>
            <Divider flexItem orientation="vertical" />
            <ButtonBase style={{margin: 12, marginLeft: "auto"}} id="logout_action" onClick={onLogout}>
                <Grid item className={styles.logoutButton}>
                    <ExitToAppIcon color="primary" />
                    <Typography variant="caption">{translation("label.logout")}</Typography>
                </Grid>
            </ButtonBase>
        </>
    );
}

function displayAlias(deviceAlias: string) {
    if (deviceAlias.length > 6) {
        return "…" + deviceAlias.slice(-5);
    } else if (deviceAlias === "") {
        return "…";
    } else {
        return deviceAlias;
    }
}
