import {TableCellProps} from "@material-ui/core/TableCell/TableCell";
import {TableCell, Typography} from "@material-ui/core";
import React, {useContext} from "react";
import {AppSettings} from "../../AppSettingsProvider";
import styles from "./Table.module.css";

export function Cell(props: TableCellProps) {
    const {
        Components: {
            Lomah: {
                Table: {fontLarge},
            },
        },
    } = useContext(AppSettings);

    return (
        <TableCell className={styles.tableCell} align={props.align}>
            <Typography style={{fontSize: fontLarge ? "140%" : "initial"}}>{props.children}</Typography>
        </TableCell>
    );
}
