import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import {Hit, ScoringType} from "../../../api/generated/esa/models";
import {center, distanceOfPoints} from "./functions/distanceOfPoints";
import {TableProps} from "../Lomah";
import {Units} from "./components/Edit";
import {AppSettings} from "../../AppSettingsProvider";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import {getAngle} from "./functions/getAngle";
import {tableContent, tableData} from "./tableContent";
import {HitRow} from "./HitRow";
import {HitSeries} from "./HitSeries";
import {localizedButtonText, translatedText, translation} from "../../i18n";
import convert from "convert-units";
import {scrollToBottom} from "../target/functions/scrollToBottom";
import {AppContext} from "../../AppContextProvider";
import styles from "./Table.module.css";

type LiveScoringProps = {
    scoringType?: ScoringType;
    hits: Hit[];
    units: Units;
    identificationNumber: boolean;
    score: boolean;
    delay: boolean;
    coordinates: boolean;
    directionArrows: boolean;
    toCenter: boolean;
    onSelect?: (id: string) => void;
    selected?: string;
    tableSeries?: boolean;
    startOnFirstShoot: boolean;
    phase: Phase;
    openSeries: number;
    setOpenSeries: Setter<number>;
    allSeriesOpen?: boolean;
};

export interface HitToDisplay extends Hit {
    delay: false | number;
    toCenter: number;
    angle: number;
}

export function HitTable(props: TableProps & Partial<LiveScoringProps>) {
    const {
        Global: {units},
        Components: {
            Lomah: {
                Table: {identificationNumber, score, delay, coordinates, directionArrows, toCenter},
            },
        },
    } = useContext(AppSettings);

    return (
        <LiveScoringView
            {...{
                onSelect: props.onSelect,
                selected: props.selected,
                scoringType: props.phase.scoring,
                hits: props.hits,
                units: props.units ?? (units as Units),
                identificationNumber: props.identificationNumber ?? identificationNumber,
                score: props.score ?? score,
                delay: props.delay ?? delay,
                coordinates: props.coordinates ?? coordinates,
                directionArrows: props.directionArrows ?? directionArrows,
                toCenter: props.toCenter ?? toCenter,
                tableSeries: props.tableSeries ?? props.tableSeries,
                startOnFirstShoot: props.startOnFirstShoot ?? false,
                phase: props.phase,
                openSeries: props.openSeries,
                setOpenSeries: props.setOpenSeries,
                allSeriesOpen: props.allSeriesOpen ?? false,
            }}
        />
    );
}

function localizedTableColumn(columnName: string) {
    return translatedText("column.table.hit", columnName);
}

type columns = "n" | "score" | "toCenter" | "x" | "y" | "angle";
export type Filter = {column: columns; reverse: boolean};

function LiveScoringView(props: LiveScoringProps) {
    const {
        scoringType,
        hits,
        units,
        identificationNumber,
        score,
        delay,
        coordinates,
        directionArrows,
        toCenter,
        onSelect,
        selected,
        tableSeries,
        startOnFirstShoot,
        phase,
        openSeries,
        setOpenSeries,
        allSeriesOpen,
    } = props;

    const {
        Components: {
            Lomah: {
                Table: {measurementUnit, fontLarge},
            },
        },
    } = useContext(AppSettings);
    const {analytics} = useContext(AppContext);

    const {maxShots, series} = phase;
    const hitsInSeries = phase.series > 1 ? maxShots / series : 0;
    const chunkSize = hitsInSeries && hitsInSeries > 0 ? hitsInSeries : false;

    const [filter, setFilter] = useState<Filter>({
        column: "n",
        reverse: true,
    });

    const [modifiedHits, setModifiedHits] = useState<HitToDisplay[][]>([]);
    const [hitsAmount, setHitsAmount] = useState(0);
    const table = document.getElementById("table");

    //Reset sorting and scroll to top after shot received
    useEffect(() => {
        if (hits.length !== hitsAmount) {
            setFilter({
                column: "n",
                reverse: true,
            });
            setHitsAmount(hits.length);
            scrollToBottom(hits[0], table);
        }
    }, [hits, hitsAmount, table]);

    // Adding hit calculations, like delay,id,angle,toCenter
    useEffect(() => {
        const hitsNumbered: HitToDisplay[] = hits
            .sort((h1, h2) => h2.n - h1.n)
            .map((it: Hit, index, arr) =>
                Object.assign(it, {
                    delay:
                        index < arr.length - 1 &&
                        +convert(-(arr[index + 1].timeStamp - it.timeStamp))
                            .from("ms")
                            .to("s")
                            .toFixed(2),
                    toCenter: +distanceOfPoints(center, it).toFixed(1),
                    angle: getAngle(it),
                }),
            );

        chunkSize && setOpenSeries(Math.ceil(hits.length / chunkSize));

        if (chunkSize && tableSeries) {
            const reversed = hitsNumbered.reverse();
            let hitSeries: HitToDisplay[][] = [];
            const length = hitsNumbered.length;
            for (let i = 0; i <= length; i += chunkSize) {
                const chunk = reversed.slice(i, i + chunkSize);
                chunk.length > 0 && (hitSeries = [...hitSeries, chunk]);
            }
            setModifiedHits(hitSeries);
        } else {
            setModifiedHits([hitsNumbered]);
        }
    }, [hits, tableSeries, chunkSize, setOpenSeries]);

    useEffect(() => {
        if (selected) {
            const selectedSeries = modifiedHits.filter(series => series.some(hit => hit.id === selected))[0];
            const index = modifiedHits.indexOf(selectedSeries);
            setOpenSeries(index + 1);
        }
    }, [selected, modifiedHits, setOpenSeries]);

    return hits.length >= 1 ? (
        <div className={styles.tableContainer}>
            <TableContainer component={Paper} id="table" className={styles.table}>
                <Table id="hit_table" className={styles.scrollFix} stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            {tableContent(
                                units,
                                identificationNumber,
                                score,
                                delay,
                                coordinates,
                                directionArrows,
                                toCenter,
                            ).map(
                                (col: tableData) =>
                                    col.display && (
                                        <TableCell
                                            id={`table_header_column_${col.objKeyName}`}
                                            key={col.columnName + "column"}
                                            className={styles.headerCell}>
                                            {col.filter ? (
                                                <Button
                                                    color={filter.column === col.objKeyName ? "primary" : "default"}
                                                    onClick={() => {
                                                        analytics.track("HitTable.Sort", {
                                                            column: col.objKeyName,
                                                            direction: filter.reverse ? "desc" : "asc",
                                                        });
                                                        setFilter({
                                                            column: col.objKeyName as columns,
                                                            reverse:
                                                                filter.column === col.objKeyName
                                                                    ? !filter.reverse
                                                                    : false,
                                                        });
                                                    }}
                                                    style={{padding: 0}}
                                                    endIcon={
                                                        col.columnName.length !== 0 &&
                                                        filter.column === col.objKeyName && (
                                                            <TrendingFlatIcon
                                                                style={{
                                                                    transform: `rotate(${
                                                                        filter.reverse ? 270 : 90
                                                                    }deg)`,
                                                                }}
                                                            />
                                                        )
                                                    }>
                                                    <div>
                                                        <Typography style={{fontSize: fontLarge ? "120%" : "initial"}}>
                                                            {localizedTableColumn(col.columnName)}
                                                        </Typography>
                                                        {col.measurement && measurementUnit && (
                                                            <Typography
                                                                style={{
                                                                    textTransform: "lowercase",
                                                                    fontSize: "70% ",
                                                                    textAlign: "left",
                                                                }}>
                                                                ({localizedButtonText(col.measurement)})
                                                            </Typography>
                                                        )}
                                                    </div>
                                                </Button>
                                            ) : (
                                                <div>
                                                    {localizedTableColumn(col.columnName)}
                                                    {col.measurement && (
                                                        <Typography
                                                            style={{
                                                                fontSize: "70% ",
                                                                textAlign: "left",
                                                            }}>
                                                            ({localizedButtonText(col.measurement)})
                                                        </Typography>
                                                    )}
                                                </div>
                                            )}
                                        </TableCell>
                                    ),
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableSeries && chunkSize
                            ? (modifiedHits as HitToDisplay[][]).map((hits, index) => (
                                  <HitSeries
                                      key={`${index}_series_key`}
                                      index={index + 1}
                                      {...{
                                          scoringType,
                                          onSelect,
                                          selected,
                                          hits,
                                          filter,
                                          openSeries,
                                          setOpenSeries,
                                          allSeriesOpen,
                                      }}
                                  />
                              ))
                            : (modifiedHits[0] as HitToDisplay[])
                                  ?.sort((a, b) => {
                                      const asc = a[filter.column] - b[filter.column];
                                      const desc = b[filter.column] - a[filter.column];
                                      return filter.reverse ? asc : desc;
                                  })
                                  ?.map((hit, i) => (
                                      <HitRow
                                          key={`${hit.id}_${i}_row_key`}
                                          {...{
                                              scoringType,
                                              onSelect,
                                              hit,
                                              selected,
                                          }}
                                      />
                                  ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    ) : (
        <Grid container justify="center" alignItems="center" style={{height: "100%"}}>
            {translation(
                phase.competitionId === null && startOnFirstShoot ? "label.start_on_first_shoot" : "label.no_shots",
            )}
        </Grid>
    );
}
