import React, {ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {ConstantsNumberEsa, ConstantsStringEsa, Phase, PhaseType} from "../../api/generated/esa";
import {Button, IconButton, Typography} from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import stylesCommon from "../common/Common.module.css";
import styles from "../Timer.module.css";
import {AppContext} from "../AppContextProvider";
import {AppSettings} from "../AppSettingsProvider";
import Confirmation from "../common/Confirmation";
import {ReactComponent as Score} from "@fortawesome/fontawesome-free/svgs/solid/flag-checkered.svg";
import {usePrevious} from "../common/ReactUtils";
import {translation, translationOrName} from "../i18n";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {Alert} from "@material-ui/lab";
import {NotificationContext} from "../NotificationContextProvider";
import animations from "../../styles/Animations.module.css";

export type Status = "Warmup" | "Counting" | "Paused" | "Ended";

export const PHASE_NOTIFICATION = "PHASE_NOTIFICATION";

interface ControlProps {
    phase: Phase;
    pause: () => void;
    start: () => void;
    reset: () => void;
    next: () => void;
    complete: () => void;
}

export default function Timer(
    props: ControlProps & {
        id: string;
        setHeader: (id: string, header: ReactNode | String) => void;
        remove: () => void;
        allowControls: boolean;
    },
) {
    const noTimeLeft = "00:00:00";
    const [[timerClass, timerText], setTimer] = useState<[string | undefined, string | ReactNode]>([
        undefined,
        noTimeLeft,
    ]);
    const [status, setStatus] = useState<Status>("Paused");
    const [addDialog, setAddDialog] = useState(false);
    const [headerText, setHeaderText] = useState<null | string>(null);

    const {notify} = useContext(NotificationContext);
    const {phase, id, setHeader, remove, next, complete, allowControls} = props;
    const {getServerTime} = useContext(AppContext);
    const [refresh, setRefresh] = useState(true);

    const phasePeriodsNoRerender = useMemo(() => {
        return phase.periods;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phase.id]);

    useEffect(() => {
        function updateStatus() {
            const now = getServerTime();
            const newStatus = getStatus(now, phase.start, phase.paused, phase.end);
            setStatus(newStatus);
            if (newStatus === "Warmup") {
                setRefresh(true);
                const diff = phase.start! - now;
                setTimer([stylesCommon.textWarning, timerFormat(diff)]);
            } else if (newStatus === "Paused" && phase.unlimited) {
                setRefresh(false);
                setTimer([undefined, timerFormat(ConstantsNumberEsa.UNLIMITED_DURATION - phase.paused!)]);
            } else if (newStatus === "Paused") {
                setRefresh(false);
                setTimer([undefined, timerFormat(phase.paused!)]);
            } else if (newStatus === "Counting" && phase.unlimited) {
                setRefresh(true);
                const diff = -(phase.end! - ConstantsNumberEsa.UNLIMITED_DURATION - now);
                const text = timerFormat(diff);
                setTimer([undefined, text]);
            } else if (newStatus === "Counting") {
                setRefresh(true);
                const periods = phasePeriodsNoRerender;
                const duration = phase.duration;

                const diff = phase.end! - now;
                const text = timerFormat(diff);
                const elapsedTime = duration - diff;

                if (periods.length > 0) {
                    const passedTraffic = periods.filter(it => Math.round(it.time) <= Math.round(elapsedTime));
                    const currentStatus = passedTraffic[passedTraffic.length - 1];
                    const currentStatusIndex = periods.indexOf(currentStatus);
                    const nextStatus = periods[currentStatusIndex + 1];
                    const timeTillNextStatus = (nextStatus ? nextStatus.time : duration) - elapsedTime;

                    const timerTextMs = timerFormat(diff);
                    if (currentStatus) {
                        setHeaderText(currentStatus.type);
                        if (phase.type === PhaseType.TrafficLight) {
                            setTimer([
                                currentStatus.className,
                                timerFormat(timeTillNextStatus, timeTillNextStatus > 60000 ? "s" : "ms"),
                            ]);
                        } else {
                            setTimer([currentStatus.className, timerTextMs]);
                        }
                    } else {
                        setTimer([undefined, timerTextMs]);
                    }
                } else {
                    setHeaderText(null);
                    setTimer([undefined, text]);
                }
            } else if (newStatus === "Ended") {
                setRefresh(false);
                setTimer([stylesCommon.textDisabled, translationOrName("component.timer.status", newStatus)]);
            }
        }

        updateStatus();

        if (refresh) {
            const refreshRateInMs = phase.type === PhaseType.TrafficLight ? 140 : 500;
            const timer = setInterval(updateStatus, refreshRateInMs);
            return () => clearInterval(timer);
        } else {
            return undefined;
        }
    }, [
        refresh,
        getServerTime,
        phase.start,
        phase.type,
        phase.end,
        phase.paused,
        phase.unlimited,
        phase.duration,
        phasePeriodsNoRerender,
    ]);

    useEffect(() => {
        setHeader(
            id,
            <div id="timer_header" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {phase.disciplineName !== ConstantsStringEsa.NO_TRAINING_NAME && (
                    <>
                        <span
                            id="status_indicator_background"
                            style={{
                                width: "100%",
                                height: 38,

                                position: "absolute",
                                backgroundColor: timerClass ? `var(--devPalette${timerClass}Main)` : "transparent",
                                zIndex: -1,
                                left: 0,
                            }}
                        />
                        <Typography id="timerStatus">
                            {headerText ? translationOrName("timer.period", headerText) : <PhaseName />}
                            {(status === "Warmup" || (status === "Paused" && !!phase.start)) && (
                                <>
                                    {" "}
                                    (<StatusName />)
                                </>
                            )}
                        </Typography>
                    </>
                )}
                {allowControls && (
                    <div>
                        <Confirmation
                            title="title.delete_training_session"
                            text="message.results_will_be_deleted"
                            okButton="button.delete"
                            onClick={remove}>
                            <IconButton id="delete">
                                <DeleteIcon />
                            </IconButton>
                        </Confirmation>
                    </div>
                )}
            </div>,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, status, setHeader, phase, allowControls, headerText, timerClass]);

    function PhaseName() {
        return translationOrName("component.timer.phase", phase.name ?? "");
    }

    function StatusName() {
        return translationOrName("component.timer.status", status);
    }

    const prevStatus = usePrevious(status);
    useEffect(() => {
        if (prevStatus !== status && prevStatus === "Counting" && status === "Ended") {
            notify(
                "info",
                <>
                    <PhaseName /> <StatusName />
                </>,
                {
                    key: PHASE_NOTIFICATION,
                    action: allowControls && <EndOrNextButton showText {...{phase, status, next, complete}} />,
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevStatus, phase, status, notify]);

    return (
        <div
            className={styles.timerContainer}
            id="timer_component"
            data-training-session-id={phase.sessionId}
            data-competition-id={phase.competitionId}>
            <div className={styles.timerMessage}>
                <Dialog open={addDialog} onClose={() => setAddDialog(false)} maxWidth="lg">
                    <DialogTitle className={styles.commentHead}>{translation("title.add_comment")}</DialogTitle>
                    <DialogContent className={styles.commentContent}>{translation("prompt.add_comment")}</DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddDialog(false)} color="primary">
                            {translation("button.not_now")}
                        </Button>
                        <Button
                            onClick={() => {
                                setAddDialog(false);
                            }}
                            variant="contained"
                            color="primary">
                            {translation("button.add_comment")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <div>
                    <Typography id="timerText" className={timerClass} variant="h1" style={{fontSize: "4.5rem"}}>
                        {timerText}
                    </Typography>
                </div>

                {allowControls && <Buttons {...props} status={status} />}
                {allowControls || <Alert severity="info">{translation("label.managed_by_admin")}</Alert>}
            </div>
        </div>
    );
}

function Buttons(
    props: ControlProps & {
        status: Status;
    },
) {
    const {phase, pause, start, reset, next, complete, status} = props;

    const {
        Components: {
            Timer: {allowPause},
        },
    } = useContext(AppSettings);

    return (
        <div>
            {allowPause && status === "Counting" && (
                <IconButton id="pause" className={styles.circleButton} disabled={status !== "Counting"} onClick={pause}>
                    <PauseIcon />
                </IconButton>
            )}

            {["Warmup", "Paused"].includes(status) && (
                <IconButton
                    id="start"
                    color="primary"
                    disabled={status !== "Paused"}
                    className={styles.circleButton}
                    onClick={start}>
                    <PlayArrowIcon />
                </IconButton>
            )}

            {status === "Ended" && (
                <IconButton id="reset" className={styles.circleButton} disabled={!!phase.nextPhase} onClick={reset}>
                    <RotateLeftIcon />
                </IconButton>
            )}

            <EndOrNextButton {...{phase, status, next, complete}} />
        </div>
    );
}

function EndOrNextButton(props: {
    phase: Phase;
    status: Status;
    showText?: boolean;
    next: () => void;
    complete: () => void;
}) {
    const {phase, showText, status, next, complete} = props;
    const buttonClassName = clsx({[styles.circleButton]: !showText});
    const {
        Components: {
            Timer: {allowPause},
        },
    } = useContext(AppSettings);

    const ended = status === "Ended";

    if (phase.nextPhase) {
        return (
            <IconButton
                id="next"
                color={ended ? "primary" : "default"}
                className={clsx([buttonClassName, ended && animations.heartbeat])}
                onClick={next}>
                <SkipNextIcon />
                {showText && translation("button.next_phase")}
            </IconButton>
        );
    } else if (status === "Paused") {
        return (
            <Confirmation
                title="title.prompt.complete_current_training_session"
                okButton="button.complete"
                onClick={complete}>
                <IconButton id="complete" className={buttonClassName}>
                    <Score className="svgIcon" />
                    {showText && translation("button.view_results")}
                </IconButton>
            </Confirmation>
        );
    } else if (status !== "Ended" && (!allowPause || phase.unlimited)) {
        return (
            <Confirmation
                title="title.prompt.complete_current_training_session"
                okButton="button.complete"
                onClick={complete}>
                <IconButton id="complete" className={buttonClassName} disabled={!phase.started}>
                    <Score className="svgIcon" />
                    {showText && translation("button.view_results")}
                </IconButton>
            </Confirmation>
        );
    } else {
        return (
            <IconButton
                id="results"
                color={status === "Ended" ? "primary" : "default"}
                disabled={!["Ended", "Paused"].includes(status)}
                className={buttonClassName}
                onClick={complete}>
                {status === "Ended" ? <CloseIcon /> : <Score className="svgIcon" />}
                {showText && translation("button.view_results")}
            </IconButton>
        );
    }
}

export function timerFormat(millis: number, type: "ms" | "s" = "s", trimZeros: boolean = false): string {
    const sign = millis < 0 ? "-" : "";

    const padStart = type === "s" ? 11 : 14;
    const padEnd = type === "s" ? -5 : -2;

    const iso = sign + new Date(Math.abs(millis)).toISOString().slice(padStart, padEnd);

    if (trimZeros) {
        const trimmed = iso.replace("00:", "");
        if (trimmed[0] === "0") {
            return trimmed.substring(1);
        } else {
            return trimmed;
        }
    } else {
        return iso;
    }
}

export function getPhaseStatus(now: number, phase: Phase): Status {
    return getStatus(now, phase.start, phase.paused, phase.end);
}

export function getStatus(now: number, start?: number, paused?: number, end?: number): Status {
    if (paused != null) {
        return "Paused";
    } else if (start != null && end != null) {
        if (start >= now) {
            return "Warmup";
        } else if (end > now) {
            return "Counting";
        } else {
            return "Ended";
        }
    } else {
        throw new Error(
            `Unable to determine status of phase ${JSON.stringify({
                now,
                start,
                paused,
                end,
            })}`,
        );
    }
}
