import React, {useEffect, useState} from "react";
import {Button, Chip, Grid, MenuItem, TextField} from "@material-ui/core";
import styles from "../UserManagment.module.css";
import {Add} from "@material-ui/icons";
import {localizedButtonText, localizedLabel, translatedString} from "../../i18n";
import {useIntl} from "react-intl";
import {ConstantsStringCommon} from "../../../api/generated/esa";

export type Role = "USER" | "ADMIN" | "SPECTATOR";

const existingRoles: Role[] = [
    ConstantsStringCommon.ROLE_USER,
    ConstantsStringCommon.ROLE_ADMIN,
    ConstantsStringCommon.ROLE_SPECTATOR,
];

export function AddRoles(props: {userRoles: Role[]; setUserRoles: Setter<Role[]>}) {
    const {userRoles, setUserRoles} = props;
    const [add, setAdd] = useState(false);
    const [toDisplay, setToDisplay] = useState<Role[]>([]);
    const [toAdd, setToAdd] = useState<Role>();
    const intl = useIntl();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToAdd(event.target.value as Role);
    };

    useEffect(() => {
        const display = existingRoles.filter(role => !userRoles.includes(role));
        setToDisplay(display);
        setToAdd(display[0]);
    }, [userRoles]);

    return (
        <>
            {add ? (
                <Grid container alignItems="center" spacing={3} style={{margin: "16px 0"}}>
                    <TextField
                        className={styles.userManagementInput}
                        select
                        label={translatedString(intl, "label", "select")}
                        value={toAdd}
                        disabled={toDisplay.length === 0}
                        onChange={handleChange}
                        style={{minWidth: 200}}
                        helperText={translatedString(intl, "label", "helper_add_role")}>
                        {toDisplay.map(role => (
                            <MenuItem key={role} value={role}>
                                {translatedString(intl, "roles", role).toUpperCase()}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Grid item>
                        <Button
                            disabled={!toAdd}
                            onClick={() => {
                                if (!userRoles.includes(toAdd as Role)) {
                                    setUserRoles([...userRoles, toAdd as Role]);
                                }
                            }}
                            size="small"
                            variant="contained"
                            color="primary">
                            {localizedButtonText("add")}
                        </Button>

                        <Button onClick={() => setAdd(false)} size="small" color="primary">
                            {localizedButtonText("close")}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid item>
                    <Chip
                        icon={<Add />}
                        onClick={() => setAdd(true)}
                        label={localizedLabel("add_role")}
                        variant="outlined"
                        color="primary"
                    />
                </Grid>
            )}
        </>
    );
}
