/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";

/**
 *
 * @export
 * @interface UserCreationRequest
 */
export interface UserCreationRequest {
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    secret?: string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof UserCreationRequest
     */
    lastName?: string;
}

export function UserCreationRequestFromJSON(json: any): UserCreationRequest {
    return UserCreationRequestFromJSONTyped(json, false);
}

export function UserCreationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreationRequest {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: json["name"],
        secret: !exists(json, "secret") ? undefined : json["secret"],
        firstName: !exists(json, "firstName") ? undefined : json["firstName"],
        lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    };
}

export function UserCreationRequestToJSON(value?: UserCreationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        secret: value.secret,
        firstName: value.firstName,
        lastName: value.lastName,
    };
}
