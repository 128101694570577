/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {PhaseDescription, PhaseDescriptionFromJSON, PhaseDescriptionToJSON} from "./";

/**
 *
 * @export
 * @interface Discipline
 */
export interface Discipline {
    /**
     *
     * @type {string}
     * @memberof Discipline
     */
    name: string;
    /**
     *
     * @type {Array<PhaseDescription>}
     * @memberof Discipline
     */
    phases: Array<PhaseDescription>;
    /**
     *
     * @type {string}
     * @memberof Discipline
     */
    governingBody: string;
    /**
     *
     * @type {number}
     * @memberof Discipline
     */
    shootingDistance: number;
    /**
     *
     * @type {string}
     * @memberof Discipline
     */
    distanceUnit: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Discipline
     */
    targets: Array<string>;
}

export function DisciplineFromJSON(json: any): Discipline {
    return DisciplineFromJSONTyped(json, false);
}

export function DisciplineFromJSONTyped(json: any, ignoreDiscriminator: boolean): Discipline {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: json["name"],
        phases: (json["phases"] as Array<any>).map(PhaseDescriptionFromJSON),
        governingBody: json["governingBody"],
        shootingDistance: json["shootingDistance"],
        distanceUnit: json["distanceUnit"],
        targets: json["targets"],
    };
}

export function DisciplineToJSON(value?: Discipline | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        phases: (value.phases as Array<any>).map(PhaseDescriptionToJSON),
        governingBody: value.governingBody,
        shootingDistance: value.shootingDistance,
        distanceUnit: value.distanceUnit,
        targets: value.targets,
    };
}
