import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    EsaDate,
    Hit,
    HitControllerApi,
    LicensingControllerApi,
    Phase,
    Target,
    TrainingSession,
    TrainingSessionControllerApi,
    Weapon,
    WebSocketMessage,
    WebSocketMessageHit,
} from "../../api/generated/esa";
import {AppContext} from "../AppContextProvider";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {defaultTarget, defaultWeapon} from "../../defaultValues";
import {NotificationContext} from "../NotificationContextProvider";
import {noop} from "../common/utils";
import {useTarget, useWeapon} from "../../customHooks";

export interface ReviewContextType {
    phase: Phase | undefined;
    setSelectedDate: Setter<EsaDate>;
    setPhase: Setter<Phase | undefined>;
    session: TrainingSession | undefined;
    setSession: Setter<TrainingSession | undefined>;
    userId: string;
    setUserId: Setter<string>;
    selectedDate: EsaDate;
    hitsInSeries: number;
    setHitsInSeries: Setter<number>;
    expanded: boolean;
    setExpanded: Setter<boolean>;
    selectedHit: string | undefined;
    setSelectedHit: Setter<string | undefined>;
    hits: Hit[];
    setHits: Setter<Hit[]>;
    sessionDays: Set<number>;
    setSessionDays: Setter<Set<number>>;
    sessions: TrainingSession[];
    setSessions: Setter<TrainingSession[]>;
    visibleDate: MaterialUiPickersDate;
    setVisibleDate: Setter<MaterialUiPickersDate>;
    isLoading: boolean;
    openSeries: number;
    setOpenSeries: Setter<number>;
    tableSeries: boolean;
    setTableSeries: Setter<boolean>;
    target: Target;
    weapon: Weapon;
    rangeName: string;
}

export const REVIEW_NOTIFICATION = "REVIEW_NOTIFICATION";

const sessionApi = new TrainingSessionControllerApi();
const hitControllerApi = new HitControllerApi();
const licensingControllerApi = new LicensingControllerApi();

export const ReviewContext = React.createContext<ReviewContextType>({
    phase: undefined,
    selectedDate: {
        day: 0,
        month: 0,
        year: 0,
    },
    setUserId: noop,
    setSelectedDate: noop,
    setPhase: noop,
    session: undefined,
    setSession: noop,
    userId: "",
    hitsInSeries: 0,
    setHitsInSeries: noop,
    expanded: false,
    setExpanded: noop,
    selectedHit: undefined,
    setSelectedHit: noop,
    hits: [],
    setHits: noop,
    sessionDays: new Set(),
    setSessionDays: noop,
    sessions: [],
    setSessions: noop,
    visibleDate: new Date(),
    setVisibleDate: noop,
    isLoading: true,
    openSeries: 0,
    setOpenSeries: noop,
    target: defaultTarget,
    weapon: defaultWeapon,
    tableSeries: true,
    setTableSeries: noop,
    rangeName: "",
});

export default function ReviewContextProvider(props: React.PropsWithChildren<any>) {
    const {children} = props;
    const {user: currentUser} = useContext(AppContext);

    const [hits, setHits] = useState<Hit[]>([]);
    const [selectedHit, setSelectedHit] = useState<string | undefined>();
    const [expanded, setExpanded] = useState(true);
    const [userId, setUserId] = useState<string>(currentUser.id);
    const [session, setSession] = useState<TrainingSession | undefined>();
    const [phase, setPhase] = useState<Phase | undefined>();
    const target = useTarget(phase?.targetName);
    const weapon = useWeapon(phase?.weaponId);
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState<EsaDate>({
        day: today.getDate(),
        month: today.getMonth(),
        year: today.getFullYear(),
    });
    const [hitsInSeries, setHitsInSeries] = useState(0);

    const [sessions, setSessions] = useState<TrainingSession[]>([]);
    const [sessionDays, setSessionDays] = useState<Set<number>>(new Set());
    const [visibleDate, setVisibleDate] = useState<MaterialUiPickersDate>(today);
    const [isLoading, setIsLoading] = useState(true);
    const [openSeries, setOpenSeries] = useState<number>(0);
    const [tableSeries, setTableSeries] = useState(true);
    const [rangeName, setRangeName] = useState("");

    const {webSocket} = useContext(AppContext);
    const {notify, unNotify} = useContext(NotificationContext);

    useEffect(() => {
        function updateListener(event: MessageEvent) {
            const update: WebSocketMessage = JSON.parse(event.data);
            const hit: WebSocketMessageHit | undefined = update.hit;
            if (hit) {
                notify("warning", "warning.shot_in_review", {
                    key: REVIEW_NOTIFICATION,
                    onClick: () => {
                        window.location.href = "#";
                        unNotify(REVIEW_NOTIFICATION);
                    },
                });
            }
        }

        webSocket.addEventListener("message", updateListener);
        return function cleanup() {
            webSocket.removeEventListener("message", updateListener);
        };
    }, [notify, unNotify, webSocket]);
    // On user switch
    useEffect(() => {
        setIsLoading(true);
        setPhase(undefined);
        setSessions([]);
        setSession(undefined);
        sessionApi
            .latestSession({...{userId}})
            .then(setSelectedDate)
            .finally(() => setIsLoading(false));
    }, [userId, setPhase, setSessions, setSession, setSelectedDate]);

    useEffect(() => {
        setIsLoading(true);
        visibleDate &&
            sessionApi
                .sessionDays({userId: userId, month: visibleDate.getMonth(), year: visibleDate.getFullYear()})
                .then(e => setSessionDays(new Set(e)))
                .finally(() => setIsLoading(false));
    }, [userId, visibleDate]);

    useEffect(() => {
        setIsLoading(true);
        sessionApi
            .usersSessions({
                userId: userId,
                day: selectedDate.day,
                month: selectedDate.month,
                year: selectedDate.year,
            })
            .then(sessions => {
                const latestSession = sessions[0];
                setSessions(sessions);
                setSession(latestSession);
                setPhase(latestSession?.phases[0]);
            })
            .finally(() => setIsLoading(false));
    }, [userId, selectedDate.day, selectedDate.month, selectedDate.year, setPhase, setSession]);

    useEffect(() => {
        if (phase) {
            setIsLoading(true);
            hitControllerApi
                .getHits({phase: [phase.id]})
                .then(setHits)
                .finally(() => setIsLoading(false));
            if (phase.series > 1) {
                setHitsInSeries(phase.maxShots && phase.maxShots / phase.series);
            } else {
                setHitsInSeries(0);
            }
        }
    }, [phase, setHits]);

    useEffect(() => {
        licensingControllerApi.getLicense().then(it => setRangeName(it.companyName));
    }, []);

    const context = useMemo<ReviewContextType>(
        () => ({
            selectedDate,
            setSelectedDate,
            phase,
            setPhase,
            session,
            setSession,
            userId,
            setUserId,
            hitsInSeries,
            setHitsInSeries,
            expanded,
            setExpanded,
            selectedHit,
            setSelectedHit,
            hits,
            setHits,
            sessionDays,
            setSessionDays,
            sessions,
            setSessions,
            visibleDate,
            setVisibleDate,
            isLoading,
            openSeries,
            setOpenSeries,
            target,
            tableSeries,
            setTableSeries,
            weapon,
            rangeName,
        }),
        [
            selectedDate,
            setSelectedDate,
            phase,
            setPhase,
            session,
            setSession,
            userId,
            setUserId,
            hitsInSeries,
            setHitsInSeries,
            expanded,
            setExpanded,
            selectedHit,
            setSelectedHit,
            hits,
            setHits,
            sessionDays,
            setSessionDays,
            sessions,
            setSessions,
            visibleDate,
            setVisibleDate,
            isLoading,
            openSeries,
            setOpenSeries,
            tableSeries,
            setTableSeries,
            target,
            weapon,
            rangeName,
        ],
    );

    return <ReviewContext.Provider value={context}>{children}</ReviewContext.Provider>;
}
