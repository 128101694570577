import React, {useEffect, useState} from "react";
import {ListItem, ListItemText, Menu} from "@material-ui/core";
import {
    CompetitionControllerApi,
    ConstantsNumberCommon,
    EsaControllerApi,
    User,
    UserDevice,
    UsersControllerApi,
} from "../../../api/generated/esa";
import style from "./UserAssignment.module.css";
import Confirmation from "../../common/Confirmation";
import {translation} from "../../i18n";

const esaSessionApi = new EsaControllerApi();
const userControllerApi = new UsersControllerApi();
const competitionApi = new CompetitionControllerApi();

export default function UserAssignment(props: {
    userMenuEl: null | HTMLElement;
    setUserMenuEl: Setter<null | HTMLElement>;
}) {
    const {userMenuEl, setUserMenuEl} = props;
    const open = Boolean(userMenuEl);
    const deviceId = parseInt(
        userMenuEl?.getAttribute("data-device-id") ?? ConstantsNumberCommon.DEVICE_UNDEFINED.toString(),
    );
    const [userDevices, setUserDevices] = useState<UserDevice[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        if (open) {
            esaSessionApi.getUserDevices().then(setUserDevices);
            userControllerApi.getAllUsers({}).then(setUsers);
        }
    }, [open]);

    function onClose() {
        setUserMenuEl(null);
    }

    return (
        <Menu id="user-assignment-menu" anchorEl={userMenuEl} keepMounted {...{open, onClose}} className={style.menu}>
            {users.map(it => {
                const device = userDevices.find(d => d.userId === it.id);
                return (
                    <Confirmation
                        key={`user-assignment-menu-${it.id}`}
                        title={translation("title.user_assigned", {
                            user: device?.userName,
                            device: device?.deviceAlias,
                        })}
                        okButton="button.assign"
                        hide={!device}
                        onClick={() => {
                            onClose();
                            void competitionApi.assignUserToLane({
                                userId: it.id,
                                deviceId,
                            });
                        }}>
                        <ListItem>
                            <ListItemText primary={it.name} secondary={device?.deviceAlias} />
                        </ListItem>
                    </Confirmation>
                );
            })}
        </Menu>
    );
}
