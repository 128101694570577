import React, {useContext} from "react";
import {AppContext} from "../AppContextProvider";
import {EsaControllerApi, PowerStatus} from "../../api/generated/esa";
import {Button, Dialog, DialogActions} from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import {translation} from "../i18n";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";

const esa = new EsaControllerApi();

export function LeaveDialog(props: {leaveDialogOpen: boolean; setLeaveDialogOpen: Setter<boolean>}) {
    const {leaveDialogOpen, setLeaveDialogOpen} = props;

    const {
        userDevice: {deviceId},
    } = useContext(AppContext);

    function off() {
        esa.setLightingPowerStatus({deviceId: deviceId, powerStatus: PowerStatus.OFF})
            .then(() =>
                fetch(`${window.location.origin}/api/system/powerOff`, {
                    method: "POST",
                }),
            )
            .finally();
    }

    function restart() {
        fetch(`${window.location.origin}/api/system/restart`, {
            method: "POST",
        }).finally();
    }

    return (
        <Dialog open={leaveDialogOpen} onClose={() => setLeaveDialogOpen(false)}>
            <DialogActions>
                <Button onClick={off} color="secondary" variant="contained" size="large">
                    <PowerSettingsNewIcon fontSize="large" />
                    {translation("button.power_off")}
                </Button>
                <Button onClick={restart} color="primary" variant="contained" autoFocus size="large">
                    <SettingsBackupRestoreIcon fontSize="large" />
                    {translation("button.restart")}
                </Button>
                <Button size="large" onClick={() => setLeaveDialogOpen(false)}>
                    {translation("button.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
