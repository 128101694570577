import React, {useEffect, useState} from "react";
import {Grid, SvgIcon} from "@material-ui/core";
import styles from "../about-dialog/About.module.css";
import {ReactComponent as Logo} from "../../resources/icons/packs/custom/filled/INTARSO-Logo-White.svg";
import buildVersion from "../../resources/build-version.json";
import {translatedText, translation} from "../i18n";
import {License, LicensingControllerApi} from "../../api/generated/esa";
import {Body1, H6} from "../common/TypographyVariants";
import {format} from "date-fns";

const licensingControllerApi = new LicensingControllerApi();

export function Overview() {
    const [licence, setLicence] = useState<License>();
    useEffect(() => {
        licensingControllerApi.getLicense().then(setLicence);
    }, []);

    const licenceDate = licence ? new Date(licence.purchaseDate) : new Date();
    const licenceDateToDisplay = format(licenceDate, "dd/MM/yyyy");
    const buildDate = licence ? new Date(buildVersion.buildDate) : new Date();
    const buildDateToDisplay = format(buildDate, "dd/MM/yyyy");

    return (
        <Grid style={{width: "100%"}}>
            <Grid className={styles.overviewLogoContainer}>
                <SvgIcon component={Logo} viewBox="0 0 793 206.2" />
            </Grid>
            <Grid style={{marginBottom: "auto"}}>
                <H6>
                    <b>
                        {translation("title.overview.main")} <sub>{translation("title.overview.sub")} </sub>{" "}
                    </b>
                </H6>

                <Body1>
                    {translatedText("label", "overview.build", {version: buildVersion.id})}
                    <b>{buildDateToDisplay}</b>
                </Body1>
                {licence && (
                    <Body1>
                        {translation("label.overview.licences")}: <b>{licence.companyName} </b>
                        <br />
                        {translation("label.overview.licenced_devices")}: <b>{licence.licencedDevices} </b>
                        <br />
                        {translation("label.overview.purchase_date")}: <b>{licenceDateToDisplay} </b> <br />
                    </Body1>
                )}
            </Grid>
        </Grid>
    );
}
