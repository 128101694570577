import {center} from "./distanceOfPoints";

type point = {
    x: number;
    y: number;
};

export function getAngle(hit: point): number {
    const start: point = {
        x: 0,
        y: 5,
    };

    const AB = Math.sqrt(Math.pow(center.x - start.x, 2) + Math.pow(center.y - start.y, 2));
    const BC = Math.sqrt(Math.pow(center.x - hit.x, 2) + Math.pow(center.y - hit.y, 2));
    const AC = Math.sqrt(Math.pow(hit.x - start.x, 2) + Math.pow(hit.y - start.y, 2));
    const angle = (Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB)) * 180) / Math.PI;
    return hit.x >= 0 ? angle : 360 - angle;
}
