import React from "react";
import {Card, CardContent, CardHeader, Chip, Grid} from "@material-ui/core";
import {Copyright, Link} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import licences from "../../resources/frontendLicenses.json";
import {Title} from "../settings-dialog/adapter/components/TextComponents";

interface FrontendModuleDescriptor {
    moduleName: string;
    moduleVersion: string;
    moduleUrl?: string;
    moduleLicenses: {
        name: string;
        url: string;
    }[];
    author: string | null;
}

export default function LicensesFrontend() {
    function FrontendModuleCard(props: {moduleDescriptor: FrontendModuleDescriptor}) {
        const {moduleDescriptor} = props;

        const replaceNames = [
            {
                toReplace: "caniuse-lite",
                newValue: "caniuse-lite by caniuse.com",
            },
        ];

        function getName(name: string) {
            const existing = replaceNames.filter(it => it.toReplace === name.trim());

            if (existing.length === 1) {
                return existing[0].newValue;
            } else if (existing.length > 1) {
                throw new Error("Duplicate value in: replaceNames Array");
            } else return name;
        }

        return (
            <Card variant="outlined" style={{margin: "8px 0"}} id={moduleDescriptor.moduleName}>
                <CardHeader title={getName(moduleDescriptor.moduleName)} subheader={moduleDescriptor.moduleVersion} />

                <CardContent>
                    {moduleDescriptor.moduleLicenses.map(it => (
                        <>
                            <Grid container alignItems="center">
                                <Copyright />
                                <Typography variant="body2"> {it.name}</Typography>
                            </Grid>
                            {it.url.length > 0 && (
                                <Grid container alignItems="center">
                                    <Link />
                                    <a href={it.url}>
                                        <Typography variant="body2" component="a">
                                            {it.url}
                                        </Typography>
                                    </a>
                                </Grid>
                            )}
                        </>
                    ))}
                    {moduleDescriptor.author && (
                        <Grid container alignItems="center">
                            <Chip label="Author" />
                            <Typography variant="body2"> {moduleDescriptor.author}</Typography>
                        </Grid>
                    )}
                </CardContent>
            </Card>
        );
    }

    return (
        <Grid container direction="column" style={{margin: 0}}>
            <Title value="Frontend Licences" />
            {licences.dependencies.map(x => (
                <FrontendModuleCard key={x.moduleName + "_frontend_" + Math.random() * 130} moduleDescriptor={x} />
            ))}
        </Grid>
    );
}
