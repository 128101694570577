import {Hit} from "../../../../api/generated/esa/models";

export const center: Hit = {
    id: "",
    n: 0,
    x: 0,
    y: 0,
    deviceId: 0,
    timeStamp: 0,
    phaseId: "",
    score: 0,
    displayScore: "0",
};

export const centerHit = center;

export const distanceOfPoints = (one: Hit, two: Hit) => {
    return Math.sqrt(Math.pow(one.x - two.x, 2) + Math.pow(one.y - two.y, 2));
};
