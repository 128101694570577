import React, {useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../components/AppContextProvider";
import {Fab} from "@material-ui/core";

import {AppContextTypeMock, canHit} from "./PortalMock";
import {
    SingleShooterLiveContext,
    SingleShooterLiveContextI,
} from "../../components/live-view/SingleShooterLiveContextProvider";
import {Aim} from "../../components/icons/generated/warfare/filled";
import {NotificationContext} from "../../components/NotificationContextProvider";
import {useTarget, useWeapon} from "../../customHooks";

export default function SingleShooterLiveContextProviderMock(props: React.PropsWithChildren<any>) {
    const {children} = props;
    const [selectedHit, setSelectedHit] = useState<string>("");
    const [openSeries, setOpenSeries] = useState<number>(0);
    const [tableSeries, setTableSeries] = useState(true);
    const {hit, hits, phase} = useContext(AppContext) as AppContextTypeMock;
    const target = useTarget(phase?.targetName);
    const weapon = useWeapon(phase?.weaponId);
    const {notify} = useContext(NotificationContext);

    useEffect(() => {
        setSelectedHit("");
    }, [hits]);

    const filteredHits = useMemo(() => hits.filter(hit => hit.phaseId === phase.id), [phase.id, hits]);

    const context = useMemo<SingleShooterLiveContextI>(() => {
        return {
            ...{selectedHit, setSelectedHit, openSeries, setOpenSeries, tableSeries, setTableSeries, target, weapon},
            hits: filteredHits,
        };
    }, [
        filteredHits,
        selectedHit,
        setSelectedHit,
        openSeries,
        setOpenSeries,
        tableSeries,
        setTableSeries,
        target,
        weapon,
    ]);

    function onClick() {
        if (canHit(phase.start, phase.paused, phase.end)) {
            hit();
        } else {
            notify("warning", "warning.shot_without_phase");
        }
    }

    return (
        <SingleShooterLiveContext.Provider value={context}>
            <Fab
                id="demo_button"
                color="primary"
                {...{onClick}}
                style={{position: "absolute", top: 200 + "px", left: "10px", zIndex: 1500}}>
                <Aim />
            </Fab>
            {children}
        </SingleShooterLiveContext.Provider>
    );
}
