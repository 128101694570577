import React from "react";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {Value} from "../types/settingsTypes";
import {translatedText} from "../../../i18n";
import {FormatterSlider} from "./FormatterSlider";

export function ShotLimit<T extends Value>(props: {value: number; setValue: (value: T) => void; name: string}) {
    const {value, setValue, name} = props;
    const limit = value <= 0 ? 10 : value;
    const amount = limit > 0 ? limit : 10;
    const [selected, setSelected] = React.useState<number>(+value);
    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setSelected(+value);
        if (+value >= 1) {
            setValue(amount as T);
        } else setValue(value as T);
    };

    return (
        <FormControl component="fieldset">
            <RadioGroup name="shot_limit" value={selected >= 1 ? 1 : selected} onChange={handleSelect}>
                <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label={translatedText("settings.shotlimit", "limited")}
                />

                {selected >= 1 && <FormatterSlider {...{value, setValue, name}} max={30} />}

                <FormControlLabel
                    value={-1}
                    control={<Radio color="primary" />}
                    label={translatedText("settings.shotlimit", "unlimited")}
                />
                <FormControlLabel
                    value={0}
                    control={<Radio color="primary" />}
                    label={translatedText("settings.shotlimit", "current")}
                />
            </RadioGroup>
        </FormControl>
    );
}
