/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
    Discipline,
    DisciplineFromJSON,
    EsaDate,
    EsaDateFromJSON,
    Phase,
    PhaseFromJSON,
    SessionComment,
    SessionCommentFromJSON,
    TrafficPattern,
    TrafficPatternToJSON,
    TrainingSession,
    TrainingSessionFromJSON,
} from "../models";

export interface CompleteRequest {
    phaseId: string;
}

export interface CreateSessionCommentRequest {
    id: string;
    body: string;
}

export interface GetDisciplineRequest {
    userId?: string;
}

export interface LatestSessionRequest {
    userId: string;
}

export interface PauseRequest {
    phaseId: string;
}

export interface RemoveRequest {
    trainingId: string;
    currentPhaseId: string;
}

export interface RemoveSessionCommentRequest {
    id: string;
}

export interface SelectRequest {
    name: string;
    phase: number;
    targetName: string;
    weaponId?: string;
    trafficPattern?: TrafficPattern;
}

export interface SessionCommentRequest {
    id: string;
}

export interface SessionDaysRequest {
    userId: string;
    month: number;
    year: number;
}

export interface StartRequest {
    phaseId: string;
    warmup?: number;
}

export interface UsersSessionsRequest {
    userId: string;
    day: number;
    month: number;
    year: number;
}

/**
 *
 */
export class TrainingSessionControllerApi extends runtime.BaseAPI {
    /**
     */
    async completeRaw(requestParameters: CompleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.phaseId === null || requestParameters.phaseId === undefined) {
            throw new runtime.RequiredError(
                "phaseId",
                "Required parameter requestParameters.phaseId was null or undefined when calling complete.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/{phaseId}/complete`.replace(
                `{${"phaseId"}}`,
                encodeURIComponent(String(requestParameters.phaseId)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async complete(requestParameters: CompleteRequest): Promise<void> {
        await this.completeRaw(requestParameters);
    }

    /**
     */
    async createSessionCommentRaw(requestParameters: CreateSessionCommentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling createSessionComment.",
            );
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError(
                "body",
                "Required parameter requestParameters.body was null or undefined when calling createSessionComment.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/trainings/{id}/comment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createSessionComment(requestParameters: CreateSessionCommentRequest): Promise<void> {
        await this.createSessionCommentRaw(requestParameters);
    }

    /**
     */
    async disciplinesRaw(): Promise<runtime.ApiResponse<Array<Discipline>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/disciplines`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(DisciplineFromJSON));
    }

    /**
     */
    async disciplines(): Promise<Array<Discipline>> {
        const response = await this.disciplinesRaw();
        return await response.value();
    }

    /**
     */
    async getDisciplineRaw(requestParameters: GetDisciplineRequest): Promise<runtime.ApiResponse<Phase>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters["userId"] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/current`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => PhaseFromJSON(jsonValue));
    }

    /**
     */
    async getDiscipline(requestParameters: GetDisciplineRequest): Promise<Phase> {
        const response = await this.getDisciplineRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async latestSessionRaw(requestParameters: LatestSessionRequest): Promise<runtime.ApiResponse<EsaDate>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                "userId",
                "Required parameter requestParameters.userId was null or undefined when calling latestSession.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters["userId"] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/latest`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => EsaDateFromJSON(jsonValue));
    }

    /**
     */
    async latestSession(requestParameters: LatestSessionRequest): Promise<EsaDate> {
        const response = await this.latestSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pauseRaw(requestParameters: PauseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.phaseId === null || requestParameters.phaseId === undefined) {
            throw new runtime.RequiredError(
                "phaseId",
                "Required parameter requestParameters.phaseId was null or undefined when calling pause.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/{phaseId}/pause`.replace(
                `{${"phaseId"}}`,
                encodeURIComponent(String(requestParameters.phaseId)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pause(requestParameters: PauseRequest): Promise<void> {
        await this.pauseRaw(requestParameters);
    }

    /**
     */
    async removeRaw(requestParameters: RemoveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.trainingId === null || requestParameters.trainingId === undefined) {
            throw new runtime.RequiredError(
                "trainingId",
                "Required parameter requestParameters.trainingId was null or undefined when calling remove.",
            );
        }

        if (requestParameters.currentPhaseId === null || requestParameters.currentPhaseId === undefined) {
            throw new runtime.RequiredError(
                "currentPhaseId",
                "Required parameter requestParameters.currentPhaseId was null or undefined when calling remove.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.currentPhaseId !== undefined) {
            queryParameters["currentPhaseId"] = requestParameters.currentPhaseId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/{trainingId}`.replace(
                `{${"trainingId"}}`,
                encodeURIComponent(String(requestParameters.trainingId)),
            ),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async remove(requestParameters: RemoveRequest): Promise<void> {
        await this.removeRaw(requestParameters);
    }

    /**
     */
    async removeSessionCommentRaw(requestParameters: RemoveSessionCommentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling removeSessionComment.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/{id}/comment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeSessionComment(requestParameters: RemoveSessionCommentRequest): Promise<void> {
        await this.removeSessionCommentRaw(requestParameters);
    }

    /**
     */
    async selectRaw(requestParameters: SelectRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError(
                "name",
                "Required parameter requestParameters.name was null or undefined when calling select.",
            );
        }

        if (requestParameters.phase === null || requestParameters.phase === undefined) {
            throw new runtime.RequiredError(
                "phase",
                "Required parameter requestParameters.phase was null or undefined when calling select.",
            );
        }

        if (requestParameters.targetName === null || requestParameters.targetName === undefined) {
            throw new runtime.RequiredError(
                "targetName",
                "Required parameter requestParameters.targetName was null or undefined when calling select.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.weaponId !== undefined) {
            queryParameters["weaponId"] = requestParameters.weaponId;
        }

        if (requestParameters.targetName !== undefined) {
            queryParameters["targetName"] = requestParameters.targetName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request({
            path: `/api/disciplines/{name}/phases/{phase}/regular/select`
                .replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name)))
                .replace(`{${"phase"}}`, encodeURIComponent(String(requestParameters.phase))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: TrafficPatternToJSON(requestParameters.trafficPattern),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async select(requestParameters: SelectRequest): Promise<void> {
        await this.selectRaw(requestParameters);
    }

    /**
     */
    async sessionCommentRaw(requestParameters: SessionCommentRequest): Promise<runtime.ApiResponse<SessionComment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling sessionComment.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/{id}/comment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => SessionCommentFromJSON(jsonValue));
    }

    /**
     */
    async sessionComment(requestParameters: SessionCommentRequest): Promise<SessionComment> {
        const response = await this.sessionCommentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sessionDaysRaw(requestParameters: SessionDaysRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                "userId",
                "Required parameter requestParameters.userId was null or undefined when calling sessionDays.",
            );
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError(
                "month",
                "Required parameter requestParameters.month was null or undefined when calling sessionDays.",
            );
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError(
                "year",
                "Required parameter requestParameters.year was null or undefined when calling sessionDays.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters["userId"] = requestParameters.userId;
        }

        if (requestParameters.month !== undefined) {
            queryParameters["month"] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters["year"] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/dates`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async sessionDays(requestParameters: SessionDaysRequest): Promise<Array<number>> {
        const response = await this.sessionDaysRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async startRaw(requestParameters: StartRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.phaseId === null || requestParameters.phaseId === undefined) {
            throw new runtime.RequiredError(
                "phaseId",
                "Required parameter requestParameters.phaseId was null or undefined when calling start.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.warmup !== undefined) {
            queryParameters["warmup"] = requestParameters.warmup;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/{phaseId}/start`.replace(
                `{${"phaseId"}}`,
                encodeURIComponent(String(requestParameters.phaseId)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async start(requestParameters: StartRequest): Promise<void> {
        await this.startRaw(requestParameters);
    }

    /**
     */
    async unconditionalRemoveRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings/current`,
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unconditionalRemove(): Promise<void> {
        await this.unconditionalRemoveRaw();
    }

    /**
     */
    async usersSessionsRaw(
        requestParameters: UsersSessionsRequest,
    ): Promise<runtime.ApiResponse<Array<TrainingSession>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                "userId",
                "Required parameter requestParameters.userId was null or undefined when calling usersSessions.",
            );
        }

        if (requestParameters.day === null || requestParameters.day === undefined) {
            throw new runtime.RequiredError(
                "day",
                "Required parameter requestParameters.day was null or undefined when calling usersSessions.",
            );
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError(
                "month",
                "Required parameter requestParameters.month was null or undefined when calling usersSessions.",
            );
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError(
                "year",
                "Required parameter requestParameters.year was null or undefined when calling usersSessions.",
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters["userId"] = requestParameters.userId;
        }

        if (requestParameters.day !== undefined) {
            queryParameters["day"] = requestParameters.day;
        }

        if (requestParameters.month !== undefined) {
            queryParameters["month"] = requestParameters.month;
        }

        if (requestParameters.year !== undefined) {
            queryParameters["year"] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trainings`,
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TrainingSessionFromJSON));
    }

    /**
     */
    async usersSessions(requestParameters: UsersSessionsRequest): Promise<Array<TrainingSession>> {
        const response = await this.usersSessionsRaw(requestParameters);
        return await response.value();
    }
}
