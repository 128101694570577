import React, {useContext} from "react";
import {Messages} from "../../Internationalization";
import MenuItem from "@material-ui/core/MenuItem";
import {translation} from "../i18n";
import {AppContext} from "../AppContextProvider";
import {AppSettingsControl} from "../AppSettingsProvider";
import {IconButton, ListItem, ListItemIcon, ListItemText, Menu} from "@material-ui/core";
import ENG from "../../translations/en.json";
import DE from "../../translations/de.json";
import LanguageIcon from "@material-ui/icons/Language";

export default function LanguageChanger(props: {icon?: boolean; onChange?: (lang: string) => void}) {
    const {icon, onChange} = props;
    const {setLang} = useContext(AppSettingsControl);
    const {analytics} = useContext(AppContext);

    function setLanguage(langCode: string) {
        setLang(langCode);
        onChange && onChange(langCode);
        analytics.track("LanguageChange", {langCode});
        handleClose();
    }

    const getLanguageName = (langCode: string): string => {
        switch (langCode) {
            case "en":
                return ENG["language.code"];
            case "de":
                return DE["language.code"];
            default:
                return "";
        }
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {icon ? (
                <IconButton id="language_changer_icon" onClick={handleClick} color="primary">
                    <LanguageIcon />
                </IconButton>
            ) : (
                <ListItem id="language_changer" button onClick={e => handleClick(e)}>
                    <ListItemIcon>
                        <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary={translation("language.code")} />
                </ListItem>
            )}
            <Menu
                id="lang-change"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}>
                {Array.from(Messages.keys()).map((langCode: string) => (
                    <MenuItem id={`lang_${langCode}`} key={langCode} onClick={() => setLanguage(langCode)}>
                        {getLanguageName(langCode)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
