import Typography from "@material-ui/core/Typography";
import React, {ReactNode} from "react";
import styles from "./Formatters.module.css";
import clsx from "clsx";

export function Title(props: {value: string | ReactNode}) {
    return (
        <Typography variant="h6" gutterBottom className={clsx(styles.title, styles.titleSpacing)}>
            {props.value}
        </Typography>
    );
}

export function Description(props: {value: string | ReactNode}) {
    return (
        <Typography variant="body2" gutterBottom className={clsx(styles.description, styles.titleSpacing)}>
            {props.value}
        </Typography>
    );
}

export function HelperText(props: {value: string | ReactNode}) {
    return (
        <Typography variant="caption" display="block" gutterBottom>
            <i>*({props.value})</i>
        </Typography>
    );
}
