import React, {useContext, useEffect, useState} from "react";
import {CircularProgress, Dialog, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {translation} from "../i18n";
import {AppContext} from "../AppContextProvider";

export default function ServerDisconnected() {
    const [serverOffline, setServerOffline] = useState(false);
    const {connected} = useContext(AppContext);

    useEffect(() => {
        if (connected) {
            setServerOffline(false);
        }

        const timer = setTimeout(() => {
            if (!connected) {
                setServerOffline(true);
            }
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, [connected]);

    if (serverOffline) {
        return (
            <Dialog open={serverOffline}>
                <Grid container direction="column" justify="center" alignItems="center" style={{padding: 24}}>
                    <CircularProgress size="4em" thickness={2} />
                    <Typography variant="h3">{translation("title.server_disconnected")}</Typography>
                    <Typography variant="subtitle1">{translation("label.wait")}</Typography>
                </Grid>
            </Dialog>
        );
    } else {
        return <></>;
    }
}
