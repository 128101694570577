import React, {useContext} from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {translation} from "../../i18n";
import {format} from "date-fns";
import {AppSettings} from "../../AppSettingsProvider";
import {Chip} from "@material-ui/core";

export default function SessionDate(props: {startTime: number}) {
    const {
        Global: {timeFormat},
    } = useContext(AppSettings);
    const {startTime} = props;

    return (
        <Chip
            id="review_session_duration"
            size="small"
            className="score"
            icon={<AccessTimeIcon />}
            label={(startTime === 0 && translation("label.not_started")) || format(new Date(startTime), timeFormat)}
            variant="outlined"
            style={{border: "none"}}
        />
    );
}
