import {Value} from "../types/settingsTypes";
import React from "react";
import {CustomKeyFormatter, Keys} from "./CustomKeyFormatter";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

export function FormatterColor<T extends Value>(props: {
    value: T;
    selectables: Keys[];
    setValue: (value: T) => void;
    name: string;
}) {
    const {value, selectables, setValue, name} = props;

    return (
        <CustomKeyFormatter
            {...{value, selectables, setValue, name}}
            translate={true}
            renderIcon={color => <FiberManualRecordIcon style={{color: color}} />}
        />
    );
}
