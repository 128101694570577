import React, {MouseEvent, useContext, useMemo} from "react";
import DateFnsUtils from "@date-io/date-fns";
import {Backdrop, Badge, CircularProgress, Grid} from "@material-ui/core";
import styles from "./Review.module.css";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {esaDateToDate} from "../../common/utils";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {Gun} from "../../icons/generated/warfare/filled";
import {ReviewContext} from "../ReviewContextProvider";
import {AppSettings} from "../../AppSettingsProvider";
import {format} from "date-fns";
import {EsaDate} from "../../../api/generated/esa";
import {useIntl} from "react-intl";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";

export function ReviewCalendar() {
    const {selectedDate, setSelectedDate, sessionDays, setVisibleDate, isLoading} = useContext(ReviewContext);

    return <TrainingSessionCalendar {...{selectedDate, setSelectedDate, sessionDays, setVisibleDate, isLoading}} />;
}

function calendarLocale(locale: string) {
    if (locale === "en") return enLocale;
    else if (locale === "de") return deLocale;
    else throw new Error(`Unknown calendar locale ${locale}`);
}

export function TrainingSessionCalendar(props: {
    selectedDate: EsaDate;
    setSelectedDate: Setter<EsaDate>;
    sessionDays: Set<number>;
    setVisibleDate: Setter<MaterialUiPickersDate>;
    isLoading: boolean;
}) {
    const {
        Global: {dateFormat},
    } = useContext(AppSettings);
    const {selectedDate, setSelectedDate, sessionDays, setVisibleDate, isLoading} = props;
    const {locale} = useIntl();

    function noop(e: MouseEvent) {
        e.stopPropagation();
    }

    function renderDay(
        day: MaterialUiPickersDate,
        selectedDate: MaterialUiPickersDate,
        dayInCurrentMonth: boolean,
        dayComponent: JSX.Element,
    ): JSX.Element {
        if (day && !sessionDays.has(day.getDate())) {
            return <div onClick={noop}>{React.cloneElement(dayComponent, {disabled: true})}</div>;
        }

        const hasSessions = dayInCurrentMonth && day && sessionDays.has(day.getDate());
        const isSelected = selectedDate?.toString() === day?.toString();

        return !hasSessions ? (
            dayComponent
        ) : (
            <div>
                <Badge color="secondary" badgeContent={<Gun style={{fontSize: "1.2em"}} />}>
                    <div className={isSelected ? styles.dayAvailable : ""}>{dayComponent}</div>
                </Badge>
            </div>
        );
    }

    const setSelectedEsaDate = useMemo(
        () => (date: MaterialUiPickersDate) =>
            date && setSelectedDate({day: date.getDate(), month: date.getMonth(), year: date.getFullYear()}),
        [setSelectedDate],
    );

    function formatDisciplinePicker(date: MaterialUiPickersDate, dateFormat: string) {
        return format(date ?? new Date(), dateFormat);
    }

    return (
        <Grid container justify="center" alignItems="center" style={{height: "100%"}}>
            <Backdrop open={isLoading} style={{zIndex: 99}}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={calendarLocale(locale)}>
                <div className={styles.calendar} id="calendar_component">
                    <DatePicker
                        variant="static"
                        openTo="date"
                        disableToolbar
                        format={dateFormat}
                        value={esaDateToDate(selectedDate)}
                        onChange={setSelectedEsaDate}
                        onMonthChange={setVisibleDate}
                        labelFunc={date => formatDisciplinePicker(date, dateFormat)}
                        renderDay={renderDay}
                    />
                </div>
            </MuiPickersUtilsProvider>
        </Grid>
    );
}
