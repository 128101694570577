/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from "../runtime";
import {MeasurementUnit, MeasurementUnitFromJSON, MeasurementUnitToJSON} from "./";

/**
 *
 * @export
 * @interface Weapon
 */
export interface Weapon {
    /**
     *
     * @type {string}
     * @memberof Weapon
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof Weapon
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Weapon
     */
    type: string;
    /**
     *
     * @type {string}
     * @memberof Weapon
     */
    category: string;
    /**
     *
     * @type {string}
     * @memberof Weapon
     */
    displayCaliber?: string;
    /**
     *
     * @type {number}
     * @memberof Weapon
     */
    caliberMm: number;
    /**
     *
     * @type {number}
     * @memberof Weapon
     */
    caliberInch: number;
    /**
     *
     * @type {MeasurementUnit}
     * @memberof Weapon
     */
    measurementUnit: MeasurementUnit;
    /**
     *
     * @type {number}
     * @memberof Weapon
     */
    chsGain: number;
    /**
     *
     * @type {number}
     * @memberof Weapon
     */
    nchsGain: number;
    /**
     *
     * @type {number}
     * @memberof Weapon
     */
    nchsTimeout: number;
    /**
     *
     * @type {number}
     * @memberof Weapon
     */
    nchsThreshold: number;
}

export function WeaponFromJSON(json: any): Weapon {
    return WeaponFromJSONTyped(json, false);
}

export function WeaponFromJSONTyped(json: any, ignoreDiscriminator: boolean): Weapon {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json["id"],
        name: json["name"],
        type: json["type"],
        category: json["category"],
        displayCaliber: !exists(json, "displayCaliber") ? undefined : json["displayCaliber"],
        caliberMm: json["caliberMm"],
        caliberInch: json["caliberInch"],
        measurementUnit: MeasurementUnitFromJSON(json["measurementUnit"]),
        chsGain: json["chsGain"],
        nchsGain: json["nchsGain"],
        nchsTimeout: json["nchsTimeout"],
        nchsThreshold: json["nchsThreshold"],
    };
}

export function WeaponToJSON(value?: Weapon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        type: value.type,
        category: value.category,
        displayCaliber: value.displayCaliber,
        caliberMm: value.caliberMm,
        caliberInch: value.caliberInch,
        measurementUnit: MeasurementUnitToJSON(value.measurementUnit),
        chsGain: value.chsGain,
        nchsGain: value.nchsGain,
        nchsTimeout: value.nchsTimeout,
        nchsThreshold: value.nchsThreshold,
    };
}
