import React, {ReactNode, useContext, useEffect, useMemo, useState} from "react";
import SwipeableViews from "react-swipeable-views";
import {FormControlLabel, Grid, Switch, Tab, Tabs, Typography} from "@material-ui/core";
import {HitTable} from "./live-scoring/HitTable";
import {IconWithLabel} from "../common/IconWithLable";
import {Aim, Gun} from "../icons/generated/warfare/filled";
import {TabPanel} from "../common/TabPanel";
import {SaveComponentConfigCallback, ScreenElementConfig} from "../LayoutManager";
import {Hit, HitControllerApi, Phase, PhaseType, Target, WebSocketMessage} from "../../api/generated/esa";
import {Assignment} from "@material-ui/icons";
import TextWithIcon from "../common/TextWithIcon";
import {AppContext} from "../AppContextProvider";
import {translatedString, translatedText} from "../i18n";
import {getMPI as MPI} from "./target/functions/getMPI";
import {convertMM} from "./live-scoring/functions/convertMM";
import {Units} from "./live-scoring/components/Edit";
import {AppSettings} from "../AppSettingsProvider";
import {useIntl} from "react-intl";
import {NotificationContext} from "../NotificationContextProvider";
import TargetControl from "../target/TargetControl";

export interface LomahScreenElementConfig extends ScreenElementConfig {
    lomah: DeviceId;
    view: "Table" | "Target";
    tabs: boolean;
}

const defaultConfig = {
    lomah: -1,
    view: "Table",
    tabs: false,
};

export interface ContainerProps {
    id: string;
    setHeader: (id: string, header: ReactNode | String) => void;
}

export interface LomahProps extends ContainerProps {
    saveConfig: SaveComponentConfigCallback;
    config: LomahScreenElementConfig;
}

export interface TargetProps {
    onSelect?: (id: string) => void;
    selected?: string;
    hits: Hit[];
    phase: Phase;
    type?: PhaseType;
    controls?: boolean;
    arrows?: boolean;
    openSeries: number;
    setOpenSeries: Setter<number>;
    allSeriesOpen?: boolean;
}

export interface TableProps extends TargetProps {}

const lomahsApi = new HitControllerApi();

/**
 * @deprecated TODO this should be removed
 */
export default function Lomah(props: LomahProps) {
    const {setHeader, config, saveConfig, id} = props;
    const {phase, analytics, webSocket} = useContext(AppContext);
    const {maxShots, series} = phase;
    const {view, tabs} = config ?? defaultConfig;
    const {notify} = useContext(NotificationContext);
    const [hits, setHits] = useState<Hit[]>([] as Hit[]);
    const {
        Global: {sounds, units},
        Components: {
            Lomah: {mpi, precision},
        },
    } = useContext(AppSettings);
    const [tableSeries, setTableSeries] = useState(true);
    const seriesAvailable = phase.series > 1;
    const hitsInSeries = phase.series > 1 ? maxShots / series : 0;
    const displayMPIX = convertMM(MPI(hits, "x", phase), units as Units, precision);
    const displayMPIY = convertMM(MPI(hits, "y", phase), units as Units, precision);
    const intl = useIntl();
    const [openSeries, setOpenSeries] = useState(0);

    const target: Target = {
        name: "0_hs_Gewehr50m0_hw_UHR",
        targetImage: "generated",
        targetRadius: 77.2,
        targetCardWidth: 550,
        targetCardHeight: 550,
        ringStep: 8.0,
        scoreOnFirstRing: 1.0,
        innerTenRadius: 2.5,
        disableInnerTen: false,
        weaponType: "Unterhebelrepetierer (.45)",
        defaultCaliber: "0.45",
        defaultCaliberMm: 11.63,
        bullsEye: 3.6,
        dpmX: 1,
        dpmY: 1,
        hitZones: false,
        includeNinthRingNumbering: false,
        whiteCenter: true,
    };

    function avoidTargetRerender() {
        return (
            <TargetControl
                onSelect={() => {
                    // TODO setSelected,
                }}
                caliberMm={target.defaultCaliberMm}
                {...{
                    hits,
                    series: phase.series,
                    maxShots: phase.maxShots,
                    openSeries,
                    setOpenSeries,
                    target,
                }}
            />
        );
    }

    // eslint-disable-next-line
    const targetMemo = useMemo(avoidTargetRerender, [hits]);

    useEffect(() => {
        analytics.track("HitTable.SeriesSwitch", {state: tableSeries ? "on" : "off"});
    }, [tableSeries, analytics]);

    useEffect(() => {
        lomahsApi.getHits({phase: phase.id}).then(it => setHits(it.sort((a, b) => b.timeStamp - a.timeStamp)));
    }, [phase.id]);

    useEffect(() => {
        function updateListener(event: MessageEvent) {
            const update: WebSocketMessage = JSON.parse(event.data);
            const hit: Hit | undefined = update.hit;

            if (hit) {
                if (hit.phaseId === phase.id) {
                    setHits(hitsUpd => {
                        const hitExist: boolean = hitsUpd.some(it => it.id === hit.id);
                        if (hitExist) {
                            const updatedHists: Hit[] = [...hitsUpd.filter(it => it.id !== hit.id), hit];
                            return updatedHists.sort((a, b) => a.timeStamp - b.timeStamp);
                        } else {
                            return [hit, ...hitsUpd];
                        }
                    });
                } else {
                    if (hits.some(it => it.id === hit.id) && hit.phaseId !== phase.id) {
                        lomahsApi
                            .getHits({phase: phase.id})
                            .then(it => setHits(it.sort((a, b) => a.timeStamp - b.timeStamp)));

                        notify("success", translatedText("message", "hit_deleted", {hitX: hit.x, hitY: hit.y}));
                    }
                }
            }
        }

        webSocket.addEventListener("message", updateListener);

        return function cleanup() {
            webSocket.removeEventListener("message", updateListener);
        };
    }, [webSocket, setHits, phase, notify, hits, view, sounds]);

    useEffect(() => {
        function formatTotalScore() {
            if (phase.fullRingScore) {
                return (
                    <Typography component="span" color="primary">
                        <b id="total">{phase.fullRingScore}</b> ({phase.decimalScore.toFixed(1)})
                    </Typography>
                );
            } else {
                return (
                    <Typography component="span" color="primary">
                        <b id="total">{phase.decimalScore.toFixed(1)}</b>
                    </Typography>
                );
            }
        }

        switch (view) {
            case "Target":
                setHeader(
                    id,
                    <TextWithIcon startIcon={<Gun />}>
                        {translatedString(intl, "discipline", phase.disciplineName)}
                    </TextWithIcon>,
                );
                break;
            case "Table":
                setHeader(
                    id,
                    hits.length > 0 ? (
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item xs>
                                {translatedText("component.hits_table_score", "header", {
                                    score: formatTotalScore(),
                                })}
                                {mpi &&
                                    translatedText("component.hits_table_score", "header_mpi", {
                                        mpiX: displayMPIX,
                                        mpiY: displayMPIY,
                                    })}
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-end">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={!seriesAvailable}
                                                checked={tableSeries}
                                                color="primary"
                                                onChange={() => setTableSeries(!tableSeries)}
                                            />
                                        }
                                        label={translatedText("component.hits_table", "series_switch")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <div>{translatedText("component.hits_table", "header_empty")}</div>
                    ),
                );
        }
    }, [
        id,
        setHeader,
        view,
        phase,
        hits,
        tableSeries,
        seriesAvailable,
        displayMPIX,
        displayMPIY,
        intl,
        mpi,
        precision,
    ]);

    function getComponent(name: "Table" | "Target", hits: Hit[]) {
        if (name === "Table") {
            return (
                <>
                    <HitTable
                        onSelect={() => {
                            // todo setSelected
                        }}
                        {...{
                            phase,
                            hits,
                            tableSeries,
                            hitsInSeries,
                            openSeries,
                            setOpenSeries,
                        }}
                        startOnFirstShoot={!phase.started}
                    />
                </>
            );
        } else {
            return targetMemo;
        }
    }

    const deviceHits = hits.filter(h => h.deviceId === phase.deviceId);

    const tab = view === "Table" ? 1 : 0;

    function changeTab(index: number) {
        const view = index === 1 ? "Table" : "Target";
        saveConfig(id, {...config, view});
    }

    return (
        <div style={{height: `calc(100% - 40px)`, width: "100%"}}>
            {tabs ? (
                <>
                    <Tabs
                        value={tab}
                        onChange={(e: React.ChangeEvent<{}>, val: number) => changeTab(val)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        style={{height: "10%"}}>
                        <Tab id="buttonTarget" label={<IconWithLabel title="Target" customIcon={Aim} />} />
                        <Tab id="buttonTable" label={<IconWithLabel title="Table" icon={<Assignment />} />} />
                    </Tabs>
                    <SwipeableViews axis="x" index={tab} style={{height: "75%"}} onChangeIndex={changeTab}>
                        <TabPanel value={tab} index={0} dir="rtl">
                            {getComponent("Target", deviceHits)}
                        </TabPanel>
                        <TabPanel value={tab} index={1} dir="rtl">
                            {getComponent("Table", deviceHits)}
                        </TabPanel>
                    </SwipeableViews>
                </>
            ) : (
                getComponent(view, deviceHits)
            )}
        </div>
    );
}
