import {Step} from "react-joyride";
import React from "react";
import {Typography, TypographyProps} from "@material-ui/core";
import styles from "./rides/Ride.module.css";

export function Hide(props: TypographyProps) {
    return (
        <Typography {...props} className={styles.hide}>
            {props.children}
        </Typography>
    );
}

export interface EnhancedStep extends Step {
    /**
     * For automated next functionality.
     * Await will expect id to be clicked. If current target cant be wrapped with id, id can be included in parent element.
     * BE CAREFUL, if parent id will be to large, it might lead to unexpected states.
     * - Automation process can be found in JoyrideContext.tsx
     */
    awaitClick?: boolean;
    /**
     * For automated next functionality.
     * If next element expected to be animated, you can add selector that will switch after element finishes animations.
     * - Automation process can be found in JoyrideContext.tsx
     */
    animationElement?: string;
    /**
     * Target always must be string.
     * Automated next functionality will be broken if HTMLElement will be provided.
     */
    target: string;
}

export function nextAfterClick(id: string, selector?: string) {
    return {
        target: "#" + id,
        awaitClick: true,
        spotlightClicks: true,
        animationElement: selector,
    };
}
