/* tslint:disable */
/* eslint-disable */
/**
 * ESA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ConstantsNumberEsa {
    LANE_RESERVATION_TIMEOUT_SEC = 30,
    MAX_SHOTS_PER_PHASE = 150,
    UNLIMITED_DURATION = 14400000,
}

export function ConstantsNumberEsaFromJSON(json: any): ConstantsNumberEsa {
    return ConstantsNumberEsaFromJSONTyped(json, false);
}

export function ConstantsNumberEsaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConstantsNumberEsa {
    return json as ConstantsNumberEsa;
}

export function ConstantsNumberEsaToJSON(value?: ConstantsNumberEsa | null): any {
    return value as any;
}
