import React, {useState} from "react";
import {Chip, Grid, Slider} from "@material-ui/core";
import {Value} from "../types/settingsTypes";

export function FormatterSlider<T extends Value>(props: {
    value: number;
    setValue: (value: T) => void;
    name: string;
    step?: number;
    max?: number;
    min?: number;
}) {
    const {value, setValue, max, step, min} = props;
    const [amount, setAmount] = useState(value);
    const handleChange = (event: any, newValue: number | number[]) => {
        setAmount(newValue as number);
    };
    const handleChangeCommit = (event: any, newValue: number | number[]) => {
        setValue(newValue as T);
    };
    return (
        <Grid container spacing={2} style={{width: 250, marginLeft: 5}} alignItems="center" alignContent="center">
            <Chip
                style={{marginBottom: "5px"}}
                variant="outlined"
                size="small"
                label={amount.toFixed(step && step < 1 ? 1 : 0)}
            />
            <Grid item xs>
                <Slider
                    step={step ? step : 1}
                    value={amount}
                    onChange={handleChange}
                    onChangeCommitted={handleChangeCommit}
                    min={min ? min : 0}
                    max={max ? max : 100}
                    valueLabelDisplay="auto"
                />
            </Grid>
        </Grid>
    );
}
