import {Selectables, Value} from "../types/settingsTypes";
import React from "react";
import DropdownButton from "../../../common/DropdownButton";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./styles.module.css";
import {translationOrName} from "../../../i18n";

export function FormatterSelect<T extends Value>(props: {
    value: T;
    selectables: Selectables;
    setValue: (value: T) => void;
    name: string;
}) {
    const items = props.selectables as string[];

    function translatedItem(itemId: any) {
        return translationOrName("settings", props.name + "." + itemId);
    }

    return (
        <>
            <DropdownButton others={{variant: "outlined"}} source={translatedItem(props.value)} showDropdownIcon>
                {items.map(item => (
                    <MenuItem key={item} onClick={() => props.setValue(item as T)}>
                        {translatedItem(item)}
                    </MenuItem>
                ))}
            </DropdownButton>
            <span className={styles.spacer} />
        </>
    );
}
