import * as yup from "yup";
import {ConstantsNumberCommon, User} from "../../api/generated/esa";

const year = new Date().getFullYear();

export const userSchema = yup.object().shape({
    yearOfBirth: yup
        .number()
        .typeError("validation_number_invalid_type")
        .min(year - 85, "validation_year_too_old")
        .max(year - 14, "validation_year_too_young")
        .nullable(true),
    userName: yup
        .string()
        .required("validation_username_required")
        .max(ConstantsNumberCommon.USERNAME_MAX_LENGTH, "validation_username_too_long")
        .test("uniqueName", "validation_username_exists", function test(value) {
            return !this.options.context?.users.find(
                (schemaUser: User) =>
                    schemaUser.name.toLowerCase() === value?.toLowerCase() &&
                    schemaUser.name.toLowerCase() !== this.options.context?.name.toLowerCase(),
            );
        }),
    password: yup
        .string()
        .max(ConstantsNumberCommon.PASSWORD_MAX_LENGTH, "validation_password_too_long")
        .when("$exist", {
            is: (exist: boolean) => exist,
            then: yup.string().required("validation_password_required"),
            otherwise: yup.string(),
        }),
});
