import React, {ReactNode, useContext, useEffect} from "react";
import "../App.css";
import Typography from "@material-ui/core/Typography";
import {AppContext} from "./AppContextProvider";
import {translation} from "./i18n";
import {Grid} from "@material-ui/core";

export function calculateAverageScore(score: number, currentShots: number) {
    return score ? Number(score / currentShots).toFixed(1) : "0";
}

export default function Score(props: {id: string; setHeader: (id: string, header: ReactNode | String) => void}) {
    const {
        phase: {decimalScore, fullRingScore, currentShots},
    } = useContext(AppContext);
    const {id, setHeader} = props;

    useEffect(() => {
        fullRingScore &&
            setHeader(
                id,
                <span>
                    {translation("label.decimal")} {decimalScore}
                </span>,
            );
    }, [fullRingScore, decimalScore, id, setHeader]);

    useEffect(() => {
        function Avg(score: number) {
            return (
                <Typography component="span">
                    {translation("label.average")}
                    <Typography component="span" color="primary">
                        <b> {calculateAverageScore(score, currentShots)} </b>
                    </Typography>
                </Typography>
            );
        }

        fullRingScore
            ? setHeader(
                  id,
                  <>
                      {translation("label.decimal")} {decimalScore} / {Avg(fullRingScore)}{" "}
                  </>,
              )
            : setHeader(id, <> {Avg(decimalScore)} </>);
    }, [fullRingScore, decimalScore, id, setHeader, currentShots]);

    return (
        <Grid container justify="center" alignItems="center" style={{height: "calc( 100% - 40px)"}}>
            {fullRingScore ? (
                <Typography color="primary" variant="h3">
                    {fullRingScore}
                </Typography>
            ) : (
                <Typography color="primary" variant="h2">
                    {decimalScore}
                </Typography>
            )}
        </Grid>
    );
}
